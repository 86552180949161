// eslint-disable-next-line no-restricted-imports
import { useBreakpoints as _useBreakpoints } from '@vueuse/core';
import tailwindConfig from '@teamwork/lightspeed-designsystem-tokens/tailwind';
import resolveConfig from 'tailwindcss/resolveConfig';

const config = resolveConfig(tailwindConfig);
const screens = Object.fromEntries(
  Object.entries(config.theme.screens).map(([name, value]) => [name, parseInt(value, 10)]),
);
export function useBreakpoints() {
  return _useBreakpoints(screens);
}
