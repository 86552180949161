<script setup>
import { FeedbackButton } from '@/module/feedback';
import { useLegacyFrameOffset, LegacyViewSwitch } from '@/module/legacy';
import { useI18n } from '@/util';
import { useFeedbackDataIdentifier } from '@/module/feedback/useFeedbackDataIdentifier';

const breadcrumbs = readLsBreadcrumbs();
const el = shallowRef(null);
useLegacyFrameOffset(el);
const feedbackDataIdentifier = useFeedbackDataIdentifier();
const { t } = useI18n();

useLsBreadcrumbs(
  [
    {
      label: t('Home'),
      path: '/home',
      icon: 'lsi-home',
      dataIdentifier: 'home',
    },
  ],
  'prepend',
);
</script>

<template>
  <div
    ref="el"
    class="flex h-8 min-w-0 items-center justify-between gap-4 border-0 border-b border-solid border-b-subtle px-5"
  >
    <LscBreadcrumbs size="sm" class="flex-1" :items="breadcrumbs" :expandedOverflow="false" />
    <div class="flex shrink-0 items-center gap-3">
      <FeedbackButton v-if="feedbackDataIdentifier" :data-identifier="feedbackDataIdentifier" size="sm" />
      <LegacyViewSwitch class="ml-auto" />
    </div>
  </div>
</template>
