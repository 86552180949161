import { useCurrentAccount } from '../account/useCurrentAccount';
import { usePricePlan } from '../account/usePricePlan';
import { usePermissions } from '../permissions/usePermissions';
import { useCurrentUser } from '../user/useCurrentUser';
import { useNowByMinute } from '@/util';
import { usePlanTrial } from '@/module/planTrial';

/**
 * Collect all helpers for targetting different cohorts into one file to run experiments, and to allow in-app targetting
 * after experiments are complete and experiment code is removed.
 */
export function useCohort() {
  const user = useCurrentUser();
  const account = useCurrentAccount();
  const { isOwnerAdmin, isSiteAdmin, isSiteOwner, isCollaborator, isClientUser, isInOwnerCompany } = usePermissions();
  const { pricePlanId, isPlanFree, isPlanMaxProjects, isPlanPerUser, isInFreeTrial, isPaid } = usePricePlan();
  const { isTrialExpired } = usePlanTrial();
  const now = useNowByMinute();

  /*
   * Constants
   */

  const PM_ROLES = [1, 2, 3, 4];
  const WORKER_BEE_ROLES = [5, 6];

  const GROW_PLAN_IDS = [48, 65, 69]; // sometimes [22, 42, 48, 65, 69]
  const DELIVER_PLAN_IDS = [21, 41, 47, 64];
  const SCALE_PLAN_IDS = [51, 66];

  /**
   * Company Size IDs
   * 1 - 1-5
   * 2 - 6-10
   * 3 - 11-20
   * 4 - 21-35
   * 5 - 36-50
   * 6 - 51-200
   * 7 - 201-500
   * 8 - 501+
   */
  const COMPANY_SIZE_ABOVE_TEN_IDS = [3, 4, 5, 6, 7, 8];
  const COMPANY_SIZE_ABOVE_TWENTY_IDS = [4, 5, 6, 7, 8];

  /*
   * Computed
   */

  const isContact = computed(() => user.value?.userType === 'contact');
  const isNormalUser = computed(() => !isContact.value && !isClientUser.value);

  const isICP = computed(() => Boolean(account.value?.isICP));
  const isCompanySizeAboveTen = computed(() =>
    COMPANY_SIZE_ABOVE_TEN_IDS.includes(Number(account.value?.companySizeId)),
  );
  const isCompanySizeAboveTwenty = computed(() =>
    COMPANY_SIZE_ABOVE_TWENTY_IDS.includes(Number(account.value?.companySizeId)),
  );

  const isCompanyRolePM = computed(() => PM_ROLES.includes(user.value?.companyRoleId));
  const isCompanyRoleWorkerBee = computed(() => WORKER_BEE_ROLES.includes(user.value?.companyRoleId));

  const isPlanGrow = computed(() => GROW_PLAN_IDS.includes(pricePlanId.value));
  const isPlanDeliver = computed(() => DELIVER_PLAN_IDS.includes(pricePlanId.value));
  const isPlanScale = computed(() => SCALE_PLAN_IDS.includes(pricePlanId.value));

  const startedMoreThanThreeMonthsAgo = computed(() => now.value.diff(account.value.dateSignedUp).as('months') > 3);
  const isFirstLogin = computed(() => user.value?.loginCount <= 1);
  const firstLoginMoreThan30DaysAgo = computed(() => {
    if (isSiteOwner.value) {
      return Math.abs(account.value?.dateSignedUp?.diffNow('days').days) > 30;
    }
    return Math.abs(user.value?.userInviteAcceptedDate?.diffNow('days').days) > 30;
  });
  const isFirstLoginDay = computed(() => {
    if (isSiteOwner.value) {
      return Math.abs(account.value?.dateSignedUp?.diffNow('days').days) < 2;
    }
    return Math.abs(user.value?.userInviteAcceptedDate?.diffNow('days').days) < 2;
  });

  /*
   * Return
   */

  return {
    /** `currentAccount.isICP` - whether account is in one of the ICP industry categories */
    isICP,
    isCompanySizeAboveTen,
    isCompanySizeAboveTwenty,

    isCompanyRolePM,
    isCompanyRoleWorkerBee,

    isPlanGrow,
    isPlanDeliver,
    isPlanScale,
    isPlanPerUser,
    isPlanMaxProjects,
    isPlanFree,

    isPaid,
    isInFreeTrial,
    isTrialExpired,

    isNormalUser,
    isClientUser,
    isContact,
    isCollaborator,
    isOwnerAdmin,
    isSiteAdmin,
    isSiteOwner,
    isInOwnerCompany,

    isFirstLogin,
    isFirstLoginDay,
    startedMoreThanThreeMonthsAgo,
    firstLoginMoreThan30DaysAgo,
  };
}
