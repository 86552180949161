import { generateUuid } from '@/util';

const symbol = Symbol('useBreadcrumbs');
const readSymbol = Symbol('readBreadcrumbs');

/**
 *
 * @typedef {object} LsBreadcrumb
 * @property {string} id - unique id for the breadcrumb
 * @property {string} label - the text to display
 * @property {string} path - the path to navigate to when clicked
 * @property {string=} icon - the icon to display (optional)
 * @property {string=} iconColor - the color of the icon (optional)
 * @property {string=} tooltip - the tooltip to display (optional)
 * @property {string=} dataIdentifier - the data identifier to use for the breadcrumb (optional)
 * @property {number=} truncationWidth - the width at which to truncate the breadcrumb (optional)
 */

/**
 * Makes sure that the breadcrumb has an id and a dataIdentifier
 */
function normalizeBreadcrumb(dataIdentifierPrefix) {
  return (breadcrumb) => {
    const id = breadcrumb.id ?? generateUuid();
    const dataIdentifier = `${dataIdentifierPrefix}-${breadcrumb.dataIdentifier ?? id}`;
    return {
      ...breadcrumb,
      id,
      dataIdentifier,
    };
  };
}

export function provideLsBreadcrumbs({ dataIdentifierPrefix = 'breadcrumb' } = {}) {
  const prepend = shallowRef([]);
  const main = shallowRef([]);
  const append = shallowRef([]);
  const full = shallowRef([]);

  const breadcrumbs = computed(
    () =>
      full.value.at(-1)?.value ??
      []
        .concat(
          prepend.value.map((s) => s.value),
          main.value.map((s) => s.value),
          append.value.map((s) => s.value),
        )
        .filter(Boolean)
        .flat(),
  );

  provide(symbol, {
    prepend,
    main,
    append,
    full,
    dataIdentifierPrefix,
  });
  provide(readSymbol, breadcrumbs);
}

export function readLsBreadcrumbs() {
  return inject(readSymbol);
}

/**
 * Adds a segment to list of breadcrumbs
 * The segment is removed automatically when the component is unmountd.
 *
 * @param {Array<Breadcrumb|import('vue').Ref<Breadcrumb>>} breadcrumbs The breadcrumbs to add.
 * @param {'prepend'|'main'|'append'|'full'} location Where to add the new segment: "prepend", "main" (default), "append", "full".
 * @param {Boolean} append If false (default), then append the new segment, otherwise prepend it.
 */
export function useLsBreadcrumbs(breadcrumbs = [], location = 'main') {
  const { prepend, main, append, full, dataIdentifierPrefix } = inject(symbol);

  let segments;

  if (location === 'prepend') {
    segments = prepend;
  }
  if (location === 'main') {
    segments = main;
  }
  if (location === 'append') {
    segments = append;
  }
  if (location === 'full') {
    segments = full;
  }

  // By creating a new computed here we can guarantee
  // that we'll remove the correct segment in `onUnmounted`.
  const segment = computed(() => {
    const breadcrumbsSegment = unref(breadcrumbs) ?? [];
    return breadcrumbsSegment.map(normalizeBreadcrumb(dataIdentifierPrefix));
  });

  segments.value = segments.value.concat([segment]);
  onUnmounted(() => {
    // eslint-disable-next-line vue/no-ref-as-operand
    segments.value = segments.value.filter((s) => s !== segment);
  });
}
