import { generateUuid } from '@/util';

const symbol = Symbol('LsToasts');
const defaultMaxToasts = 5;

/**
 * @typedef {object} Toast
 * @property {string} id - A unique ID for the toast.
 * @property {"info"|"success"|"warning"|"critical"|"error"} type - The type of toast.
 * @property {number} duration - The duration of the toast in ms.
 * @property {string} title - The title of the toast.
 * @property {string=} message  - The optional addition message of the toast.
 * @property {string=} icon - The icon of the toast. Will default to the type icon.
 * @property {() => void} action - The action a user can perform on the toast.
 * @property {string} actionText - The text of the action.
 * @property {() => void} onRemove - The callback when the toast is removed.
 * */

/**
 * @typedef {Toast|(string) => void} ToastParams
 */

/**
 * @returns {Toast}
 */
function defaultToast() {
  return {
    type: 'info',
    duration: 5000,
    id: generateUuid(),
  };
}

/**
 *
 * @param {ToastParams} toast
 * @returns {Toast}
 */
function normalizeToast(toast) {
  if (typeof toast === 'string') {
    return {
      ...defaultToast(),
      title: toast,
    };
  }
  return {
    ...defaultToast(),
    ...toast,
  };
}

function LsToast({ maxToasts = defaultMaxToasts } = {}) {
  /**
   * @type {import('vue').ShallowRef<Toast[]>}
   */
  const toasts = ref([]);

  /**
   *
   * @param {Toast} toast
   * @param {() => void} removeCallback
   */
  function removeToast(toast) {
    toasts.value = toasts.value.filter(({ id }) => toast.id !== id);
    toast.onRemove?.();
  }
  /**
   * Creates a toast, please use the other methods instead of this one
   * @param {ToastParams} toast
   * @returns {Toast}
   */
  function createToast(_toast) {
    const normalizedToast = normalizeToast(_toast);

    if (import.meta.env.DEV) {
      if (
        (normalizedToast.action && !normalizedToast.actionText) ||
        (!normalizedToast.action && normalizedToast.actionText)
      ) {
        // eslint-disable-next-line no-console
        console.error('toast.action and toast.actionText must be provided together');
      }
    }

    // Add remove toast to the end of the action
    if (normalizeToast.action) {
      normalizedToast.action = () => {
        normalizedToast.action();
        removeToast(normalizedToast);
      };
    }

    // Add a timeoutId to the toast
    normalizedToast.timeoutId = setTimeout(() => {
      removeToast(normalizedToast);
    }, normalizedToast.duration);

    // keep the last 5 toasts
    const newToasts = [...toasts.value, normalizedToast].slice(-maxToasts);
    toasts.value = newToasts;

    return normalizedToast;
  }
  /**
   * Creates a success toast
   * @param {ToastParams} toast
   */
  function success(toast) {
    return createToast({
      ...normalizeToast(toast),
      type: 'success',
    });
  }

  /**
   * Creates a warning toast
   * @param {ToastParams} toast
   */
  function warning(toast) {
    return createToast({
      ...normalizeToast(toast),
      type: 'warning',
    });
  }

  /**
   * Creates an info toast
   * @param {ToastParams} toast
   */
  function info(toast) {
    return createToast({
      ...normalizeToast(toast),
      type: 'info',
    });
  }

  /**
   * Creates a critical toast
   * @param {ToastParams} toast
   */
  function critical(toast) {
    return createToast({
      ...normalizeToast(toast),
      type: 'critical',
    });
  }

  /**
   * This is deprecated, please use toast.critical instead
   * @deprecated
   * @param {ToastParams} toast
   */
  function error(toast) {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.info('toast.error is deprecated, use toast.critical instead');
    }
    return critical(toast);
  }

  /**
   * Clears a toasts timeout
   * @param {Toast} toast
   */
  function pauseToast(toast) {
    clearTimeout(toast.timeoutId);
  }

  /**
   * Restart a toasts timeout
   * @param {Toast} toast
   */
  function resumeToast(toast) {
    // eslint-disable-next-line no-param-reassign
    toast.timeoutId = setTimeout(() => {
      removeToast(toast);
    }, toast.duration);
  }

  return {
    toasts,
    removeToast,
    pauseToast,
    resumeToast,
    success,
    error,
    warning,
    info,
    critical,
  };
}

export const lsToastPlugin = {
  install(app, options) {
    app.provide(symbol, LsToast(options));
  },
};

/**
 *
 * @returns {ReturnType<lsToastPlugin>}
 */
export function useLsToast() {
  const toast = inject(symbol);

  if (!toast) {
    throw new Error('No toast provider!');
  }

  return toast;
}
