<script setup>
import { useI18n, sanitize } from '@/util';
import { useKnowledgeBaseArticleByCategoryAndSlugLoader, usePendo, useExperimentA49 } from '@/api';
import HelpCenterDrawerMenuMedium from '../HelpCenterDrawerMenuMedium.vue';
import { useHelpCenter } from '../../useHelpCenter';
import { STATE_KNOWLEDGE_BASE_ARTICLE } from '../../constants';

const props = defineProps({
  articleSlug: { type: String, required: true },
  categorySlug: { type: String, required: true },
  isRecommended: { type: Boolean, default: false },
});

const { t, formatDate } = useI18n();
const { trackPendoEvent } = usePendo();
const { pushState, resetState } = useHelpCenter();
const { EXP_A49_COMMON_METRICS: commonMetrics } = useExperimentA49();
const state = useKnowledgeBaseArticleByCategoryAndSlugLoader({
  params: { categorySlug: props.categorySlug, articleSlug: props.articleSlug },
});

const { item } = state;

const recommendedMessage = t("This is recommended reading for the area of the app you're in.");

function handleArticleClicked(article) {
  pushState(STATE_KNOWLEDGE_BASE_ARTICLE, article);
}

function onReturnClick() {
  trackPendoEvent({
    eventName: 'CONTEXT_AWARE_HELP_EVENT',
    commonMetrics,
    metadata: {
      event_action: 'return_to_help_home_clicked',
    },
  });
  resetState();
}
</script>

<template>
  <div class="m-0 h-full max-h-[inherit] overflow-y-auto p-0">
    <WidgetLoadingState :state="state">
      <div class="ml-6 mr-3 pb-6">
        <LscAlert v-if="isRecommended" :message="recommendedMessage" class="mb-4">
          <template #action>
            <LscAlertButton @click="onReturnClick">{{ t('Back to home') }}</LscAlertButton>
          </template>
        </LscAlert>

        <div class="mb-6 border-x-0 border-b border-t-0 border-solid border-bold pb-6">
          <h1 class="text-subtitle-1 text-default">{{ item.article?.title }}</h1>
          <span v-if="item.article?.updatedAt && item.article?.updatedAt?.isValid" class="mt-2 text-body-1 text-subtle">
            {{ t('Last modified on {date}', { date: formatDate(item.article?.updatedAt) }) }}
          </span>
        </div>
        <div
          :class="item.relatedArticles?.length && 'mb-6 border-x-0 border-b border-t-0 border-solid border-bold pb-6'"
        >
          <!-- eslint-disable vue/no-v-html-->
          <div
            class="articleContent mb-4 overflow-hidden text-body-1 text-default"
            v-html="sanitize(item.article?.content, { allowIframe: true })"
          />
          <!-- eslint-enable vue/no-v-html-->

          <HelpCenterDrawerMenuMedium
            :items="[
              {
                title: t('Open in our website'),
                icon: 'lsi-website',
                hoverAppendIcon: 'lsi-external-link',
                href: item.article?.canonicalURL,
              },
            ]"
          />
        </div>

        <HelpCenterDrawerMenuMedium
          v-if="item.relatedArticles?.length"
          :title="t('Related articles')"
          :items="item.relatedArticles"
          @itemClicked="
            handleArticleClicked({
              id: $event.id,
              categorySlug: $event.categorySlug,
              articleSlug: $event.articleSlug,
              title: $event.title,
            })
          "
        />
      </div>
    </WidgetLoadingState>
  </div>
</template>

<style scoped>
.articleContent :deep(table) {
  @apply bg-inherit;
}

.articleContent :deep(img:not(table img)) {
  @apply !static !mx-auto block max-w-full object-contain;
}

.articleContent :deep(table img) {
  @apply block max-w-full object-contain;
}

.articleContent :deep(td) {
  @apply overflow-x-scroll;
}

.articleContent :deep(ul) {
  @apply pl-6;
}

.articleContent :deep(ol) {
  @apply pl-6;
}

.articleContent :deep(a) {
  @apply !text-on-emphasis no-underline;
}

.articleContent :deep(iframe) {
  @apply max-w-full;
}

.articleContent :deep(.table-bordered-mobile-rw) {
  @apply hidden;
}
</style>
