<script setup>
const props = defineProps({
  panelSource: {
    // value used for pendo tracking of where modal was opened from
    type: String,
    default: '',
  },
});

const emit = defineEmits(['usersInvited']);

const UserAddYourTeamDialogPanel = defineAsyncComponent(() => import('./UserAddYourTeamDialogPanel.vue'));
</script>

<template>
  <WidgetDialog width="560px">
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <UserAddYourTeamDialogPanel v-bind="props" @usersInvited="emit('usersInvited', $event)" @close="close" />
    </template>
  </WidgetDialog>
</template>
