import { usePendo } from '@/api';

/**
 * Provides functions for tracking Pendo events related to forms.
 */
export function useFormPendoEvents() {
  const { trackPendoEvent } = usePendo();

  function track(data) {
    trackPendoEvent({
      eventName: 'FORMS_EVENT',
      commonMetrics: ['user_role', 'plan_name', 'page', 'on_trial', 'is_icp', 'is_paid'],
      metadata: {
        ...data,
      },
    });
  }

  /**
   * Track the viewing of the forms panel
   * * @param {string} formsCount
   */
  function trackFormsPanelViewed(formsCount) {
    track({
      event_action: 'forms_panel_viewed',
      event_category: 'discovery_event',
      visible_forms: formsCount,
    });
  }

  /**
   * Track the viewing of the project forms page
   * * @param {string} formsCount
   */
  function trackProjectFormsPageViewed(formsCount) {
    track({
      event_action: 'forms_page_viewed',
      event_category: 'discovery_event',
      page_type: 'project_forms',
      visible_forms: formsCount,
    });
  }

  /**
   * Track the clicking of the add form button
   */
  function trackAddFormButtonClicked() {
    track({
      event_action: 'add_form_clicked',
      event_category: 'advanced_discovery_event',
      page_type: 'project_forms',
    });
  }

  /**
   * Track the clicking of the publish form button
   */
  function trackPublishFormButtonClicked() {
    track({
      event_action: 'publish_form_clicked',
      event_category: 'advanced_discovery_event',
      page_type: 'project_forms_editor',
    });
  }

  /**
   * Track a form published activation event
   */
  function trackFormPublishedEvent(taskAssigned, conditionalLogicIncluded) {
    track({
      event_action: 'form_published',
      event_category: 'activation_event',
      page_type: 'project_forms_editor',
      task_assigned: taskAssigned,
      conditional_logic_included: conditionalLogicIncluded,
    });
  }

  /**
   * Track a form validation errorx
   * * @param {object} errors
   */
  function trackFormValidationError(errors) {
    let errorStr = '';

    if ('content.name' in errors) {
      errorStr = 'title';
    }

    if ('content.definition' in errors) {
      errorStr = errorStr.length ? (errorStr += ' | question') : 'question';
    }

    track({
      event_action: 'form_error_displayed',
      event_category: 'advanced_discovery_event',
      page_type: 'project_forms_editor',
      error_fields: errorStr, // "title", "question" or "title | question"
    });
  }

  /**
   * Track the clicking of an option in more menu of form on project forms page
   * @param {string} option
   */
  function trackFormOptionClicked(option) {
    track({
      event_action: `${option}_clicked`,
      event_category: 'advanced_discovery_event',
      page_type: 'projects_forms',
    });
  }

  /**
   * Track content added to a form
   * @param {string} contentType
   */
  function trackFormContentAdded(contentType) {
    track({
      event_action: 'form_content_added',
      event_category: 'advanced_discovery_event',
      page_type: 'project_forms_editor',
      content_type: contentType,
    });
  }

  /**
   * Track form toggled active or inactive
   * @param {string} toggleStatus
   */
  function trackFormActiveToggle(toggleStatus) {
    track({
      event_action: 'form_active_toggled',
      event_category: 'activation_event',
      page_type: 'project_forms_editor',
      toggle_type: toggleStatus,
    });
  }

  /**
   * Track the clicking of the copy link button
   */
  function trackFormCopyLinkClicked() {
    track({
      event_action: 'form_link_copied',
      event_category: 'activation_event',
      page_type: 'project_forms_editor',
    });
  }

  /**
   * Track the clicking of the form embed code copy button
   */
  function trackFormCopyEmbedCodeClicked() {
    track({
      event_action: 'form_embed_link_copied',
      event_category: 'activation_event',
      page_type: 'project_forms_editor',
    });
  }

  /**
   * Track when send responses to option selected
   */
  function trackSendResponsesToSelected() {
    track({
      event_action: 'send_responses_to_created',
      event_category: 'activation_event',
      page_type: 'project_forms_editor',
    });
  }

  /**
   * Track when conditional logic switch is toggled
   * * @param {string} toggleStatus
   */
  function trackConditionalLogicToggled(toggleStatus) {
    track({
      event_action: 'conditional_logic_toggled',
      event_category: 'advanced_discovery_event',
      page_type: 'project_forms_editor',
      toggle_type: toggleStatus,
    });
  }

  /**
   * Track the clicking of the preview button
   */
  function trackPreviewButtonClicked() {
    track({
      event_action: 'preview_clicked',
      event_category: 'advanced_discovery_event',
      page_type: 'project_forms_editor',
    });
  }

  /**
   * Track the clicking of the new to logic link
   */
  function trackNewToLogicLinkClicked() {
    track({
      event_action: 'new_to_logic_clicked',
      event_category: 'advanced_discovery_event',
      page_type: 'project_forms_editor',
    });
  }

  /**
   * Track the clicking of buttons in conditional logic modal
   * * @param {string} action
   */
  function trackConditionalLogicModalAction(action) {
    track({
      event_action: action,
      event_category: 'advanced_discovery_event',
      page_type: 'project_forms_video_modal',
    });
  }

  return {
    trackFormsPanelViewed,
    trackProjectFormsPageViewed,
    trackAddFormButtonClicked,
    trackPublishFormButtonClicked,
    trackFormPublishedEvent,
    trackFormValidationError,
    trackFormOptionClicked,
    trackFormContentAdded,
    trackFormActiveToggle,
    trackFormCopyLinkClicked,
    trackFormCopyEmbedCodeClicked,
    trackSendResponsesToSelected,
    trackConditionalLogicToggled,
    trackPreviewButtonClicked,
    trackNewToLogicLinkClicked,
    trackConditionalLogicModalAction,
  };
}
