<script setup>
defineProps({
  placeholder: {
    type: String,
    default: 'Placeholder text',
  },
  disabled: {
    type: Boolean,
    default: undefined,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
});

const modelValue = defineModel({
  type: String,
  default: '',
});

function clearText() {
  modelValue.value = '';
}
</script>

<template>
  <VTextField
    v-bind="VTextFieldFilter"
    v-model="modelValue"
    :placeholder="placeholder"
    :clearable="false"
    :prependInnerIcon="undefined"
    :disabled="disabled"
  >
    <template #prepend-inner>
      <VIcon :color="'var(--lsds-a-color-icon-subtle)'" icon="lsi-search" size="small" />
    </template>
    <template #append-inner>
      <VIcon
        v-if="modelValue && clearable"
        :color="'var(--lsds-a-color-icon-default)'"
        icon="lsi-close"
        size="small"
        @click="clearText"
      />
    </template>
  </VTextField>
</template>
