<script setup>
import { useI18n } from '@/util';
import ApiSetupShared1 from './ApiSetupShared1.vue';

const { languageCode, timeZoneName, dateFormat, timeFormat } = useI18n();
</script>

<template>
  <!-- The `key` recreates the child component when the basic localization data changes. -->
  <ApiSetupShared1 :key="`${languageCode}/${timeZoneName}/${dateFormat}/${timeFormat}`">
    <slot />
  </ApiSetupShared1>
</template>
