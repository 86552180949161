<script setup>
import { provideCurrentAccountState } from '../account/useCurrentAccount';
import { useCurrentAccountV3Loader } from '../account/useCurrentAccountV3Loader';
import { provideHandle401 } from '../base/useHandle401';
import { publicShareToken } from '../base/publicShareToken';
import { provideCurrentUserState } from '../user/useCurrentUser';
import { useCurrentUserV3Loader } from '../user/useCurrentUserV3Loader';
import ApiSetupShared2 from './ApiSetupShared2.vue';

provideHandle401();

const currentAccountState = useCurrentAccountV3Loader({
  params: {
    token: publicShareToken,
  },
});
provideCurrentAccountState(currentAccountState);

const currentUserState = useCurrentUserV3Loader({
  params: {
    token: publicShareToken,
    includeAuth: true,
  },
});
provideCurrentUserState(currentUserState);
</script>
<template>
  <ApiSetupShared2>
    <slot />
  </ApiSetupShared2>
</template>
