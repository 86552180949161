<script setup>
import { useI18n, normalizeFile, downloadFile } from '@/util';

const props = defineProps({
  files: {
    type: Array,
    required: true,
  },
  limit: {
    type: Number,
    default: 0,
  },
  variant: {
    type: String,
    default: 'default',
    validator(prop) {
      return ['default', 'compact', 'thumbnail'].includes(prop);
    },
  },
  grid: {
    type: String,
    default: '2',
  },
  selected: {
    type: Array,
    default: () => undefined,
  },
  /**
   * Whether the user can download files or not. Ususally retrieved from the `project permissions`.
   */
  canDownloadFiles: {
    type: Boolean,
    required: false,
  },
  /**
   * Whether the user can preview files or not. Ususally retrieved from the `project permissions`.
   */
  canPreviewFiles: {
    type: Boolean,
    required: false,
  },
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['update:selected', 'preview']);

const { t } = useI18n();

function onDownloadFile(file) {
  downloadFile(file);
}

// local pagination
const pageSize = shallowRef(props.limit);
const items = computed(() => {
  const pagination = props.limit > 0 ? props.files.slice(0, pageSize.value) : props.files;
  return pagination.map(normalizeFile);
});

const isLoadMore = computed(() => props.limit && pageSize.value < props.files.length);
const selectedItems = shallowRef([]);

function onLoadMore() {
  pageSize.value += props.limit;
}

// TODO Remove this in favour of a predefined grid setting
function gridClass() {
  const { grid } = props;
  return {
    // tailwind classes needs to be declare pure
    'grid-cols-1': grid === '1',
    'grid-cols-2': grid === '2',
    'grid-cols-3': grid === '3',
    'grid-cols-4': grid === '4',
    'grid-cols-5': grid === '5',
    'grid-cols-6': grid === '6',
    'grid-cols-7': grid === '7',
    'grid-cols-8': grid === '8',
    'grid-cols-9': grid === '9',
    'grid-cols-10': grid === '10',
    'grid-cols-11': grid === '11',
    'grid-cols-12': grid === '12',
    'grid-cols-none': grid === 'none',
  };
}

function gapClass() {
  const { variant } = props;
  return {
    'gap-1': variant === 'compact',
    'gap-2': variant === 'default' || variant === 'thumbnail',
  };
}

function onSelectedItems({ id }) {
  if (props.selected) {
    if (selectedItems.value.includes(id)) {
      selectedItems.value = [...selectedItems.value.filter((item) => item !== id)];
    } else {
      selectedItems.value = [...selectedItems.value, id];
    }
    emit(
      'update:selected',
      props.files.filter((f) => selectedItems.value.includes(f.id || f.fileId)),
    );
  }
}

const listeners = computed(() => {
  // we add the click listener only if the selected prop is defined
  const listenersObj = {};
  if (props.selected !== undefined) {
    listenersObj.click = onSelectedItems;
  }
  return listenersObj;
});
</script>
<template>
  <div
    class="grid max-h-[--task-details-comment-editor-filelist-max-height] w-full overflow-y-auto"
    :class="{
      ...gridClass(),
      ...gapClass(),
    }"
  >
    <LscFileItem
      v-for="file in items"
      :key="file.id"
      :canPreviewFiles="canPreviewFiles"
      :canDownloadFiles="canDownloadFiles && file.fileSource === 'teamworkpm'"
      :file="file"
      :variant="variant"
      class="h-18"
      :class="{ '!bg-surface-emphasis-selected': selectedItems.includes(file.id) }"
      :dataIdentifierPrefix="dataIdentifierPrefix"
      @preview="emit('preview', file)"
      @download="onDownloadFile(file)"
      v-on="listeners"
    >
      <template #menu="menu">
        <slot name="menu" v-bind="menu" />
      </template>
      <template #actions="actions">
        <slot name="actions" v-bind="actions" />
      </template>
    </LscFileItem>
    <LscButton v-if="isLoadMore" variant="plain-primary" @click="onLoadMore">
      {{
        files.length - pageSize < limit
          ? t('Show 1 remaining file | Show {n} remaining files', {
              n: files.length - pageSize,
            })
          : t('Show +{limit} more files', { limit })
      }}
    </LscButton>
    <slot />
  </div>
</template>
