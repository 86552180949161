<script setup>
import { useI18n } from '@/util';
import LscEmptyState from '../emptyState/LscEmptyState.vue';
import WidgetLoadingProgress from './WidgetLoadingProgress.vue';
// Helps to manage and render the state of a single item or list loader.

const props = defineProps({
  // The `state` object from `useItemLoader` or `useListLoader`.
  state: {
    type: Object,
    required: true,
  },
  loadingMessage: {
    type: String,
    default: undefined,
  },
  blankTitle: {
    type: String,
    default: undefined,
  },
  blankMessage: {
    type: String,
    default: undefined,
  },
  errorTitle: {
    type: String,
    default: undefined,
  },
  errorMessage: {
    type: String,
    default: undefined,
  },
});

const { t } = useI18n();
const uiState = computed(() => {
  const item = unref(props.state.item);
  const items = unref(props.state.items);
  const loaded = unref(props.state.loaded);
  const error = unref(props.state.error);

  if (error) {
    return 'error';
  }
  if (!loaded) {
    return 'loading';
  }
  if (items ? items.length === 0 : item === null) {
    return 'blank';
  }
  return 'default';
});
</script>

<template>
  <LscSlotSwitch :name="uiState">
    <template #default><slot name="default" /></template>
    <template #blank>
      <slot name="blank">
        <LscEmptyState class="mt-4" :title="blankTitle" :message="blankMessage" />
      </slot>
    </template>
    <template #error>
      <slot name="error">
        <LscEmptyState variant="error" class="mt-4" :title="errorTitle ?? t('Loading failed')" :message="errorMessage">
          <template #actions>
            <LscButton variant="primary" @click="state.retry">
              {{ t('Retry') }}
            </LscButton>
          </template>
        </LscEmptyState>
      </slot>
    </template>
    <template #loading>
      <slot name="loading">
        <WidgetLoadingProgress :message="loadingMessage" />
      </slot>
    </template>
  </LscSlotSwitch>
</template>
