import {
  useCurrentUser,
  useCurrentAccount,
  useExperimentA18,
  useExperimentA29,
  useExperimentA45,
  useFeatures,
  usePreferences,
} from '@/api';

export function useOnboardingChecklist() {
  const user = useCurrentUser();
  const account = useCurrentAccount();
  const {
    projectTimeBudgetsEnabled,
    projectFinancialBudgetsEnabled,
    utilizationReportEnabled,
    workloadPlannerEnabled,
    lightspeedOnboardingChecklistEnabled,
  } = useFeatures();
  const { isExpA18Variation } = useExperimentA18();
  const { isExpA29Variation } = useExperimentA29();
  const { invitedUserFirst30Days, isExpA45Variation } = useExperimentA45();

  const { onboardingChecklistDismissed } = usePreferences();

  const isAdminTrialUser = computed(() => {
    return (
      user.value.administrator &&
      user.value.inOwnerCompany &&
      // account is in free trial state
      account.value.trialDaysRemaining > 0 &&
      account.value.paymentStatus !== 'paid'
    );
  });

  const productOutcomeFlowEnabled = computed(() => {
    return (
      projectFinancialBudgetsEnabled.value &&
      projectTimeBudgetsEnabled.value &&
      workloadPlannerEnabled.value &&
      utilizationReportEnabled.value &&
      lightspeedOnboardingChecklistEnabled.value
    );
  });

  const isChecklistFlowEnabled = computed(() => {
    if (onboardingChecklistDismissed.value) {
      return false;
    }
    if (
      isExpA18Variation.value ||
      isExpA29Variation.value ||
      (isExpA45Variation.value && invitedUserFirst30Days.value)
    ) {
      return true;
    }
    return productOutcomeFlowEnabled.value && isAdminTrialUser.value;
  });

  return {
    isChecklistFlowEnabled,
  };
}
