<script setup>
import TeamworkLogoFullWhite from '@/designsystem/assets/TeamworkLogoFullWhite.svg';
import TeamworkLogoShortWhite from '@/designsystem/assets/TeamworkLogoShortWhite.svg';
import TeamworkLogoFullWhiteDot from '@/designsystem/assets/TeamworkLogoFullWhiteDot.svg';
import TeamworkLogoShortWhiteDot from '@/designsystem/assets/TeamworkLogoShortWhiteDot.svg';

import { useThemeColor } from '@/module/theme';
import { useAppShellSidebar } from './useAppShellSidebar';

const { isCustomTheme } = useThemeColor();
const { isSidebarExpanded } = useAppShellSidebar();
</script>

<template>
  <RouterLink
    to="/home"
    :exact="true"
    class="flex items-center rounded-full p-3 outline-none ring-0 ring-[color:--lsds-c-sidebar-drawer-list-item-background-color-on-focus] focus:ring-2"
  >
    <div class="flex h-[18px] items-center">
      <Transition
        enterFromClass="opacity-0"
        enterActiveClass="transition-opacity duration-300"
        enterToClass="opacity-100"
        leaveFromClass="opacity-100"
        leaveActiveClass="absolute transition-opacity duration-300"
        leaveToClass="opacity-0"
      >
        <template v-if="isCustomTheme">
          <TeamworkLogoFullWhiteDot v-if="isSidebarExpanded" />
          <TeamworkLogoShortWhiteDot v-else />
        </template>
        <template v-else>
          <TeamworkLogoFullWhite v-if="isSidebarExpanded" />
          <TeamworkLogoShortWhite v-else />
        </template>
      </Transition>
    </div>
  </RouterLink>
</template>
