<script setup>
import { tv } from '../../../util/tailwind/tv';
import { provideLscCard } from './useLscCard';

const props = defineProps({
  /**
   * The layout of the card.
   * @type {import('vue').PropType<'vertical' | 'horizontal'>}
   */
  layout: {
    type: String,
    default: 'vertical',
    validator: (value) => ['vertical', 'horizontal'].includes(value),
  },
  /**
   * Whether the card is inset and has a border & padding.
   */
  inset: {
    type: Boolean,
    default: true,
  },
  /**
   * Whether the card is being dragged.
   */
  isDragging: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the card is active.
   */
  active: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the card is draggable.
   */
  isDraggable: {
    type: Boolean,
    default: false,
  },
});

// TODO add token for the emphasis border
const cardVariantStyleConfig = tv({
  base: [
    'flex flex-col gap-6 border-[--lsds-a-color-surface-emphasis-default] bg-surface-emphasis-default hover:border-strong',
  ],
  slots: {
    title: 'flex cursor-pointer gap-2',
    content: 'flex flex-col gap-3',
    footer: 'shrink-0 border-0 border-t border-solid border-separator pt-3',
    body: 'flex w-full flex-col gap-2',
  },
  variants: {
    inset: {
      true: {
        base: ['rounded-md border border-solid border-default bg-default p-4 shadow-1'],
      },
      false: {
        base: ['p-0'],
      },
    },
    isDraggable: {
      true: {
        base: ['cursor-grab'],
        content: ['cursor-grab'],
      },
      false: {
        base: ['cursor-pointer'],
      },
    },
    active: {
      true: {
        base: ['bg-surface-emphasis-default'],
      },
      false: {
        base: ['bg-default'],
      },
    },
    isDragging: {
      true: { base: ['shadow-4'] },
    },
    layout: {
      vertical: {
        base: ['flex-col'],
      },
      horizontal: {
        base: ['flex-row'],
        content: ['justify-between'],
      },
    },
  },
});

const isActive = shallowRef(props.active);

provideLscCard({
  layout: computed(() => props.layout),
  inset: computed(() => props.inset),
  isDragging: computed(() => props.isDragging),
  active: computed(() => isActive.value),
});

const classes = computed(() =>
  cardVariantStyleConfig({
    ...props,
    active: isActive.value,
  }),
);

const toggleisActive = () => {
  isActive.value = !isActive.value;
};
</script>

<template>
  <div :inset="inset" :isDragging="isDragging" :class="classes.base()" @click="toggleisActive">
    <slot name="header" />
    <div :class="classes.content()">
      <div class="flex gap-2">
        <div :class="classes.body()">
          <div v-if="$slots.action || $slots.title" :class="classes.title()">
            <slot name="action" />
            <slot name="title" />
          </div>

          <slot name="body" />
        </div>
      </div>
      <div v-if="$slots.footer">
        <div :class="classes.footer()">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>
