<script setup>
import { refDebounced } from '@vueuse/core';
import { useProjectsV3Loader } from '@/api';
import ProjectJumpToDrawerProjectsList from './ProjectJumpToDrawerProjectsList.vue';
import ProjectJumpToDrawerAllBlankSlate from './ProjectJumpToDrawerAllBlankSlate.vue';

const props = defineProps({
  searchTerm: {
    type: String,
    default: '',
  },
});

const { searchTerm } = toRefs(props);
const debouncedSearchTerm = refDebounced(searchTerm, 300);

const step = 30;
const count = shallowRef(step);
const state = useProjectsV3Loader({
  count,
  pageSize: step,
  params: computed(() => ({
    searchTerm: debouncedSearchTerm.value,
    include: 'companies',
    includeProjectUserInfo: true,
    'fields[projects]': 'id,name,isStarred,companyId,startPage',
    orderBy: 'name',
    orderMode: 'asc',
    onlyStarredProjects: false,
    onlyProjectsWithExplicitMembership: 1,
  })),
});
</script>

<template>
  <ProjectJumpToDrawerProjectsList v-model:count="count" :step="step" :state="state">
    <template #blank>
      <ProjectJumpToDrawerAllBlankSlate :searchTerm="searchTerm" />
    </template>
  </ProjectJumpToDrawerProjectsList>
</template>
