const symbol = Symbol('useOptimisticUpdates');

/**
 * @template {string} [T = unknown] the type of the affected entity, for example "task", "project", "user", "boardColumn", etc.
 * @typedef OptimisticUpdateEvent
 * @property {Promise} promise a Promise tracking the API request
 * @property {T} type the type of the affected entity, for example "task", "project", "user", "boardColumn", etc.
 * @property {'create' | 'update' | 'delete'} action the name of the action
 * @property {{id:number} & Record<string, unknown>} [T] an item containing only the affected properties and the id
 */

/** @typedef {(event: OptimisticUpdateEvent) => void} OptimisticUpdateListener */

function OptimisticUpdates() {
  let listeners = [];
  let emitting = 0;

  /**
   * Registers `listener` to be called on optimistic updates events.
   * Must be called within a component.
   * Unregisters the listener automatically when the component unmounts.
   * @param {OptimisticUpdateListener} listener
   */
  function on(listener) {
    // copy on write, if currently emitting
    if (emitting > 0) {
      listeners = listeners.slice();
    }
    listeners.push(listener);

    onScopeDispose(() => {
      // copy on write, if currently emitting
      if (emitting > 0) {
        listeners = listeners.slice();
      }

      const index = listeners.indexOf(listener);

      if (index > -1) {
        listeners[index] = listeners[listeners.length - 1];
        listeners.pop();
      }
    });
  }

  /**
   * Emits the specified optimistic updates event.
   * @param {OptimisticUpdateEvent} event
   */
  function emit(event) {
    try {
      emitting += 1;
      // thanks to "copy on write" above, fixedListeners will not change while iterating
      const fixedListeners = listeners;
      for (let i = 0; i < fixedListeners.length; i += 1) {
        try {
          fixedListeners[i](event);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('useOptimisticUpdates: Error in an event listener', error);
        }
      }
    } finally {
      emitting -= 1;
    }
  }

  return { emit, on };
}

/**
 * Provides a new optimistic updates instance which can be later obtained using `useOptimisticUpdates`.
 * @param {import('vue').App} app
 */
export function optimisticUpdatesPlugin(app) {
  app.provide(symbol, OptimisticUpdates());
}

/**
 * Returns the optimistic updates instance provided by `optimisticUpdatesPlugin`.
 * If `listener` is specified, it is registered to listen for optimistic updates events.
 * @param {(event: OptimisticUpdateEvent) => void} listener
 * @returns {ReturnType<typeof OptimisticUpdates>>}
 */
export function useOptimisticUpdates(listener) {
  const optimisticUpdates = inject(symbol);
  if (listener) {
    optimisticUpdates.on(listener);
  }
  return optimisticUpdates;
}
