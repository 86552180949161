export const ENTITY_ICON_MAP = {
  budget: 'lsi-billable',
  comment: 'lsi-comment-text',
  company: 'lsi-assignee',
  customreport: 'lsi-reports',
  event: 'lsi-event',
  file: 'lsi-file',
  default: 'lsi-notification-placeholder',
  billinginvoice: 'lsi-budget',
  link: 'lsi-link',
  link_comment: 'lsi-reply',
  message: 'lsi-email',
  message_comment: 'lsi-reply',
  milestone: 'lsi-milestone',
  milestone_comment: 'lsi-reply',
  notebook: 'lsi-notebook',
  notebook_comment: 'lsi-reply',
  project: 'lsi-project',
  projectupdate: 'lsi-project',
  proof: 'lsi-proofs',
  proofrole: 'lsi-proofs',
  prooffeedback: 'lsi-proofs',
  proofdecision: 'lsi-proofs',
  proofversion: 'lsi-proofs',
  task: 'lsi-task',
  tasklist: 'lsi-tasklist',
  task_comment: 'lsi-reply',
  team: 'lsi-group',
  timelog: 'lsi-time',
  form: 'lsi-text-field',
  timereminder: 'lsi-notifications',
};

export const ENTITY_COLOR_MAP = {
  budget: '#e1e6ee',
  comment: '#95e1c1',
  company: '#e1e6ee',
  customreport: '#bda3ff',
  event: '#bba2ff',
  file: '#a1e9f0',
  form: '#dfa275',
  billinginvoice: '#8F9BB3',
  default: '#E1E6EE',
  link: '#ffe29b',
  link_comment: '#ffe29b',
  message: '#ffa0dd',
  message_comment: '#ffa0dd',
  milestone: '#8f9fe7',
  milestone_comment: '#8f9fe7',
  notebook: '#ed818e',
  notebook_comment: '#ed818e',
  project: '#94c7fb',
  projectupdate: '#94c7fb',
  proof: '#94C7FB',
  proofrole: '#94C7FB',
  prooffeedback: '#94C7FB',
  proofdecision: '#94C7FB',
  proofversion: '#94C7FB',
  reacted: '#95e1c1',
  status: '#e1e6ee',
  task: '#ffad8d',
  task_comment: '#ffad8d',
  tasklist: '#ffad8d',
  team: '#e1e6ee',
  user: '#e1e6ee',
  timereminder: 'var(--lsds-g-palette-pink-30)',
};
