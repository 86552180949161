import { useLocalStorage } from '@vueuse/core';
import {
  usePendo,
  useFeatures,
  usePreferences,
  usePermissions,
  useEnabledApps,
  useCurrentAccount,
  useCurrentUser,
  useExperimentA42,
  useExperimentE16,
  useExperimentE17,
  useCohort,
} from '@/api';
import { useCurrentProject, useRoute } from '@/route';
import { useI18n, formatKeyboardShortcut } from '@/util';

const symbol = Symbol('useAppShellSidebar');

function AppShellSidebar() {
  const allowAccessPermission = computed(() => true);
  const project = useCurrentProject();
  const account = useCurrentAccount();
  const route = useRoute();
  const { t } = useI18n();
  const { trackPendoEvent, trackExperimentInPendo } = usePendo();
  const { sidebarPinnedItems } = usePreferences();
  const { lightspeedSpacesPanelEnabled, commandCenterEnabled } = useFeatures();
  const { trackExperimentA42 } = useExperimentA42();
  const { isExpE16Variation } = useExperimentE16();
  const { isExpE17BudgetViewVariation, trackExperimentE17 } = useExperimentE17();
  const {
    isAccount,
    isSiteAdmin,
    canViewCalendar,
    canAccessPeopleCompaniesTeams,
    canViewFormsPanel,
    canViewProofs,
    canViewReports,
    canViewReportsUpgradeCta,
    canViewProjectTemplates,
  } = usePermissions();
  const { isSpacesAvailable } = useEnabledApps();
  const isSpacesPanelAllowed = computed(() => isSpacesAvailable.value && lightspeedSpacesPanelEnabled.value);
  const isMyShortcutsOpen = shallowRef(false);
  const isProductUpdatesOpen = shallowRef(false);
  const isSuccessPlannerOpen = shallowRef(false);
  const activeDrawerPanelState = shallowRef(null);
  const isQuickAddOpen = shallowRef(false);
  const isUserMenuMoreItemsOpen = shallowRef(false);
  const isUserMenuSwitchAccountsOpen = shallowRef(false);
  const isSidebarOpenOnMobile = shallowRef(false);
  const user = useCurrentUser();
  const { isClientUser, isCollaborator } = useCohort();

  trackExperimentA42(trackExperimentInPendo);
  trackExperimentE17(trackExperimentInPendo);

  const canViewWelcomePage = computed(() => !(isClientUser.value || isCollaborator.value));

  const sidebarNavItems = [
    {
      itemKey: 'home',
      path: '/home',
      text: t('Home'),
      icon: 'lsi-sidenav-home',
      permission: allowAccessPermission,
      fixed: true,
      pendoLabel: 'home',
      dataIdentifier: 'side-nav-home-button',
    },
    {
      itemKey: 'search',
      path: null,
      text: t('Search'),
      icon: 'lsi-sidenav-quick-search',
      drawerPanel: commandCenterEnabled.value ? undefined : 'search',
      tooltip: {
        text: t('Search'),
        subtext: commandCenterEnabled.value ? formatKeyboardShortcut('Cmd K') : formatKeyboardShortcut('F'),
      },
      permission: allowAccessPermission,
      fixed: true,
      pendoLabel: 'search',
      dataIdentifier: 'app-nav__search',
      dialogName: commandCenterEnabled.value ? 'CommandCenterDialog' : undefined,
      keyboardShortcut: commandCenterEnabled.value ? 'COMMAND K' : 'F',
      keyboardShortcutActiveOnInput: commandCenterEnabled.value,
    },
    {
      itemKey: 'welcome',
      path: '/welcome/the-fundamentals',
      text: t('Welcome'),
      icon: 'lsi-sidenav-welcome',
      permission: canViewWelcomePage,
      pendoLabel: 'welcome',
      dataIdentifier: 'app-nav__welcome',
      isActive: () => route.path.startsWith('/welcome'),
    },
    {
      itemKey: 'jumpTo',
      path: null,
      text: t('Jump to'),
      tooltip: {
        text: t('Jump to'),
        subtext: formatKeyboardShortcut('S'),
      },
      icon: 'lsi-sidenav-jump-to',
      drawerPanel: 'jumpTo',
      permission: allowAccessPermission,
      fixed: true,
      pendoLabel: 'jump_to',
      dataIdentifier: 'side-nav-jump-to-button',
      keyboardShortcut: 'S',
    },
    {
      itemKey: 'clients',
      path: '/clients',
      text: t('Clients'),
      icon: 'lsi-sidenav-clients',
      permission: computed(() => {
        return account.value.useClientView && canAccessPeopleCompaniesTeams.value;
      }),
      pendoLabel: 'clients',
      dataIdentifier: 'side-nav-clients-button',
      onClick() {
        trackPendoEvent({
          eventName: 'CLIENTS_NAVIGATION_EVENT',
        });
      },
    },
    {
      itemKey: 'projects',
      path: '/projects',
      text: t('Projects'),
      icon: 'lsi-sidenav-projects',
      permission: allowAccessPermission,
      isActive: () => ['/projects'].some((path) => route.path.startsWith(path)) || Boolean(project.value),
      pendoLabel: 'projects',
      dataIdentifier: 'side-nav-projects-button',
    },
    {
      itemKey: 'templates',
      path: null,
      text: t('Templates'),
      icon: 'lsi-sidenav-templates',
      drawerPanel: 'templates',
      permission: canViewProjectTemplates,
      pendoLabel: 'templates',
      dataIdentifier: 'side-nav-templates-button',
    },
    {
      itemKey: 'spaces',
      path: null,
      text: t('Spaces'),
      icon: 'lsi-sidenav-spaces',
      drawerPanel: 'spaces',
      permission: isSpacesPanelAllowed,
      pendoLabel: 'spaces',
      dataIdentifier: 'side-nav-spaces-button',
    },
    {
      itemKey: 'planning',
      path: '/planning',
      text: t('Planning'),
      icon: 'lsi-sidenav-planning',
      permission: allowAccessPermission,
      pendoLabel: 'planning',
      dataIdentifier: 'side-nav-planning-button',
    },
    {
      itemKey: 'budgets',
      path: '/budgets',
      text: t('Budgets'),
      icon: 'lsi-budget',
      permission: computed(() => isExpE17BudgetViewVariation.value && isSiteAdmin.value),
      pendoLabel: 'budgets',
      dataIdentifier: 'side-nav-budgets-button',
    },
    {
      itemKey: 'reports',
      path: '/reports',
      text: t('Reports'),
      icon: 'lsi-sidenav-reports',
      permission: computed(
        () => canViewReports.value || canViewReportsUpgradeCta.value || user.value.permissions.canAccessCustomReports,
      ),
      pendoLabel: 'reports',
      dataIdentifier: 'side-nav-reports-button',
    },
    {
      itemKey: 'time',
      path: isExpE16Variation.value ? '/time/user' : '/time',
      text: isExpE16Variation.value ? t('Timesheets') : t('Time'),
      icon: 'lsi-sidenav-time',
      permission: computed(() => isAccount.value),
      pendoLabel: 'time',
      dataIdentifier: 'side-nav-time-button',
    },
    {
      itemKey: 'proofs',
      path: '/proofs',
      text: t('Proofs'),
      icon: 'lsi-sidenav-proofs',
      permission: canViewProofs,
      pendoLabel: 'proofs',
      dataIdentifier: 'side-nav-proofs-button',
    },
    {
      itemKey: 'forms',
      path: null,
      text: t('Forms'),
      icon: 'lsi-sidenav-forms',
      drawerPanel: 'forms',
      permission: canViewFormsPanel,
      pendoLabel: 'forms',
      dataIdentifier: 'side-nav-forms-button',
    },
    {
      itemKey: 'everything',
      path: '/everything',
      text: t('Everything'),
      icon: 'lsi-sidenav-everything',
      permission: isAccount,
      pendoLabel: 'everything',
      dataIdentifier: 'side-nav-everything-button',
    },
    {
      itemKey: 'calendar',
      path: '/calendar',
      text: t('Calendar'),
      icon: 'lsi-sidenav-calendar',
      permission: canViewCalendar,
      pendoLabel: 'calendar',
      dataIdentifier: 'side-nav-calendar-button',
    },
    {
      itemKey: 'people',
      path: '/people',
      text: t('People'),
      icon: 'lsi-sidenav-people',
      permission: canAccessPeopleCompaniesTeams,
      pendoLabel: 'people',
      dataIdentifier: 'side-nav-people-button',
      isActive: () => ['/people', '/teams', '/companies'].some((path) => route.path.startsWith(path)),
    },
  ];

  const appShellSidebarPanelState = useLocalStorage(
    'teamwork/useAppShellSidebar',
    {
      isSidebarExpanded: true,
      activeDrawerPanel: null,
      isDrawerPanelPinned: false,
    },
    {
      listenToStorageChanges: false,
    },
  );

  function setItemPinned(key, pinned) {
    sidebarPinnedItems.value = {
      ...sidebarPinnedItems.value,
      [key]: {
        ...sidebarPinnedItems.value[key],
        docked: pinned,
      },
    };
  }

  if (canViewProofs.value) {
    trackPendoEvent({
      eventName: 'PROOFS_FEATURE_ENABLED',
    });
  }

  const isSidebarExpanded = computed({
    get() {
      return appShellSidebarPanelState.value.isSidebarExpanded;
    },
    set(val) {
      appShellSidebarPanelState.value.isSidebarExpanded = val;
    },
  });

  const activeDrawerPanel = computed({
    get() {
      if (appShellSidebarPanelState.value.activeDrawerPanel) {
        activeDrawerPanelState.value = appShellSidebarPanelState.value.activeDrawerPanel;
      }

      return activeDrawerPanelState.value;
    },
    set(val) {
      if (appShellSidebarPanelState.value.isDrawerPanelPinned && val !== null) {
        appShellSidebarPanelState.value.isDrawerPanelPinned = val;
        appShellSidebarPanelState.value.activeDrawerPanel = val;
      }

      activeDrawerPanelState.value = val;
    },
  });

  const sidebarWidth = computed(() => {
    return isSidebarExpanded.value
      ? 'var(--lsds-c-sidebar-drawer-width-expanded)'
      : 'var(--lsds-c-sidebar-drawer-width-collapsed)';
  });

  const activeDrawerPanelWidth = computed(() => {
    switch (activeDrawerPanel.value) {
      case 'help-center-wide':
        return 'var(--help-center-wide-panel-width)';
      case 'chat':
      case 'help-center':
      case 'notifications':
      case 'search':
        return 'var(--lsds-c-sidebar-drawer-panel-width-lg)';
      case 'forms':
      case 'jumpTo':
      case 'spaces':
      case 'templates':
        return 'var(--lsds-c-sidebar-drawer-panel-width-sm)';
      default:
        return 'var(--lsds-c-sidebar-drawer-panel-width-sm)';
    }
  });

  const isDrawerPanelPinned = computed({
    get() {
      return appShellSidebarPanelState.value.isDrawerPanelPinned;
    },
    set(val) {
      if (val === activeDrawerPanelState.value) {
        appShellSidebarPanelState.value.activeDrawerPanel = val;
      }

      if (val === null) {
        appShellSidebarPanelState.value.activeDrawerPanel = null;
      }

      appShellSidebarPanelState.value.isDrawerPanelPinned = val;
    },
  });

  function toggleSidebarExpansion() {
    isSidebarExpanded.value = !isSidebarExpanded.value;
  }

  function setActiveDrawerPanel(val) {
    activeDrawerPanel.value = val;
  }

  function clearActiveDrawerPanel() {
    appShellSidebarPanelState.value.activeDrawerPanel = null;
    activeDrawerPanelState.value = null;
  }

  function clearActiveDrawerPanelIfNotPinned() {
    if (!isDrawerPanelPinned.value) {
      clearActiveDrawerPanel();
    }
  }

  function toggleActiveDrawerPanel(panel) {
    if (activeDrawerPanel.value === panel) {
      clearActiveDrawerPanel();
    } else {
      setActiveDrawerPanel(panel);
    }
  }
  function isItemPinned(item) {
    return Boolean(sidebarPinnedItems.value[item.itemKey]?.docked);
  }

  function isItemActive(item) {
    return item.isActive?.() ?? route.path.startsWith(item.path);
  }

  const dockedNavItems = computed(() => {
    return sidebarNavItems.filter((item) => item.permission.value && (item.fixed || isItemPinned(item)));
  });

  const moreNavItems = computed(() => {
    return sidebarNavItems.filter((item) => item.permission.value && !(item.fixed || isItemPinned(item)));
  });

  function trackSideNavItemClicked(pendoLabel, additionalMetadata = {}) {
    trackPendoEvent({
      eventName: 'NAVIGATION_EVENT',
      commonMetrics: ['plan_name', 'user_role', 'page'],
      metadata: {
        event_action: 'left_navigation_clicked',
        navigation_item: pendoLabel,
        ...additionalMetadata,
      },
    });
  }

  function closeSidebarOnMobile() {
    isSidebarOpenOnMobile.value = false;
  }

  onMounted(() => {
    const activeNavItem = sidebarNavItems.find(({ itemKey }) => itemKey === activeDrawerPanel.value);
    if (!activeNavItem) {
      return;
    }

    if (!activeNavItem.permission.value) {
      clearActiveDrawerPanel();

      if (isDrawerPanelPinned.value) {
        setItemPinned(activeDrawerPanel.value, false);
      }
    }
  });

  return {
    closeSidebarOnMobile,
    clearActiveDrawerPanel,
    clearActiveDrawerPanelIfNotPinned,
    activeDrawerPanel,
    activeDrawerPanelWidth,
    dockedNavItems,
    isItemActive,
    isItemPinned,
    isSidebarOpenOnMobile,
    isSidebarExpanded,
    isMyShortcutsOpen,
    isProductUpdatesOpen,
    isQuickAddOpen,
    isDrawerPanelPinned,
    isSuccessPlannerOpen,
    isUserMenuMoreItemsOpen,
    isUserMenuSwitchAccountsOpen,
    moreNavItems,
    setItemPinned,
    setActiveDrawerPanel,
    sidebarWidth,
    sidebarNavItems,
    toggleActiveDrawerPanel,
    toggleSidebarExpansion,
    trackSideNavItemClicked,
    canViewWelcomePage,
  };
}

/**
 * @type {AppShellSidebar}
 */
export function provideAppShellSidebar() {
  const appShellSidebar = AppShellSidebar();
  provide(symbol, appShellSidebar);
  return appShellSidebar;
}

/**
 * @type {AppShellSidebar}
 */
export function useAppShellSidebar() {
  return inject(symbol);
}
