import './VAutocomplete.variants.css';

export const VAutocompleteLswPickers = {
  class: 'VAutocompleteLswPickers',
  menuProps: {
    class: 'VAutocompleteLswPickersMenu',
    height: 360,
    maxHeight: 360,
    width: 320,
  },
};
