import { useItemLoader } from '../base/useItemLoader';
import { useItemToListLoader } from '../base/useItemToListLoader';

/**
 * @typedef {Object} Webinar webinar result item
 * @property {string} id
 * @property {string} title
 * @property {string} description
 * @property {string} category
 * @property {string} webinarLink
 */

/**
 * @returns {Webinar[]} listLoader with items array
 */
function responseToItem({ data: { stories } }) {
  return stories.map((story) => ({
    id: story.uuid,
    description: story.content.landing_page_description.content[0].content[0].text,
    title: story.content.title,
    category: story.content.category,
    webinarLink: `https://teamwork.com/${story.full_slug}`,
  }));
}

/**
 * Get upcoming webinars with a filter, with searches being cached by filter string
 * @param {import('@vueuse/core').MaybeRef<number>} count The number of webinars to return.
 * @param {import('@vueuse/core').MaybeRef<object>} params The GET params for te request.
 */
export function useWebinarsLoader({ params, count }) {
  // Using `useItemLoader` because the API endpoint does not support pagination.
  const { state } = useItemLoader({
    url: 'https://api.storyblok.com/v2/cdn/stories/?token=FdpPt9u4qrVnQUb1D2i8LAtt&content_type=webinar&excluding_fields=seo,description,hosts,component,sections,hostsHeading,gotoWebinarId,zoomWebinarId,GTWRecurrenceKey,background_image,displayExtraFields,landing_page_title,landing_page_body_copy,externalUrl',
    responseToItem,
    params,
  });

  return useItemToListLoader(state, count);
}
