import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function toApiProject(project) {
  const apiProject = {};
  apiProject.id = project.id;
  if (Object.hasOwn(project, 'description')) {
    apiProject.description = project.description;
  }
  if (Object.hasOwn(project, 'name')) {
    apiProject.name = project.name;
  }

  if (Object.hasOwn(project, 'startDate')) {
    apiProject.startDate = project.startDate;
  }

  if (Object.hasOwn(project, 'endDate')) {
    apiProject.endDate = project.endDate;
  }

  if (Object.hasOwn(project, 'ignoreConstraints')) {
    apiProject.ignoreConstraints = project.ignoreConstraints;
  }

  if (Object.hasOwn(project, 'categoryId')) {
    apiProject['category-id'] = Number(project.categoryId);
  } else if (Object.hasOwn(project, 'category')) {
    apiProject['category-id'] = Number(project.category.id);
  }
  if (Object.hasOwn(project, 'customFieldValues')) {
    apiProject.customFields = project.customFieldValues.map(({ customFieldId, value, urlTextToDisplay }) => ({
      customFieldId,
      value,
      urlTextToDisplay,
    }));
  }
  if (Object.hasOwn(project, 'tags')) {
    apiProject.tagIds = project.tags
      .map((tag) => {
        if (typeof tag === 'object' && tag.id !== undefined) {
          return tag.id;
        }
        return tag;
      })
      .join(',');
  }
  if (Object.hasOwn(project, 'projectOwnerId')) {
    apiProject.projectOwnerId = Number(project.projectOwnerId);
  }

  // Allow all "use-*" keys for toggling project features like tasks, notebooks, messages, etc.
  Object.entries(project).forEach(([key, value]) => {
    if (key.startsWith('use-')) {
      apiProject[key] = value;
    }
  });

  return apiProject;
}

function hasCategoryChange(updatedProject) {
  return Object.hasOwn(updatedProject, 'categoryId') || Object.hasOwn(updatedProject, 'category');
}

/**
 * Loads the specificed project.
 */
export function useProjectActions() {
  const { t } = useI18n();
  const api = useAxios();
  const { socketId, emit: _emitRealTimeUpdate } = useRealTimeUpdates();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();

  function config() {
    return {
      headers: {
        'Socket-ID': socketId,
      },
    };
  }

  /**
   *
   * @param {Promise} promise
   * @param {'create'|'update'|'delete'} action
   * @param {object} project
   */
  function emitOptimisticUpdate(promise, action, project) {
    _emitOptimisticUpdate({
      promise,
      type: 'project',
      action,
      project,
    });
  }

  /**
   *
   * @param {string} action
   * @param {object} project
   */
  function emitRealTimeUpdate(action, project) {
    _emitRealTimeUpdate({
      type: 'project',
      action,
      projectId: project.id,
      categoryChanged: hasCategoryChange(project),
    });
  }

  function archiveProject(project) {
    const updatedProject = { ...project, status: 'archived' };
    const promise = api.put(`/projects/${project.id}/archive.json`, { status: 'inactive' }, config()).then(() => {
      emitRealTimeUpdate('archived', project);
    });
    emitOptimisticUpdate(promise, 'update', updatedProject);
    return promise;
  }

  function cloneProject(project) {
    const promise = api.post(`/projects/${project.id}/clone.json`, { project }, config()).then((response) => {
      const clonedProject = response.data;
      emitRealTimeUpdate('added', clonedProject);
    });

    emitOptimisticUpdate(promise, 'create', { ...project, id: -1 });
    return promise;
  }

  function createProject(project) {
    const promise = api.post(`/projects.json`, { project: toApiProject(project) }, config()).then((response) => {
      const createdProject = response.data;
      emitRealTimeUpdate('added', project);
      return createdProject;
    });
    emitOptimisticUpdate(promise, 'update', project);
    return promise;
  }

  function createTentativeProject(project) {
    const promise = api
      .post('/projects/tentative.json', {
        project,
      })
      .then((response) => {
        emitRealTimeUpdate('added', response.data);
        return response.data.id;
      });
    return promise;
  }

  function completeProject(project) {
    const updatedProject = { ...project, subStatus: 'completed' };
    const promise = api.put(`/projects/${project.id}/complete.json`).then(() => {
      emitRealTimeUpdate('completed', project);
    });
    emitOptimisticUpdate(promise, 'update', updatedProject);
    return promise;
  }

  function convertTentativeProjectToNormal(project) {
    const updatedProject = { ...project, type: 'normal' };
    const promise = api
      .put(
        `/projects/api/v3/projects/tentative/${updatedProject.id}/convert.json`,
        { project: toApiProject(updatedProject) },
        config(),
      )
      .then(() => {
        emitRealTimeUpdate('edited', updatedProject);
      });
    emitOptimisticUpdate(promise, 'update', updatedProject);
    return promise;
  }

  function deleteProject(project) {
    const promise = api.delete(`/projects/${project.id}.json`, null, config()).then(() => {
      emitRealTimeUpdate('deleted', project);
    });
    emitOptimisticUpdate(promise, 'delete', project);
    return promise;
  }

  function starProject(project) {
    const updatedProject = { id: project.id, isStarred: true };
    const promise = api.put(`/projects/${project.id}/star.json`, null, config()).then(() => {
      emitRealTimeUpdate('starred', project);
    });

    emitOptimisticUpdate(promise, 'update', updatedProject);
    return promise;
  }

  function unarchiveProject(project) {
    const updatedProject = { ...project, status: 'active' };
    const promise = api.put(`/projects/${project.id}/archive.json`, { status: 'active' }, config()).then(() => {
      emitRealTimeUpdate('unarchived', project);
    });
    emitOptimisticUpdate(promise, 'update', updatedProject);
    return promise;
  }

  function uncompleteProject(project) {
    const updatedProject = { ...project, subStatus: 'current' };
    const promise = api.put(`/projects/${project.id}/uncomplete.json`).then(() => {
      emitRealTimeUpdate('uncompleted', project);
    });
    emitOptimisticUpdate(promise, 'update', updatedProject);
    return promise;
  }

  function unstarProject(project) {
    const updatedProject = { id: project.id, isStarred: false };
    const promise = api.put(`/projects/${project.id}/unstar.json`, null, config()).then(() => {
      emitRealTimeUpdate('unstarred', project);
    });
    emitOptimisticUpdate(promise, 'update', updatedProject);
    return promise;
  }

  function updateProject(updatedProject) {
    const promise = api
      .put(`/projects/${updatedProject.id}.json`, { project: toApiProject(updatedProject) }, config())
      .then(() => {
        emitRealTimeUpdate('edited', updatedProject);
      });
    emitOptimisticUpdate(promise, 'update', updatedProject);
    return promise;
  }

  /**
   * Update project email address
   * @param {number} projectId
   * @param {string} code
   */
  function updateProjectEmailAddress(projectId, code) {
    return api.put(`/projects/${projectId}/emailaddress.json`, { emailaddress: { code } }, config()).then(() => {
      // emitRealTimeUpdate('edited', { id: projectId });
    });
  }

  /**
   * Update project feature orders
   * @param {number} projectId
   * @param {object} featureOrder
   */
  function updateProjectFeatureOrder(projectId, featureOrder) {
    return api.put(`/projects/api/v3/projects/${projectId}/featureorder.json`, featureOrder, config());
  }

  /**
   * Update project users
   * @param {number} projectId
   * @param {object} userIds
   */
  function updateProjectUsers({ projectId, userIds }) {
    return api.put(`/projects/api/v3/projects/${projectId}/people.json`, { userIds }, config()).then(() => {
      emitRealTimeUpdate('edited', { id: projectId });
    });
  }

  /**
    @param {number} projectId - The project id which we want to request access
    @param {string} feature - The name of the feature
  */
  function requestProjectAccess(projectId, feature) {
    const payload = { projectPermissionsForFeature: feature };
    return api.post(`/projects/api/v3/projects/${projectId}/requestaccess.json`, payload, {
      headers: {
        'Socket-ID': socketId,
      },
      errorMessage: t('Failed to request project access'),
    });
  }

  /**
   * Update project health
   * @param {number} projectId
   * @param {object} update
   */
  function updateProjectHealth(projectId, update) {
    return api.post(`/projects/${projectId}/update.json`, { update }, config()).then(() => {
      emitRealTimeUpdate('edited', { id: projectId });
    });
  }

  return {
    archiveProject,
    cloneProject,
    completeProject,
    convertTentativeProjectToNormal,
    createProject,
    createTentativeProject,
    deleteProject,
    starProject,
    unarchiveProject,
    uncompleteProject,
    unstarProject,
    updateProject,
    updateProjectEmailAddress,
    updateProjectFeatureOrder,
    updateProjectHealth,
    updateProjectUsers,
    requestProjectAccess,
  };
}
