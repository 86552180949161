<script setup>
import { useDropZone, useScroll } from '@vueuse/core';
import { hasVNodeContent } from '@/util';
import LscDropzoneOverlay from './LscDropzoneOverlay.vue';
import LscDropzonePlaceholder from './LscDropzonePlaceholder.vue';

const props = defineProps({
  /**
   * Allow our dropzone to accept multiple files. By default it is set to `false`.
   */
  multiple: {
    type: Boolean,
    default: false,
  },
  /**
   * The size of the dropzone. By default it is set to `md`.
   * @type {import('vue').PropType<'sm' | 'md' | 'full'>}
   */
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md', 'full'].includes(value),
  },
  /**
   * If an error happens, the dropzone will show an error state. By default it is set to `false`.
   */
  error: {
    type: Boolean,
    default: false,
  },
  /**
   * The placeholder message
   */
  message: {
    type: String,
    default: '',
  },
  /**
   * Controls the disabled state of the dropzone. By default it is set to `false`.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['change']);

/**
 * The model value of the dropzone. By default it is set to an empty array.
 */
const modelValue = defineModel({
  type: Array,
  default: () => [],
});
const slots = useSlots();
const dropzoneRef = shallowRef();

function selectFiles(files) {
  const selectedFiles = props.multiple ? [...files] : [files[0]];
  modelValue.value = selectedFiles;
  emit('change', selectedFiles);
}

function onDropFiles(files) {
  selectFiles(files);
}

function onPasteFiles(event) {
  const clipboardData = event.clipboardData || window.clipboardData;

  if (clipboardData?.files.length > 0) {
    selectFiles(clipboardData.files);
  }
}

function onSelectFiles(files) {
  selectFiles(files);
}

const computedDropzoneRef = computed(() => (props.disabled ? undefined : dropzoneRef.value));

const { isOverDropZone } = useDropZone(computedDropzoneRef, onDropFiles);
const { y } = useScroll(dropzoneRef);

const hasDefaultSlot = computed(() => hasVNodeContent(slots.default?.({ ...props, isOverDropZone })));
</script>
<template>
  <div
    ref="dropzoneRef"
    class="relative"
    :class="{
      'min-h-20': size === 'sm',
      'min-h-36': size === 'md',
      'h-full': size === 'full',
    }"
    @paste="onPasteFiles"
  >
    <slot name="default" v-bind="props" :isOverDropZone="isOverDropZone">
      <LscDropzonePlaceholder
        v-if="!disabled"
        :error="error"
        :isOverDropZone="isOverDropZone"
        :multiple="multiple"
        :size="size"
        :message="message"
        @change="onSelectFiles"
      />
    </slot>
    <template v-if="hasDefaultSlot">
      <LscDropzoneOverlay
        :error="error"
        :isOverDropZone="isOverDropZone"
        :multiple="multiple"
        :shouldStickToTop="y > 0"
        :size="size"
      />
    </template>
  </div>
</template>
