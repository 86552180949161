<script setup>
const emit = defineEmits(['close']);

/**
 * https://www.figma.com/file/JEwlFYZfqpZE8w7rr5jNSn/Deliver-to-Grow-Bundle-offer-E-23-25?type=design&node-id=1079-11206&t=UIhkQZIEK8gcgx87-4
 */
const DeskPromoDialogPanel = defineAsyncComponent(() => import('./DeskPromoDialogPanel.vue'));
</script>

<template>
  <WidgetDialog width="960px">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <DeskPromoDialogPanel
        @close="
          close();
          emit('close');
        "
      />
    </template>
  </WidgetDialog>
</template>
