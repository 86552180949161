import { useRouter } from 'vue-router';
import {
  ACTIVE_PROJECT_LIMIT_FEATURE_KEY,
  BUDGET_EXPENSES_FEATURE_KEY,
  CUSTOM_FIELDS_FEATURE_KEY,
  CUSTOM_REPORTS_FEATURE_KEY,
  FINANCIAL_BUDGETS_FEATURE_KEY,
  INTAKE_FORMS_FEATURE_KEY,
  PROJECT_TEMPLATES_FEATURE_KEY,
  PROOFS_FEATURE_KEY,
  RETAINER_BUDGETS_FEATURE_KEY,
  TASKLIST_BUDGETS_FEATURE_KEY,
  TIME_BUDGETS_FEATURE_KEY,
  useCurrentUser,
  useFeatureLimitActions,
  usePermissions,
  usePricePlan,
} from '@/api';
import { useFeedback } from '@/module/feedback';
import { useI18n } from '@/util';
import { useBudgetsLimit } from './useBudgetsLimit';
import { useCustomFieldsLimit } from './useCustomFieldsLimit';
import { useFeatureAccessEvent } from './useFeatureAccessEvent';
import { useFormsLimit } from './useFormsLimit';
import { useProofsLimit } from './useProofsLimit';
import { useTasklistBudgetsLimit } from './useTasklistBudgetsLimit';

const featuresWithLimits = /** @type {const} */ ([
  PROOFS_FEATURE_KEY,
  FINANCIAL_BUDGETS_FEATURE_KEY,
  TIME_BUDGETS_FEATURE_KEY,
  RETAINER_BUDGETS_FEATURE_KEY,
  CUSTOM_FIELDS_FEATURE_KEY,
  PROJECT_TEMPLATES_FEATURE_KEY,
  CUSTOM_REPORTS_FEATURE_KEY,
  BUDGET_EXPENSES_FEATURE_KEY,
  INTAKE_FORMS_FEATURE_KEY,
  ACTIVE_PROJECT_LIMIT_FEATURE_KEY,
  TASKLIST_BUDGETS_FEATURE_KEY,
]);

/** @typedef {typeof featuresWithLimits[number]} FeatureWithLimits */

/**
 * @typedef {Object} FeatureLimitObject
 * @property {ComputedRef<number>} featureLimit
 * @property {ComputedRef<boolean>} isFeatureEnabled
 * @property {ComputedRef<boolean>} isOverFeatureLimit
 * @property {ComputedRef<boolean>} loading
 */

/**
 * Returns the limits for a given feature
 * @param {Object} options
 * @param {FeatureWithLimits} options.feature
 * @param {boolean} options.fromCreationModal
 * @return {FeatureLimitObject}
 */
function useLimit({ feature, fromCreationModal = true } = {}) {
  if (feature === FINANCIAL_BUDGETS_FEATURE_KEY || feature === TIME_BUDGETS_FEATURE_KEY) {
    const type = feature.replace(/project|budgets/gi, '');
    return useBudgetsLimit({ type, isNewBudget: fromCreationModal });
  }

  if (feature === CUSTOM_FIELDS_FEATURE_KEY) {
    return useCustomFieldsLimit();
  }

  if (feature === PROOFS_FEATURE_KEY) {
    return useProofsLimit();
  }

  if (feature === TASKLIST_BUDGETS_FEATURE_KEY) {
    return useTasklistBudgetsLimit();
  }

  if (feature === INTAKE_FORMS_FEATURE_KEY) {
    return useFormsLimit();
  }

  return {
    isOverFeatureLimit: computed(() => false),
    isFeatureEnabled: computed(() => false),
    featureLimit: computed(() => 0),
    loading: computed(() => true),
  };
}

/**
 * @param {Object} options
 * @param {FeatureWithLimits} options.feature
 * @param {boolean} options.fromCreationModal
 */
export function useFeaturesLimit({ feature, fromCreationModal = true }) {
  const featureHasLimits = computed(() => featuresWithLimits.includes(feature));

  const user = useCurrentUser();
  const { isOwnerAdmin: canAccessCheckout } = usePermissions();
  const toast = useLsToast();
  const { t } = useI18n();
  const { pricePlanId } = usePricePlan();
  const { showContactForm } = useFeedback();
  const { sendPlanTrialRequest } = useFeatureLimitActions();
  const router = useRouter();
  const { trackFeatureAccessEvent } = useFeatureAccessEvent();

  const { isOverFeatureLimit, isFeatureEnabled, featureLimit, loading } = useLimit({ feature, fromCreationModal });

  const isSending = shallowRef(false);

  const featureLimitsPerPlan = {
    Grow: {
      [ACTIVE_PROJECT_LIMIT_FEATURE_KEY]: 600,
      [CUSTOM_FIELDS_FEATURE_KEY]: 'unlimited',
      [PROJECT_TEMPLATES_FEATURE_KEY]: 50,
      [FINANCIAL_BUDGETS_FEATURE_KEY]: 30,
      [TIME_BUDGETS_FEATURE_KEY]: 'unlimited',
      [PROOFS_FEATURE_KEY]: 50,
    },
  };

  function nextPlanUpFeatureLimit(nextPlanUpName) {
    return featureLimitsPerPlan?.[nextPlanUpName]?.[feature];
  }

  async function onClickFeatureCallToActionUser(planId, entryPoint = '') {
    if (isSending.value) {
      return;
    }

    let message = `${t('Hey there,')}\n\n`;

    if (feature === FINANCIAL_BUDGETS_FEATURE_KEY) {
      message += t('I have reached the maximum number of financial budgets available on our plan.');
      message += t(
        'To add more financial budgets we need to either delete one or upgrade to a higher plan to get more financial budgets and much more.',
      );
    } else if (feature === TIME_BUDGETS_FEATURE_KEY) {
      message += t('I have reached the maximum number of time budgets available on our plan.');
      message += t(
        'To add more time budgets we need to either delete one or upgrade to a higher plan to get more time budgets and much more.',
      );
    } else if (feature === CUSTOM_FIELDS_FEATURE_KEY) {
      message += t('I have reached the maximum number of custom fields available on our plan.');
      message += t(
        'To add more custom fields we need to either delete one or upgrade to a higher plan to get more custom fields and much more.',
      );
    } else if (feature === PROOFS_FEATURE_KEY) {
      message += t('I have reached the maximum number of proofs available on our plan.');
      message += t(
        'To add more proofs we need to either delete one or upgrade to a higher plan to get more proofs and much more.',
      );
    } else if (feature === INTAKE_FORMS_FEATURE_KEY) {
      message += t('I have reached the maximum number of forms available on our plan.');
      message += t(
        'To add more forms we need to either delete one or upgrade to a higher plan to get more forms and much more.',
      );
    }

    message += t('As an admin, could you review our upgrade options.');

    message += `\n\n${t('Thanks!')}`;

    try {
      isSending.value = true;
      await sendPlanTrialRequest(planId, feature, message);
      toast.success(t('A request has been sent to your administrator'));
      if (entryPoint === 'FeatureLimitUpgradeModal') {
        trackFeatureAccessEvent('ask_admin_form_submitted');
      }
    } finally {
      isSending.value = false;
    }
  }

  function onClickFeatureCallToActionAdmin(entryPoint = '') {
    if (feature === 'budgets' || isFeatureEnabled.value) {
      if (canAccessCheckout.value) {
        router.push({ path: '/redirect/checkout' });
      } else {
        showContactForm(
          false,
          'checkoutExcludedUser',
          'Contact Us',
          'Simply fill out your details here to make changes to your subscription, and your Customer Success representative will contact you to figure out the best solution for your needs.',
          entryPoint,
        );
      }
    } else if (isOverFeatureLimit.value) {
      window.location = 'mailto:sales@teamwork.com';
    }
  }

  function onClickFeatureCallToAction(entryPoint = '') {
    if (user.value.administrator) {
      onClickFeatureCallToActionAdmin(entryPoint);
    } else {
      onClickFeatureCallToActionUser(pricePlanId.value, entryPoint);
    }
  }

  return {
    featureHasLimits,
    featureLimit,
    onClickFeatureCallToAction,
    onClickFeatureCallToActionUser,
    isFeatureEnabled,
    isOverFeatureLimit,
    isSending,
    nextPlanUpFeatureLimit,
    loading,
  };
}
