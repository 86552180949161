import { useFeaturesUtil } from './useFeaturesUtil';

const symbol = Symbol('useFeatures');

function Features(minimal) {
  const { initialized, ldFlag, twEnabled, twValue } = useFeaturesUtil(minimal);

  const lightspeedListViewToggleEnabledEarlyAccessSegment = ldFlag('projects-lightspeed-view-toggle--list-view', false);
  const lightspeedListViewToggleEnabledFinalSegment = ldFlag('projects-listview-beta-toggle-default-off', false);

  const lightspeedListViewToggleEnabled = computed(
    () => lightspeedListViewToggleEnabledEarlyAccessSegment.value || lightspeedListViewToggleEnabledFinalSegment.value,
  );

  return {
    initialized,
    // LaunchDarkly features
    hotjarEnabled: ldFlag('projects-hotjar', false),
    importOnboardingExperimentEnabled: ldFlag('projects-import-onboarding-experiment', false),
    integrateOnboardingExperimentEnabled: ldFlag('projects-integrate-onboarding-experiment', false),
    legacyPlansCanUpdatePaymentDetailsEnabled: ldFlag('projects-legacy-plans-can-update-payment-details', false),
    productTourEnabled: ldFlag('projects-show-in-app-product-tour', false),
    inboxEnabled: ldFlag('projects-show-inbox-new-notification-bell', false),
    projectsGoogledriveEnabled: ldFlag('projects-task-file-integration-googledrive', false),
    captureProductOutcomesExperimentEnabled: ldFlag('projects-capture-product-outcomes-experiment', false),
    hotspotTourEnabled: ldFlag('projects-hotspot-tour-flag', false),
    hideUpgradePathsForMaxpSmallAccountsEnabled: ldFlag('projects-hide-upgrade-paths-maxp-small-accounts', false),
    vueListViewEnabled: ldFlag('projects-vue-list-view', false),
    addUsersCheckoutModalEnabled: ldFlag('projects-show-add-users-checkout-modal', false),
    productOutcomeFlowEnabled: ldFlag('projects-show-product-outcome-flow', false),
    clientOverviewEnabled: ldFlag('projects-client-view-dashboard', false),
    clientListDeskTicketCountEnabled: ldFlag('projects-client-ticket-count', false),
    clientOverviewCardsEnabled: ldFlag('projects-client-overview-3widgets-plangate-cow01', false),
    clientTimePageEnabled: ldFlag('projects-client-view-logged-time', false),
    peopleJobRolesPageEnabled: ldFlag('projects-people-job-roles', false),
    customReportingEnabledLD: ldFlag('projects-custom-reporting', false),
    customReportingActionsRestricted: ldFlag('projects-custom-reporting-restricted', false),
    projectsScaleUpgradeSlatesEnabled: ldFlag('projects-scale-upgrade-slates-enabled', false),
    lightSpeedReportsGalleryEnabled: ldFlag('projects-lightspeed-reports-gallery', false),
    lightSpeedReportsPortEnabled: ldFlag('projects-reports-lightspeed-port', false),
    newPrecannedReportsEnabled: ldFlag('projects-reporting-new-precanned', false),
    userTaskCompletionReportLDEnabled: ldFlag('projects-reporting-pre-tasks-completion-report', false),
    projectsReportsFormulaFieldsEnabled: ldFlag('projects-reports-formula-fields', false),
    showTableViewFilterEnabled: ldFlag('projects-show-table-view-filter', false),
    profilePhotoPromptEnabled: ldFlag('projects-profile-photo-prompt-experiment', false),
    addTaskButtonTextEnabled: ldFlag('projects-add-task-button-to-text', false),
    inlineEditingUpdateEnabled: ldFlag('projects-inline-editing-update', false),
    logTimeFeedbackLinkEnabled: ldFlag('projects-log-time-feedback-link-enabled', false),
    shouldReduceElasticsearchLoad: ldFlag('projects-elasticsearch-should-reduce-load', false),
    lightspeedOnboardingChecklistEnabled: ldFlag('projects-lightspeed-onboarding-checklist', false),
    lightspeedOnboardingEnabled: ldFlag('projects-lightspeed-onboarding', false),
    newOverviewEnabled: ldFlag('projects-new-planning-overview-tab', false),
    showOverviewToggle: ldFlag('projects-planning-new-overview-tab-toggle', false),
    clientCommunicationsEnabled: ldFlag('projects-lightspeed-client-comms', false),
    projectsPlantrialUpgradeSlatesEnabled: ldFlag('projects-plantrial-upgrade-slates-enabled', false),
    newSchedulerEnabled: ldFlag('projects-show-new-scheduler', false),
    oldSchedulerEnabled: ldFlag('projects-show-old-scheduler', false),
    lightspeedSpacesPanelEnabled: ldFlag('projects-spacesPanel-enabled', false), // Owner Peter Coppinger
    newUserProfileEnabled: ldFlag('projects-lightspeed-new-user-profile', false),
    projectsWorkflowsEnabled: ldFlag('projects-workflows', false),
    lightspeedProjectsAddTasklistDefaultsTab: ldFlag('projects-add-tasklist-defaults-tab', false),
    automationsBoardTriggerButtonExperimentEnabled: ldFlag(
      'projects-automations-board-trigger-button-experiment',
      false,
    ),
    projectsLightspeedTaskDetails: ldFlag('projects-lightspeed-task-details', false),
    projectsOpenAIEnabled: ldFlag('projects-open-ai-enabled', false),
    projectsListViewNewTaskCreateOptions: ldFlag('projects-list-view-new-task-create-options', false),
    projectsReplaceTaskDetailsPage: ldFlag('projects-replace-task-details-page', false),
    timeApprovalsFlagEnabled: ldFlag('projects-time-approvals', false),
    projectListViewBetaToggleLocation: ldFlag('projects-listview-beta-toggle-location', false),
    formConditionalLogicEnabled: ldFlag('projects-forms-conditional-logic-enabled', false),
    formQuestionsConditionalLogicEnabled: ldFlag('projects-forms-questions-conditional-logic-enabled', false),
    formConditionalLogicMultipleConditionsEnabled: ldFlag(
      'projects-forms-conditional-logic-multiple-conditions',
      false,
    ),
    formsStyleTabEnabled: ldFlag('projects-forms-style-tab', false),
    projectsDependentsSkipWeekends: ldFlag('projects-list-view-dependents-skip-weekends', false),
    hubspotChatEnabled: ldFlag('projects-hubspot-chat-enabled', false),
    timeTaskProjectPickersRecentTabEnabled: ldFlag('projects-time-task-project-pickers-recent-tab', false),
    projectsClientTicketsEnabled: ldFlag('projects-client-tickets', false),

    // LD Experiment flags
    userInviteV2Enabled: ldFlag('projects-e-23-23b-invite-user-flow', false),
    workerBeeLogTimeCtaEnabled: ldFlag('projects-exp-a-23-15-worker-bee-log-time-ctas', false),
    personalizedWorkerBeeOnboardingEnabled: ldFlag('projects-exp-a-23-29-personalised-worker-bee-onboarding', false),
    icpOnboardingImprovementsEnabled: ldFlag('projects-a-23-30-personalised-icp-onboarding-improvements', false),
    leanerUIForICPProjectManagersEnabled: ldFlag('projects-exp-e-23-17-leaner-ui-for-icp-project-managers', false),
    leanerUIForICPProjectManagersBudgetViewEnabled: ldFlag(
      'projects-exp-e-23-17-leaner-ui-for-icp-project-managers-budget-view',
      false,
    ),
    onboardingSignupSourceSurveyStepEnabled: ldFlag('projects-g-23-07-onboarding-signup-source-survey', false),
    projectsFinanceOnboardingV2Enabled: ldFlag('projects-exp-a-23-01-finance-onboarding-v2', false),
    slackIntegrationDuringOnboarding: ldFlag('projects-exp-a-23-41-slack-integration-during-onboarding', 'control'),
    exp46BadgeRatingSystemEnabled: ldFlag('projects-exp-46-badge-rating-system', false),
    leanerUIForWorkerBeesEnabled: ldFlag('projects-exp-e-23-16-leaner-ui-for-worker-bees', false),
    projectsDeskExpansionExperimentEnabled: ldFlag('projects-exp-23-25-deliver-desk-expansion', false),
    projectsDeliverToGrowSurfaceUpgradePaths: ldFlag('projects-exp-e-23-30-dg-upgrade-paths', false),
    projectsDummyDataEnabled: ldFlag('projects-exp-a-23-27-dummy-data', false),
    projectsDummyDataV3Enabled: ldFlag('projects-exp-a-23-27-dummy-data-v3', false),
    projectsFeatureTrialScale: ldFlag('projects-exp-e-23-21a-feature-trials-scale', false),
    projectsFeatureTrialGrow: ldFlag('projects-exp-e-23-21b-feature-trials-grow', false),
    projectsFeatureTrialGrowTasklistBudgets: ldFlag('projects-exp-e-23-21b-feature-trials-grow-tasklistbudgets', false),
    projectsFeatureTrialGrowRetainerBudgets: ldFlag('projects-exp-e-23-21b-feature-trials-grow-retainerbudgets', false),
    projectsFeatureTrialGrowCustomReports: ldFlag('projects-exp-e-23-21b-feature-trials-grow-customreporting', false),
    projectsFeatureTrialGrowCompanyTimesheets: ldFlag(
      'projects-exp-e-23-21b-feature-trials-grow-companytimesheets',
      false,
    ),
    pinningTimeToSideNavForPMsEnabled: ldFlag('projects-exp-a-23-42-pinning-time-to-side-nav-for-pms', false),
    referralProgramMessagingOptimisationEnabled: ldFlag(
      'projects-exp-a-23-44-referral-program-messaging-optimisation',
      false,
    ),
    onboardingForPaidInvitedUsersEnabled: ldFlag('projects-exp-a-23-45-onboarding-for-paid-invited-users', false),
    optimiseFreeForeverToPaidUpgradeUXEnabled: ldFlag(
      'projects-exp-a-23-18-optimise-free-forever-to-paid-upgrade-ux',
      false,
    ),
    projectsCraicDealerMaxProjects: ldFlag('projects-exp-e-23-01-craic-dealer-maxp', false),
    exp12ImproveCancellationFlowEnabled: ldFlag('projects-exp-23-12-improved-cancellation', false),
    optimiseUpgradeNowCTAForTialsEnabled: ldFlag('projects-exp-a-23-54-optimise-upgrade-now-cta-for-trials', 'control'),
    contextAwareHelpMenuEnabled: ldFlag('projects-exp-a-23-49-context-aware-help-menu', false),
    projectsBlackFridayDeliver: ldFlag('projects-exp-r-23-04-black-friday-deliver', false),
    projectsBlackFridayGrow: ldFlag('projects-exp-r-23-04-black-friday-grow', false),
    projectsBlackFridayOffer: ldFlag('projects-exp-r-23-04-black-friday-offer', false),
    promoteGrowPlanDuringTrialEnabled: ldFlag('projects-exp-a-23-57-promote-grow-plan-during-trial', false),
    projectsJan2024SaleEnabled: ldFlag('projects-exp-e-24-01-jan-sale', false),
    budgetsUxImprovementsEnabled: ldFlag('projects-exp-a-23-63-budgets-ux-improvements', false),
    projectsGlobalWorkflowsEnabled: ldFlag('projects-global-workflows', false),
    projectsRepsContactEnabled: ldFlag('projects-exp-r-24-01-reps-contact', false),
    displayAccountExecContacDetailsToTrialsEnabled: ldFlag(
      'projects-exp-a-24-08-display-account-exec-contac-details-to-trials',
      false,
    ),

    // Lightspeed Route Features
    lightspeedListViewToggleEnabled,
    lightspeedListViewEnabled: ldFlag('projects-lightspeed-list-view', false),
    projectsListViewBetaToggleDefaultOff: lightspeedListViewToggleEnabledFinalSegment,
    lightspeedTableViewEnabled: ldFlag('projects-lightspeed-table-view', false),
    lightspeedTableViewToggleEnabled: ldFlag('projects-lightspeed-view-toggle-table-view', false),
    lightspeedInboxEnabled: ldFlag('projects-lightspeed-inbox', false),
    cancellationFlowReactivationEnabled: ldFlag('projects-cancellation-end-of-subscription', false),
    lightspeedPeoplePageEnabled: ldFlag('projects-lightspeed-people-page', false), // Owner Peter Coppinger (repurposed for project level)
    lightspeedPeoplePageToggleEnabled: ldFlag('projects-lightspeed-view-toggle--people-page', false), // Owner Peter Coppinger (repurposed for project level)
    lightspeedPeopleCompaniesPageEnabled: ldFlag('projects-lightspeed-people-companies-page', false), // Owner Peter Coppinger
    lightspeedPeopleCompaniesPageToggleEnabled: ldFlag('projects-lightspeed-view-toggle--people-companies-page', false), // Owner Peter Coppinger
    commandCenterEnabled: ldFlag('projects-view-command-center', false), // Owner Peter Coppinger
    lightspeedTimePagesEnabled: ldFlag('projects-lightspeed-time-pages', false),
    lightspeedFormsPublicFormEnabled: ldFlag('projects-forms-new-public-form', false),
    projectsRevenueUxSprintEnabled: ldFlag('projects-revenue-ux-sprint', false),

    // Teamwork features
    proofsLimit: twValue('proofs', Number),
    proofsEnabled: twEnabled('proofs'),
    activeProjectLimit: twValue('activeprojectlimit', Number),
    auditTrailEnabled: twEnabled('audittrail'),
    clockInOutEnabled: twEnabled('clockinout'),
    clientUsersEnabled: twEnabled('clientusers'),
    companyTeamsEnabled: twEnabled('statusescompanyteams'),
    customfieldsLimit: twValue('customfields', Number, undefined, true),
    customfieldsProjectsEnabled: twEnabled('customfieldsprojects'),
    customfieldsTasksEnabled: twEnabled('customfieldstasks'),
    customReportingEnabled: twEnabled('customreporting'),
    companyTimesheetsEnabled: twEnabled('companytimesheets'),
    timeRemindersEnabled: twEnabled('timereminders'),
    timeApprovalsEnabled: twEnabled('timeapprovals'),
    intakeFormsEnabled: twEnabled('intakeforms'),
    intakeFormsConditionalLogicEnabled: twEnabled('intakeformsconditionallogic'),
    intakeFormsAdvancedConditionalLogicEnabled: twEnabled('intakeformsadvancedconditionallogic'),
    myBoardsEnabled: twEnabled('myboards'),
    newGanttEnabled: twEnabled('newgantt'),
    newSchedulerInPricePlan: twEnabled('newscheduler'),
    newSchedulerPeopleTab: twEnabled('newschedulerpeopletab'),
    newSchedulerInsights: twEnabled('newschedulerinsights'),
    newSchedulerTentativeProjects: twEnabled('newschedulertentativeprojects'),
    newSchedulerTentativeProjectsLimit: twValue('newschedulertentativeprojects', Number),
    newSchedulerTasksEnabled: twEnabled('newschedulertasks'),
    resourceSchedulingEnabled: twEnabled('resourcescheduling'),
    plannedVsActualMilestoneReportEnabled: twEnabled('plannedactualmilestonereport'),
    plannedVsActualTasksReportEnabled: twEnabled('plannedactualtasksreport'),
    portfolioEnabled: twEnabled('portfolio'),
    profitabilityEnabled: twEnabled('profitability'),
    projectBudgetExpensesEnabled: twEnabled('projectbudgetexpenses'),
    financialBudgetLimit: twValue('projectfinancialbudgets', Number, undefined, true),
    projectFinancialBudgetsEnabled: twEnabled('projectfinancialbudgets'),
    projectTemplatesEnabled: twEnabled('projecttemplates'),
    timeBudgetLimit: twValue('projecttimebudgets', Number, undefined, true),
    projectTimeBudgetsEnabled: twEnabled('projecttimebudgets'),
    projectUpdatesEnabled: twEnabled('projectupdates'),
    reportsEnabled: twEnabled('reports'),
    projectsHealthReportEnabled: twEnabled('projectshealthreport'),
    projectTimeReportEnabled: twEnabled('projecttimereport'),
    scheduleReportingEnabled: twEnabled('schedulereporting'),
    taskTimeReportEnabled: twEnabled('tasktimereport'),
    userTimeReportEnabled: twEnabled('userloggedtimereport'),
    timeReportEnabled: twEnabled('timereport'),
    userLoggedTimeReportEnabled: twEnabled('userloggedtimereport'),
    userTaskCompletionReportEnabled: twEnabled('usertaskcompletionreport'),
    tasklistBudgetsEnabled: twEnabled('tasklistbudgets'),
    tasklistBudgetsLimit: twValue('tasklistbudgets', Number, undefined, true),
    companyTimesheetsLimit: twValue('companytimesheets', Number, undefined, true),
    teamsSubteamsEnabled: twEnabled('teamssubteams'),
    teamsProjectsEnabled: twEnabled('teamsdefaultprojects'),
    utilizationReportEnabled: twEnabled('utilizationreport'),
    workloadBacklogEnabled: twEnabled('workloadbacklog'),
    workloadPlannerEnabled: twEnabled('workloadplanner'),
    riskRegisterEnabled: twEnabled('riskregister'),
    accessGoogleSheetsExports: twEnabled('accessgooglesheetsexports'),
    retainerBudgetsEnabled: twEnabled('retainerbudgets'),
    retainerBudgetsLimit: twValue('retainerbudgets', Number, undefined, true),
    projectTemplatesLimit: twValue('projecttemplates', Number, undefined, true),
    intakeFormsLimit: twValue('intakeforms', Number, undefined, true),
    retrospectiveRates: twEnabled('retrospectiverates'),
    clientOverviewCardsFeatureEnabled: twEnabled('clientoverviewcards'),
    teamBulkActionsEnabled: twEnabled('teamsbulkactions'),
    sessionTimeoutEnabled: twEnabled('sessiontimeout'),

    // Calendar flags
    calendarGoogleLogtimeFlagEnabled: ldFlag('projects-google-calendar-log-time', false),
    calendarGoogleLogtimeEnabled: twEnabled('googlecalendarlogtime'),
    googleCalendarIntegrationEnabled: ldFlag('projects-integrations-google-calendar', false),
    lightspeedCalendarEnabled: ldFlag('projects-lightspeed-calendar', false),
    calendarEnabled: twEnabled('mycalendar'),
    calendarTasksEnabled: ldFlag('projects-calendar-tasks-calendar', false),
    calendarGoogleEnabled: ldFlag('projects-calendar-google-calendar', false),
    calendarGoogleCalSyncEnabled: twEnabled('googlecalsync'),
    calendarGoogleReadEnabled: twEnabled('googlecalendarreadevents'),
    calendarGoogleEditEnabled: twEnabled('googlecalendareditevents'),
    calendarTimeBlocksEnabled: twEnabled('timeblocks'),
    calendarTimeBlocksFlagEnabled: ldFlag('projects-calendar-time-blocks', false),
    calendarLogTimeBlocksEnabled: twEnabled('logtimeblocks'),
    calendarLogTimeBlocksFlagEnabled: ldFlag('projects-calendar-log-time-blocks', false),
    calendarGoogleRecurringEventEditEnabled: ldFlag('projects-calendar-google-recurring-event-edit', false),
  };
}

/**
 * Sets up LaunchDarkly and Teamwork features.
 * @param {Boolean} minimal Facilitates 'minimal API' context. If `true` will only
 * support LaunchDarkly features
 */
export function provideFeatures(minimal = false) {
  provide(symbol, Features(minimal));
}

/**
 * Provides access to LaunchDarkly and Teamwork features.
 * @type {Features}
 */
export function useFeatures() {
  return inject(symbol);
}
