export const createFormatNumber = ({ languageCode }) => {
  /**
   * Returns a formatted number as a string.
   * @param {Number} number
   * @param {Intl.NumberFormatOptions} [options] Optional object with options on how to format the number.
   * @return {String} Formatted number string.
   */
  function formatNumber(number, options) {
    return Intl.NumberFormat(languageCode.value, options).format(number);
  }

  /**
   *
   * @param {Number} number
   * @param {Intl.NumberFormatOptions} [options] Optional object with options on how to format the percentage.
   * @returns {String} Formatted percentage string.
   */
  function formatPercentage(number, options = {}) {
    return formatNumber(number, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      style: 'percent',
      ...options,
    });
  }

  /**
   * Formats a file size in bytes into a human readable string.
   * @param {Number} fileSize
   * @param {Intl.NumberFormatOptions} [options] Optional object with options on how to format the percentage.
   * @returns {String} Formatted percentage string.
   */
  function formatFileSize(fileSize, options = {}) {
    return formatNumber(fileSize ?? 0, {
      notation: 'compact',
      style: 'unit',
      unit: 'byte',
      unitDisplay: 'narrow',
      ...options,
    });
  }

  return {
    formatNumber,
    formatPercentage,
    formatFileSize,
  };
};
