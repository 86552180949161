import './VTabs.variants.css';

export const VTabsUnderline = {
  class: 'VTabsUnderline',
};

export const VTabsWidgetNavigation = {
  class: 'VTabsWidgetNavigation',
};

export const VTabsWidgetEditor = {
  class: 'VTabsWidgetEditor',
};

export const VTabsSmall = {
  class: 'VTabsSmall',
};
