<script setup>
import { useElementSize } from '@vueuse/core';
import * as TOKENS from '@teamwork/lightspeed-designsystem-tokens/default';
import LscFileItemOverlayButton from './LscFileItemOverlayButton.vue';
import { escapeMarkdown, getFileIcon, isImage, isPreviewableFile, useI18n } from '@/util';
import LscChip from '../chip/LscChip.vue';

const props = defineProps({
  /**
   * Whether the user can download files or not. Ususally retrieved from the `project permissions`.
   */
  canDownloadFiles: {
    type: Boolean,
    required: true,
  },
  /**
   * Whether the user can preview files or not. Ususally retrieved from the `project permissions`.
   */
  canPreviewFiles: {
    type: Boolean,
    required: true,
  },
  /**
   * The `file` object. Refer to `fileUtils.js` for more details on how we normalize the `file` object.
   */
  file: {
    type: Object,
    required: true,
  },
  /**
   * The variants of the file item. By default it is set to `default`. WIP Only default is currently reflected in our Design System.
   */
  variant: {
    type: String,
    default: 'default',
    validator(prop) {
      return ['default', 'compact', 'thumbnail'].includes(prop);
    },
  },
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['preview', 'download', 'click']);

const { formatDateTime, t } = useI18n();

const GoogleDriveIcon = defineAsyncComponent(() => import('../../../assets/icons/GoogleDriveIcon.svg'));
const DropboxIcon = defineAsyncComponent(() => import('../../../assets/icons/DropboxIcon.svg'));
const OneDriveIcon = defineAsyncComponent(() => import('../../../assets/icons/OneDriveIcon.svg'));
const SharePointIcon = defineAsyncComponent(() => import('../../../assets/icons/SharePointIcon.svg'));
const BoxIcon = defineAsyncComponent(() => import('../../../assets/icons/BoxIcon.svg'));

const fileItemRef = shallowRef();
const { width } = useElementSize(fileItemRef);

const variantMap = {
  thumbnail: { sheet: 'flex-col', image: 'w-full h-32' },
  compact: { image: 'flex-none size-14' },
  default: {
    image: 'flex-none size-18',
  },
};
const activeVariant = computed(() => variantMap[props.variant] || variantMap.default);

const sourceMap = {
  default: undefined,
  googledrive: {
    icon: GoogleDriveIcon,
    text: 'Google Drive',
  },
  onedrive: {
    icon: OneDriveIcon,
    text: 'OneDrive',
  },
  onedrivebusiness: {
    icon: OneDriveIcon,
    text: 'OneDrive Business',
  },
  sharepoint: {
    icon: SharePointIcon,
    text: 'Sharepoint',
  },
  dropbox: {
    icon: DropboxIcon,
    text: 'Dropbox',
  },
  'box.com': {
    icon: BoxIcon,
    text: 'Box.com',
  },
};

const fileName = computed(() => props.file.name || props.file.fileName || props.file.displayName);

const fileSource = computed(() => sourceMap[props.file.fileSource] || sourceMap.default);

const fileIcon = computed(() => getFileIcon(fileName.value));

const isImageAvailable = computed(() => props.file.src && isImage(fileName.value) && props.file.mode === 'server');

const isFilePreviewable = computed(() => {
  return props.canPreviewFiles && props.file.mode === 'server' && isPreviewableFile(props.file, props.canPreviewFiles);
});

function previewFile() {
  emit('preview', props.file);
}

const isFileDownloadable = computed(() => {
  return props.canDownloadFiles && props.file.mode === 'server';
});

function downloadFile() {
  emit('download', props.file);
}

function onContentClick() {
  if (isFilePreviewable.value) {
    previewFile();
  } else if (isFileDownloadable.value) {
    downloadFile();
  }
}

function getTooltipText(file) {
  let text = `**${escapeMarkdown(fileName.value)}**\n`;

  if (file.uploadDate?.isValid) {
    text += `${escapeMarkdown(
      t('Uploaded at {dateTime}', {
        dateTime: formatDateTime(file.uploadDate),
      }),
    )}`;

    if (file.size !== '0KB') {
      text += ' - ';
    }
  }

  if (file.size !== '0KB') {
    text += file.size;
  }
  return text;
}
</script>

<template>
  <div
    ref="fileItemRef"
    class="group/file hover:border-1 relative flex w-full cursor-pointer overflow-hidden rounded-md border border-solid border-transparent shadow-1 transition-colors ease-in-out hover:border-form-hover focus:border-2 focus:border-focus-secondary"
    :class="activeVariant.sheet"
    :data-identifier="`${dataIdentifierPrefix}-file-item`"
    @click="emit('click', file)"
  >
    <!-- upload progress -->
    <VProgressLinear
      v-if="file.isUploading && file.uploadProgress"
      :modelValue="file.uploadProgress"
      class="absolute top-0 w-full"
      color="primary"
    />

    <!-- image -->
    <div class="relative bg-surface-default" :class="activeVariant.image">
      <VImg v-if="isImageAvailable" :src="file.thumbURL" :alt="fileName" class="h-full w-full rounded-l-sm" cover>
        <template #placeholder>
          <div class="flex h-full items-center justify-center">
            <VProgressCircular
              :size="28"
              :width="2"
              indeterminate
              :bgColor="TOKENS.LsdsAColorBorderDefault"
              :color="TOKENS.LsdsAColorBorderBold"
            />
          </div>
        </template>
      </VImg>

      <div v-else-if="!file.isUploading" class="flex h-full w-full items-center justify-center">
        <VIcon :icon="fileIcon.icon" :size="36" :color="fileIcon.color" />
      </div>

      <div
        v-if="isFilePreviewable || isFileDownloadable"
        class="opacity-0 transition-opacity group-hover/file:opacity-100"
      >
        <div class="absolute inset-0 bg-surface-dark opacity-20" />
        <div class="absolute inset-0 flex items-center justify-center gap-2">
          <LscFileItemOverlayButton
            v-if="isFilePreviewable"
            icon="lsi-file-preview"
            :text="t('Preview')"
            :data-identifier="`${dataIdentifierPrefix}-file-item-preview`"
            @click.prevent="previewFile"
          />
          <LscFileItemOverlayButton
            v-if="isFileDownloadable"
            icon="lsi-download"
            :text="t('Download')"
            :data-identifier="`${dataIdentifierPrefix}-file-item-download`"
            @click.prevent="downloadFile"
          />
        </div>
      </div>
    </div>

    <!-- content -->
    <div
      class="flex w-full flex-col justify-center gap-1 overflow-hidden bg-default py-3 pl-3 pr-2"
      :class="activeVariant.content"
      @click="onContentClick"
    >
      <div class="flex justify-between gap-1 text-body-1">
        <div v-LsdTooltip.markdown="getTooltipText(file)" class="flex min-w-0 items-center gap-1 truncate">
          <LscChip v-if="file?.latestFileVersionNo > 1" size="xs">V{{ file.latestFileVersionNo }}</LscChip>
          <span class="truncate">{{ fileName }}</span>
        </div>

        <div class="flex items-center gap-2">
          <template v-if="variant === 'thumbnail' || variant === 'compact'">
            <!-- @slot Use the `menu` to show a dropdown menu to perform actions on the file -->
            <slot name="menu" :file="file" />

            <!-- @slot Use the `actions` section for privacy, lock, and comment buttons. -->
            <slot name="actions" :file="file" />
          </template>
          <template v-if="variant === 'default'">
            <slot name="menu" :file="file" />
          </template>
        </div>
      </div>

      <div v-if="variant === 'default'" class="flex justify-between gap-2">
        <div class="flex items-center gap-2 text-body-2 text-subtle">
          <template v-if="fileSource">
            <component :is="fileSource.icon" />
            <span v-if="width >= 350">{{ fileSource.text }}</span>
            <span>·</span>
          </template>
          <span v-if="file.sizeRaw > 0" class="uppercase">{{ file.size }}</span>
        </div>
        <div class="flex items-center gap-2 pr-1">
          <!-- @slot Use the `actions` section for privacy, lock, and comment buttons. -->
          <slot name="actions" :file="file" />
        </div>
      </div>
    </div>
  </div>
</template>
