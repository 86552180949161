<script setup>
defineProps({
  template: {
    type: Object,
    required: true,
  },
});

const SampleTemplatePreviewPanel = defineAsyncComponent(() => import('./SampleTemplatePreviewPanel.vue'));
</script>

<template>
  <WidgetDialog :width="860" :minHeight="400">
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <SampleTemplatePreviewPanel :template="template" @close="close" />
    </template>
  </WidgetDialog>
</template>
