<script setup>
import { useI18n } from '@/util';
import TemplatesDrawerProjectsBlankSlate from './TemplatesDrawerProjectsBlankSlate.svg';

const { t } = useI18n();
</script>
<template>
  <LscEmptyState class="my-8" size="sm" :title="t('No results')" :message="t('No templates match your search')">
    <template #image>
      <TemplatesDrawerProjectsBlankSlate />
    </template>
  </LscEmptyState>
</template>
