<script setup>
import { tv } from '../../../util/tailwind/tv';

const props = defineProps({
  /**
   * The icon to display.
   * @type {import('vue').PropType<import('@teamwork/lightspeed-icons/dist/icon-list').all|undefined>}
   */
  icon: {
    type: String,
    default: '',
  },
  /**
   * The title of the info callout.
   */
  title: {
    type: String,
    required: true,
  },
  /**
   * The size of the callout.
   * @type {import('vue').PropType<'sm' | 'md' | 'lg'>}
   */
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md', 'lg'].includes(value),
  },
  /**
   * The message of the info callout.
   */
  message: {
    type: String,
    default: '',
  },
  /**
   * The primary button text.
   */
  primaryButtonText: {
    type: String,
    default: undefined,
  },
  /**
   * The secondary button text.
   */
  secondaryButtonText: {
    type: String,
    default: undefined,
  },
  /**
   * The image to display.
   */
  image: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['primaryAction', 'secondaryAction']);

const LscInfoCalloutVariantStyleConfig = tv({
  base: ['flex flex-col items-center overflow-auto rounded-lg bg-default shadow-2'],
  slots: {
    body: 'flex flex-col items-center justify-center p-6',
    icon: 'text-[color:--lsds-a-color-decorative-6]',
    title: 'text-balance py-2 text-center text-default',
    message: 'text-pretty text-center text-body-1 text-default',
    media: 'flex w-full items-center justify-center',
    image: 'w-full object-cover',
    buttons: 'mt-3 flex flex-wrap items-center justify-center gap-4',
  },
  variants: {
    size: {
      sm: {
        image: ['h-32'],
        base: ['w-56'],
        body: ['px-4'],
        buttons: ['flex-col-reverse gap-3'],
        title: ['text-subtitle-2'],
      },
      md: {
        image: ['h-40'],
        base: ['w-72'],
        title: ['text-subtitle-2'],
      },
      lg: {
        image: ['h-44'],
        base: ['w-80'],
        title: ['text-subtitle-1'],
      },
    },
  },
});

const classes = computed(() => LscInfoCalloutVariantStyleConfig({ size: props.size }));
</script>

<template>
  <WidgetMenu>
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <div :class="classes.base()">
      <div v-if="$slots.media || image" :class="classes.media()">
        <!--@slot media - the slot for the image -->
        <slot name="media"><img :src="image" :class="classes.image()" /></slot>
      </div>
      <div :class="classes.body()">
        <LscIcon v-if="icon" :class="classes.icon()" :icon="icon" size="xl" />
        <h4 :class="classes.title()">{{ title }}</h4>
        <div v-if="message || $slots.message" :class="classes.message()">
          <slot name="message">
            <p>{{ message }}</p>
          </slot>
        </div>
        <div :class="classes.buttons()">
          <slot name="actions">
            <LscButton
              v-if="secondaryButtonText"
              size="md"
              variant="plain-primary"
              @click="emit('secondaryAction', $event)"
            >
              {{ secondaryButtonText }}
            </LscButton>
            <LscButton v-if="primaryButtonText" size="md" variant="primary" @click="emit('primaryAction', $event)">
              {{ primaryButtonText }}
            </LscButton>
          </slot>
        </div>
      </div>
    </div>
  </WidgetMenu>
</template>
