<script setup>
import { useLocalStorage } from '@vueuse/core';
import { useCurrentAccount, useCurrentUser, usePermissions, useExperimentA57, usePendo } from '@/api';
import { useLegacyFrameOffset } from '@/module/legacy';
import { useI18n, getToday } from '@/util';
import AppShellTrialWelcomeBannerIcon from './AppShellTrialWelcomeBannerIcon.svg';

defineOptions({
  inheritAttrs: false,
});

const { t, formatDate } = useI18n();
const user = useCurrentUser();
const account = useCurrentAccount();
const { isExpA57Variation, EXP_A57_COMMON_METRICS: commonMetrics } = useExperimentA57();
const { isSiteOwner } = usePermissions();
const showTrialWelcomeBanner = useLocalStorage('showTrialWelcomeBanner', isExpA57Variation.value);
const { trackPendoEvent } = usePendo();

const bannerEl = shallowRef(null);
const today = getToday();

useLegacyFrameOffset(bannerEl);

const isTrialWelcomeBannerVisible = computed(() => {
  return (
    showTrialWelcomeBanner.value &&
    isSiteOwner.value &&
    account.value.paymentStatus !== 'paid' &&
    formatDate(account.value.dateSignedUp, 'medium') === formatDate(today, 'medium')
  );
});

const bannerTitle = computed(() => {
  return t('Welcome, {userName}! Your {trialDaysRemaining} day {pricePlanName} trial starts today!', {
    userName: user.value.firstName,
    trialDaysRemaining: account.value.trialDaysRemaining,
    pricePlanName: 'Grow',
  });
});

const bannerMessage = computed(() => {
  return t(
    'The {pricePlanName} plan is perfect for marketing agencies who want to track billable time, manage team capacity and report on project performance.',
    { pricePlanName: 'Grow' },
  );
});

function trackEvent() {
  trackPendoEvent({
    eventName: 'GROW_PROMO_EVENT',
    commonMetrics,
    metadata: {
      event_action: 'welcome_banner_dismissed',
    },
  });
}

function onCloseTrialWelcomeBanner() {
  trackEvent();
  showTrialWelcomeBanner.value = false;
}
</script>

<template>
  <LscAlert
    v-if="isTrialWelcomeBannerVisible"
    v-bind="$attrs"
    ref="bannerEl"
    layout="banner"
    closable
    variant="info-subdued"
    :title="bannerTitle"
    :message="bannerMessage"
    @close="onCloseTrialWelcomeBanner"
  >
    <template #media>
      <AppShellTrialWelcomeBannerIcon />
    </template>
  </LscAlert>
</template>
