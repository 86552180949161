<script setup>
const props = defineProps({
  /**
   * Used to set the item's active state.
   */
  active: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to set the item's draggable state.
   */
  draggable: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to highlight the bottom of the item.
   */
  showBottomDragIndicator: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to highlight the top of the item.
   */
  showTopDragIndicator: {
    type: Boolean,
    default: false,
  },
  /**
   * Link to a external page. Component will render an anchor tag.
   */
  href: {
    type: String,
    default: undefined,
  },
  /**
   * Any icon, will be rendered before the item's label.
   */
  prependIcon: {
    type: String,
    default: undefined,
  },
  /**
   * To link to a route. Component will render a router-link.
   */
  to: {
    type: String,
    default: undefined,
  },
});

const componentToRender = computed(() => {
  if (props.href) {
    return 'a';
  }
  if (props.to) {
    return 'RouterLink';
  }
  return 'button';
});
</script>

<template>
  <Component
    v-bind="$attrs"
    :is="componentToRender"
    :class="[
      'group/LscSidebarItem relative flex h-9 w-full cursor-pointer items-center text-body-1 text-default no-underline',
      'hover:bg-hover aria-selected:bg-surface-emphasis-selected',
      {
        'before:absolute before:-top-px before:z-10 before:h-0.5 before:w-full before:bg-action-primary-default before:content-[\'\']':
          showTopDragIndicator,
        'after:absolute after:-bottom-px after:z-10 after:h-0.5 after:w-full after:bg-action-primary-default after:content-[\'\']':
          showBottomDragIndicator,
      },
    ]"
    :type="componentToRender === 'button' ? 'button' : undefined"
    :aria-selected="active"
    :href="href"
    :to="to"
    :draggable="draggable"
  >
    <!-- Left border that appears on selected items -->
    <div v-if="active" class="absolute left-0 top-0 h-full w-1 border-0 border-l-4 border-solid border-form-active" />

    <div
      class="mx-2 inline-flex h-4 w-4 flex-none items-center justify-center"
      :class="{
        'cursor-move': draggable,
      }"
    >
      <div
        class="flex items-center justify-center"
        :class="{
          'group-hover/LscSidebarItem:hidden': draggable,
          'text-[color:--lsds-a-color-icon-secondary-default]': active,
          'text-icon-subtle': !active,
        }"
      >
        <!-- @slot For content that should replace handle on hover -->
        <slot name="handle-default" />
      </div>
      <LscIcon v-if="draggable" icon="lsi-drag" size="md" class="hidden group-hover/LscSidebarItem:block" />
    </div>

    <div v-if="prependIcon" class="mr-2 inline-flex w-[--lsds-a-icon-size-md] items-center justify-center">
      <LscIcon
        v-if="prependIcon"
        :icon="prependIcon"
        size="md"
        :class="active ? 'text-[color:--lsds-a-color-icon-secondary-default]' : 'text-icon-subtle'"
      />
    </div>

    <LscOverflowEllipsis
      class="block items-center"
      :class="{
        'font-semibold text-default': active,
      }"
    >
      <slot />
    </LscOverflowEllipsis>

    <div v-if="$slots.append" class="mx-3 ml-auto inline-flex h-full items-center justify-center pl-3">
      <!-- @slot To render a badge -->
      <slot name="append" />
    </div>
  </Component>
</template>
