<script setup>
import FeatureTrialUpgradeDialogPanel from './FeatureTrialUpgradeDialogPanel.vue';

const props = defineProps({
  featureKey: {
    type: String,
    required: true,
  },
  featureName: {
    type: String,
    required: true,
  },
  originFeature: {
    type: String,
    default: '',
  },
  learnMoreLink: {
    type: String,
    default: '',
  },
  showLearnMoreLink: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <WidgetDialog width="960px">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <FeatureTrialUpgradeDialogPanel v-bind="props" @close="close()" />
    </template>
  </WidgetDialog>
</template>
