<script setup>
import { useI18n } from '@/util';
import ProjectJumpToDrawerListProjects from './ProjectJumpToDrawerListProjects.vue';

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
  step: {
    type: Number,
    default: 10,
  },
  shouldLoadOnScroll: {
    type: Boolean,
    default: true,
  },
  useNewList: {
    type: Boolean,
    default: true,
  },
});

const count = defineModel('count', {
  type: Number,
  required: true,
});

const { t } = useI18n();

const projects = computed(() => unref(props.state.items));
</script>

<template>
  <WidgetLoadingState :state="state">
    <template #default>
      <template v-if="useNewList">
        <div class="h-full overflow-y-auto">
          <VList density="compact" class="m-0 !px-4 !pt-4">
            <ProjectJumpToDrawerListProjects :projects="projects" />
          </VList>
          <WidgetLoadingLazy v-model:count="count" :step="step" :state="state" />
        </div>
      </template>
      <template v-else>
        <ProjectJumpToDrawerListProjects :projects="projects" />
        <WidgetLoadingLazy v-model:count="count" :step="step" :state="state" />
      </template>
    </template>
    <template #loading>
      <slot name="loading"><WidgetLoadingProgress :message="t('Loading projects')" /></slot>
    </template>
    <template #blank>
      <slot name="blank" />
    </template>
  </WidgetLoadingState>
</template>
