<script setup>
import { useIntervalFn } from '@vueuse/core';
import { useVersion } from '@/api';
import { useI18n } from '@/util';
import { useLegacyFrameOffset } from '@/module/legacy';

const { t } = useI18n();
const { hasNewMajorVersion } = useVersion();
const bannerEl = shallowRef(null);

useLegacyFrameOffset(bannerEl);
// eslint-disable-next-line no-use-before-define
const { pause, resume } = useIntervalFn(reloadApp, 1000 * 60, { immediate: false });

function reloadApp() {
  // `window.location.reload()` blocks the event loop when `onbeforeunload` returns `false` and
  // the browser displays a Confirm Close dialog. In case that happens, we want to pause `useIntevalFn`,
  // so that it would not fire immediately or soon after the user clicks Cancel or Reload,
  // as that could force the user to click the button potentially many times
  // to catch up with all the accumulated `reload` and `onbeforeunload` calls.
  pause();
  // Try to reload the app knowing that the user might abort this action.
  window.location.reload();
  // In case the user clicked Cancel in the Confirm Close dialog, we want to restart `useIntervalFn`
  // and try to force the reload again.
  resume();
}

watch(hasNewMajorVersion, () => {
  if (hasNewMajorVersion.value) {
    resume();
  } else {
    pause();
  }
});
</script>

<template>
  <LscAlert
    v-if="hasNewMajorVersion"
    ref="bannerEl"
    variant="warning"
    layout="banner"
    :message="
      t(
        'A new version of the application is required, the application will refresh automatically in 1 minute, or you can refresh it now.',
      )
    "
  >
    <template #action>
      <LscAlertButton @click="reloadApp">
        {{ t('Refresh') }}
      </LscAlertButton>
    </template>
  </LscAlert>
</template>
