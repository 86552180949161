<script setup>
import WidgetDialogCardHeader from './WidgetDialogCardHeader.vue';
import { useLsDraggable } from '../../../composables/draggable/useLsDraggable';

defineProps({
  /**
   * The title to display in the dialog.
   */
  title: {
    type: String,
    default: '',
  },
  /**
   * An optional tooltip to display next to the title.
   */
  infoTooltip: {
    type: [String, Object],
    default: undefined,
  },
  /**
   * The prefix to use for data identifiers.
   */
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
  /**
   * Whether the dialog is full screen.
   */
  fullScreen: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const { draggable } = useLsDraggable();
const card = shallowRef(null);
const dragHandle = shallowRef();

function close() {
  emit('close');
}

watch(
  [card, dragHandle],
  () => {
    if (card.value && dragHandle.value) {
      draggable(card, { dragHandle });
    }
  },
  { immediate: true },
);
</script>

<template>
  <div
    ref="card"
    class="flex min-h-0 flex-auto transform-gpu flex-col items-stretch overflow-hidden bg-default shadow-dialog"
    :class="{
      'rounded-0': fullScreen,
      'rounded-md': !fullScreen,
    }"
  >
    <!-- @slot container - Use this when you want to customize the container entirey. This might be deprecated soon -->
    <slot name="container" v-bind="{ close, dragHandle }">
      <div class="flex h-full min-h-0 grow flex-col items-stretch">
        <WidgetDialogCardHeader
          ref="dragHandle"
          :title="title"
          :infoTooltip="infoTooltip"
          :dataIdentifierPrefix="dataIdentifierPrefix"
          class="grow-0 p-6 pb-3"
          @close="close"
        >
          <template #prependHeaderLeft>
            <!-- @slot prependHeaderLeft - Before the title -->

            <slot name="prependHeaderLeft" />
          </template>
          <template #appendHeaderLeft>
            <!-- @slot appendHeaderLeft - After the title -->
            <slot name="appendHeaderLeft" />
          </template>
          <template #title>
            <!-- @slot title - Title slot -->
            <slot name="title" />
          </template>
          <template #prependHeaderRight>
            <!-- @slot appendHeaderLeft - Before the close button -->
            <slot name="prependHeaderRight" />
          </template>
          <template #appendHeaderRight>
            <!-- @slot appendHeaderRight - After the close button -->
            <slot name="appendHeaderRight" />
          </template>
        </WidgetDialogCardHeader>
        <div class="flex min-h-0 flex-1 flex-col items-stretch">
          <!-- @slot content - Use this when you dont want padding or a scrollbar -->
          <slot name="content">
            <div ref="rootEl" class="overflow-y-auto px-6 py-3 text-body-1">
              <!--@slot default - This is the main content of the dialog. -->
              <slot />
            </div>
          </slot>
        </div>

        <div v-if="$slots.actions" class="flex p-6 pt-3">
          <!-- @slot actions - Use this when you want to add actions to the dialog -->
          <slot name="actions" />
        </div>
      </div>
    </slot>
  </div>
</template>
