/**
 * Do not edit directly
 * Generated on Sat, 30 Mar 2024 00:02:50 GMT
 */

export const LsdsCSidebarDrawerColorSurface = "#d14711";
export const LsdsCSidebarDrawerIconColorOnSurface = "#ffe4d9";
export const LsdsCSidebarDrawerIconColorOnHover = "#fff1ec";
export const LsdsCSidebarDrawerIconColorOnSelected = "#ffffff";
export const LsdsCSidebarDrawerListItemBackgroundColorOnHover = "#c73f0a";
export const LsdsCSidebarDrawerListItemBackgroundColorOnFocus = "#8f9fe7";
export const LsdsCSidebarDrawerListItemBackgroundColorOnSelected = "#bf3905";
export const LsdsCSidebarDrawerListItemColorOnSurface = "#ffffff";
export const LsdsCSidebarDrawerListItemColorOnHover = "#ffffff";
export const LsdsCSidebarDrawerListItemColorOnSelected = "#ffffff";
export const LsdsCSidebarDrawerQuickAddColorSurface = "#ffffff";
export const LsdsCSidebarDrawerQuickAddColorSurfaceHover = "#edeff5";
export const LsdsCSidebarDrawerQuickAddColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerAddYourTeamColorSurface = "#ce5f34";
export const LsdsCSidebarDrawerUpgradeNowColorSurface = "#c73f0a";
export const LsdsCSidebarDrawerNotificationsBadgeColorSurface = "#ffffff";
export const LsdsCSidebarDrawerNotificationsBadgeColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerChatBadgeColorSurface = "#ffffff";
export const LsdsCSidebarDrawerChatBadgeColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerClosePanelIconColorSurface = "#0c0e1f";
export const LsdsCSidebarDrawerClosePanelIconColorOnSurface = "#eeeeee";
export const LsdsCSidebarDrawerClosePanelIconColorOnHover = "#0d0f2f";
export const LsdsCSidebarDrawerProfileColorOnSurface = "#ffffff";
export const LsdsCSidebarDrawerProfileColorOnSurfaceSubtle = "#ffffff";
