<script setup>
import TeamworkConstructionSite from '@/designsystem/assets/TeamworkConstructionSite.svg';
import { useDocumentTitle, useI18n } from '@/util';

const { t } = useI18n();
useDocumentTitle(t('Not found'));
</script>
<template>
  <main class="main">
    <div class="content">
      <div class="content-left">
        <div class="logo">
          <img src="https://cdn-brand.teamwork.com/images/logos/teamwork-slate.svg" />
        </div>
        <h1 class="heading">{{ t("This isn't the page you're looking for") }}</h1>
        <hr class="divider" />
        <p class="context">{{ t("Sorry, we can't find that page. It might be an old link or maybe it was moved.") }}</p>
      </div>
      <div class="content-right">
        <div class="graphic">
          <TeamworkConstructionSite />
        </div>
      </div>
    </div>
  </main>
</template>
<style scoped>
.main {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .main {
    max-width: 744px;
  }
}

@media (min-width: 992px) {
  .main {
    max-width: 992px;
  }
}

@media (min-width: 1280px) {
  .main {
    max-width: 1164px;
  }
}

@media (min-width: 1440px) {
  .main {
    max-width: 1224px;
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
  justify-content: center;
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.content-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
  flex: 0 0 100%;
  max-width: 100%;
}

.content-right {
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
  flex: 0 0 0%;
  max-width: 0%;
}

@media (min-width: 1280px) {
  .content-left {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .content-right {
    display: flex;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
}

.logo {
  width: 165px;
  margin-bottom: 4.5rem !important;
}

.logo img {
  width: 100%;
}

.heading {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga', 'clig';
  font-variant-ligatures: common-ligatures;
  line-height: 1;
  margin-bottom: 1rem;
  font-family: inherit;
  line-height: 1.1;
  color: inherit;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 4.571rem;
  line-height: 1.4;
  font-weight: 400;
}

.divider {
  background-color: #ff22b1;
  border: 0;
  height: 4px;
  width: 100px;
  margin: 1.5rem auto 1.5rem 0 !important;
  display: block;
  padding: 0;
  box-sizing: content-box;
  overflow: visible;
}

.context {
  color: #494e6a !important;
  font-size: 1.429rem;
  line-height: 1.6;
  margin-top: 0.5rem !important;
  margin-bottom: 1.5rem !important;
}

@media (max-width: 991.98px) {
  .heading {
    font-size: 3.6rem;
  }

  .context {
    font-size: 1.2rem;
  }
}

@media (min-width: 1280px) {
  .context {
    margin-right: 1.5rem !important;
  }
}

.graphic {
  width: 400px;
  margin-bottom: 50px;
}
</style>
