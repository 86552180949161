/**
 * Constants, computed properties and functions related to Experiment A-23-44
 * https://app.launchdarkly.com/default/production/features/projects-exp-a-23-44-referral-program-messaging-optimisation/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/48202-a-23-44-referral-program-messaging-optimisation
 * https://digitalcrew.teamwork.com/spaces/growth-and-acquisition/page/46037-g-23-06-referral-program-revive-and-expand
 */

import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

export function useExperimentA44() {
  const { referralProgramMessagingOptimisationEnabled } = useFeatures();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();

  const EXP_A44_EVENT_NAME = 'REFERRAL_EVENT';
  const EXP_A44_TEST_GROUP_EVENT_NAME = 'A-23-44_TEST_GROUP';
  const EXP_A44_COMMON_METRICS = ['plan_name', 'user_role', 'page'];
  const { launchDarklyFlagKey, defaultValue } = referralProgramMessagingOptimisationEnabled;
  const ignoreInExperimentOnRuleMatch = true;

  const isExpA44Variation = computed(() => referralProgramMessagingOptimisationEnabled.value);

  function trackExperimentA44() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
    });
  }

  function trackExperimentA44Variation() {
    trackPendoEvent({
      eventName: EXP_A44_TEST_GROUP_EVENT_NAME,
      metadata: { event_action: 'entered_test_group_a-23-44' },
      commonMetrics: EXP_A44_COMMON_METRICS,
    });
  }

  return {
    EXP_A44_EVENT_NAME,
    EXP_A44_COMMON_METRICS,
    isExpA44Variation,
    trackExperimentA44,
    trackExperimentA44Variation,
  };
}
