const pages = Array.from(Object.entries(import.meta.glob('@/module/playground/*.vue')), ([path, load]) => ({
  name: path.replace(/^.*\/(\w+)\.vue$/, '$1'),
  load,
}));

export const playgroundRoutes = [
  {
    path: '/playground',
    component: () => import('./RoutePlayground.vue'),
    children: [
      {
        path: '',
        component: () => import('./RoutePlaygroundHome.vue'),
        props: {
          playgrounds: pages.map(({ name }) => name),
        },
      },
      ...pages.map(({ name, load }) => ({
        path: name,
        component: () => import('./RoutePlaygroundPage.vue'),
        props: {
          name,
        },
        children: [
          {
            path: '',
            component: load,
          },
        ],
      })),
    ],
  },
];
