<script setup>
import { useExperimentA30, useExperimentA45 } from '@/api';
import { useI18n } from '@/util';

const { t } = useI18n();
const { isExpA30Variation } = useExperimentA30();
const { isExpA45SiteAdministratorVariation } = useExperimentA45();

const isICPOnboarding = computed(() => isExpA30Variation.value || isExpA45SiteAdministratorVariation.value);
</script>
<template>
  <div class="text-body-1">
    <p>{{ t('Make sure your team delivers on budget and is paid for every minute of work.') }}</p>
    <p class="pt-6">{{ t('Over the next 2 minutes, discover how you can:') }}</p>
    <ul class="mx-10">
      <!-- Track billable time -->
      <li>{{ t('Work within a budget') }}</li>
      <li>{{ t('Set billable rates') }}</li>
      <li>{{ t('Track every billable minute') }}</li>
      <li v-if="isICPOnboarding">{{ t('Manage your time logs') }}</li>
      <li v-if="isICPOnboarding">{{ t('Budget at a granular level') }}</li>
    </ul>
  </div>
</template>
