import { DateTime } from 'luxon';
import { useItemLoader } from '../base/useItemLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';

/* eslint-disable no-param-reassign */
function responseToItem({ data: { project } }) {
  project.createdOn = DateTime.fromISO(project.createdOn);
  project.isCompleted = Boolean(project.substatus === 'completed');
  project.isStarred = Boolean(project.isStarred || project.starred);
  project.isTemplate = Boolean(project.type === 'projects-template');
  project.lastChangeOn = DateTime.fromISO(project.lastChangeOn);
  project.customFields = project.customFields
    ? project.customFields.map((customField) => ({ ...customField, customfieldId: customField.customFieldId }))
    : [];

  const { minMaxAvailableDates } = project;
  if (minMaxAvailableDates) {
    minMaxAvailableDates.maxEndDate = minMaxAvailableDates.maxEndDate
      ? DateTime.fromFormat(minMaxAvailableDates.maxEndDate, 'yyyyMMdd')
      : null;
    minMaxAvailableDates.minStartDate = minMaxAvailableDates.minStartDate
      ? DateTime.fromFormat(minMaxAvailableDates.minStartDate, 'yyyyMMdd')
      : null;
    minMaxAvailableDates.suggestedEndDate = minMaxAvailableDates.suggestedEndDate
      ? DateTime.fromFormat(minMaxAvailableDates.suggestedEndDate, 'yyyyMMdd')
      : null;
    minMaxAvailableDates.suggestedStartDate = minMaxAvailableDates.suggestedStartDate
      ? DateTime.fromFormat(minMaxAvailableDates.suggestedStartDate, 'yyyyMMdd')
      : null;
  }
  return project;
}
/* eslint-enable no-param-reassign */

/**
 * Loads a single project by an ID of a project or any item within a project.
 * The item type and ID can be provided using either:
 * - `itemType` and `itemId` OR
 * - one of the supported type-specific properties, eg projectId, taskId, etc.
 *
 * Note: For now we load the current project using a v2 API
 * because the v3 API endpoint does not yet support loading a project by taskId, etc.
 * and permissions are not yet implemented for the v3 API.
 */
export function useProjectV2Loader({
  projectId: _projectId,
  taskId: _taskId,
  tasklistId: _tasklistId,
  notebookId: _notebookId,
  fileId: _fileId,
  linkId: _linkId,
  messageId: _messageId,
  milestoneId: _milestoneId,
  itemType: _itemType,
  itemId: _itemId,
  params,
}) {
  const itemType = computed(() => {
    if (unref(_itemType)) {
      return unref(_itemType);
    }
    if (unref(_projectId)) {
      return 'project';
    }
    if (unref(_taskId)) {
      return 'task';
    }
    if (unref(_tasklistId)) {
      return 'tasklist';
    }
    if (unref(_notebookId)) {
      return 'notebook';
    }
    if (unref(_fileId)) {
      return 'file';
    }
    if (unref(_linkId)) {
      return 'link';
    }
    if (unref(_messageId)) {
      return 'message';
    }
    if (unref(_milestoneId)) {
      return 'milestone';
    }
    return undefined;
  });

  const itemId = computed(() => {
    if (unref(_itemType)) {
      return unref(_itemId);
    }
    if (unref(_projectId)) {
      return unref(_projectId);
    }
    if (unref(_taskId)) {
      return unref(_taskId);
    }
    if (unref(_tasklistId)) {
      return unref(_tasklistId);
    }
    if (unref(_notebookId)) {
      return unref(_notebookId);
    }
    if (unref(_fileId)) {
      return unref(_fileId);
    }
    if (unref(_linkId)) {
      return unref(_linkId);
    }
    if (unref(_messageId)) {
      return unref(_messageId);
    }
    if (unref(_milestoneId)) {
      return unref(_milestoneId);
    }
    return undefined;
  });

  const url = computed(() => {
    const type = itemType.value;
    const id = itemId.value;
    if (type && Number.isSafeInteger(id) && id > 0) {
      if (type === 'project') {
        return `/projects/api/v2/projects/${id}.json`;
      }
      if (type === 'task') {
        return `/projects/api/v2/tasks/${id}/project.json`;
      }
      if (type === 'tasklist') {
        return `/projects/api/v2/tasklists/${id}/project.json`;
      }
      if (type === 'notebook') {
        return `/projects/api/v2/notebooks/${id}/project.json`;
      }
      if (type === 'file') {
        return `/projects/api/v2/files/${id}/project.json`;
      }
      if (type === 'link') {
        return `/projects/api/v2/links/${id}/project.json`;
      }
      if (type === 'message') {
        return `/projects/api/v2/messages/${id}/project.json`;
      }
      if (type === 'milestone') {
        return `/projects/api/v2/milestones/${id}/project.json`;
      }
    }
    return undefined;
  });

  const { state, refresh, update } = useItemLoader({
    url,
    params,
    responseToItem,
  });

  useOptimisticUpdates((event) => {
    if (event.type === 'project') {
      if (event.action === 'update') {
        update((project) => {
          return project?.id === event.project.id ? { ...project, ...event.project } : project;
        }, event.promise);
      }
    }
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'project') {
      if (
        // If the project which we have loaded has changed, we have to refresh.
        state.item.value?.id === event.projectId ||
        // If any project changed and we could not find the requested project,
        // we have to refresh because now there is a chance that we will find it.
        state.item.value === null ||
        // If we have not finished loading a project but we are still trying,
        // we must refresh to avoid loading stale data and
        // because loading might successed now, if we previously got an error.
        (state.item.value === undefined && !state.inSync.value)
      ) {
        refresh();
      }
    }

    // update the project when the message gets updates
    else if (itemType.value === 'message' && event.type === 'message' && event.messageId === itemId.value) {
      refresh();
    }
  });

  return state;
}
