<script setup>
import { useI18n } from '@/util';
import { useAppShellSidebar } from './useAppShellSidebar';

const { t } = useI18n();

const {
  isItemActive,
  setItemPinned,
  moreNavItems,
  toggleActiveDrawerPanel,
  clearActiveDrawerPanelIfNotPinned,
  trackSideNavItemClicked,
} = useAppShellSidebar();

const isMoreMenuOpen = shallowRef(false);

const isMoreMenuActive = computed(() => {
  return moreNavItems.value.some(isItemActive);
});

function onClickPinAction(item) {
  setItemPinned(item.itemKey, true);
  isMoreMenuOpen.value = false;
}

function onClickNavItem(item) {
  trackSideNavItemClicked(item.pendoLabel);
  if (item.drawerPanel) {
    toggleActiveDrawerPanel(item.drawerPanel);
  } else {
    clearActiveDrawerPanelIfNotPinned();
  }
}
</script>

<template>
  <WidgetMenu v-model="isMoreMenuOpen" location="end">
    <template #activator="activator">
      <slot
        name="activator"
        v-bind="{
          ...activator,
          isActive: activator.isActive || isMoreMenuActive,
        }"
      />
    </template>
    <VList v-bind="VListRounded">
      <VListItem
        v-for="item in moreNavItems"
        :key="item.itemKey"
        :to="item.path"
        :exact="item.exact"
        tabindex="0"
        class="group/item focus-visible:group-focus-within:bg-selected"
        link
        density="compact"
        :active="isItemActive(item)"
        :data-identifier="item.dataIdentifier"
        @click="onClickNavItem(item)"
      >
        <template #prepend>
          <VIcon size="20" class="!mr-2">{{ item.icon }}</VIcon>
        </template>
        <VListItemTitle class="inline-flex items-center gap-2">
          {{ item.text }}
        </VListItemTitle>
        <template #append>
          <LscIconButton
            v-if="!item.fixed"
            v-LsdTooltip="t('Pin')"
            :ariaLabel="t('Pin')"
            class="invisible group-focus-within/item:visible group-hover/item:visible"
            size="sm"
            icon="lsi-pin"
            @click.prevent="onClickPinAction(item)"
          />
        </template>
      </VListItem>
    </VList>
  </WidgetMenu>
</template>
