<script setup>
import { useEmbeddedChat, useEnabledApps, useNotifications, usePendo, usePermissions } from '@/api';
import AppShellSidebarOtherAppsMenu from './AppShellSidebarOtherAppsMenu.vue';
import { useAppShellSidebar } from './useAppShellSidebar';
import { useHelpCenter } from '@/module/helpCenter';
import { formatKeyboardShortcut, useI18n, useKeyboardShortcut } from '@/util';

const { t, formatNumber } = useI18n();
const { trackPendoEvent } = usePendo();
const { isOwnerAdmin } = usePermissions();

const {
  activeDrawerPanel,
  closeSidebarOnMobile,
  isSidebarExpanded,
  setActiveDrawerPanel,
  toggleActiveDrawerPanel,
  trackSideNavItemClicked,
} = useAppShellSidebar();

const { notificationUnreadCount, areNotificationsUnmuted } = useNotifications();
const embeddedChat = useEmbeddedChat();
const { isChatAvailable, isCrmAvailable, isDeskAvailable, isSpacesAvailable } = useEnabledApps();
const { HELP_CENTER_PANEL } = useHelpCenter();

const shouldShowOtherApps = computed(() =>
  [isOwnerAdmin, isChatAvailable, isSpacesAvailable, isDeskAvailable, isCrmAvailable].some(unref),
);

const tooltipDirection = computed(() => (isSidebarExpanded.value ? 'top' : 'right'));

function badgeNumberFormat(val) {
  return formatNumber(val, {
    notation: 'compact',
    compactDisplay: 'short',
    maximumSignificantDigits: 2,
    roundingMode: 'halfTrunc',
  });
}

useKeyboardShortcut('H', () => {
  trackSideNavItemClicked('help');
  setActiveDrawerPanel(HELP_CENTER_PANEL);
  trackPendoEvent({
    eventName: 'NAVIGATION_EVENT',
    commonMetrics: ['plan_name', 'user_role', 'page'],
    metadata: {
      event_action: 'shortcut_key_pressed',
      navigation_item: 'help',
    },
  });
});

function openChatPanel() {
  closeSidebarOnMobile();
  toggleActiveDrawerPanel('chat');
  trackSideNavItemClicked('chat');
}

function openHelpCenterPanel() {
  closeSidebarOnMobile();
  toggleActiveDrawerPanel(HELP_CENTER_PANEL);
  trackSideNavItemClicked('help');
}
</script>

<template>
  <div
    class="flex"
    :class="{
      'flex-col': !isSidebarExpanded,
      'justify-between': isSidebarExpanded,
    }"
  >
    <button
      v-LsdTooltip:[tooltipDirection]="activeDrawerPanel === 'notifications' ? undefined : t('Notifications')"
      type="button"
      :class="[
        'relative flex size-10 items-center justify-center rounded-full outline-none transition-colors',
        'bg-transparent',
        'ring-0 ring-[color:--lsds-c-sidebar-drawer-list-item-background-color-on-focus]',
        'hover:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
        'focus-visible:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
        'focus-visible:ring-2',
        'aria-pressed:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-selected]',
        'aria-pressed:text-[--lsds-c-sidebar-drawer-list-item-color-on-selected]',
        'text-[--lsds-c-sidebar-drawer-icon-color-on-surface]',
        'hover:text-[color:--lsds-c-sidebar-drawer-list-item-color-on-hover]',
      ]"
      :aria-pressed="activeDrawerPanel === 'notifications'"
      :aria-label="t('Notifications')"
      data-identifier="app-nav__notifications"
      @click.stop="
        toggleActiveDrawerPanel('notifications');
        trackSideNavItemClicked('notifications');
      "
    >
      <LscIcon
        size="md"
        :icon="areNotificationsUnmuted ? 'lsi-sidenav-notifications' : 'lsi-sidenav-notifications-muted'"
      />
      <span
        v-if="notificationUnreadCount && areNotificationsUnmuted"
        class="pointer-events-none absolute right-0 top-0 flex h-5 min-w-5 cursor-pointer items-center justify-center rounded-full bg-[color:--lsds-c-sidebar-drawer-notifications-badge-color-surface] px-1 text-body-2 text-[color:--lsds-c-sidebar-drawer-notifications-badge-color-on-surface]"
      >
        {{ badgeNumberFormat(notificationUnreadCount) }}
      </span>
    </button>

    <button
      v-if="isChatAvailable && embeddedChat.state.isEnabled"
      v-LsdTooltip:[tooltipDirection]="activeDrawerPanel === 'chat' ? undefined : 'Chat'"
      aria-label="Chat"
      type="button"
      :class="[
        'relative flex size-10 items-center justify-center rounded-full outline-none transition-colors',
        'bg-transparent',
        'ring-0 ring-[color:--lsds-c-sidebar-drawer-list-item-background-color-on-focus]',
        'hover:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
        'focus-visible:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
        'focus-visible:ring-2',
        'aria-pressed:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-selected]',
        'aria-pressed:text-[--lsds-c-sidebar-drawer-list-item-color-on-selected]',
        'text-[--lsds-c-sidebar-drawer-icon-color-on-surface]',
        'hover:text-[color:--lsds-c-sidebar-drawer-list-item-color-on-hover]',
      ]"
      :aria-pressed="activeDrawerPanel === 'chat'"
      @click.stop="openChatPanel"
    >
      <LscIcon size="md" icon="lsi-app-chat" />
      <span
        v-if="embeddedChat.state.importantUnreadCounts"
        class="pointer-events-none absolute right-0 top-0 flex h-5 min-w-5 cursor-pointer items-center justify-center rounded-full bg-[color:--lsds-c-sidebar-drawer-chat-badge-color-surface] px-1 text-body-2 text-[color:--lsds-c-sidebar-drawer-chat-badge-color-on-surface]"
      >
        {{ badgeNumberFormat(embeddedChat.state.importantUnreadCounts) }}
      </span>
      <span
        v-else-if="embeddedChat.state.unreadCounts > 0"
        class="pointer-events-none absolute right-0.5 top-0 bg-[color:--lsds-c-sidebar-drawer-chat-badge-color-surface]"
      />
    </button>

    <AppShellSidebarOtherAppsMenu v-if="shouldShowOtherApps" :location="isSidebarExpanded ? 'top' : 'right'">
      <template #activator="activator">
        <button
          v-LsdTooltip:[tooltipDirection]="activator.isActive ? undefined : t('Other apps')"
          :aria-label="t('Other apps')"
          type="button"
          :class="[
            'flex size-10 items-center justify-center rounded-full outline-none transition-colors',
            'bg-transparent',
            'ring-0 ring-[color:--lsds-c-sidebar-drawer-list-item-background-color-on-focus] ring-offset-0',
            'hover:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
            'focus-visible:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
            'focus-visible:ring-1 focus-visible:ring-offset-2',
            'aria-pressed:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-selected]',
            'aria-pressed:text-[--lsds-c-sidebar-drawer-list-item-color-on-selected]',
            'text-[--lsds-c-sidebar-drawer-icon-color-on-surface]',
            'hover:text-[color:--lsds-c-sidebar-drawer-list-item-color-on-hover]',
          ]"
          :aria-pressed="activator.isActive"
          v-bind="activator.props"
          @click="trackSideNavItemClicked('other_apps')"
        >
          <LscIcon size="md" icon="lsi-sidenav-other-apps" />
        </button>
      </template>
    </AppShellSidebarOtherAppsMenu>

    <button
      v-LsdTooltip:[tooltipDirection]="
        activeDrawerPanel === HELP_CENTER_PANEL
          ? undefined
          : {
              text: t('Help'),
              subtext: formatKeyboardShortcut('H'),
            }
      "
      type="button"
      :class="[
        'flex size-10 items-center justify-center rounded-full outline-none transition-colors',
        'bg-transparent',
        'ring-0 ring-[color:--lsds-c-sidebar-drawer-list-item-background-color-on-focus]',
        'hover:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
        'focus-visible:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
        'focus-visible:ring-2',
        'aria-pressed:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-selected]',
        'aria-pressed:text-[--lsds-c-sidebar-drawer-list-item-color-on-selected]',
        'text-[--lsds-c-sidebar-drawer-icon-color-on-surface]',
        'hover:text-[color:--lsds-c-sidebar-drawer-list-item-color-on-hover]',
      ]"
      :aria-pressed="activeDrawerPanel === HELP_CENTER_PANEL"
      data-identifier="app-nav__help-center"
      :aria-label="t('Help')"
      @click.stop="openHelpCenterPanel"
    >
      <LscIcon size="md" icon="lsi-sidenav-help-center" />
    </button>
  </div>
</template>
