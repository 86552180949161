import { useCurrentAccount, useCurrentUser, useFeedbackActions } from '@/api';
import { useFeatureAccessEvent } from '@/module/featureLimit';
import { useLegacyBridge } from '@/module/legacy';
import { useI18n } from '@/util';

const salesExpansionInbox = 2749;
const sdrInbox = 2099;
const formTypes = [
  // Scale Trials go to sales expansion inbox
  {
    type: 'scaleTrialActivated',
    request: 'Scale Trial - Book a Call',
    summary: 'Contact Form - Scale Trial - Book a Call',
    inboxId: salesExpansionInbox,
    sendToHubspot: true,
  },
  // Generic book a call requests go to SDR inbox
  {
    type: 'sdrCall',
    request: 'Trial - Book a Call',
    summary: 'Contact Form - Trial - Book a Call',
    inboxId: sdrInbox,
    sendToHubspot: true,
  },
  // Experiment 42: variant[sales-driven] Scale Trial Request
  {
    type: 'scaleTrialRequest',
    request: 'Scale Demo Request',
    summary: 'Scale Plan Demo or Conversation Request',
    inboxId: salesExpansionInbox,
    sendToHubspot: true,
  },
  // Stripe V2: Preventing customers from going to checkout
  {
    type: 'checkoutExcludedUser',
    request: 'Handle Subscription Changes Manually',
    summary: 'This customer is excluded from carrying out any self-service changes to their subscription',
    inboxId: sdrInbox,
    sendToHubspot: true,
  },
  // Welcome Page: book callback
  {
    type: 'welcomePageSDRCall',
    request: 'Trial Demo Request',
    summary: 'Demo Request from New Business Trial',
    inboxId: sdrInbox,
    sendToHubspot: true,
  },
  // Checkout excluded user add users request
  {
    type: 'checkoutExcludedAddUsers',
    request: 'Add Users Request',
    summary:
      'This customer wants to add users but excluded from carrying out any self-service changes to their subscription',
    inboxId: salesExpansionInbox,
    sendToHubspot: true,
  },
  {
    type: 'scaleUpgradeRequest',
    request: 'Scale Upgrade Request',
    summary: 'Scale Upgrade Request',
    inboxId: salesExpansionInbox,
    sendToHubspot: true,
  },
];

export function useFeedback() {
  const { postFeedback } = useFeedbackActions();
  const account = useCurrentAccount();
  const user = useCurrentUser();
  const { t } = useI18n();
  const { showLegacyModal } = useLegacyBridge();
  const toast = useLsToast();
  const { trackFeatureAccessEvent } = useFeatureAccessEvent();

  async function messageDesk(formEmail, phone, msg, formType, toastMsg) {
    const formFields = formTypes.find((ft) => ft.type === formType);
    if (!formFields) {
      toast.critical(t('Error sending your message.'));
      return;
    }

    const payload = {
      feedback: {
        source: 'Teamwork Projects',
        type: formFields.request,
        summary: formFields.summary,
        installationId: account.value.id,
        email: formEmail ?? user.value.emailAddress,
        telephone: phone,
        inboxId: formFields.inboxId,
        sendToHubspot: formFields.sendToHubspot,
        message: `
          Name: ${user.value.firstName} ${user.value.lastName}  \n
          Installation: ${account.value.id} \n
          Email: ${formEmail ?? user.value.emailAddress} \n
          Phone Number: ${phone ?? 'N/A'} \n
          Message Content: ${msg ?? 'N/A'} \n
        `,
      },
    };

    const successMessage =
      toastMsg ??
      t('Form submitted. Thank you for contacting us, {firstName}. We will contact you as soon as possible.', {
        firstName: user.value.firstName,
      });
    await postFeedback(payload)
      .then(() => {
        toast.success(successMessage);
      })
      .catch((err) => {
        const errMsg = err.response.statusText ? err.response.statusText : err.response.data.MSG;
        toast.critical(errMsg);
      });
  }

  function showContactForm(
    isSDRPage,
    formType,
    title = t('Contact our sales team'),
    subtitle = t('Enter your details below and one of our SDRs will be in touch shortly.'),
    entryPoint = '',
  ) {
    showLegacyModal({
      modalName: 'contactForm',
      args: {
        isSdrCallForm: isSDRPage,
        title,
        subtitle,
        onSubmitCallBack: (r) => {
          messageDesk(r.emailAddress, r.phoneNumber, r.message, formType, null);
          if (entryPoint === 'FeatureLimitUpgradeModal') {
            trackFeatureAccessEvent('contact_us_form_submitted');
            trackFeatureAccessEvent('contact_us_to_upgrade_submitted');
          }
        },
      },
    });
    if (entryPoint === 'FeatureLimitUpgradeModal') {
      trackFeatureAccessEvent('contact_us_modal_displayed');
      trackFeatureAccessEvent('contact_us_to_upgrade_clicked');
    }
  }

  return {
    showContactForm,
    messageDesk,
  };
}
