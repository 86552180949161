<script setup>
import { usePermissions, usePendo } from '@/api';
import { useI18n } from '@/util';
import { useLegacyBridge } from '@/module/legacy';
import ProjectJumpToDrawerEmptyProjectsAll from './ProjectJumpToDrawerEmptyProjectsAll.svg';
import ProjectJumpToDrawerEmptyProjectsSearch from './ProjectJumpToDrawerEmptyProjectsSearch.svg';

const props = defineProps({
  searchTerm: {
    type: String,
    default: '',
  },
});
const { t } = useI18n();
const { showLegacyModal } = useLegacyBridge();
const { canAddProjects } = usePermissions();
const { trackPendoEvent } = usePendo();

const advancedSearchRoute = computed(() =>
  props.searchTerm ? `/search/${encodeURIComponent(props.searchTerm)}` : '/search',
);

const title = computed(() => {
  if (!props.searchTerm) {
    return t('Using projects');
  }
  return t('No matching projects');
});

const message = computed(() => {
  if (!props.searchTerm) {
    return t('All your recent project activity would be shown here.');
  }
  return t('Please try again with a different term, or refine your results with the advanced search.');
});

function openProjectModal() {
  const source = 'jump_to_sidebar_menu_all';

  trackPendoEvent({
    eventName: 'PROJECTS_EVENT',
    commonMetrics: ['plan_name', 'user_role', 'page', 'on_trial', 'is_icp', 'is_paid'],
    metadata: {
      page_type: 'projects',
      event_action: 'add_project_clicked',
      event_category: 'advanced_discovery_event',
      source,
    },
  });

  showLegacyModal({
    modalName: 'addOrEditProjectWizard',
    params: { source },
  });
}
</script>
<template>
  <LscEmptyState class="my-8" size="sm" :title="title" :message="message">
    <template #image>
      <ProjectJumpToDrawerEmptyProjectsSearch v-if="searchTerm" />
      <ProjectJumpToDrawerEmptyProjectsAll v-else />
    </template>

    <template #actions>
      <RouterLink v-if="searchTerm" class="font-semibold text-primary no-underline" :to="advancedSearchRoute">
        {{ t('Advanced search') }}
      </RouterLink>
      <LscButton
        v-if="!searchTerm && canAddProjects"
        variant="primary"
        prependIcon="lsi-add"
        @click.stop="openProjectModal"
      >
        {{ t('Add project') }}
      </LscButton>
    </template>
  </LscEmptyState>
</template>
