<script setup>
import { useI18n } from '@/util';
import { AppShellDrawerPanel, AppShellDrawerTitle, useAppShellSidebar } from '@/appShell';
import FormDrawerAll from './FormDrawerAll.vue';
import FormDrawerGlobal from './FormDrawerGlobal.vue';
import FormDrawerProject from './FormDrawerProject.vue';
import { useFormDrawer } from './useFormDrawer';

const { selectedTab } = useFormDrawer();
const { activeDrawerPanel, clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();

const { t } = useI18n();

const searchTerm = shallowRef('');
const hasSomeForms = shallowRef(false);
</script>

<template>
  <AppShellDrawerPanel v-if="activeDrawerPanel === 'forms'" data-identifier="side-nav-form-panel">
    <template #header>
      <AppShellDrawerTitle :title="t('Forms')" />
    </template>
    <template #content>
      <div class="m-0 flex h-full flex-col overflow-hidden p-0">
        <VTextField
          v-if="hasSomeForms"
          :key="activeDrawerPanel"
          v-model.trim="searchTerm"
          v-bind="VTextFieldPanelSearch"
          autofocus
          class="mx-6 grow-0"
          :placeholder="t('Search')"
        />

        <!--- Tabs are off until we have global forms (soon)
        <div v-else-if class="!mt-2 grow-0 px-6">
          <VTabs v-bind="VTabsUnderline" v-model="selectedTab">
            <VTab value="all">{{ t('All') }}</VTab>
            <VTab value="global">{{ t('Global') }}</VTab>
            <VTab value="project">{{ t('Project') }}</VTab>
          </VTabs>
        </div>
        -->

        <KeepAlive>
          <FormDrawerAll v-if="searchTerm" :searchTerm="searchTerm" />
          <FormDrawerAll v-else-if="selectedTab === 'all'" @hasSomeForms="hasSomeForms = $event" />
          <FormDrawerGlobal v-else-if="selectedTab === 'global'" />
          <FormDrawerProject v-else-if="selectedTab === 'project'" />
        </KeepAlive>
      </div>
    </template>
    <template #footer>
      <div class="my-7 flex flex-col gap-4">
        <div class="h-px w-full border-0 border-t border-solid border-separator-on-surface-default" />
        <a
          href="https://support.teamwork.com/projects/forms/creating-forms-on-your-projects"
          target="_blank"
          class="flex w-full items-center text-body-1 font-semibold text-subtle no-underline hover:text-default"
          @click="clearActiveDrawerPanelIfNotPinned"
        >
          <VIcon class="mr-2">lsi-forms</VIcon>
          {{ t('Learn about forms') }}
          <VIcon class="ml-auto">lsi-open-panel</VIcon>
        </a>
      </div>
    </template>
  </AppShellDrawerPanel>
</template>
