import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

export function usePreferenceActions() {
  const { t } = useI18n();
  const api = useAxios();
  const { socketId } = useRealTimeUpdates();

  return {
    savePreferences(scope, preferences) {
      return api
        .put(
          `/${scope}/prefs.json`,
          { prefs: preferences },
          {
            headers: {
              'Socket-ID': socketId,
            },
            errorMessage: t('Failed to update preferences'),
          },
        )
        .then(() => undefined);
    },
  };
}
