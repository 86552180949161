import plugin from 'tailwindcss/plugin.js';
import TypographyPlugin from '@tailwindcss/typography';
import FormsPlugin from '@tailwindcss/forms';
import AnimateCssPlugin from 'tailwindcss-animatecss';
import { tailwindTypography } from './tailwindTypography.js';
import { scrollbarPlugin } from './scrollbarPlugin.js';

/** @type {import('tailwindcss').Config} */
export default {
  theme: {
    screens: {
      // We can't use css variables here, so we have to hardcode the values
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
    spacing: {
      px: 'var(--lsds-g-spacing-px)',
      0: 'var(--lsds-g-spacing-0)',
      0.5: 'var(--lsds-g-spacing-0-5)',
      1: 'var(--lsds-g-spacing-1)',
      1.5: 'var(--lsds-g-spacing-1-5)',
      2: 'var(--lsds-g-spacing-2)',
      3: 'var(--lsds-g-spacing-3)',
      3.5: 'var(--lsds-g-spacing-3-5)',
      4: 'var(--lsds-g-spacing-4)',
      5: 'var(--lsds-g-spacing-5)',
      6: 'var(--lsds-g-spacing-6)',
      7: 'var(--lsds-g-spacing-7)',
      8: 'var(--lsds-g-spacing-8)',
      9: 'var(--lsds-g-spacing-9)',
      10: 'var(--lsds-g-spacing-10)',
      11: 'var(--lsds-g-spacing-11)',
      12: 'var(--lsds-g-spacing-12)',
      14: 'var(--lsds-g-spacing-14)',
      16: 'var(--lsds-g-spacing-16)',
      18: 'var(--lsds-g-spacing-18)',
      20: 'var(--lsds-g-spacing-20)',
      24: 'var(--lsds-g-spacing-24)',
      28: 'var(--lsds-g-spacing-28)',
      30: 'var(--lsds-g-spacing-30)',
      32: 'var(--lsds-g-spacing-32)',
      36: 'var(--lsds-g-spacing-36)',
      40: 'var(--lsds-g-spacing-40)',
      44: 'var(--lsds-g-spacing-44)',
      48: 'var(--lsds-g-spacing-48)',
      52: 'var(--lsds-g-spacing-52)',
      56: 'var(--lsds-g-spacing-56)',
      60: 'var(--lsds-g-spacing-60)',
      64: 'var(--lsds-g-spacing-64)',
      72: 'var(--lsds-g-spacing-72)',
      80: 'var(--lsds-g-spacing-80)',
      96: 'var(--lsds-g-spacing-96)',
      100: 'var(--lsds-g-spacing-100)',
    },
    minWidth: {
      px: '1px',
      0: '0px',
      0.5: '0.125rem',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      3.5: '0.875rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '1.75rem',
      8: '2rem',
      9: '2.25rem',
      10: '2.5rem',
      11: '2.75rem',
      12: '3rem',
      14: '3.5rem',
      16: '4rem',
      20: '5rem',
      24: '6rem',
      28: '7rem',
      30: '7.5rem',
      32: '8rem',
      36: '9rem',
      40: '10rem',
      44: '11rem',
      48: '12rem',
      52: '13rem',
      56: '14rem',
      60: '15rem',
      64: '16rem',
      72: '18rem',
      80: '20rem',
      96: '24rem',
      114: '28.5rem',
      full: '100%',
    },
    minHeight: {
      px: '1px',
      0: '0px',
      0.5: '0.125rem',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      3.5: '0.875rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '1.75rem',
      8: '2rem',
      9: '2.25rem',
      10: '2.5rem',
      11: '2.75rem',
      12: '3rem',
      14: '3.5rem',
      16: '4rem',
      18: '4.5rem',
      20: '5rem',
      24: '6rem',
      28: '7rem',
      30: '7.5rem',
      32: '8rem',
      36: '9rem',
      40: '10rem',
      44: '11rem',
      48: '12rem',
      52: '13rem',
      56: '14rem',
      60: '15rem',
      64: '16rem',
      72: '18rem',
      80: '20rem',
      96: '24rem',
      114: '28.5rem',
      full: '100%',
    },
    maxWidth: {
      px: '1px',
      0: '0px',
      0.5: '0.125rem',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      3.5: '0.875rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '1.75rem',
      8: '2rem',
      9: '2.25rem',
      10: '2.5rem',
      11: '2.75rem',
      12: '3rem',
      14: '3.5rem',
      16: '4rem',
      20: '5rem',
      24: '6rem',
      28: '7rem',
      30: '7.5rem',
      32: '8rem',
      36: '9rem',
      40: '10rem',
      44: '11rem',
      48: '12rem',
      52: '13rem',
      56: '14rem',
      60: '15rem',
      64: '16rem',
      72: '18rem',
      80: '20rem',
      96: '24rem',
      114: '28.5rem',
      full: '100%',
    },
    maxHeight: {
      px: '1px',
      0: '0px',
      0.5: '0.125rem',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      3.5: '0.875rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '1.75rem',
      8: '2rem',
      9: '2.25rem',
      10: '2.5rem',
      11: '2.75rem',
      12: '3rem',
      14: '3.5rem',
      16: '4rem',
      18: '4.5rem',
      20: '5rem',
      24: '6rem',
      28: '7rem',
      30: '7.5rem',
      32: '8rem',
      36: '9rem',
      40: '10rem',
      44: '11rem',
      48: '12rem',
      52: '13rem',
      56: '14rem',
      60: '15rem',
      64: '16rem',
      72: '18rem',
      80: '20rem',
      96: '24rem',
      100: '25rem',
      114: '28.5rem',
      full: '100%',
    },
    colors: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: 'var(--lsds-g-palette-black)',
      white: 'var(--lsds-g-palette-white)',
    },
    textStrokeColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: 'var(--lsds-g-palette-black)',
      white: 'var(--lsds-g-palette-white)',
    },
    backgroundColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      default: 'var(--lsds-a-color-background-default)',
      hover: 'var(--lsds-a-color-background-hover)',
      selected: 'var(--lsds-a-color-background-selected)',
      surface: {
        default: 'var(--lsds-a-color-surface-default)',
        hover: 'var(--lsds-a-color-surface-hover)',
        selected: 'var(--lsds-a-color-surface-selected)', // insert what this is for
        emphasis: {
          default: 'var(--lsds-a-color-surface-emphasis-default)',
          hover: 'var(--lsds-a-color-surface-emphasis-hover)',
          selected: 'var(--lsds-a-color-surface-emphasis-selected)',
          contrast: 'var(--lsds-a-color-surface-emphasis-contrast)',
        },
        info: {
          default: 'var(--lsds-a-color-surface-info-default)',
          subdued: 'var(--lsds-a-color-surface-info-subdued)',
        },
        success: {
          default: 'var(--lsds-a-color-surface-success-default)',
          subdued: 'var(--lsds-a-color-surface-success-subdued)',
        },
        warning: {
          default: 'var(--lsds-a-color-surface-warning-default)',
          subdued: 'var(--lsds-a-color-surface-warning-subdued)',
        },
        critical: {
          default: 'var(--lsds-a-color-surface-critical-default)',
          subdued: 'var(--lsds-a-color-surface-critical-subdued)',
        },
        accent: 'var(--lsds-a-color-surface-accent)',
        highlight: 'var(--lsds-a-color-surface-highlight)',
        dark: 'var(--lsds-a-color-surface-dark)',
      },
      action: {
        primary: {
          default: 'var(--lsds-a-color-action-primary-default)',
          pressed: 'var(--lsds-a-color-action-primary-pressed)',
          hover: 'var(--lsds-a-color-action-primary-hover)',
          disabled: 'var(--lsds-a-color-action-primary-disabled)',
        },
        secondary: {
          default: 'var(--lsds-a-color-action-secondary-default)',
          hover: 'var(--lsds-a-color-action-secondary-hover)',
          pressed: 'var(--lsds-a-color-action-secondary-pressed)',
          disabled: 'var(--lsds-a-color-action-secondary-disabled)',
        },
        tertiary: 'var(--lsds-a-color-action-tertiary-default)',
        critical: {
          default: 'var(--lsds-a-color-action-critical-default)',
          pressed: 'var(--lsds-a-color-action-critical-pressed)',
          hover: 'var(--lsds-a-color-action-critical-hover)',
          disabled: 'var(--lsds-a-color-action-critical-disabled)',
        },
      },
      separator: 'var(--lsds-c-separator-color)',
      decorative: {
        1: 'var(--lsds-a-color-decorative-1)',
        2: 'var(--lsds-a-color-decorative-2)',
        3: 'var(--lsds-a-color-decorative-3)',
        4: 'var(--lsds-a-color-decorative-4)',
        5: 'var(--lsds-a-color-decorative-5)',
        6: 'var(--lsds-a-color-decorative-6)',
        7: 'var(--lsds-a-color-decorative-7)',
        8: 'var(--lsds-a-color-decorative-8)',
      },
    },
    textColor: {
      inherit: 'inherit',
      current: 'currentColor',
      // Clashes with vuetify's transparent class
      // transparent: 'transparent',
      default: 'var(--lsds-a-color-text-default)',
      placeholder: 'var(--lsds-a-color-text-placeholder)',
      subtle: 'var(--lsds-a-color-text-subtle)',
      disabled: 'var(--lsds-a-color-text-disabled)',
      selected: 'var(--lsds-a-color-background-selected)',
      primary: 'var(--lsds-a-color-text-primary)',
      accent: 'var(--lsds-a-color-text-accent)',
      tertiary: 'var(--lsds-a-color-text-tertiary)',
      info: 'var(--lsds-a-color-text-info)',
      success: 'var(--lsds-a-color-text-success)',
      warning: 'var(--lsds-a-color-text-warning)',
      critical: 'var(--lsds-a-color-text-critical)',
      reversed: 'var(--lsds-a-color-text-reversed)',
      on: {
        primary: 'var(--lsds-a-color-text-on-primary)',
        secondary: 'var(--lsds-a-color-text-on-secondary)',
        critical: 'var(--lsds-a-color-text-on-critical)',
        warning: 'var(--lsds-a-color-text-on-warning)',
        success: 'var(--lsds-a-color-text-on-success)',
        info: 'var(--lsds-a-color-text-on-info)',
        accent: 'var(--lsds-a-color-text-on-accent)',
        dark: 'var(--lsds-a-color-text-on-dark)',
        emphasis: 'var(--lsds-a-color-text-on-emphasis)',
      },
      icon: {
        default: 'var(--lsds-a-color-icon-default)',
        subtle: 'var(--lsds-a-color-icon-subtle)',
        disabled: 'var(--lsds-a-color-icon-disabled)',
        info: 'var(--lsds-a-color-icon-info)',
        success: 'var(--lsds-a-color-icon-success)',
        warning: 'var(--lsds-a-color-icon-warning)',
        critical: 'var(--lsds-a-color-icon-critical)',
        primary: {
          default: 'var(--lsds-a-color-icon-primary-default)',
          hover: 'var(--lsds-a-color-icon-primary-hover)',
        },
        on: {
          primary: 'var(--lsds-a-color-icon-on-primary)',
          emphasis: 'var(--lsds-a-color-icon-on-emphasis)',
          dark: 'var(--lsds-a-color-icon-on-dark)',
        },
      },
      action: {
        primary: {
          default: 'var(--lsds-a-color-action-primary-default)',
          pressed: 'var(--lsds-a-color-action-primary-pressed)',
          hover: 'var(--lsds-a-color-action-primary-hover)',
        },
      },
      decorative: {
        1: 'var(--lsds-a-color-decorative-1)',
        2: 'var(--lsds-a-color-decorative-2)',
        3: 'var(--lsds-a-color-decorative-3)',
        4: 'var(--lsds-a-color-decorative-4)',
        5: 'var(--lsds-a-color-decorative-5)',
        6: 'var(--lsds-a-color-decorative-6)',
        7: 'var(--lsds-a-color-decorative-7)',
        8: 'var(--lsds-a-color-decorative-8)',
      },
    },
    borderColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      default: 'var(--lsds-a-color-border-default)',
      bold: 'var(--lsds-a-color-border-bold)',
      subtle: 'var(--lsds-a-color-border-subtle)',
      strong: 'var(--lsds-a-color-border-strong)',
      contrast: 'var(--lsds-a-color-border-contrast)',
      reversed: 'var(--lsds-a-color-border-reversed)',
      accent: 'var(--lsds-a-color-border-accent)',
      separator: 'var(--lsds-a-color-border-separator)',
      'separator-on-surface-default': 'var(--lsds-a-color-border-separator-on-surface-default)',
      emphasis: 'var(--lsds-a-color-border-emphasis)',
      primary: {
        default: 'var(--lsds-a-color-border-primary-default)',
        hover: 'var(--lsds-a-color-border-primary-hover)',
        disabled: 'var(--lsds-a-color-border-primary-disabled)',
      },
      info: {
        default: 'var(--lsds-a-color-border-info-default)',
        hover: 'var(--lsds-a-color-border-info-hover)',
        disabled: 'var(--lsds-a-color-border-info-disabled)',
      },
      success: {
        default: 'var(--lsds-a-color-border-success-default)',
        hover: 'var(--lsds-a-color-border-success-hover)',
        disabled: 'var(--lsds-a-color-border-success-disabled)',
      },
      warning: {
        default: 'var(--lsds-a-color-border-warning-default)',
        hover: 'var(--lsds-a-color-border-warning-hover)',
        disabled: 'var(--lsds-a-color-border-warning-disabled)',
      },
      critical: {
        default: 'var(--lsds-a-color-border-critical-default)',
        hover: 'var(--lsds-a-color-border-critical-hover)',
        disabled: 'var(--lsds-a-color-border-critical-disabled)',
      },
      form: {
        default: 'var(--lsds-c-form-color-border-default)',
        hover: 'var(--lsds-c-form-color-border-hover)',
        active: 'var(--lsds-c-form-color-border-active)',
        disabled: 'var(--lsds-c-form-color-border-disabled)',
      },
      focus: {
        primary: 'var(--lsds-a-color-border-focus-primary)',
        secondary: 'var(--lsds-a-color-border-focus-secondary)',
        tertiary: 'var(--lsds-a-color-border-focus-tertiary)',
        critical: 'var(--lsds-a-color-border-focus-critical)',
      },
    },
    outlineColor: ({ theme }) => theme('borderColor'),
    ringColor: ({ theme }) => theme('borderColor'),
    divideColor: ({ theme }) => theme('borderColor'),
    boxShadow: {
      1: 'var(--lsds-g-shadow-1)',
      2: 'var(--lsds-g-shadow-2)',
      3: 'var(--lsds-g-shadow-3)',
      4: 'var(--lsds-g-shadow-4)',
      drawer: 'var(--lsds-c-drawer-shadow)',
      'drawer-expand-button': 'var(--lsds-c-drawer-expand-button-shadow)',
      dialog: 'var(--lsds-c-dialog-shadow)',
      popover: 'var(--lsds-c-popover-shadow)',
      none: 'none',
    },
    dropShadow: {
      1: 'var(--lsds-g-shadow-1)',
      2: 'var(--lsds-g-shadow-2)',
      3: 'var(--lsds-g-shadow-3)',
      4: 'var(--lsds-g-shadow-4)',
      none: '0 0 #0000',
    },
    borderRadius: {
      0: 'var(--lsds-g-border-rounded-0)',
      sm: 'var(--lsds-g-border-rounded-sm)',
      md: 'var(--lsds-g-border-rounded-md)',
      lg: 'var(--lsds-g-border-rounded-lg)',
      xl: 'var(--lsds-g-border-rounded-xl)',
      full: 'var(--lsds-g-border-rounded-full)',
    },
    fontFamily: {
      sans: 'var(--lsds-g-font-family-default)',
      mono: 'var(--lsds-g-font-family-mono)',
    },
    fontSize: {
      h1: ['var(--lsds-a-typography-heading-1-font-size)', 'var(--lsds-a-typography-heading-1-line-height)'],
      h2: ['var(--lsds-a-typography-heading-2-font-size)', 'var(--lsds-a-typography-heading-2-line-height)'],
      h3: ['var(--lsds-a-typography-heading-3-font-size)', 'var(--lsds-a-typography-heading-3-line-height)'],
      h4: ['var(--lsds-a-typography-heading-4-font-size)', 'var(--lsds-a-typography-heading-4-line-height)'],
      h5: ['var(--lsds-a-typography-heading-5-font-size)', 'var(--lsds-a-typography-heading-5-line-height)'],
      h6: ['var(--lsds-a-typography-heading-6-font-size)', 'var(--lsds-a-typography-heading-6-line-height)'],
      'subtitle-1': [
        'var(--lsds-a-typography-subtitle-1-font-size)',
        'var(--lsds-a-typography-subtitle-1-line-height)',
      ],
      'subtitle-2': [
        'var(--lsds-a-typography-subtitle-2-font-size)',
        'var(--lsds-a-typography-subtitle-2-line-height)',
      ],
      'body-1': [
        'var(--lsds-a-typography-body-1-regular-font-size)',
        'var(--lsds-a-typography-body-1-regular-line-height)',
      ],
      'body-2': [
        'var(--lsds-a-typography-body-2-regular-font-size)',
        'var(--lsds-a-typography-body-2-regular-line-height)',
      ],
      'body-3': [
        'var(--lsds-a-typography-body-3-regular-font-size)',
        'var(--lsds-a-typography-body-3-regular-line-height)',
      ],
      'button-1': ['var(--lsds-a-typography-button-1-font-size)', 'var(--lsds-a-typography-button-1-line-height)'],
      'button-2': ['var(--lsds-a-typography-button-2-font-size)', 'var(--lsds-a-typography-button-2-line-height)'],
    },
    fontWeight: {
      normal: 'var(--lsds-g-font-weight-regular)',
      medium: 'var(--lsds-g-font-weight-medium)',
      semibold: 'var(--lsds-g-font-weight-semibold)',
      bold: 'var(--lsds-g-font-weight-bold)',
    },
    lineHeight: {
      0: 'var(--lsds-g-font-line-height-0)',
      1: 'var(--lsds-g-font-line-height-1)',
      2: 'var(--lsds-g-font-line-height-2)',
      3: 'var(--lsds-g-font-line-height-3)',
      4: 'var(--lsds-g-font-line-height-4)',
      5: 'var(--lsds-g-font-line-height-5)',
      6: 'var(--lsds-g-font-line-height-6)',
      7: 'var(--lsds-g-font-line-height-7)',
      none: 'var(--lsds-g-font-line-height-none)',
      full: 'var(--lsds-g-font-line-height-100)',
    },
    transitionTimingFunction: {
      DEFAULT: 'var(--lsds-g-motion-ease-in-out)',
      ease: 'var(--lsds-g-motion-ease)',
      in: 'var(--lsds-g-motion-ease-in)',
      out: 'var(--lsds-g-motion-ease-out)',
      'in-out': 'var(--lsds-g-motion-ease-in-out)',
      linear: 'var(--lsds-g-motion-ease-linear)',
    },
    transitionDuration: {
      DEFAULT: 'var(--lsds-g-motion-duration-100)',
      100: 'var(--lsds-g-motion-duration-100)',
      300: 'var(--lsds-g-motion-duration-300)',
      500: 'var(--lsds-g-motion-duration-500)',
    },
    extend: {
      typography: tailwindTypography,
      aspectRatio: {
        '4/3': '4 / 3', // Use of / is deprecated in sass so for now it's placed here directly rather than in a token
      },
    },
  },
  plugins: [
    scrollbarPlugin({
      layer: 'base',
      className: '*',
      scrollbarWidth: 'thin',
      scrollbarColor: 'var(--lsds-c-scrollbar-color-default)',
    }),
    scrollbarPlugin({
      className: '.scrollbar-lg',
      scrollbarWidth: 'auto',
      scrollbarColor: 'var(--lsds-c-scrollbar-color-default)',
    }),
    scrollbarPlugin({
      className: '.scrollbar-on-dark',
      scrollbarWidth: 'thin',
      scrollbarColor: 'var(--lsds-c-scrollbar-color-on-dark-default)',
    }),
    plugin(({ addUtilities }) => {
      addUtilities({
        '.text-overline': {
          fontSize: 'var(--lsds-a-typography-overline-font-size)',
          fontWeight: 'var(--lsds-a-typography-overline-font-weight)',
          letterSpacing: 'var(--lsds-a-typography-overline-letter-spacing)',
          lineHeight: 'var(--lsds-a-typography-overline-line-height)',
          textTransform: 'var(--lsds-a-typography-overline-text-transform)',
        },
      });
      addUtilities({
        '.text-label': {
          fontSize: 'var(--lsds-a-typography-label-font-size)',
          fontWeight: 'var(--lsds-a-typography-label-font-weight)',
          letterSpacing: 'var(--lsds-a-typography-label-letter-spacing)',
          lineHeight: 'var(--lsds-a-typography-label-line-height)',
          textTransform: 'var(--lsds-a-typography-label-text-transform)',
        },
      });
    }),
    AnimateCssPlugin,
    TypographyPlugin,
    FormsPlugin({
      strategy: 'class', // only generate classes
    }),
  ],
};
