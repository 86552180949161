import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { delayedPromise } from '@/util';

export function useNotificationsActions() {
  const api = useAxios();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  function config() {
    return {
      headers: {
        'Socket-ID': socketId,
      },
    };
  }

  function emitOptimisticUpdate(promise, action, notification) {
    _emitOptimisticUpdate({
      promise,
      type: 'notification',
      action,
      notification,
    });
  }

  function setNotificationRead({ id, read }) {
    const promise = api
      .patch(
        `/projects/api/v3/notifications/${id}.json`,
        {
          notification: {
            read,
          },
        },
        config(),
      )
      .then(() => {
        emitRealTimeUpdate({
          type: 'notification',
          action: 'update',
          notificationId: id,
          read,
        });
      });

    emitOptimisticUpdate(delayedPromise(promise), 'update', { id, read });

    return promise;
  }

  function markAllNotificationAsRead() {
    const promise = api.post('/projects/api/v3/notifications/read.json', {}, config()).then(() => {
      emitRealTimeUpdate({
        type: 'notification',
        action: 'markAllRead',
      });
    });

    emitOptimisticUpdate(delayedPromise(promise), 'update', { read: true });

    return promise;
  }

  return {
    markAllNotificationAsRead,
    setNotificationRead,
  };
}
