<script setup>
import { tv } from '../../../util/tailwind/tv';

const props = defineProps({
  /**
   * The variant of the link.
   * @type {import('vue').PropType<'inline' | 'inline-monochrome' | 'standalone'>}
   */
  variant: {
    type: String,
    default: 'inline',
    validator: (value) => ['inline', 'inline-monochrome', 'standalone'].includes(value),
  },
  /**
   * This icon will be rendered after the link's content.
   */
  appendIcon: {
    type: String,
    default: '',
  },
  /**
   * This icon will be rendered before the link's content.
   */
  prependIcon: {
    type: String,
    default: '',
  },
  /**
   * The external link to display.
   */
  href: {
    type: String,
    default: undefined,
  },
  /**
   * The internal link to display.
   */
  to: {
    type: String,
    default: undefined,
  },
  /**
   * The target of the link.
   */
  target: {
    type: String,
    default: undefined,
  },
});

const linkVariantStyleConfig = tv({
  base: 'group inline-flex items-center gap-1 font-semibold no-underline focus-visible:border-focus-primary',
  slots: {
    icon: '',
    text: 'group-hover:underline group-focus-visible:underline group-active:underline',
  },
  variants: {
    variant: {
      inline: {
        base: 'text-action-primary-default hover:text-action-primary-hover focus-visible:text-action-primary-pressed active:text-action-primary-pressed',
      },
      'inline-monochrome': {
        base: 'text-icon-default hover:text-default focus-visible:text-default active:text-default',
      },
      standalone: {
        base: 'text-body-1',
        icon: 'text-icon-primary-default',
        text: 'group-active text-icon-primary-default group-hover:text-primary group-focus-visible:text-primary group-active:text-primary',
      },
    },
  },
});

const classes = computed(() => linkVariantStyleConfig(props));

function validateLink() {
  if (props.href && props.to) {
    // eslint-disable-next-line no-console
    console.warn("Only 'href' or 'to' should be provided");
  }
  if (props.prependIcon && props.appendIcon) {
    // eslint-disable-next-line no-console
    console.warn("Only 'prependIcon' or 'appendIcon' should be provided.");
  }
}

watchEffect(validateLink);
</script>

<template>
  <RouterLink v-if="to" :to="to" :target="target" :class="classes.base()">
    <slot name="prepend">
      <LscIcon v-if="prependIcon && variant === 'standalone'" :class="classes.icon()" :icon="prependIcon" />
    </slot>
    <!-- @slot Default link text -->
    <span :class="classes.text()"><slot /></span>

    <slot name="append">
      <LscIcon v-if="appendIcon && variant === 'standalone'" :class="classes.icon()" :icon="appendIcon" />
    </slot>
  </RouterLink>
  <a v-else :href="href" :target="target" :class="classes.base()">
    <slot name="prepend">
      <LscIcon v-if="prependIcon && variant === 'standalone'" :class="classes.icon()" :icon="prependIcon" />
    </slot>
    <!-- @slot Default link text -->
    <span :class="classes.text()"><slot /></span>

    <slot name="append">
      <LscIcon v-if="appendIcon && variant === 'standalone'" :class="classes.icon()" :icon="appendIcon" />
    </slot>
  </a>
</template>
