<script setup>
import HelpCenterDrawerTitle from './HelpCenterDrawerTitle.vue';

defineProps({
  title: {
    type: String,
    required: true,
  },
  items: {
    type: Array,
    default: () => [],
    required: false,
  },
  iconSize: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md'].includes(value.toLowerCase()),
  },
});

const emit = defineEmits(['itemClicked']);
</script>
<template>
  <div>
    <HelpCenterDrawerTitle :title="title" />
    <VList class="flex flex-col gap-2 py-0">
      <VListItem
        v-for="(item, index) in items"
        :key="index"
        :href="item?.href"
        :target="item?.href && '_blank'"
        v-bind="VListItemSidebarHelpCenterDrawer"
        class="group"
        @click="emit('itemClicked', item)"
      >
        <template #prepend>
          <span
            :class="`mr-2 flex flex-none items-center justify-center rounded-full text-icon-default ${item.iconCss} ${
              iconSize === 'sm' ? 'size-12' : 'size-14'
            }`"
          >
            <LscIcon size="lg" :icon="item.icon ?? 'lsi-project'" />
          </span>
        </template>
        <template #append>
          <LscIcon
            :icon="item.hoverAppendIcon ?? 'lsi-open-panel'"
            size="lg"
            class="invisible text-icon-subtle group-hover:visible group-focus:visible"
          />
        </template>
        <VListItemTitle class="text-subtitle-2 font-semibold" :title="item.title">
          {{ item.title }}
        </VListItemTitle>
        <VListItemSubtitle class="text-body-1" :title="item.description">
          {{ item.description }}
        </VListItemSubtitle>
      </VListItem>
    </VList>
  </div>
</template>
