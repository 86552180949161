import { useExperimentA42 } from '../experiments/useExperimentA42';
import { useExperimentE17 } from '../experiments/useExperimentE17';
import {
  useArrayType,
  useBooleanType,
  useInitialized,
  useObjectType,
  usePreference,
  useServerStore,
  useStringType,
} from './preferencesUtil';

const symbol = Symbol('usePreferences');

function Preferences() {
  const initialized = useInitialized();
  const { isExpA42Variation, isExpA42Control } = useExperimentA42();
  const { isExpE17Variation, isExpE17Control } = useExperimentE17();

  const isExpA42OrE17Variation = computed(() => isExpA42Variation.value || isExpE17Variation.value);

  return {
    initialized,
    notificationDrawerActiveTab: usePreference({
      type: useStringType('unread'),
      store: useServerStore({
        scope: 'me',
        name: 'notificationDrawerActiveTab',
      }),
    }),
    switchAllUsersToRedesignedSidebar: usePreference({
      type: useBooleanType(),
      store: useServerStore({
        scope: 'account',
        name: 'switchAllUsersToRedesignedSidebar',
      }),
    }),
    shouldMinimizeSidebar: usePreference({
      type: useBooleanType(),
      store: useServerStore({
        scope: 'me',
        name: 'shouldMinimizeSidebar',
      }),
    }),
    shouldShowRedesignedSidebar: usePreference({
      type: useBooleanType(),
      store: useServerStore({
        scope: 'me',
        name: 'shouldShowRedesignedSidebar',
      }),
    }),
    projectHealthGreenLabel: usePreference({
      type: useStringType(),
      store: useServerStore({
        scope: 'account',
        name: 'projectHealthGreenLabel',
      }),
    }),
    projectHealthAmberLabel: usePreference({
      type: useStringType(),
      store: useServerStore({
        scope: 'account',
        name: 'projectHealthAmberLabel',
      }),
    }),
    projectHealthRedLabel: usePreference({
      type: useStringType(),
      store: useServerStore({
        scope: 'account',
        name: 'projectHealthRedLabel',
      }),
    }),
    sidebarPinnedItems: usePreference({
      type: useObjectType(
        computed(() => ({
          home: {
            docked: true,
          },
          search: {
            docked: true,
          },
          welcome: {
            docked: true,
          },
          jumpTo: {
            docked: true,
          },
          clients: {
            docked: isExpA42OrE17Variation.value,
          },
          projects: {
            docked: true,
          },
          templates: {
            docked: isExpA42Control.value, // if user is in test group for Time pinning, unpin templates to make room
          },
          planning: {
            docked: isExpA42OrE17Variation.value,
          },
          budgets: {
            docked: isExpA42OrE17Variation.value,
          },
          reports: {
            docked: isExpA42OrE17Variation.value,
          },
          time: {
            docked: isExpE17Control.value || isExpA42Variation.value,
          },
          proofs: {
            docked: false,
          },
          forms: {
            docked: false,
          },
          everything: {
            docked: false,
          },
          calendar: {
            docked: false,
          },
          people: {
            docked: false,
          },
        })),
      ),
      store: useServerStore({
        scope: 'me',
        name: 'sidebarPinnedItems',
      }),
    }),
    sidebarPinnedPanel: usePreference({
      type: useObjectType({ name: null, isOpen: false }),
      store: useServerStore({
        scope: 'me',
        name: 'sidebarPinnedPanel',
      }),
    }),
    hotspots: usePreference({
      type: useObjectType(),
      store: useServerStore({
        scope: 'me',
        name: 'hotspots',
      }),
    }),
    areNotificationsUnmuted: usePreference({
      type: useBooleanType(true),
      store: useServerStore({
        scope: 'me',
        name: 'webBNotifsOn',
      }),
    }),
    itemCommentsSortBy: usePreference({
      type: useStringType('date'),
      store: useServerStore({
        scope: 'me',
        name: 'itemCommentsSortBy',
      }),
    }),
    commentThreadSort: usePreference({
      type: useStringType('desc'),
      store: useServerStore({
        scope: 'me',
        name: 'commentThreadSort',
      }),
    }),
    useDesktopNotifications: usePreference({
      type: useBooleanType(true),
      store: useServerStore({
        scope: 'me',
        name: 'useDesktopNotifications',
      }),
    }),
    vueListViewActive: usePreference({
      type: useBooleanType(),
      store: useServerStore({
        scope: 'me',
        name: 'vueListViewActive',
      }),
    }),
    firstTimeExperienceVisibility: usePreference({
      type: useObjectType({ proofing: true, clientEmail: true }),
      store: useServerStore({
        scope: 'me',
        name: 'firstTimeExperienceVisibility',
      }),
    }),
    taskPushSubTasks: usePreference({
      type: useBooleanType(),
      store: useServerStore({
        scope: 'me',
        name: 'taskPushSubTasks',
      }),
    }),
    taskSkipWeekends: usePreference({
      type: useBooleanType(),
      store: useServerStore({
        scope: 'me',
        name: 'taskSkipWeekends',
      }),
    }),
    tasklistTemplateSkipWeekends: usePreference({
      type: useBooleanType(),
      store: useServerStore({
        scope: 'me',
        name: 'tasklistTemplateSkipWeekends',
      }),
    }),
    taskPushDependentDates: usePreference({
      type: useBooleanType(),
      store: useServerStore({
        scope: 'me',
        name: 'taskPushDependentDates',
      }),
    }),
    onboardingChecklistDismissed: usePreference({
      type: useBooleanType(false),
      store: useServerStore({
        scope: 'me',
        name: 'onboardingChecklistDismissed',
      }),
    }),
    productToursCompleted: usePreference({
      type: useArrayType([]),
      store: useServerStore({
        scope: 'me',
        name: 'productToursCompleted',
      }),
    }),
    sampleProjectsBannerDismissed: usePreference({
      type: useBooleanType(false),
      store: useServerStore({
        scope: 'me',
        name: 'sampleProjectsBannerDismissed',
      }),
    }),
    sampleProjectsVisible: usePreference({
      type: useArrayType([]),
      store: useServerStore({
        scope: 'me',
        name: 'sampleProjectsVisible',
      }),
    }),
    sampleProjectIds: usePreference({
      type: useArrayType([]),
      store: useServerStore({
        scope: 'me',
        name: 'sampleProjectIds',
      }),
    }),
    sampleProjectsRemovedDuringCheckout: usePreference({
      type: useBooleanType(false),
      store: useServerStore({
        scope: 'me',
        name: 'sampleProjectsRemovedDuringCheckout',
      }),
    }),
    sampleUserIds: usePreference({
      type: useArrayType([]),
      store: useServerStore({
        scope: 'me',
        name: 'sampleUserIds',
      }),
    }),
    sampleClientIds: usePreference({
      type: useArrayType([]),
      store: useServerStore({
        scope: 'me',
        name: 'sampleClientIds',
      }),
    }),
    trialWarningBannerDismissed: usePreference({
      type: useObjectType({
        trialEndingSoon: false,
        trialExpiry: false,
      }),
      store: useServerStore({
        scope: 'me',
        name: 'trialWarningBannerDismissed',
      }),
    }),
    invoiceBannerDismissed: usePreference({
      type: useBooleanType(false),
      store: useServerStore({
        scope: 'me',
        name: 'invoiceBannerDismissed',
      }),
    }),
    financeRatesBannerDismissed: usePreference({
      type: useBooleanType(false),
      store: useServerStore({
        scope: 'me',
        name: 'financeRatesBannerDismissed',
      }),
    }),
    featureTrialsBannersVisibility: usePreference({
      type: useObjectType({
        tasklistbudgets: true,
        retainerbudgets: true,
        profitability: true,
        customreporting: true,
      }),
      store: useServerStore({
        scope: 'me',
        name: 'featureTrialsBannersVisibility',
      }),
    }),
    calendarShowDescriptions: usePreference({
      type: useBooleanType(true),
      store: useServerStore({
        scope: 'me',
        name: 'calendarShowDescriptions',
      }),
    }),
    calendarShowSubtask: usePreference({
      type: useBooleanType(true),
      store: useServerStore({
        scope: 'me',
        name: 'calendarShowSubtask',
      }),
    }),
    calendarShowCompletedTask: usePreference({
      type: useBooleanType(true),
      store: useServerStore({
        scope: 'me',
        name: 'calendarShowCompletedTask',
      }),
    }),
    calendarShowWeekend: usePreference({
      type: useBooleanType(false),
      store: useServerStore({
        scope: 'me',
        name: 'calendarShowWeekend',
      }),
    }),
    calendarShowTasksOnlyOnDueDate: usePreference({
      type: useBooleanType(false),
      store: useServerStore({
        scope: 'me',
        name: 'calendarShowTasksOnlyOnDueDate',
      }),
    }),
    showCalendarWelcomeDialog: usePreference({
      type: useBooleanType(true),
      store: useServerStore({
        scope: 'me',
        name: 'showCalendarWelcomeDialog',
      }),
    }),
    switchAllUsersToWorkflows: usePreference({
      type: useBooleanType(false),
      store: useServerStore({
        scope: 'account',
        name: 'switchAllUsersToWorkflows',
      }),
    }),
    onboardingSelectedIntegrations: usePreference({
      type: useArrayType([]),
      store: useServerStore({
        scope: 'me',
        name: 'onboardingSelectedIntegrations',
      }),
    }),
    blackFridayPromotionBannersDismissed: usePreference({
      type: useObjectType(
        computed(() => ({
          confirmBanner: false,
          remindBanner: false,
          saleUpgradeBannerFinal: false,
        })),
      ),
      store: useServerStore({
        scope: 'me',
        name: 'blackFridayPromotionBannersDismissed',
      }),
    }),
    blackFridayFeatureTrialsStarted: usePreference({
      type: useArrayType([]),
      store: useServerStore({
        scope: 'account',
        name: 'blackFridayFeatureTrialsStarted',
      }),
    }),
    createCalendarEventLastProjectId: usePreference({
      type: useStringType(),
      store: useServerStore({
        scope: 'me',
        name: 'createCalendarEventLastProjectId',
      }),
    }),
    quicklyAddTasksLastProjectId: usePreference({
      type: useStringType(),
      store: useServerStore({
        scope: 'me',
        name: 'quicklyAddTasksLastProjectId',
      }),
    }),
    quicklyAddTasksLastTasklistId: usePreference({
      type: useStringType(),
      store: useServerStore({
        scope: 'me',
        name: 'quicklyAddTasksLastTasklistId',
      }),
    }),
    shouldHideAllTimeApprovalsAlert: usePreference({
      type: useBooleanType(false),
      store: useServerStore({
        scope: 'me',
        name: 'shouldHideAllTimeApprovalsAlert',
      }),
    }),
    firstTimeVisitingApprovals: usePreference({
      type: useBooleanType(true),
      store: useServerStore({
        scope: 'me',
        name: 'firstTimeVisitingApprovals',
      }),
    }),
    firstTimeVisitingUserTimesheetApprovals: usePreference({
      type: useBooleanType(true),
      store: useServerStore({
        scope: 'me',
        name: 'firstTimeVisitingUserTimesheetApprovals',
      }),
    }),
    userWasATimeApprover: usePreference({
      type: useBooleanType(false),
      store: useServerStore({
        scope: 'me',
        name: 'userWasATimeApprover',
      }),
    }),
    shouldShowJan2024SaleBanner: usePreference({
      type: useBooleanType(true),
      store: useServerStore({
        scope: 'me',
        name: 'shouldShowJan2024SaleBannerV2',
      }),
    }),
    firstTimeVisitingFormsConditional: usePreference({
      type: useBooleanType(true),
      store: useServerStore({
        scope: 'me',
        name: 'firstTimeVisitingFormsConditional',
      }),
    }),
    firstTimeRepsModalViewed: usePreference({
      type: useBooleanType(false),
      store: useServerStore({
        scope: 'me',
        name: 'firstTimeRepsModalViewed',
      }),
    }),
    logTimeModalSmartDefaults: usePreference({
      type: useObjectType({
        projectId: null,
        minutes: 30,
      }),
      store: useServerStore({
        scope: 'me',
        name: 'logTimeModalSmartDefaults',
      }),
    }),
  };
}

export function providePreferences() {
  provide(symbol, Preferences());
}

/**
 * @type {Preferences}
 */
export function usePreferences() {
  return inject(symbol);
}
