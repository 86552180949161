<script setup>
import { useRouter } from 'vue-router';
import { useI18n } from '@/util';
import { useCurrentUser } from '@/api';
import { useLegacyFrameOffset } from '@/module/legacy';
import { useCalendarGoogleSyncAuthPopup, useCalendarGoogleSyncStatus } from '@/module/calendar';

const { t } = useI18n();
const user = useCurrentUser();
const { openGoogleSyncAuthPopup } = useCalendarGoogleSyncAuthPopup();
const { isSyncExpired } = useCalendarGoogleSyncStatus();
const router = useRouter();

const bannerEl = shallowRef(null);

useLegacyFrameOffset(bannerEl);

const variant = computed(() => (isSyncExpired.value ? 'warning' : 'info'));
const icon = computed(() => (isSyncExpired.value ? 'lsi-alert' : 'lsi-tooltip'));
const title = computed(() => (isSyncExpired.value ? t('Calendar expired') : 'Google Calendar'));
const message = computed(() =>
  isSyncExpired.value
    ? t('Your Google calendar integration has expired. Please reconnect to ensure your events are up to date.')
    : t(
        "Your calendar has stopped syncing. We'll keep trying to connect your calendar or you can remove it and try again",
      ),
);
const buttonText = computed(() => (isSyncExpired.value ? t('Connect calendar') : t('Calendar setting')));

function handleButtonClicked() {
  if (isSyncExpired.value) {
    openGoogleSyncAuthPopup(true);
  } else {
    router.push(`/people/${user.value.id}/integrations`);
  }
}
</script>

<template>
  <LscAlert ref="bannerEl" layout="banner" :variant="variant" :icon="icon" :title="title" :message="message">
    <template #action>
      <LscAlertButton @click="handleButtonClicked">
        {{ buttonText }}
      </LscAlertButton>
    </template>
  </LscAlert>
</template>
