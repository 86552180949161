<script setup>
import { useMounted, unrefElement, onClickOutside, invoke, until } from '@vueuse/core';
import {
  useCurrentAccount,
  useExperiment46,
  useFeatures,
  useDummyPendoExperiment,
  provideExperimentE23b,
  useExperimentE21a,
  useExperimentE21b,
  useExperimentE2325,
  useExperimentA29,
  usePreferences,
  useExperimentA44,
  useExperimentA45,
  useExperimentA49,
  useExperimentA18,
  useExperimentA22,
  useExperimentA30,
  useExperimentA54,
  useExperimentA57,
  useExperimentA63,
  useExperimentA2408,
  useExperimentE2304,
  useExperimentE2301,
  useExperimentE2401,
  useCalenderExperiment,
  useExperimentR2401,
  useExperimentR2403,
  useUtmTracking,
  usePermissions,
  useCohort,
} from '@/api';
import { useTheaterMode, useEmbeddedMode, useRoute, MainRouterView, QuickViewRouterView } from '@/route';
import { BadgeAchievedDialog, useBadge } from '@/module/badge';
import { LegacyAppFrame, provideLegacyBridge } from '@/module/legacy';
import { provideDeskDrawer } from '@/module/desk';
import { TimeTimer } from '@/module/time';
import { RepsAccountPrompt } from '@/module/reps';
import { HubspotChatActivator } from '@/module/hubspotChat';
import { useKeyboardShortcut, useDocumentTitle, useNowByMinute } from '@/util';
import { OnboardingChecklist, provideActiveProductTourId, useOnboardingChecklist } from '@/module/onboarding';
import { useAppShellSidebar } from './sidebar/useAppShellSidebar';
import { useFavicon } from './useFavicon';
import { provideHelpCenter } from '@/module/helpCenter';
import { useCalendarGoogleSyncStatus } from '@/module/calendar';

import AppShellFeatureTrialBundleBanner from '@/module/featureTrialBundle/FeatureTrialBundleBanner.vue';
import AppShellBreadcrumbs from './breadcrumbs/AppShellBreadcrumbs.vue';
import AppShellCalendarSyncBanner from './calendarWarning/AppShellCalendarSyncBanner.vue';
import AppShellDrawer from './drawer/AppShellDrawer.vue';
import AppShellImpersonateBanner from './impersonate/AppShellImpersonateBanner.vue';
import AppShellImporterBanner from './importer/AppShellImporterBanner.vue';
import AppShellInvoiceBanner from './invoice/AppShellInvoiceBanner.vue';
import AppShellNewVersionBanner from './newVersion/AppShellNewVersionBanner.vue';
import AppShellSampleProjectsBanner from './sampleProjects/AppShellSampleProjectsBanner.vue';
import AppShellSidebar from './sidebar/AppShellSidebar.vue';
import AppShellSidebarMobileHeader from './sidebar/AppShellSidebarMobileHeader.vue';
import AppShellTrialWarningBanner from './trialWarning/AppShellTrialWarningBanner.vue';
import AppShellTrialWelcomeBanner from './trialWelcome/AppShellTrialWelcomeBanner.vue';
import AppShellSaleBanner from '@/module/sale/SaleBanner.vue';
import { useSampleProjects } from './sampleProjects/useSampleProjects';
import AppShellTheatreModeToolbar from './AppShellTheatreModeToolbar.vue';
import AppShellToastContainer from './toast/AppShellToastContainer.vue';

const DeskDrawer = defineAsyncComponent(() => import('@/module/desk').then((m) => m.DeskDrawer));

const { isLegacyQuickViewOpen, topOffset } = provideLegacyBridge();

provideHelpCenter();
provideDeskDrawer();
provideExperimentE23b('people_page');
const { shouldShowCalendarAlert } = useCalendarGoogleSyncStatus();

useFavicon();
const {
  activeDrawerPanel,
  activeDrawerPanelWidth,
  sidebarWidth,
  isDrawerPanelPinned,
  clearActiveDrawerPanelIfNotPinned,
  setItemPinned,
  canViewWelcomePage,
} = useAppShellSidebar();
const isMounted = useMounted();
const account = useCurrentAccount();
const { isTheaterModeEnabled, canToggleTheaterMode } = useTheaterMode();
const { isEmbeddedModeEnabled } = useEmbeddedMode();
const route = useRoute();
const { initialized, hubspotChatEnabled } = useFeatures();
const { trackDummyExperiments } = useDummyPendoExperiment();
const { trackExperimentA44, isExpA44Variation, trackExperimentA44Variation } = useExperimentA44();
const { trackExperimentA45 } = useExperimentA45();
const { trackExperimentA29 } = useExperimentA29();
const { trackExperimentA30 } = useExperimentA30();
const { trackExperimentA18 } = useExperimentA18();
const { trackExperimentA22 } = useExperimentA22();
const { trackExperiment46, trackAppLaunchedEvent, isExp46Variation } = useExperiment46();
const { trackExperimentE21a } = useExperimentE21a();
const { trackExperimentE21b } = useExperimentE21b();
const { trackExperimentE2325 } = useExperimentE2325();
const { trackExperimentA54 } = useExperimentA54();
const { trackExperimentA49 } = useExperimentA49();
const { trackExperimentA57 } = useExperimentA57();
const { trackExperimentA63 } = useExperimentA63();
const { isExpA2408Variation, trackExperimentA2408 } = useExperimentA2408();
const { trackExperimentE2304, isExp2304Variation } = useExperimentE2304();
const { trackExperimentE2301 } = useExperimentE2301();
const { trackExperimentE2401 } = useExperimentE2401();
const { isExpR2401Variation, trackExperimentR2401 } = useExperimentR2401();
const { trackExperimentR2403 } = useExperimentR2403();
const { trackCalendarExperiment } = useCalenderExperiment();

const { getOtherExp46CommonMetrics } = useBadge();
const { checkIsPaidAfterCheckout } = useSampleProjects();

const { sampleProjectsBannerDismissed } = usePreferences();
const { isChecklistFlowEnabled } = useOnboardingChecklist();

const { trackEventUtmDetected } = useUtmTracking();
const { canViewMyCalendar } = usePermissions();
const { isInFreeTrial, isTrialExpired, firstLoginMoreThan30DaysAgo } = useCohort();
const now = useNowByMinute();

useDocumentTitle(computed(() => account.value?.name));

provideActiveProductTourId();
const appBodyContent = shallowRef(null);
const appShellSidebarRef = shallowRef(null);
const appShellSidebarDrawerRef = shallowRef(null);

onClickOutside(
  appShellSidebarDrawerRef,
  (event) => {
    if (activeDrawerPanel.value === null || isDrawerPanelPinned.value) {
      return;
    }

    // AppShellSidebar component has multiple nodes.
    const { nextElementSibling: sidebar } = unrefElement(appShellSidebarRef);

    if (event.target instanceof HTMLElement && (sidebar.contains(event.target) || event.target === sidebar)) {
      return;
    }

    // Ensures notification panel does not close as a result of interaction with quick views
    if (activeDrawerPanel.value === 'notifications' && isLegacyQuickViewOpen.value) {
      return;
    }

    clearActiveDrawerPanelIfNotPinned();
  },
  { detectIframe: true },
);

useKeyboardShortcut('Shift T', () => {
  if (canToggleTheaterMode.value) {
    isTheaterModeEnabled.value = !isTheaterModeEnabled.value;
  }
});

const shouldShowSidebar = computed(() => !isTheaterModeEnabled.value && !isEmbeddedModeEnabled.value);

const onboardingRoutePaths = ['getting-started', 'onboarding'];
const isOnboardingRoute = computed(() => onboardingRoutePaths.some((path) => route.path.includes(path)));
const isMyCalendarRoute = computed(() => route.path.includes('/home/calendar'));

const startedFiveMonthsAgo = computed(() => now.value.diff(account.value.dateSignedUp).as('months') < 5);
const shouldLoadHubspot = computed(
  () => initialized.value && hubspotChatEnabled.value && startedFiveMonthsAgo.value && account.value.isPaid,
);

invoke(async () => {
  await until(initialized).toBe(true);
  checkIsPaidAfterCheckout();
  trackExperiment46();
  trackExperimentA29();
  trackExperimentE21a();
  trackExperimentE21b();
  trackExperimentE2325();
  trackExperimentA44();
  trackExperimentA45();
  trackExperimentA49();
  trackExperimentA18();
  trackExperimentA22();
  trackExperimentA30();
  trackExperimentA54();
  trackExperimentA57();
  trackExperimentA63();
  trackExperimentA2408();
  trackExperimentE2304();
  trackEventUtmDetected();
  trackExperimentE2301();
  trackExperimentE2401();
  trackExperimentR2401();
  trackExperimentR2403();
  trackCalendarExperiment();

  if (
    canViewWelcomePage.value &&
    ((!isInFreeTrial.value && firstLoginMoreThan30DaysAgo.value) || isTrialExpired.value)
  ) {
    setItemPinned('welcome', false);
  }

  if (isExp46Variation.value) {
    trackAppLaunchedEvent(getOtherExp46CommonMetrics());
  }

  if (isExpA44Variation.value) {
    trackExperimentA44Variation();
  }
});
onMounted(() => {
  trackDummyExperiments();
});

const isToolbarVisible = shallowRef(false);

watch(isTheaterModeEnabled, (newVal, oldVal) => {
  if (newVal && !oldVal) {
    isToolbarVisible.value = true;
    setTimeout(() => {
      isToolbarVisible.value = false;
    }, 6000);
  } else {
    isToolbarVisible.value = false;
  }
});
</script>

<template>
  <div
    :style="{
      '--sidebar-width': !shouldShowSidebar ? '0px' : sidebarWidth,
      '--active-drawer-panel-width': activeDrawerPanelWidth,
      '--legacy-top-offset': `${topOffset}px`,
    }"
  >
    <AppShellSidebar v-if="shouldShowSidebar" ref="appShellSidebarRef" />
    <div class="relative transition-[margin-left] duration-300 will-change-[margin-left] sm:ml-[--sidebar-width]">
      <AppShellDrawer ref="appShellSidebarDrawerRef" />

      <div
        ref="appBodyContent"
        class="flex h-screen flex-col items-stretch overflow-hidden"
        :class="{
          [activeDrawerPanel]: activeDrawerPanel,
        }"
        :style="{ paddingLeft: activeDrawerPanel && isDrawerPanelPinned ? activeDrawerPanelWidth : 0 }"
      >
        <AppShellNewVersionBanner />
        <AppShellTrialWarningBanner />
        <AppShellTrialWelcomeBanner v-if="!isOnboardingRoute" />
        <AppShellInvoiceBanner />
        <AppShellImpersonateBanner />
        <AppShellImporterBanner />
        <AppShellCalendarSyncBanner v-if="isMyCalendarRoute && canViewMyCalendar && shouldShowCalendarAlert" />
        <AppShellSampleProjectsBanner v-if="!sampleProjectsBannerDismissed" />
        <AppShellFeatureTrialBundleBanner v-if="isExp2304Variation" />
        <AppShellSaleBanner />
        <AppShellSidebarMobileHeader />
        <AppShellBreadcrumbs v-if="!isTheaterModeEnabled" class="shrink-0" />
        <MainRouterView />
        <AppShellTheatreModeToolbar
          v-if="canToggleTheaterMode"
          class="fixed bottom-4 left-1/2 transition-transform duration-500 ease-in-out"
          :class="isToolbarVisible ? 'translate-y-0' : 'translate-y-16'"
        />
        <TimeTimer v-if="isMounted" :containerRef="appBodyContent" />
      </div>

      <QuickViewRouterView />
      <LegacyAppFrame />

      <HubspotChatActivator v-if="shouldLoadHubspot" />
      <RepsAccountPrompt v-if="isExpR2401Variation || isExpA2408Variation" />
      <!-- match TWA Onboarding -->
      <OnboardingChecklist v-if="isChecklistFlowEnabled && !isOnboardingRoute" />
      <AppShellToastContainer />
    </div>
    <BadgeAchievedDialog v-if="isExp46Variation" />
    <DeskDrawer v-if="account.deskEnabled" />
  </div>
</template>
