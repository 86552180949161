<script setup>
import { useI18n } from '@/util';
import WidgetDialog from './WidgetDialog.vue';
import WidgetDialogCard from './WidgetDialogCard.vue';

defineProps({
  /**
   * The title to display in the dialog.
   */
  title: {
    type: String,
    default: null,
  },
  /**
   * The message to display in the dialog.
   */
  message: {
    type: String,
    default: null,
  },
  /**
   * The icon to display on the left side of the dialog.
   * @type {import('vue').PropType<import('@teamwork/lightspeed-icons/dist/icon-list').all>}
   */
  icon: {
    type: String,
    default: 'lsi-alert',
  },
  /**
   * Whether the dialog is critical.
   */
  critical: {
    type: Boolean,
    default: false,
  },
  /**
   * The text to display on the confirm button.
   */
  confirmButtonText: {
    type: String,
    default: null,
  },
  /**
   * The text to display on the cancel button.
   */
  cancelButtonText: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['cancel', 'confirm']);

const { t } = useI18n();

function onCancel(close) {
  emit('cancel');
  close();
}

function onConfirm(close) {
  emit('confirm');
  close();
}
</script>

<template>
  <WidgetDialog width="580px">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <WidgetDialogCard :title="title" @close="onCancel(close)">
        <template #prependHeaderLeft>
          <slot name="prependHeaderLeft">
            <LscIcon :icon="icon" size="lg" />
          </slot>
        </template>
        <div class="text-body-1">
          <slot>{{ message ?? t('Please confirm?') }}</slot>
        </div>
        <template #actions>
          <div class="flex flex-1 items-center justify-between">
            <LscButton variant="tertiary" @click="onCancel(close)">
              {{ cancelButtonText ?? t('Cancel') }}
            </LscButton>

            <LscButton :variant="critical ? 'critical' : 'primary'" @click="onConfirm(close)">
              {{ confirmButtonText ?? t('OK') }}
            </LscButton>
          </div>
        </template>
      </WidgetDialogCard>
    </template>
  </WidgetDialog>
</template>
