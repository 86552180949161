<script setup>
defineProps({
  proofData: {
    type: Object,
    default: undefined,
  },
  /**
   * Disables the project picker and sets its value to this
   */
  forceProject: {
    type: Object,
    default: undefined,
  },
  /**
   * Disables the task picker and sets its value to this
   */
  forceTask: {
    type: Object,
    default: undefined,
  },
  userCacheSeed: {
    type: Array,
    default: () => [],
  },
});

const modelValue = defineModel({
  type: Boolean,
  default: false,
});

const ProofAddOrEditDialogPanel = defineAsyncComponent(() => import('./ProofAddOrEditDialogPanel.vue'));
</script>

<template>
  <WidgetDialog v-model="modelValue" width="680px" data-identifier="proofs-add-edit-modal">
    <template v-if="$slots.activator" #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <ProofAddOrEditDialogPanel v-bind="{ proofData, forceProject, forceTask, userCacheSeed }" @close="close" />
    </template>
  </WidgetDialog>
</template>
