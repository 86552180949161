import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

/**
 * Turns the `account` object into a payload suitable for the PUT `/account.json` API endpoint.
 */
function accountToPayload(account) {
  const payload = {
    ...account,
  };

  if (Object.hasOwn(account, 'name')) {
    payload.installationName = account.name;
    payload.name = undefined;
  }

  if (Object.hasOwn(account, 'currency')) {
    payload.currencyId = account.currency.id;
    payload.currency = undefined;
  }

  return payload;
}

export function useCurrentAccountActions() {
  const api = useAxios();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { socketId, emit: emitRealTimeUpdate } = useRealTimeUpdates();
  const { t } = useI18n();

  return {
    /**
     * Update the current account.
     * @param {object} account
     */
    updateAccount(account) {
      const promise = api
        .put(`/account.json`, accountToPayload(account), {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('Error updating account'),
        })
        .then(() => {
          emitRealTimeUpdate({
            type: 'account',
            action: 'edited',
          });
        });

      emitOptimisticUpdate({
        promise,
        type: 'account',
        action: 'update',
        account,
      });

      return promise;
    },

    async accessLinkedAccount({ id, userId }) {
      try {
        const {
          data: { accessURL },
        } = await api.get(`/linkedSites/${id}/accessURL.json${userId ? `?userId=${userId}` : ''}`);

        return accessURL;
      } catch (error) {
        return '';
      }
    },

    /**
     * Checks the specified teamwork.com subdomain.
     * @param {string} subdomain
     * @returns {'available'|'taken'|'invalid'}
     */
    async checkDomain(subdomain) {
      try {
        await api.get(`/account/checkdomain.json?subdomain=${encodeURIComponent(subdomain)}`, {
          errorMessage: (error) => (error.response?.status === 422 ? undefined : t('Failed to check the subdomain')),
        });
        return 'available';
      } catch (error) {
        if (error.response?.status === 422) {
          if (error.response.data.MESSAGE === 'Already taken') {
            return 'taken';
          }
          return 'invalid';
        }
        throw error;
      }
    },

    /**
     * Updates the account domain.
     * @param {Object} data The request data.
     * @returns {Promise} A promise that resolves when the domain has been updated.
     */
    async updateDomain(data) {
      const promise = api
        .put(`/account/domain.json`, data, {
          errorMessage: t('Failed to update domain'),
        })
        .then(() => {
          emitRealTimeUpdate({
            type: 'account',
            action: 'edited',
          });
        });

      if (data.subdomain && data.domain) {
        emitOptimisticUpdate({
          promise,
          type: 'account',
          action: 'update',
          account: {
            URL: `https://${data.subdomain}.${data.domain}`,
          },
        });
      }
      if (data.customDomain) {
        emitOptimisticUpdate({
          promise,
          type: 'account',
          action: 'update',
          account: {
            customDomainUrl: data.customDomain,
            customDomainStatus: 'disabled',
          },
        });
      }

      return promise;
    },
  };
}
