import axios from 'axios';
import { useAxios } from './useAxios';
import { deepEqual, exponentialBackOff, useBackOff } from '@/util';
import { useLoaders } from './useLoaders';
import { useLoadersInSyncSource } from './useLoadersInSync';
import { useRealTimeUpdates } from './useRealTimeUpdates';

/**
 * Loads a single item from the Teamwork API.
 * Supports retrying with customizable delay and real-time updates.
 * @template Item
 * @template Meta
 * @param {Object} options
 * @param {import('@vueuse/core').MaybeRef<string | null | undefined>} options.url - The request url.
 * @param {import('@vueuse/core').MaybeRef<object>} [options.params] - The request params.
 * @param {(response: unknown) => Item} [options.responseToItem] - Gets an item from the server response.
 * @param {(response: unknown) => Meta} [options._responseToMeta] - Gets metadata from the server response.
 * @param {string} [options.type] - The type name of the loaded item.
 * @param {(retryAttempt: unknown) => number} [options.retryDelay] - The retry delay strategy.
 * @param {import('@vueuse/core').MaybeRef<boolean>} [options.cache] - Should the first request be sent directly to the browser cache.
 */
export function useItemLoader({
  /**
   * The request url.
   */
  url: _url,
  /**
   * The request params.
   */
  params: _params,
  /**
   * Gets an item from the server response.
   */
  responseToItem: _responseToItem = () => undefined,
  /**
   * Gets metadata from the server response.
   */
  responseToMeta: _responseToMeta = () => null,
  /**
   * The type name of the loaded item.
   */
  type: _type = undefined,
  /**
   * The retry delay strategy.
   */
  retryDelay = exponentialBackOff({ minDelay: 4000 }),
  /**
   * Should the first request be sent directly to the browser cache.
   */
  cache: _cache = true,
}) {
  const axiosInstance = useAxios();
  const { registerItems } = useLoaders();
  const { handlingEventFromSocket } = useRealTimeUpdates();
  const url = shallowRef(_url);
  const params = shallowRef(_params);
  const responseToItem = shallowRef(_responseToItem);
  const responseToMeta = shallowRef(_responseToMeta);
  const type = shallowRef(_type);
  const backOff = useBackOff({ retryDelay });
  const cache = shallowRef(_cache);
  /** @type {import("vue").ShallowRef<undefined | null | Item>} */
  const loadedItem = shallowRef(undefined);
  const needsRefresh = shallowRef(true);
  /** @type {import("vue").ShallowRef<import("axios").AxiosError>} */
  const error = shallowRef(undefined);
  /** @type {import("vue").ShallowRef<undefined | Meta>} */
  const meta = shallowRef(undefined);
  const cancel = shallowRef(undefined);
  const loaded = shallowRef(false);
  const optimisticUpdates = shallowRef(new Set());
  const item = computed(() => {
    let processedItem = loadedItem.value;
    optimisticUpdates.value.forEach((optimisticUpdate) => {
      processedItem = optimisticUpdate.apply(processedItem);
    });
    return processedItem;
  });
  const inSync = computed(() => !needsRefresh.value && optimisticUpdates.value.size === 0);
  useLoadersInSyncSource(inSync);

  let isInitialState = true;
  let triggeredBy = 'user';

  function refresh() {
    needsRefresh.value = true;
    if (cancel.value) {
      cancel.value();
    }
    backOff.reset();
    optimisticUpdates.value.forEach((optimisticUpdate) => {
      if (optimisticUpdate.updated) {
        clearTimeout(optimisticUpdate.timeout);
        // eslint-disable-next-line no-param-reassign
        optimisticUpdate.refreshing = true;
      }
    });
    triggeredBy = handlingEventFromSocket.value ? 'event/ws' : 'event/local';
  }

  function reset() {
    isInitialState = true;
    loadedItem.value = undefined;
    error.value = undefined;
    meta.value = undefined;
    loaded.value = false;
    refresh();
    triggeredBy = 'user';
  }

  function resetOnChange(newValue, oldValue) {
    if (deepEqual(newValue, oldValue)) {
      return;
    }
    reset();
  }

  /**
   * Updates the item locally, while waiting for the same change to be saved on the server.
   * @param apply {(item: Item | null | undefined) => Item | null | undefined}
   *   Gets an item and returns its new version with modifications.
   *   It MUST NOT modify the original item.
   * @param promise {Promise} A Promise tracking the request which makes the corresponding change on the server.
   */
  function update(apply, promise) {
    const optimisticUpdate = { apply, promise };
    promise.then(
      () => {
        if (needsRefresh.value) {
          // Keep the update until the data is refreshed.
          optimisticUpdate.promise = undefined;
        } else {
          // Discard the update, as it did not affect this loader.
          optimisticUpdates.value.delete(optimisticUpdate);
          triggerRef(optimisticUpdates);
        }
      },
      () => {
        // Discard the update, as it failed.
        optimisticUpdates.value.delete(optimisticUpdate);
        triggerRef(optimisticUpdates);
      },
    );
    // Apply the update optimistically.
    optimisticUpdates.value.add(optimisticUpdate);
    triggerRef(optimisticUpdates);
  }

  // Prunes optimistic updates which have been saved and read back from the server.
  function prune() {
    if (!needsRefresh.value) {
      optimisticUpdates.value.forEach((optimisticUpdate) => {
        if (!optimisticUpdate.promise) {
          optimisticUpdates.value.delete(optimisticUpdate);
          triggerRef(optimisticUpdates);
        }
      });
    }
  }

  async function load() {
    if (cancel.value) {
      return;
    } // loading in progress
    if (backOff.active.value) {
      return;
    } // back-off active
    if (!needsRefresh.value) {
      return;
    } // already in sync with the server
    if (typeof url.value !== 'string') {
      needsRefresh.value = false;
      return; // invalid url
    }

    // We force using the browser cache for the initial request in order to show the cached data immediately.
    // After that request completes, we immediately call `refresh` to load fresh data from the server.
    // Note also that direct requests to the browser cache are possible only for same-origin requests.
    // See https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
    const shouldUseCache =
      isInitialState && cache.value && (/^\/\w/.test(url.value) || import.meta.env.MODE === 'test');

    try {
      const response = await new Promise((resolve, reject) => {
        // Marks the request as canceled but allows it to complete,
        // so that it could be cached by the browser.
        cancel.value = () => reject(new axios.Cancel());
        axiosInstance
          .get(url.value, {
            params: params.value,
            headers: {
              'Triggered-By': triggeredBy,
            },
            cache: shouldUseCache ? 'only-if-cached' : undefined,
            mode: shouldUseCache ? 'same-origin' : undefined,
          })
          .then(resolve, reject);
      });

      error.value = undefined;

      loadedItem.value = responseToItem.value(response);
      meta.value = responseToMeta.value(response);
      needsRefresh.value = false;

      loaded.value = true;
      backOff.reset();
    } catch (axiosError) {
      if (shouldUseCache || axios.isCancel(axiosError)) {
        return;
      }

      if (import.meta.env.MODE !== 'test') {
        // eslint-disable-next-line no-console
        console.error('Error in useItemLoader:', axiosError);
      }

      if (axiosError.response && axiosError.response.status === 404) {
        error.value = undefined;
        loadedItem.value = null;
        meta.value = null;
        needsRefresh.value = false;
        loaded.value = true;
        backOff.reset();
      } else {
        error.value = axiosError;
        backOff.start();
      }
    } finally {
      isInitialState = false;
      cancel.value = undefined;
      if (shouldUseCache) {
        refresh();
        triggeredBy = 'user';
      }
    }
  }

  watch(url, resetOnChange);
  watch(params, resetOnChange);
  watch(responseToItem, resetOnChange);
  watch(responseToMeta, resetOnChange);

  watch(cancel, load);
  watch(backOff.active, load);
  watch(needsRefresh, load);
  watch(needsRefresh, prune);

  onScopeDispose(reset);
  load();
  registerItems(
    type,
    computed(() => (item.value != null ? [item.value] : [])),
  );

  return {
    state: {
      /**
       * The loaded item, if loaded and exists,
       * `null`, if loaded and does not exist,
       * or `undefined`, if not loaded yet.
       */
      item,
      /**
       * Indicates if the item is in sync with the server.
       */
      inSync,
      /**
       * Indicates if the loader has completed its initial load.
       */
      loaded,
      /**
       * The loaded metadata.
       */
      meta,
      /**
       * The error produced by the last axios request.
       */
      error,
      /**
       * Triggers an immediate retry.
       */
      retry: backOff.reset,
    },
    /**
     * Initial load.
     */
    load,
    /**
     * Refreshes the item by reloading it from the server.
     */
    refresh,
    update,
  };
}
