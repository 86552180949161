<script setup>
import { usePermissions, usePendo } from '@/api';
import { useI18n } from '@/util';
import { AppShellDrawerPanel, AppShellDrawerTitle, useAppShellSidebar } from '@/appShell';
import { useLegacyBridge } from '@/module/legacy';
import ProjectJumpToDrawerAll from './ProjectJumpToDrawerAll.vue';
import ProjectJumpToDrawerRecent from './ProjectJumpToDrawerRecent.vue';
import ProjectJumpToDrawerStarred from './ProjectJumpToDrawerStarred.vue';
import ProjectJumpToDrawerCategories from './ProjectJumpToDrawerCategories.vue';
import { useProjectJumpToDrawer } from './useProjectJumpToDrawer';

const searchTerm = shallowRef('');

const { t } = useI18n();
const { showLegacyModal } = useLegacyBridge();
const { selectedTab } = useProjectJumpToDrawer();
const { canAddProjects, canViewProjectTemplates } = usePermissions();
const { activeDrawerPanel, clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();
const { trackPendoEvent } = usePendo();

function addProject() {
  const source = 'add_project_jump_to_drawer_icon';

  trackPendoEvent({
    eventName: 'PROJECTS_EVENT',
    commonMetrics: ['plan_name', 'user_role', 'page', 'on_trial', 'is_icp', 'is_paid'],
    metadata: {
      page_type: 'projects',
      event_action: 'add_project_clicked',
      event_category: 'advanced_discovery_event',
      source,
    },
  });

  showLegacyModal({
    modalName: 'addOrEditProjectWizard',
    params: { source },
  });
}
</script>

<template>
  <AppShellDrawerPanel v-if="activeDrawerPanel === 'jumpTo'" data-identifier="side-nav-jump-to-panel">
    <template #header>
      <AppShellDrawerTitle :title="t('Jump to')">
        <template #prepend-icons="{ buttonSize }">
          <LscIconButton
            v-if="canAddProjects"
            v-LsdTooltip="t('Add project')"
            :size="buttonSize"
            :ariaLabel="t('Add project')"
            icon="lsi-add"
            data-identifier="side-nav-notifications-options-mark-all-as-read"
            @click="addProject"
          />
        </template>
      </AppShellDrawerTitle>
    </template>
    <template #content>
      <div class="m-0 flex h-full flex-col overflow-hidden p-0">
        <VTextField
          id="jumpToDrawerInput"
          :key="activeDrawerPanel"
          v-model.trim="searchTerm"
          v-bind="VTextFieldPanelSearch"
          class="mx-6 grow-0"
          :placeholder="t('Search')"
          autofocus
        />

        <template v-if="searchTerm">
          <h4 class="ml-6 mr-6 mt-6">{{ t('Search results') }}</h4>
        </template>

        <div v-else class="!mt-2 grow-0 px-6">
          <VTabs v-bind="VTabsUnderline" v-model="selectedTab">
            <VTab value="recent" data-identifier="projects-panel-tab-recent" tabindex="0">{{ t('Recent') }}</VTab>
            <VTab value="starred" data-identifier="projects-panel-tab-starred" tabindex="0">{{ t('Starred') }}</VTab>
            <VTab value="all" data-identifier="projects-panel-tab-all" tabindex="0">{{ t('All') }}</VTab>
            <VTab value="categories" data-identifier="projects-panel-tab-categories" tabindex="0">
              {{ t('Categories') }}
            </VTab>
          </VTabs>
        </div>

        <KeepAlive>
          <ProjectJumpToDrawerAll v-if="searchTerm" :searchTerm="searchTerm" />
          <ProjectJumpToDrawerRecent v-else-if="selectedTab === 'recent'" />
          <ProjectJumpToDrawerStarred v-else-if="selectedTab === 'starred'" />
          <ProjectJumpToDrawerAll v-else-if="selectedTab === 'all'" />
          <ProjectJumpToDrawerCategories v-else-if="selectedTab === 'categories'" />
        </KeepAlive>
      </div>
    </template>
    <template #footer>
      <div class="my-7 flex flex-col gap-4">
        <RouterLink
          v-if="canViewProjectTemplates"
          to="/projects/list"
          class="flex w-full items-center text-body-1 font-semibold text-primary no-underline"
          @click="clearActiveDrawerPanelIfNotPinned"
        >
          <VIcon class="mr-2">lsi-project</VIcon>
          {{ t('See all projects') }}
          <VIcon class="ml-auto">lsi-open-panel</VIcon>
        </RouterLink>
        <div class="h-px w-full border-0 border-t border-solid border-separator-on-surface-default" />
        <RouterLink
          to="/projects/templates"
          class="flex w-full items-center text-body-1 font-semibold text-subtle no-underline hover:text-default"
          @click="clearActiveDrawerPanelIfNotPinned"
        >
          <VIcon class="mr-2">lsi-single-template</VIcon>
          {{ t('Project templates') }}
          <VIcon class="ml-auto">lsi-open-panel</VIcon>
        </RouterLink>
      </div>
    </template>
  </AppShellDrawerPanel>
</template>
