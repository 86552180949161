<script setup>
import { useLsDraggable } from '../../../composables/draggable/useLsDraggable';

// NOTE: THIS COMPONENT IS DEPRECATED AND WILL BE REMOVED IN THE FUTURE
// PLEASE USE `WidgetDialogCard` INSTEAD

defineProps({
  title: {
    type: String,
    default: '',
  },
  closeButtonText: {
    type: String,
    default: '',
  },
  saveButtonText: {
    type: String,
    default: '',
  },
  saveButtonPrependIcon: {
    type: String,
    default: '',
  },
  saveButtonDisabled: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  hasFooter: {
    type: Boolean,
    default: true,
  },
  /* Set a minimum height to prevent janking of UI */
  minHeight: {
    type: Number,
    default: 40,
  },
  critical: {
    type: Boolean,
    default: false,
  },
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['close', 'save']);

const { draggable } = useLsDraggable();
const vCard = shallowRef(null);
const dragHandle = shallowRef(null);

onMounted(() => {
  if (vCard.value && dragHandle.value) {
    draggable(vCard.value.$el, { dragHandle: dragHandle.value.$el });
  }
});

// the canceled parameter was added to track whether the dialog was closed by clicking the close button
// it can be removed once the experiment is done
function close(canceled) {
  emit('close', canceled);
}

function save() {
  emit('save');
}
</script>

<template>
  <VCard ref="vCard" target="VDialogCard">
    <slot name="header">
      <VCardTitle ref="dragHandle" class="flex items-center p-6 text-h4 font-semibold">
        <slot name="before-header-title" />
        <slot name="title">
          <span :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-title` : undefined">{{ title }}</span>
        </slot>
        <slot name="after-header-title" />
        <VSpacer />
        <slot name="before-header-close-btn" />

        <slot name="header-close-btn">
          <LscIconButton
            v-LsdTooltip="closeButtonText"
            size="md"
            variant="primary"
            icon="lsi-close"
            :ariaLabel="closeButtonText"
            @click="close(false)"
          />
        </slot>
      </VCardTitle>
    </slot>

    <VCardText
      id="dialogForm"
      class="px-6 !pb-0 !pt-2 !text-body-1 !text-default"
      :style="{ minHeight: minHeight ? `${minHeight}px` : undefined }"
      tag="form"
      @submit.prevent="save"
    >
      <slot />
    </VCardText>

    <template v-if="hasFooter">
      <VCardActions v-if="closeButtonText || saveButtonText" class="m-6 min-h-10 p-0">
        <LscButton
          v-if="closeButtonText"
          variant="tertiary"
          :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-cancel-button` : undefined"
          @click="close(true)"
        >
          {{ closeButtonText }}
        </LscButton>
        <slot name="after-cancel-btn" />
        <VSpacer />
        <slot name="before-save-btn" />
        <LscButton
          v-if="saveButtonText"
          type="submit"
          :variant="critical ? 'critical' : 'primary'"
          :loading="isLoading"
          :prependIcon="saveButtonPrependIcon"
          form="dialogForm"
          :disabled="saveButtonDisabled || isLoading"
          :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-submit-button` : undefined"
          @click.prevent.stop="save"
        >
          {{ saveButtonText }}
        </LscButton>
      </VCardActions>

      <template v-if="$slots['after-footer']">
        <VDivider />

        <slot name="after-footer" />
      </template>
    </template>
  </VCard>
</template>
