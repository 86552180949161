import { DateTime } from 'luxon';
import { until } from '@vueuse/core';
import { badgeIds } from './badgeIds';
import {
  useBadgesV3Loader,
  useBadgeLevelsV3Loader,
  useCurrentUser,
  useBadgesActions,
  usePendo,
  useExperiment46,
  loaderStatePromise,
} from '@/api';

const symbol = Symbol('useBadge');

const recruitLevel = {
  id: 0,
  code: 'recruit',
  displayName: 'Recruit',
  pathRank: 0,
  isAchieved: true,
  achievedAt: DateTime.now(),
};

// achieved dialog not shown for project pioneer badge
const badgesForWhichWeDontShowProgress = [badgeIds.projectPioneer];

function Badge() {
  const { EXP_46_COMMON_METRICS, isExp46Variation } = useExperiment46();

  const user = useCurrentUser();
  const { trackPendoEvent } = usePendo();

  const badgesState = useBadgesV3Loader({
    userId: computed(() => user.value?.id),
    count: computed(() => (isExp46Variation.value ? Infinity : -1)),
    params: {
      include: 'badgeLevels',
    },
  });
  const { items: _badges, inSync: badgesInSync } = badgesState;

  const { items: levels, inSync: levelsInSync } = useBadgeLevelsV3Loader({
    userId: computed(() => user.value?.id),
    count: computed(() => (isExp46Variation.value ? Infinity : -1)),
  });

  const badgesAndLevelsInSync = computed(() => badgesInSync.value && levelsInSync.value);

  function getCompletableBadges() {
    return _badges.value.filter((badge) => Boolean(user.value?.administrator) || !badge.IsAdminOnly);
  }

  const badges = computed(() => getCompletableBadges(_badges.value));

  const currentLevel = computed(() => {
    return levelsInSync.value ? levels.value.findLast(({ isAchieved }) => isAchieved) ?? recruitLevel : undefined;
  });

  const nextLevel = computed(() => {
    if (!currentLevel.value) {
      return null;
    }

    return levels.value.find(({ pathRank }) => pathRank === currentLevel.value.pathRank + 1) ?? null;
  });

  const applicableBadges = computed(() =>
    badges.value.filter((badge) => user.value?.administrator || !badge.IsAdminOnly),
  );

  /**
   * Not the ones left. All of them
   */
  const mandatoryBadgesFromNextLevel = computed(() => {
    return applicableBadges.value.filter(
      (badge) => badge.level.pathRank === nextLevel.value?.pathRank && badge.isOptional === false,
    );
  });

  const nextLevelProgress = computed(() => {
    const lastPathRank = levels.value[levels.value.length - 1]?.pathRank;
    if (currentLevel.value?.pathRank === lastPathRank) {
      return 0;
    }

    const percentage = Math.ceil(
      (mandatoryBadgesFromNextLevel.value.filter((badge) => badge.isAchieved).length /
        mandatoryBadgesFromNextLevel.value.length) *
        100,
    );

    return Number.isNaN(percentage) ? 0 : percentage;
  });

  const ready = computed(() => badges.value.length > 0 && levels.value.length > 0);

  // Badge completion logic
  const { completeBadge } = useBadgesActions();

  const dialogShown = shallowRef(false);
  const lastBadgeIDCompleted = shallowRef(undefined);

  /**
   * Indicates level / progress change from the last badge completed
   * @type {import('vue').Ref<null | {
   * currentLevel: null | {from: BadgeLevel, to: BadgeLevel}
   * nextLevelProgress: null | {from: number, to: number}
   * }>}
   */
  const progressFromBadgeCompletion = shallowRef(null);

  const lastBadgeCompleted = computed(() => badges.value.find(({ id }) => id === lastBadgeIDCompleted.value));

  const badgeWasReachieved = shallowRef(undefined);

  /**
   * @param {Array<'current_level' | 'next_level_progress'>} selectedMetrics
   * @returns {{current_level?: string, next_level_progress?: number}}
   */
  function getOtherExp46CommonMetrics() {
    return {
      current_level: currentLevel.value?.code,
      next_level_progress: nextLevelProgress.value,
    };
  }

  async function tryCompleteBadge(badgeID) {
    const badge = badges.value.find(({ id }) => id === badgeID);
    badgeWasReachieved.value = badge.isAchieved;

    if (!badge.isAchieved) {
      const before = {
        currentLevel: currentLevel.value,
        nextLevelProgress: nextLevelProgress.value,
      };

      completeBadge(badgeID);

      // Waits until progress is updated
      await until(badgesAndLevelsInSync).toBe(true);

      trackPendoEvent({
        eventName: 'BADGE_RATING_EVENT',
        commonMetrics: EXP_46_COMMON_METRICS,
        metadata: {
          event_action: 'badge_completed',
          badge_level: currentLevel.value?.code, // novice, intermediate, etc
          badge_type: badge.code, // project_pioneer, timekeeper, etc
          ...getOtherExp46CommonMetrics(),
        },
      });

      progressFromBadgeCompletion.value = {
        // Storing the whole level instead of just the fact that a levelUp occured
        // Because multiple levelups can occur at once
        currentLevel:
          currentLevel.value.id === before.currentLevel.id
            ? null
            : { from: before.currentLevel, to: currentLevel.value },
        nextLevelProgress:
          before.nextLevelProgress === nextLevelProgress.value
            ? null
            : { from: before.nextLevelProgress, to: nextLevelProgress.value },
      };
    } else {
      progressFromBadgeCompletion.value = null;
    }

    lastBadgeIDCompleted.value = badgeID;
    dialogShown.value = !badgesForWhichWeDontShowProgress.includes(badgeID);
  }

  // Complete Project Pioneer if the user is invited (does not go through project creation)
  loaderStatePromise(badgesState).then(() => {
    if (user.value.userInvited) {
      tryCompleteBadge(badgeIds.projectPioneer);
    }
  });

  return {
    levels,
    badges,
    currentLevel,
    nextLevel,
    nextLevelProgress,
    mandatoryBadgesFromNextLevel,
    ready,
    recruitLevel,

    completeBadge: tryCompleteBadge,

    lastBadgeCompleted,
    dialogShown,
    badgeWasReachieved,
    progressFromBadgeCompletion,

    getOtherExp46CommonMetrics,
  };
}

export function provideBadge() {
  const badge = Badge();
  provide(symbol, badge);
  return badge;
}

/** @returns {ReturnType<typeof Badge>} */
export function useBadge() {
  return inject(symbol);
}
