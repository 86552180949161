<script setup>
import { SuccessDrawer } from '@/module/success';
import { useLegacyBridge } from '@/module/legacy';
import { ShortcutDrawer } from '@/module/shortcut';
import { ProductUpdatesDrawer } from '@/module/productUpdates';
import { useI18n, useKeyboardShortcut } from '@/util';
import {
  useCurrentAccount,
  useCurrentUser,
  useCurrentUserActions,
  useFeatures,
  usePermissions,
  useExperiment46,
  usePendo,
} from '@/api';
import { useAppShellSidebar } from '../useAppShellSidebar';
import AppShellSidebarUserMenuMoreItems from './AppShellSidebarUserMenuMoreItems.vue';
import AppShellSidebarUserMenuSwitchAccounts from './AppShellSidebarUserMenuSwitchAccounts.vue';
import { BadgeLevelProgressBar, BadgeLevelUser, useBadge } from '@/module/badge';

defineOptions({
  inheritAttrs: false,
});

const { t } = useI18n();
const user = useCurrentUser();
const account = useCurrentAccount();

const { showLegacyModal } = useLegacyBridge();
const { logout, toggleClockIn } = useCurrentUserActions();
const { canViewSettings, canViewGlobalSettingsCustomfields, isAccount, isOwnerAdmin } = usePermissions();
const { clockInOutEnabled } = useFeatures();
const { isMyShortcutsOpen, isProductUpdatesOpen, isSuccessPlannerOpen } = useAppShellSidebar();

const { currentLevel, nextLevelProgress, nextLevel, getOtherExp46CommonMetrics } = useBadge();
const { EXP_46_COMMON_METRICS, isExp46Variation } = useExperiment46();

const { trackPendoEvent } = usePendo();
// Edge case where a non admin can access settings to see the custom fields only
const defaultSettingsRoute = computed(() => {
  return !isOwnerAdmin.value && canViewGlobalSettingsCustomfields.value ? '/settings/customfields' : '/settings';
});

const canShowPlayground = import.meta.env.DEV;

function openMyShortcuts() {
  isMyShortcutsOpen.value = !isMyShortcutsOpen.value;
}

function trackProfileMenuItemClicked(itemText) {
  trackPendoEvent({
    eventName: 'NAVIGATION_EVENT',
    commonMetrics: EXP_46_COMMON_METRICS,
    metadata: {
      event_action: 'profile_menu_item_clicked',
      navigation_item: itemText,
      ...getOtherExp46CommonMetrics(),
    },
  });
}

function editMyDetailsClicked() {
  showLegacyModal({ modalName: 'addOrEditPerson', params: { personId: user.value.id } });
  trackProfileMenuItemClicked('edit_my_details');
}

function toggleClockClicked() {
  toggleClockIn();
  trackProfileMenuItemClicked('toggle_clock');
}

function logoutClicked() {
  logout();
  trackProfileMenuItemClicked('logout');
}

useKeyboardShortcut('Shift S', openMyShortcuts);
</script>

<template>
  <WidgetMenu v-bind="$attrs">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <VList v-bind="VListRounded" class="max-w-[250px] !pt-2">
      <VListItem
        v-LsdTooltip="`${user.firstName} ${user.lastName}`"
        class="focus-visible:bg-selected"
        v-bind="VListItemSidebarMenuLink"
        tabindex="0"
        twoLine
        :to="`/people/${user.id}`"
        link
        @click="trackProfileMenuItemClicked('view_profile')"
      >
        <div v-if="isExp46Variation && currentLevel" class="absolute right-0 top-0 z-10">
          <BadgeLevelUser :code="currentLevel.code" />
        </div>
        <template #prepend>
          <LscAvatar v-bind="getLsAvatarProps({ user })" size="lg" class="mr-2" />
        </template>
        <VListItemTitle class="!text-body-1 font-semibold !text-default">
          {{ user.firstName }} {{ user.lastName }}
        </VListItemTitle>
        <BadgeLevelProgressBar v-if="isExp46Variation && nextLevel !== null" :progress="nextLevelProgress" />
        <VListItemSubtitle v-else class="!text-body-2 !text-subtle">{{ t('View profile') }}</VListItemSubtitle>
      </VListItem>

      <VDivider />

      <VListItem
        class="focus-visible:bg-selected"
        v-bind="VListItemSidebarMenuLink"
        link
        tabindex="0"
        @click="editMyDetailsClicked"
      >
        <template #prepend>
          <VIcon size="20">lsi-edit</VIcon>
        </template>
        <VListItemTitle>{{ t('Edit my details') }}</VListItemTitle>
      </VListItem>
      <VListItem
        v-if="isAccount && clockInOutEnabled"
        class="focus-visible:bg-selected"
        tabindex="0"
        v-bind="VListItemSidebarMenuLink"
        @click="toggleClockClicked"
      >
        <template #prepend>
          <VIcon size="22">lsi-time</VIcon>
        </template>
        <VListItemTitle>{{ user.isClockedIn ? t('Clock out') : t('Clock in') }}</VListItemTitle>
      </VListItem>

      <VDivider />

      <VListItem
        v-if="canViewSettings"
        class="focus-visible:bg-selected"
        tabindex="0"
        v-bind="VListItemSidebarMenuLink"
        :to="defaultSettingsRoute"
        link
        @click="trackProfileMenuItemClicked('settings')"
      >
        <template #prepend>
          <VIcon size="20">lsi-settings</VIcon>
        </template>
        <VListItemTitle>{{ t('Settings') }}</VListItemTitle>
      </VListItem>

      <VListItem
        v-if="isOwnerAdmin"
        class="focus-visible:bg-selected"
        v-bind="VListItemSidebarMenuLink"
        tabindex="0"
        :to="`/settings/subscription`"
        link
        @click="trackProfileMenuItemClicked('subscription')"
      >
        <template #prepend>
          <VIcon size="20">lsi-subscription</VIcon>
        </template>
        <VListItemTitle>{{ t('Subscription') }}</VListItemTitle>
      </VListItem>

      <VListItem
        v-if="isAccount"
        class="focus-visible:bg-selected"
        v-bind="VListItemSidebarMenuLink"
        tabindex="0"
        :to="`/people/${user.id}/tasks`"
        link
        @click="trackProfileMenuItemClicked('my_tasks')"
      >
        <template #prepend>
          <VIcon size="20">lsi-task</VIcon>
        </template>
        <VListItemTitle>{{ t('My tasks') }}</VListItemTitle>
      </VListItem>

      <VListItem
        v-if="isExp46Variation"
        class="focus-visible:bg-selected"
        v-bind="VListItemSidebarMenuLink"
        tabindex="0"
        link
        :to="`/people/${user.id}/badges`"
        @click="trackProfileMenuItemClicked('my_badges')"
      >
        <template #prepend>
          <VIcon size="20">lsi-badge</VIcon>
        </template>
        <VListItemTitle>{{ t('My badges') }}</VListItemTitle>
      </VListItem>

      <VListItem
        v-if="isAccount"
        class="focus-visible:bg-selected"
        tabindex="0"
        v-bind="VListItemSidebarMenuLink"
        :to="`/people/${user.id}/integrations`"
        link
        @click="trackProfileMenuItemClicked('my_apps')"
      >
        <template #prepend>
          <VIcon size="20">lsi-my-apps</VIcon>
        </template>
        <VListItemTitle>{{ t('My apps') }}</VListItemTitle>
      </VListItem>

      <VListItem
        v-if="isOwnerAdmin"
        class="focus-visible:bg-selected"
        v-bind="VListItemSidebarMenuLink"
        tabindex="0"
        :to="`/settings/integrations`"
        link
        @click="trackProfileMenuItemClicked('apps_and_integrations')"
      >
        <template #prepend>
          <VIcon size="20">lsi-sidenav-other-apps</VIcon>
        </template>
        <VListItemTitle>{{ t('Apps and integrations') }}</VListItemTitle>
      </VListItem>

      <AppShellSidebarUserMenuMoreItems @click="trackProfileMenuItemClicked('more_items')" />

      <VDivider />

      <VListItem
        v-if="canShowPlayground"
        class="focus-visible:bg-selected"
        tabindex="0"
        v-bind="VListItemSidebarMenuLink"
        :to="`/playground`"
        link
      >
        <template #prepend>
          <VIcon size="20" color="primary">lsi-dev</VIcon>
        </template>
        <VListItemTitle class="text-primary">Dev playground</VListItemTitle>
      </VListItem>

      <AppShellSidebarUserMenuSwitchAccounts
        v-if="user.accounts.length > 0 || account.isPartner"
        @click="trackProfileMenuItemClicked('switch_accounts')"
      />

      <VListItem class="focus-visible:bg-selected" link tabindex="0" @click="logoutClicked">
        <template #prepend>
          <VIcon size="20">lsi-logout</VIcon>
        </template>
        <VListItemTitle>{{ t('Logout') }}</VListItemTitle>
      </VListItem>
    </VList>
  </WidgetMenu>
  <ShortcutDrawer v-model="isMyShortcutsOpen" />
  <SuccessDrawer v-model="isSuccessPlannerOpen" />
  <ProductUpdatesDrawer v-model="isProductUpdatesOpen" />
</template>
