/**
 * Do not edit directly
 * Generated on Sat, 30 Mar 2024 00:02:47 GMT
 */

export const LsdsGBorderWidth1 = "1px";
export const LsdsGBorderWidth2 = "2px";
export const LsdsGBorderWidth3 = "3px";
export const LsdsGBorderStyleSolid = "solid";
export const LsdsGBorderStyleDashed = "dashed";
export const LsdsGBorderRounded0 = "0";
export const LsdsGBorderRoundedSm = "4px";
export const LsdsGBorderRoundedMd = "8px";
export const LsdsGBorderRoundedLg = "12px";
export const LsdsGBorderRoundedXl = "24px";
export const LsdsGBorderRoundedFull = "9999px";
export const LsdsGFontFamilyDefault = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsGFontFamilyMono = "ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, \"Liberation Mono\", \"Courier New\", monospace";
export const LsdsGFontWeightRegular = "400";
export const LsdsGFontWeightMedium = "500";
export const LsdsGFontWeightSemibold = "600";
export const LsdsGFontWeightBold = "700";
export const LsdsGFontStyleNormal = "style";
export const LsdsGFontStyleItalic = "italic";
export const LsdsGFontSize1 = "12px";
export const LsdsGFontSize2 = "14px";
export const LsdsGFontSize3 = "16px";
export const LsdsGFontSize4 = "18px";
export const LsdsGFontSize5 = "20px";
export const LsdsGFontSize6 = "24px";
export const LsdsGFontSize7 = "28px";
export const LsdsGFontSize8 = "32px";
export const LsdsGFontSize05 = "10px";
export const LsdsGFontTextTransformUppercase = "uppercase";
export const LsdsGFontTextDecorationUnderline = "underline";
export const LsdsGFontLetterSpacingDefault = "0%";
export const LsdsGFontLetterSpacingLoose = "2%";
export const LsdsGFontLetterSpacingRelaxed = "5%";
export const LsdsGFontLineHeight0 = "0";
export const LsdsGFontLineHeight1 = "16px";
export const LsdsGFontLineHeight2 = "20px";
export const LsdsGFontLineHeight3 = "24px";
export const LsdsGFontLineHeight4 = "28px";
export const LsdsGFontLineHeight5 = "32px";
export const LsdsGFontLineHeight6 = "40px";
export const LsdsGFontLineHeight7 = "44px";
export const LsdsGFontLineHeight100 = "100%";
export const LsdsGFontLineHeightNone = 1;
export const LsdsGMotionEaseEase = "cubic-bezier(0.25, 0.1, 0.25, 1)";
export const LsdsGMotionEaseIn = "cubic-bezier(0.42, 0, 1, 1)";
export const LsdsGMotionEaseOut = "cubic-bezier(0.19, 0.91, 0.38, 1)";
export const LsdsGMotionEaseInOut = "cubic-bezier(0.42, 0, 0.58, 1)";
export const LsdsGMotionEaseLinear = "cubic-bezier(0, 0, 1, 1)";
export const LsdsGMotionDuration100 = "100ms";
export const LsdsGMotionDuration300 = "300ms";
export const LsdsGMotionDuration500 = "500ms";
export const LsdsGPaletteIndigo10 = "#f2f4fc";
export const LsdsGPaletteIndigo15 = "#ebefff";
export const LsdsGPaletteIndigo20 = "#d8e0ff";
export const LsdsGPaletteIndigo25 = "#bac9fd";
export const LsdsGPaletteIndigo30 = "#8f9fe7";
export const LsdsGPaletteIndigo40 = "#4461d7";
export const LsdsGPaletteIndigo50 = "#3c55bd";
export const LsdsGPaletteIndigo60 = "#364daa";
export const LsdsGPaletteBlue10 = "#edf6fe";
export const LsdsGPaletteBlue20 = "#c2e9ff";
export const LsdsGPaletteBlue30 = "#94c7fb";
export const LsdsGPaletteBlue40 = "#4da2f8";
export const LsdsGPaletteBlue50 = "#4592df";
export const LsdsGPaletteBlue60 = "#3578bb";
export const LsdsGPaletteTeal10 = "#edfbfc";
export const LsdsGPaletteTeal20 = "#c5f2f2";
export const LsdsGPaletteTeal30 = "#97e8f0";
export const LsdsGPaletteTeal40 = "#4cd5e3";
export const LsdsGPaletteTeal50 = "#44c0cc";
export const LsdsGPaletteTeal60 = "#3dadb8";
export const LsdsGPalettePurple10 = "#f3effe";
export const LsdsGPalettePurple20 = "#e2daff";
export const LsdsGPalettePurple30 = "#bba1ff";
export const LsdsGPalettePurple40 = "#895ef7";
export const LsdsGPalettePurple50 = "#7450d2";
export const LsdsGPalettePurple60 = "#6848bd";
export const LsdsGPaletteYellow10 = "#fff9ec";
export const LsdsGPaletteYellow20 = "#fff0ca";
export const LsdsGPaletteYellow30 = "#ffdc87";
export const LsdsGPaletteYellow40 = "#ffc63c";
export const LsdsGPaletteYellow45 = "#ffb400";
export const LsdsGPaletteYellow50 = "#e2b13a";
export const LsdsGPaletteYellow60 = "#cb9f34";
export const LsdsGPaletteRed10 = "#fceaec";
export const LsdsGPaletteRed20 = "#ffd0da";
export const LsdsGPaletteRed30 = "#ed7987";
export const LsdsGPaletteRed40 = "#e12d42";
export const LsdsGPaletteRed50 = "#cb283b";
export const LsdsGPaletteRed60 = "#b42435";
export const LsdsGPaletteOrange10 = "#fff1ec";
export const LsdsGPaletteOrange20 = "#ffe4d9";
export const LsdsGPaletteOrange30 = "#ffa785";
export const LsdsGPaletteOrange40 = "#ff7641";
export const LsdsGPaletteOrange50 = "#e56a3a";
export const LsdsGPaletteOrange60 = "#ce5f34";
export const LsdsGPalettePink10 = "#ffeff9";
export const LsdsGPalettePink20 = "#f1cce6";
export const LsdsGPalettePink30 = "#ffa0dd";
export const LsdsGPalettePink40 = "#ff61c7";
export const LsdsGPalettePink50 = "#e557b3";
export const LsdsGPalettePink60 = "#c43692";
export const LsdsGPaletteGreen10 = "#edfaf5";
export const LsdsGPaletteGreen20 = "#d2fde8";
export const LsdsGPaletteGreen30 = "#95e1c1";
export const LsdsGPaletteGreen40 = "#4ecd97";
export const LsdsGPaletteGreen50 = "#46b988";
export const LsdsGPaletteGreen60 = "#318160";
export const LsdsGPaletteBrown10 = "#faf0e8";
export const LsdsGPaletteBrown20 = "#f4e0d1";
export const LsdsGPaletteBrown30 = "#dfa275";
export const LsdsGPaletteBrown40 = "#ca6419";
export const LsdsGPaletteBrown50 = "#ac5515";
export const LsdsGPaletteBrown60 = "#9b4d13";
export const LsdsGPaletteGrey10 = "#fafafc";
export const LsdsGPaletteGrey20 = "#eef1f5";
export const LsdsGPaletteGrey30 = "#e8eaf1";
export const LsdsGPaletteGrey40 = "#cacddc";
export const LsdsGPaletteGrey50 = "#a7acc5";
export const LsdsGPaletteGrey60 = "#969bb1";
export const LsdsGPaletteGrey70 = "#4d4d4d";
export const LsdsGPaletteWhite = "#ffffff";
export const LsdsGPaletteBlack = "#000000";
export const LsdsGPaletteTransparent = "#00000000";
export const LsdsGPaletteNeutral10 = "#f5f7fa";
export const LsdsGPaletteNeutral20 = "#edeff5";
export const LsdsGPaletteNeutral25 = "#e1e4ee";
export const LsdsGPaletteNeutral30 = "#c5cae0";
export const LsdsGPaletteNeutral40 = "#8f96b3";
export const LsdsGPaletteNeutral50 = "#64697d";
export const LsdsGPaletteNeutral55 = "#383c4e";
export const LsdsGPaletteNeutral60 = "#0b0f1f";
export const LsdsGPaletteNeutral05 = "#fafafc";
export const LsdsGScreenSm = "640px";
export const LsdsGScreenMd = "768px";
export const LsdsGScreenLg = "1024px";
export const LsdsGScreenXl = "1280px";
export const LsdsGScreen2Xl = "1536px";
export const LsdsGShadow1 = "0 2px 4px rgba(11,14,31,0.1)";
export const LsdsGShadow2 = "0 4px 8px rgba(11,14,31,0.1)";
export const LsdsGShadow3 = "0 8px 16px rgba(11,14,31,0.1)";
export const LsdsGShadow4 = "0 16px 32px rgba(11,14,31,0.12)";
export const LsdsGSize0 = "0";
export const LsdsGSize1 = "0.25rem";
export const LsdsGSize2 = "0.5rem";
export const LsdsGSize3 = "0.75rem";
export const LsdsGSize4 = "1rem";
export const LsdsGSize5 = "1.25rem";
export const LsdsGSize6 = "1.5rem";
export const LsdsGSize7 = "1.75rem";
export const LsdsGSize8 = "2rem";
export const LsdsGSize9 = "2.25rem";
export const LsdsGSize10 = "2.5rem";
export const LsdsGSize11 = "2.75rem";
export const LsdsGSize12 = "3rem";
export const LsdsGSize14 = "3.5rem";
export const LsdsGSize16 = "4rem";
export const LsdsGSize18 = "4.5rem";
export const LsdsGSize20 = "5rem";
export const LsdsGSize24 = "6rem";
export const LsdsGSize28 = "7rem";
export const LsdsGSize32 = "8rem";
export const LsdsGSize36 = "9rem";
export const LsdsGSize40 = "10rem";
export const LsdsGSize44 = "11rem";
export const LsdsGSize48 = "12rem";
export const LsdsGSize52 = "13rem";
export const LsdsGSize56 = "14rem";
export const LsdsGSize60 = "15rem";
export const LsdsGSize64 = "16rem";
export const LsdsGSize72 = "18rem";
export const LsdsGSize80 = "20rem";
export const LsdsGSize88 = "22rem";
export const LsdsGSize96 = "24rem";
export const LsdsGSize100 = "25rem";
export const LsdsGSize144 = "36rem";
export const LsdsGSize160 = "40rem";
export const LsdsGSizePx = "1px";
export const LsdsGSize05 = "0.125rem";
export const LsdsGSize15 = "0.375rem";
export const LsdsGSize25 = "0.625rem";
export const LsdsGSize35 = "0.875rem";
export const LsdsGSizeW25 = "25%";
export const LsdsGSizeW50 = "50%";
export const LsdsGSizeW75 = "75%";
export const LsdsGSizeW100 = "100%";
export const LsdsGSizeWAuto = "auto";
export const LsdsGSizeH25 = "25%";
export const LsdsGSizeH50 = "50%";
export const LsdsGSizeH75 = "75%";
export const LsdsGSizeH100 = "100%";
export const LsdsGSizeHAuto = "auto";
export const LsdsGSpacing0 = "0";
export const LsdsGSpacing1 = "0.25rem";
export const LsdsGSpacing2 = "0.5rem";
export const LsdsGSpacing3 = "0.75rem";
export const LsdsGSpacing4 = "1rem";
export const LsdsGSpacing5 = "1.25rem";
export const LsdsGSpacing6 = "1.5rem";
export const LsdsGSpacing7 = "1.75rem";
export const LsdsGSpacing8 = "2rem";
export const LsdsGSpacing9 = "2.25rem";
export const LsdsGSpacing10 = "2.5rem";
export const LsdsGSpacing11 = "2.75rem";
export const LsdsGSpacing12 = "3rem";
export const LsdsGSpacing14 = "3.5rem";
export const LsdsGSpacing16 = "4rem";
export const LsdsGSpacing18 = "4.5rem";
export const LsdsGSpacing20 = "5rem";
export const LsdsGSpacing24 = "6rem";
export const LsdsGSpacing28 = "7rem";
export const LsdsGSpacing32 = "8rem";
export const LsdsGSpacing36 = "9rem";
export const LsdsGSpacing40 = "10rem";
export const LsdsGSpacing44 = "11rem";
export const LsdsGSpacing48 = "12rem";
export const LsdsGSpacing52 = "13rem";
export const LsdsGSpacing56 = "14rem";
export const LsdsGSpacing60 = "15rem";
export const LsdsGSpacing64 = "16rem";
export const LsdsGSpacing72 = "18rem";
export const LsdsGSpacing80 = "20rem";
export const LsdsGSpacing96 = "24rem";
export const LsdsGSpacing100 = "25rem";
export const LsdsGSpacingPx = "1px";
export const LsdsGSpacing05 = "0.125rem";
export const LsdsGSpacing15 = "0.375rem";
export const LsdsGSpacing25 = "0.625rem";
export const LsdsGSpacing35 = "0.875rem";
export const LsdsAColorActionPrimaryDefault = "#4461d7";
export const LsdsAColorActionPrimaryPressed = "#3c55bd";
export const LsdsAColorActionPrimaryHover = "#3c55bd";
export const LsdsAColorActionPrimaryDisabled = "#edeff5";
export const LsdsAColorActionSecondaryDefault = "#edeff5";
export const LsdsAColorActionSecondaryHover = "#c5cae0";
export const LsdsAColorActionSecondaryPressed = "#c5cae0";
export const LsdsAColorActionSecondaryDisabled = "#edeff5";
export const LsdsAColorActionTertiaryDefault = "#0b0f1f";
export const LsdsAColorActionErrorDefault = "#e12d42";
export const LsdsAColorActionCriticalDefault = "#e12d42";
export const LsdsAColorActionCriticalHover = "#cb283b";
export const LsdsAColorActionCriticalPressed = "#cb283b";
export const LsdsAColorActionCriticalDisabled = "#edeff5";
export const LsdsAColorDecorative1 = "#bba1ff";
export const LsdsAColorDecorative2 = "#94c7fb";
export const LsdsAColorDecorative3 = "#ffa785";
export const LsdsAColorDecorative4 = "#ffa0dd";
export const LsdsAColorDecorative5 = "#97e8f0";
export const LsdsAColorDecorative6 = "#ffdc87";
export const LsdsAColorDecorative7 = "#95e1c1";
export const LsdsAColorDecorative8 = "#ed7987";
export const LsdsAColorTextDefault = "#0b0f1f";
export const LsdsAColorTextSubtle = "#64697d";
export const LsdsAColorTextDisabled = "#8f96b3";
export const LsdsAColorTextAccent = "#e557b3";
export const LsdsAColorTextOnPrimary = "#ffffff";
export const LsdsAColorTextOnSecondary = "#0b0f1f";
export const LsdsAColorTextOnError = "#ffffff";
export const LsdsAColorTextOnCritical = "#ffffff";
export const LsdsAColorTextOnInfo = "#0b0f1f";
export const LsdsAColorTextOnSuccess = "#0b0f1f";
export const LsdsAColorTextOnWarning = "#0b0f1f";
export const LsdsAColorTextOnAccent = "#0b0f1f";
export const LsdsAColorTextOnDark = "#c5cae0";
export const LsdsAColorTextOnEmphasis = "#3c55bd";
export const LsdsAColorTextPrimary = "#4461d7";
export const LsdsAColorTextError = "#b42435";
export const LsdsAColorTextCritical = "#b42435";
export const LsdsAColorTextWarning = "#ac5515";
export const LsdsAColorTextSuccess = "#318160";
export const LsdsAColorTextInfo = "#4592df";
export const LsdsAColorTextTertiary = "#0b0f1f";
export const LsdsAColorTextPlaceholder = "#64697d";
export const LsdsAColorTextReversed = "#ffffff";
export const LsdsAColorBackgroundDefault = "#ffffff";
export const LsdsAColorBackgroundVuetifySurface = "#ffffff";
export const LsdsAColorBackgroundHover = "#f5f7fa";
export const LsdsAColorBackgroundSelected = "#edeff5";
export const LsdsAColorSurfaceDefault = "#f5f7fa";
export const LsdsAColorSurfaceHover = "#edeff5";
export const LsdsAColorSurfaceSelected = "#edeff5";
export const LsdsAColorSurfaceSubduedDefault = "#fafafc";
export const LsdsAColorSurfaceEmphasisDefault = "#f2f4fc";
export const LsdsAColorSurfaceEmphasisHover = "#ebefff";
export const LsdsAColorSurfaceEmphasisSelected = "#ebefff";
export const LsdsAColorSurfaceEmphasisContrast = "#ebefff";
export const LsdsAColorSurfaceCriticalDefault = "#e12d42";
export const LsdsAColorSurfaceCriticalSubdued = "#fceaec";
export const LsdsAColorSurfaceWarningDefault = "#ffc63c";
export const LsdsAColorSurfaceWarningSubdued = "#fff9ec";
export const LsdsAColorSurfaceSuccessDefault = "#4ecd97";
export const LsdsAColorSurfaceSuccessSubdued = "#edfaf5";
export const LsdsAColorSurfaceInfoDefault = "#4da2f8";
export const LsdsAColorSurfaceInfoSubdued = "#edf6fe";
export const LsdsAColorSurfaceAccent = "#ff61c7";
export const LsdsAColorSurfaceDark = "#0b0f1f";
export const LsdsAColorSurfaceHighlight = "#ff61c7";
export const LsdsAColorBorderSubtle = "#f5f7fa";
export const LsdsAColorBorderDefault = "#edeff5";
export const LsdsAColorBorderSeparator = "#edeff5";
export const LsdsAColorBorderBold = "#c5cae0";
export const LsdsAColorBorderStrong = "#8f96b3";
export const LsdsAColorBorderContrast = "#0b0f1f";
export const LsdsAColorBorderSuccessDefault = "#4ecd97";
export const LsdsAColorBorderSuccessHover = "#46b988";
export const LsdsAColorBorderSuccessDisabled = "#edeff5";
export const LsdsAColorBorderReversed = "#ffffff";
export const LsdsAColorBorderAccent = "#e557b3";
export const LsdsAColorBorderSeparatorOnSurfaceDefault = "#e1e4ee";
export const LsdsAColorBorderPrimaryDefault = "#4461d7";
export const LsdsAColorBorderPrimaryHover = "#3c55bd";
export const LsdsAColorBorderPrimaryPressed = "#3c55bd";
export const LsdsAColorBorderPrimaryDisabled = "#edeff5";
export const LsdsAColorBorderInfoDefault = "#4da2f8";
export const LsdsAColorBorderInfoHover = "#4592df";
export const LsdsAColorBorderInfoDisabled = "#edeff5";
export const LsdsAColorBorderWarningDefault = "#ffc63c";
export const LsdsAColorBorderWarningHover = "#e2b13a";
export const LsdsAColorBorderWarningDisabled = "#edeff5";
export const LsdsAColorBorderCriticalDefault = "#e12d42";
export const LsdsAColorBorderCriticalHover = "#cb283b";
export const LsdsAColorBorderCriticalDisabled = "#edeff5";
export const LsdsAColorBorderFocusPrimary = "#8f9fe7";
export const LsdsAColorBorderFocusTertiary = "#8f9fe7";
export const LsdsAColorBorderFocusSecondary = "#8f9fe7";
export const LsdsAColorBorderFocusCritical = "#ed7987";
export const LsdsAColorBorderEmphasis = "#d8e0ff";
export const LsdsAColorIconDefault = "#0b0f1f";
export const LsdsAColorIconSecondaryDefault = "#64697d";
export const LsdsAColorIconSecondaryHover = "#0b0f1f";
export const LsdsAColorIconSubtle = "#8f96b3";
export const LsdsAColorIconDisabled = "#c5cae0";
export const LsdsAColorIconPrimaryDefault = "#4461d7";
export const LsdsAColorIconPrimaryHover = "#3c55bd";
export const LsdsAColorIconOnPrimary = "#ffffff";
export const LsdsAColorIconOnDark = "#c5cae0";
export const LsdsAColorIconOnEmphasis = "#3c55bd";
export const LsdsAColorIconInfo = "#3578bb";
export const LsdsAColorIconSuccess = "#318160";
export const LsdsAColorIconWarning = "#ac5515";
export const LsdsAColorIconCritical = "#b42435";
export const LsdsAColorIconTertiary = "#0b0f1f";
export const LsdsAIconSizeXs = "0.75rem";
export const LsdsAIconSizeSm = "1rem";
export const LsdsAIconSizeMd = "1.25rem";
export const LsdsAIconSizeLg = "1.5rem";
export const LsdsAIconSizeXl = "2.5rem";
export const LsdsATypographyHeading1FontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyHeading1FontWeight = "600";
export const LsdsATypographyHeading1LineHeight = "44px";
export const LsdsATypographyHeading1FontSize = "32px";
export const LsdsATypographyHeading2FontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyHeading2FontWeight = "600";
export const LsdsATypographyHeading2LineHeight = "40px";
export const LsdsATypographyHeading2FontSize = "28px";
export const LsdsATypographyHeading3FontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyHeading3FontWeight = "600";
export const LsdsATypographyHeading3LineHeight = "32px";
export const LsdsATypographyHeading3FontSize = "24px";
export const LsdsATypographyHeading4FontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyHeading4FontWeight = "600";
export const LsdsATypographyHeading4LineHeight = "32px";
export const LsdsATypographyHeading4FontSize = "20px";
export const LsdsATypographyHeading5FontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyHeading5FontWeight = "600";
export const LsdsATypographyHeading5LineHeight = "32px";
export const LsdsATypographyHeading5FontSize = "20px";
export const LsdsATypographyHeading6FontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyHeading6FontWeight = "600";
export const LsdsATypographyHeading6LineHeight = "32px";
export const LsdsATypographyHeading6FontSize = "20px";
export const LsdsATypographySubtitle1FontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographySubtitle1FontWeight = "600";
export const LsdsATypographySubtitle1LineHeight = "28px";
export const LsdsATypographySubtitle1FontSize = "18px";
export const LsdsATypographySubtitle2FontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographySubtitle2FontWeight = "600";
export const LsdsATypographySubtitle2LineHeight = "24px";
export const LsdsATypographySubtitle2FontSize = "16px";
export const LsdsATypographyBody1RegularFontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyBody1RegularFontWeight = "400";
export const LsdsATypographyBody1RegularLineHeight = "24px";
export const LsdsATypographyBody1RegularFontSize = "14px";
export const LsdsATypographyBody1SemiboldFontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyBody1SemiboldFontWeight = "600";
export const LsdsATypographyBody1SemiboldLineHeight = "24px";
export const LsdsATypographyBody1SemiboldFontSize = "14px";
export const LsdsATypographyBody1BoldFontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyBody1BoldFontWeight = "700";
export const LsdsATypographyBody1BoldLineHeight = "24px";
export const LsdsATypographyBody1BoldFontSize = "16px";
export const LsdsATypographyBody2RegularFontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyBody2RegularFontWeight = "400";
export const LsdsATypographyBody2RegularLineHeight = "20px";
export const LsdsATypographyBody2RegularFontSize = "12px";
export const LsdsATypographyBody2SemiboldFontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyBody2SemiboldFontWeight = "600";
export const LsdsATypographyBody2SemiboldLineHeight = "20px";
export const LsdsATypographyBody2SemiboldFontSize = "12px";
export const LsdsATypographyBody2BoldFontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyBody2BoldFontWeight = "700";
export const LsdsATypographyBody2BoldLineHeight = "20px";
export const LsdsATypographyBody2BoldFontSize = "12px";
export const LsdsATypographyBody3RegularFontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyBody3RegularFontWeight = "400";
export const LsdsATypographyBody3RegularLineHeight = "16px";
export const LsdsATypographyBody3RegularFontSize = "10px";
export const LsdsATypographyButton1FontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyButton1FontWeight = "400";
export const LsdsATypographyButton1LineHeight = "100%";
export const LsdsATypographyButton1FontSize = "14px";
export const LsdsATypographyButton2FontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyButton2FontWeight = "600";
export const LsdsATypographyButton2LineHeight = "100%";
export const LsdsATypographyButton2FontSize = "12px";
export const LsdsATypographyButton3FontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyButton3FontWeight = "400";
export const LsdsATypographyButton3LineHeight = "100%";
export const LsdsATypographyButton3FontSize = "12px";
export const LsdsATypographyCaptionFontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyCaptionFontWeight = "400";
export const LsdsATypographyCaptionLineHeight = "20px";
export const LsdsATypographyCaptionFontSize = "12px";
export const LsdsATypographyOverlineFontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyOverlineFontWeight = "500";
export const LsdsATypographyOverlineLineHeight = "100%";
export const LsdsATypographyOverlineFontSize = "10px";
export const LsdsATypographyOverlineLetterSpacing = "0.03rem";
export const LsdsATypographyOverlineTextTransform = "uppercase";
export const LsdsATypographyLabelFontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyLabelFontWeight = "600";
export const LsdsATypographyLabelLineHeight = "100%";
export const LsdsATypographyLabelFontSize = "10px";
export const LsdsATypographyLabelLetterSpacing = "0.03rem";
export const LsdsATypographyLabelTextTransform = "uppercase";
export const LsdsATypographyLink1FontFamily = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsATypographyLink1FontWeight = "400";
export const LsdsATypographyLink1LineHeight = "100%";
export const LsdsATypographyLink1FontSize = "14px";
export const LsdsATypographyLink1TextDecoration = "underline";
export const LsdsCAlertColorInfoDefault = "#4da2f8";
export const LsdsCAlertColorInfoSubdued = "#edf6fe";
export const LsdsCAlertColorSuccessDefault = "#4ecd97";
export const LsdsCAlertColorSuccessSubdued = "#edfaf5";
export const LsdsCAlertColorWarningDefault = "#ffc63c";
export const LsdsCAlertColorWarningSubdued = "#fff9ec";
export const LsdsCAlertColorCriticalDefault = "#e12d42";
export const LsdsCAlertColorCriticalSubdued = "#fceaec";
export const LsdsCAlertButtonColorBgDefault = "#0b0f1f0f";
export const LsdsCAlertButtonColorBgHover = "#0b0f1f1a";
export const LsdsCToastMaxWidth = "25rem";
export const LsdsCAllocationTimeDefault = "#8f9fe7";
export const LsdsCAllocationTimeCritical = "#e12d42";
export const LsdsCAvatarSizeXxs = "1rem";
export const LsdsCAvatarSizeXs = "1.25rem";
export const LsdsCAvatarSizeSm = "1.5rem";
export const LsdsCAvatarSizeMd = "2rem";
export const LsdsCAvatarSizeLg = "2.5rem";
export const LsdsCAvatarSizeXl = "4.5rem";
export const LsdsCAvatarSizeXxl = "9rem";
export const LsdsCAvatarTextSizeXxs = "8px";
export const LsdsCAvatarTextSizeXs = "8px";
export const LsdsCAvatarTextSizeSm = "10px";
export const LsdsCAvatarTextSizeMd = "14px";
export const LsdsCAvatarTextSizeLg = "18px";
export const LsdsCAvatarTextSizeXl = "24px";
export const LsdsCAvatarTextSizeXxl = "48px";
export const LsdsCAvatarColorPage = "#bba1ff";
export const LsdsCAvatarColorProject = "#94c7fb";
export const LsdsCAvatarColorTask = "#ffa785";
export const LsdsCAvatarColorPerson = "#ffa0dd";
export const LsdsCAvatarColorUnassigned = "#edeff5";
export const LsdsCAvatarColorAttention = "#fff0ca";
export const LsdsCButtonSizeXxs = "0.75rem";
export const LsdsCButtonSizeXs = "1.25rem";
export const LsdsCButtonSizeSm = "1.5rem";
export const LsdsCButtonSizeMd = "2rem";
export const LsdsCButtonSizeLg = "2.5rem";
export const LsdsCButtonSpacingOuterSm = "0.5rem";
export const LsdsCButtonSpacingOuterMd = "0.75rem";
export const LsdsCButtonSpacingOuterLg = "1rem";
export const LsdsCButtonSpacingGapSm = "0.25rem";
export const LsdsCButtonSpacingGapMd = "0.5rem";
export const LsdsCButtonSpacingGapLg = "0.75rem";
export const LsdsCButtonColorBgPrimaryDefault = "#4461d7";
export const LsdsCButtonColorBgPrimaryHover = "#3c55bd";
export const LsdsCButtonColorBgPrimaryActive = "#3c55bd";
export const LsdsCButtonColorBgPrimaryPressed = "#3c55bd";
export const LsdsCButtonColorBgPrimaryDisabled = "#edeff5";
export const LsdsCButtonColorBgSecondaryDefault = "#00000000";
export const LsdsCButtonColorBgSecondaryHover = "#f2f4fc";
export const LsdsCButtonColorBgSecondaryActive = "#d8e0ff";
export const LsdsCButtonColorBgSecondaryPressed = "#d8e0ff";
export const LsdsCButtonColorBgSecondaryDisabled = "#edeff5";
export const LsdsCButtonColorBgTertiaryDefault = "#00000000";
export const LsdsCButtonColorBgTertiaryHover = "#f5f7fa";
export const LsdsCButtonColorBgTertiaryActive = "#edeff5";
export const LsdsCButtonColorBgTertiaryPressed = "#ebefff";
export const LsdsCButtonColorBgTertiaryDisabled = "#00000000";
export const LsdsCButtonColorBgCriticalDefault = "#e12d42";
export const LsdsCButtonColorBgCriticalHover = "#cb283b";
export const LsdsCButtonColorBgCriticalActive = "#cb283b";
export const LsdsCButtonColorBgCriticalDisabled = "#edeff5";
export const LsdsCButtonColorBgCriticalSecondaryDefault = "#00000000";
export const LsdsCButtonColorBgCriticalSecondaryHover = "#fceaec";
export const LsdsCButtonColorBgCriticalSecondaryActive = "#ffd0da";
export const LsdsCButtonColorBgCriticalSecondaryDisabled = "#edeff5";
export const LsdsCButtonColorBorderSecondaryDefault = "#4461d7";
export const LsdsCButtonColorBorderSecondaryHover = "#3c55bd";
export const LsdsCButtonColorBorderSecondaryActive = "#3c55bd";
export const LsdsCButtonColorBorderSecondaryFocus = "#00000000";
export const LsdsCButtonColorBorderSecondaryDisabled = "#edeff5";
export const LsdsCButtonColorBorderCriticalSecondaryDefault = "#e12d42";
export const LsdsCButtonColorBorderCriticalSecondaryHover = "#cb283b";
export const LsdsCButtonColorBorderCriticalSecondaryActive = "#cb283b";
export const LsdsCButtonColorBorderCriticalSecondaryFocus = "#00000000";
export const LsdsCButtonColorBorderCriticalSecondaryDisabled = "#edeff5";
export const LsdsCButtonColorTextPrimaryDefault = "#ffffff";
export const LsdsCButtonColorTextPrimaryDisabled = "#8f96b3";
export const LsdsCButtonColorTextSecondaryDefault = "#4461d7";
export const LsdsCButtonColorTextSecondaryPressed = "#3c55bd";
export const LsdsCButtonColorTextSecondaryDisabled = "#8f96b3";
export const LsdsCButtonColorTextTertiaryDefault = "#0b0f1f";
export const LsdsCButtonColorTextTertiaryPressed = "#3c55bd";
export const LsdsCButtonColorTextTertiaryDisabled = "#8f96b3";
export const LsdsCButtonColorTextCriticalDefault = "#ffffff";
export const LsdsCButtonColorTextCriticalDisabled = "#8f96b3";
export const LsdsCButtonColorTextCriticalSecondaryDefault = "#b42435";
export const LsdsCButtonColorTextCriticalSecondaryDisabled = "#8f96b3";
export const LsdsCButtonColorTextPlainPrimaryDefault = "#4461d7";
export const LsdsCButtonColorTextPlainPrimaryHover = "#3c55bd";
export const LsdsCButtonColorTextPlainPrimaryActive = "#3c55bd";
export const LsdsCButtonColorTextPlainPrimaryDisabled = "#8f96b3";
export const LsdsCButtonColorTextPlainSecondaryDefault = "#64697d";
export const LsdsCButtonColorTextPlainSecondaryHover = "#0b0f1f";
export const LsdsCButtonColorTextPlainSecondaryActive = "#0b0f1f";
export const LsdsCButtonColorTextPlainSecondaryDisabled = "#8f96b3";
export const LsdsCButtonColorIconSecondaryDefault = "#4461d7";
export const LsdsCButtonColorIconSecondaryPressed = "#3c55bd";
export const LsdsCButtonColorIconSecondaryActive = "#4461d7";
export const LsdsCButtonColorIconSecondaryDisabled = "#8f96b3";
export const LsdsCButtonColorIconTertiaryDefault = "#0b0f1f";
export const LsdsCButtonColorIconTertiaryPressed = "#3c55bd";
export const LsdsCButtonColorIconTertiaryDisabled = "#c5cae0";
export const LsdsCButtonColorIconPlainSecondaryDefault = "#64697d";
export const LsdsCButtonColorIconPlainSecondaryHover = "#0b0f1f";
export const LsdsCButtonColorIconPlainSecondaryActive = "#0b0f1f";
export const LsdsCButtonColorIconPlainSecondaryDisabled = "#8f96b3";
export const LsdsCButtonColorDividerPrimary = "#ffffff";
export const LsdsCButtonColorDividerSecondary = "#4461d7";
export const LsdsCButtonColorDividerTertiary = "#8f96b3";
export const LsdsCButtonColorFocusRingPrimary = "#8f9fe7";
export const LsdsCButtonColorFocusRingSecondary = "#8f9fe7";
export const LsdsCButtonColorFocusRingTertiary = "#8f9fe7";
export const LsdsCButtonColorFocusRingCritical = "#ed7987";
export const LsdsCButtonColorFocusRingCriticalSecondary = "#ed7987";
export const LsdsCButtonColorFocusRingPlainPrimary = "#8f9fe7";
export const LsdsCButtonColorFocusRingPlainSecondary = "#8f9fe7";
export const LsdsCButtonFocusRingWidth = "2px";
export const LsdsCCalendarEventBackgroundConfirmed = "#ebefff";
export const LsdsCCalendarEventBackgroundInvited = "#ffffff";
export const LsdsCCalendarEventBackgroundCancelled = "#ffffff";
export const LsdsCCalendarEventBackgroundTentative = "#ebefff";
export const LsdsCCalendarEventBackgroundStripes = "#ebefff";
export const LsdsCCalendarEventStripesColor = "#d8e0ff";
export const LsdsCCalendarEventStripesWidth = "1.5rem";
export const LsdsCCalendarEventBorderColorDefault = "#d8e0ff";
export const LsdsCCalendarEventBorderColorResize = "#8f9fe7";
export const LsdsCCalendarEventBorderColorDrag = "#3c55bd";
export const LsdsCCalendarEventBorderColorConfirmed = "#ebefff";
export const LsdsCCalendarEventBorderColorStacked = "#ffffff";
export const LsdsCCalendarEventBorderColorHover = "#8f9fe7";
export const LsdsCCalendarEventBorderColorSelected = "#3c55bd";
export const LsdsCCalendarEventTextLineHeightXs = "12px";
export const LsdsCCalendarEventStatusAccepted = "#d2fde8";
export const LsdsCCalendarEventStatusDeclined = "#ffd0da";
export const LsdsCCalendarEventStatusTentative = "#fff0ca";
export const LsdsCCalendarEventStatusNeedsAction = "#ffffff";
export const LsdsCCardBorderHover = "#8f96b3";
export const LsdsCCardBorderActive = "#4461d7";
export const LsdsCCardShadowDefault = "0 2px 4px rgba(11,14,31,0.1)";
export const LsdsCCardShadowElevated = "0 16px 32px rgba(11,14,31,0.12)";
export const LsdsCChartColor1Default = "#4461d7";
export const LsdsCChartColor1Subdued = "#d8e0ff";
export const LsdsCChartColor2Default = "#ff61c7";
export const LsdsCChartColor2Subdued = "#f1cce6";
export const LsdsCChartColorCriticalDefault = "#e12d42";
export const LsdsCChartColorSuccessDefault = "#4ecd97";
export const LsdsCChartColorWarningDefault = "#ffc63c";
export const LsdsCChartCellColorIndicatorSuccess = "#d2fde8";
export const LsdsCChartCellColorIndicatorNeutral = "#d8e0ff";
export const LsdsCChartCellColorIndicatorCritical = "#ffd0da";
export const LsdsCChartCellColorIndicatorWarning = "#fff0ca";
export const LsdsCChartCellColorSurfaceSuccess = "#edfaf5";
export const LsdsCChartCellColorSurfaceNeutral = "#f2f4fc";
export const LsdsCChartCellColorSurfaceCritical = "#fceaec";
export const LsdsCChartCellColorSurfaceWarning = "#fff9ec";
export const LsdsCChartDashboardHeight = "18rem";
export const LsdsCChartActivityWidth = "9rem";
export const LsdsCChatCounterColor = "#8f9fe7";
export const LsdsCChipHeightXs = "1.25rem";
export const LsdsCChipHeightSm = "1.5rem";
export const LsdsCChipHeightLg = "2rem";
export const LsdsCChipColorBgDefaultDefault = "#edeff5";
export const LsdsCChipColorBgDefaultHover = "#c5cae0";
export const LsdsCChipColorBgOnSurfaceDefault = "#ffffff";
export const LsdsCChipColorBorderOnSurfaceDefault = "#c5cae0";
export const LsdsCChipColorBorderOnSurfaceHover = "#8f96b3";
export const LsdsCColorPicker1 = "#e12d42";
export const LsdsCColorPicker2 = "#ff7641";
export const LsdsCColorPicker3 = "#ffc63c";
export const LsdsCColorPicker4 = "#4ecd97";
export const LsdsCColorPicker5 = "#4461d7";
export const LsdsCColorPicker6 = "#4da2f8";
export const LsdsCColorPicker7 = "#4cd5e3";
export const LsdsCColorPicker8 = "#895ef7";
export const LsdsCColorPicker9 = "#ff61c7";
export const LsdsCColorPicker10 = "#bba1ff";
export const LsdsCColorPicker11 = "#0b0f1f";
export const LsdsCColorPicker12 = "#a7acc5";
export const LsdsCCommentCardGutterSize = "1.5rem";
export const LsdsCCounterColorDefault = "#d8e0ff";
export const LsdsCCounterColorHover = "#d8e0ff";
export const LsdsCDatavisFontDefault = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsCDatavisFontWeight = "600";
export const LsdsCDatavisFontSize = "8px";
export const LsdsCDatavisLineHeight = "100%";
export const LsdsCDatepickerRangeColorBackgroundDefault = "#d8e0ff";
export const LsdsCDatepickerRangeColorBackgroundHover = "#bac9fd";
export const LsdsCDialogShadow = "0 16px 32px rgba(11,14,31,0.12)";
export const LsdsCDialogSizeFull = "100%";
export const LsdsCDialogSizeLg = "918px";
export const LsdsCDialogSizeMd = "680px";
export const LsdsCDialogSizeSm = "560px";
export const LsdsCDialogSizeXs = "440px";
export const LsdsCDrawerShadow = "5px 0 10px -6px rgba(0,0,0,0.2)";
export const LsdsCDrawerExpandButtonShadow = "-12px 0px 32px rgba(11,14,31,0.12)";
export const LsdsCDrawerSizeLg = "768px";
export const LsdsCDrawerSizeMd = "640px";
export const LsdsCDrawerSizeSm = "500px";
export const LsdsCDropzoneSizeMaxWidth = "40rem";
export const LsdsCEmptyStateSizeXs = "11rem";
export const LsdsCEmptyStateSizeSm = "14rem";
export const LsdsCEmptyStateSizeMd = "20rem";
export const LsdsCEmptyStateSizeLg = "36rem";
export const LsdsCEmptyStateImageSizeXs = "11rem";
export const LsdsCEmptyStateImageSizeSm = "14rem";
export const LsdsCEmptyStateImageSizeMd = "14rem";
export const LsdsCEmptyStateImageSizeLg = "18rem";
export const LsdsCFileColorDefault = "#64697d";
export const LsdsCFileColorDocument = "#4592df";
export const LsdsCFileColorImage = "#46b988";
export const LsdsCFileColorPresentation = "#ffa785";
export const LsdsCFileColorPdf = "#e12d42";
export const LsdsCFirstTimeExperienceMessageMaxWidth = "40rem";
export const LsdsCFormColorBorderDefault = "#c5cae0";
export const LsdsCFormColorBorderHover = "#8f96b3";
export const LsdsCFormColorBorderActive = "#4461d7";
export const LsdsCFormColorBorderError = "#b42435";
export const LsdsCFormColorBorderDisabled = "#c5cae0";
export const LsdsCFormColorIconDefault = "#c5cae0";
export const LsdsCFormBorderRadius = "8px";
export const LsdsCLabelColorBackgroundEmphasisDefault = "#ebefff";
export const LsdsCLabelColorBackgroundHighlightDefault = "#d2fde8";
export const LsdsCLabelColorTextEmphasisDefault = "#3c55bd";
export const LsdsCLabelColorTextHighlightDefault = "#318160";
export const LsdsCListviewIconColorComment = "#46b988";
export const LsdsCListviewIconColorReminder = "#46b988";
export const LsdsCOptionsMenuColorIconCritical = "#e12d42";
export const LsdsCOptionsMenuColorIconWarning = "#ffb400";
export const LsdsCPopoverShadow = "0 4px 8px rgba(11,14,31,0.1)";
export const LsdsCPopoverWidthSm = "14rem";
export const LsdsCPopoverWidthMd = "18rem";
export const LsdsCPopoverWidthLg = "20rem";
export const LsdsCPopoverWidthXl = "32.5rem";
export const LsdsCPriorityColorIconNone = "#8f96b3";
export const LsdsCPriorityColorIconLow = "#46b988";
export const LsdsCPriorityColorIconHigh = "#cb283b";
export const LsdsCPriorityColorIconMedium = "#ffb400";
export const LsdsCProgressCircularSizeSm = "2rem";
export const LsdsCProgressCircularSizeMd = "2.5rem";
export const LsdsCProgressCircularSizeLg = "3rem";
export const LsdsCProgressColorDefault = "#8f9fe7";
export const LsdsCProgressColorOnCritical = "#ed7987";
export const LsdsCProgressColorBackgroundDefault = "#e8eaf1";
export const LsdsCReportCellColorNeutral = "#f2f4fc";
export const LsdsCReportCellColorSuccess = "#d2fde8";
export const LsdsCReportCellColorCritical = "#ffd0da";
export const LsdsCReportCellColorWarning = "#fff0ca";
export const LsdsCColorPickerScheduler1 = "#ed7987";
export const LsdsCColorPickerScheduler2 = "#ffa785";
export const LsdsCColorPickerScheduler3 = "#ffdc87";
export const LsdsCColorPickerScheduler4 = "#95e1c1";
export const LsdsCColorPickerScheduler5 = "#97e8f0";
export const LsdsCColorPickerScheduler6 = "#94c7fb";
export const LsdsCColorPickerScheduler7 = "#bba1ff";
export const LsdsCColorPickerScheduler8 = "#ffa0dd";
export const LsdsCScrollbarColorDefault = "#c5cae0";
export const LsdsCScrollbarColorHover = "#8f96b3";
export const LsdsCScrollbarColorPressed = "#8f96b3";
export const LsdsCScrollbarColorOnDarkDefault = "#383c4e";
export const LsdsCScrollbarColorOnDarkHover = "#64697d";
export const LsdsCScrollbarColorOnDarkPressed = "#64697d";
export const LsdsCSeparatorColor = "#8f96b3";
export const LsdsCSidebarDrawerWidthExpanded = "220px";
export const LsdsCSidebarDrawerWidthCollapsed = "88px";
export const LsdsCSidebarDrawerPanelWidthSm = "320px";
export const LsdsCSidebarDrawerPanelWidthLg = "400px";
export const LsdsCSidebarDrawerColorSurface = "#0b0f1f";
export const LsdsCSidebarDrawerIconColorOnSurface = "#8f96b3";
export const LsdsCSidebarDrawerIconColorOnHover = "#ffffff";
export const LsdsCSidebarDrawerIconColorOnSelected = "#ffffff";
export const LsdsCSidebarDrawerListItemBackgroundColorOnHover = "#242736";
export const LsdsCSidebarDrawerListItemBackgroundColorOnFocus = "#8f9fe7";
export const LsdsCSidebarDrawerListItemBackgroundColorOnSelected = "#242736";
export const LsdsCSidebarDrawerListItemColorOnSurface = "#c5cae0";
export const LsdsCSidebarDrawerListItemColorOnHover = "#ffffff";
export const LsdsCSidebarDrawerListItemColorOnSelected = "#ffffff";
export const LsdsCSidebarDrawerQuickAddColorSurface = "#3457cc";
export const LsdsCSidebarDrawerQuickAddColorSurfaceHover = "#3c55bd";
export const LsdsCSidebarDrawerQuickAddColorOnSurface = "#ffffff";
export const LsdsCSidebarDrawerAddYourTeamColorSurface = "#2a2f40";
export const LsdsCSidebarDrawerUpgradeNowColorSurface = "#2a2f40";
export const LsdsCSidebarDrawerNotificationsBadgeColorSurface = "#ff61c7";
export const LsdsCSidebarDrawerNotificationsBadgeColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerChatBadgeColorSurface = "#8f9fe7";
export const LsdsCSidebarDrawerChatBadgeColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerClosePanelIconColorSurface = "#0c0e1f";
export const LsdsCSidebarDrawerClosePanelIconColorOnSurface = "#eeeeee";
export const LsdsCSidebarDrawerClosePanelIconColorOnHover = "#0d0f2f";
export const LsdsCSidebarDrawerProfileColorOnSurface = "#ffffff";
export const LsdsCSidebarDrawerProfileColorOnSurfaceSubtle = "#64697d";
export const LsdsCSplitchartColor1Default = "#4461d7";
export const LsdsCSplitchartColor1Subdued = "#d8e0ff";
export const LsdsCSplitchartColor2Default = "#ff61c7";
export const LsdsCSplitchartColor2Subdued = "#f1cce6";
export const LsdsCSwitchColorBackgroundOn = "#4461d7";
export const LsdsCSwitchColorBackgroundOff = "#c5cae0";
export const LsdsCSwitchColorBackgroundDisabled = "#edeff5";
export const LsdsCSwitchColorHandle = "#ffffff";
export const LsdsCTabColorBgSecondaryDefault = "#ffffff";
export const LsdsCTabColorTextDefaultDefault = "#64697d";
export const LsdsCTabColorTextDefaultHover = "#4461d7";
export const LsdsCTabColorTextDefaultActive = "#4461d7";
export const LsdsCTabColorTextDefaultDisabled = "#8f96b3";
export const LsdsCTabColorTextSecondaryDefault = "#4461d7";
export const LsdsCTabColorTextSecondaryDisabled = "#8f96b3";
export const LsdsCTabBorderSecondaryDefault = "#bac9fd";
export const LsdsCTabBorderSecondaryHover = "#bac9fd";
export const LsdsCTableColorBorderDefault = "#e1e6ee";
export const LsdsCTableRowHeightSm = "2.5rem";
export const LsdsCTableRowHeightMd = "3.5rem";
export const LsdsCTableRowHeightLg = "4.5rem";
export const LsdsCTableRowHeightSizeSm = "2.5rem";
export const LsdsCTableRowHeightSizeMd = "3.5rem";
export const LsdsCTableRowHeightSizeLg = "4.5rem";
export const LsdsCTableCellResizeDefault = "#c5cae0";
export const LsdsCTableCellResizeActive = "#4461d7";
export const LsdsCTableCellPaddingNone = "0.25rem";
export const LsdsCTableCellPaddingSm = "0.25rem";
export const LsdsCTableCellPaddingMd = "0.5rem";
export const LsdsCTableCellPaddingLg = "0.75rem";
export const LsdsCTableCellPaddingHalf = "50%";
export const LsdsCTableOrderByButtonAbsolute = "-28px";
export const LsdsCTagDeskColorBlue = "#4592df";
export const LsdsCTagDeskColorGreen = "#46b988";
export const LsdsCTagDeskColorOrange = "#e2b13a";
export const LsdsCTagDeskColorRed = "#e12d42";
export const LsdsCTagDeskColorPurple = "#bba1ff";
export const LsdsCTagDeskColorGold = "#e56a3a";
export const LsdsCTagDeskColorLime = "#4ecd97";
export const LsdsCTagDeskColorAqua = "#4cd5e3";
export const LsdsCTagDeskColorPink = "#ffa0dd";
export const LsdsCTagDeskColorSilver = "#a7acc5";
export const LsdsCTagDeskColorNavy = "#383c4e";
export const LsdsCTagDeskColorBrown = "#ac5515";
export const LsdsCTimerColorBackgroundDefault = "#d8e0ff";
export const LsdsCTimerColorBackgroundHover = "#bac9fd";
export const LsdsCTinytextFontDefault = "Inter, ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"";
export const LsdsCTinytextFontWeight = "600";
export const LsdsCTinytextFontSize = "8px";
export const LsdsCTinytextLineHeight = "100%";
export const LsdsCTinytextTextDecoration = "underline";
export const LsdsCToolbarInputColorBackground = "#383c4e";
export const LsdsCToolbarControlColorTextDefault = "#c5cae0";
export const LsdsCToolbarControlColorTextHover = "#c5cae0";
export const LsdsCToolbarControlColorTextPressed = "#c5cae0";
export const LsdsCToolbarControlColorTextDisabled = "#edeff5";
export const LsdsCToolbarControlColorBackgroundDefault = "#00000000";
export const LsdsCToolbarControlColorBackgroundHover = "#383c4e";
export const LsdsCToolbarControlColorBackgroundPressed = "#383c4e";
export const LsdsCToolbarControlColorBackgroundDisabled = "#00000000";
export const LsdsCTooltipColorBackgroundDefault = "#0b0f1f";
export const LsdsCTooltipFontSize = "12px";
