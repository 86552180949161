<script setup>
import { provideLaunchDarkly } from '../features/useLaunchDarkly';
import ApiSetupShared3 from './ApiSetupShared3.vue';

provideLaunchDarkly();
</script>
<template>
  <ApiSetupShared3>
    <slot />
  </ApiSetupShared3>
</template>
