<script setup>
import { tv } from '../../../util/tailwind/tv';
import { useLscTabs } from './useLscTabs';
import LscBadge from '../../infodisplay/badge/LscBadge.vue';
import LscOverflowEllipsis from '../../infodisplay/overflow/LscOverflowEllipsis.vue';

/**
 * @typedef {import('./useLscTabs').LsTab} LsTab
 */

const props = defineProps({
  /**
   * The item to display.
   * @type {import('vue').PropType<LsTab>}
   */
  item: {
    type: Object,
    default: () => ({}),
  },
  /**
   * Used to set the item's active state.
   */
  active: {
    type: Boolean,
    default: false,
  },
});

const componentToRender = computed(() => {
  if (props.item.href?.startsWith('/')) {
    return 'RouterLink';
  }
  if (props.item.href) {
    return 'a';
  }
  return 'button';
});

const { variant } = useLscTabs();

const lscTabVariantStyleConfig = tv({
  base: [
    'relative flex w-full cursor-pointer items-center justify-center gap-2 overflow-hidden border-solid border-transparent p-1 text-body-1 no-underline',
    'focus:outline-focus-primary focus-visible:border-transparent',
    'aria-disabled:cursor-not-allowed aria-disabled:font-normal',
  ],
  slots: {
    label: 'whitespace-nowrap',
    icon: 'shrink-0',
    badge: 'font-normal',
  },
  variants: {
    variant: {
      default: {
        base: [
          'border-x-0 border-y border-b-default text-[color:--lsds-c-tab-color-text-default-default] hover:text-[color:--lsds-c-tab-color-text-default-hover]',
          'aria-selected:border-y-2 aria-selected:border-b-primary-default aria-selected:font-semibold aria-selected:text-[color:--lsds-c-tab-color-text-default-active]',
          'aria-disabled:text-[color:--lsds-c-tab-color-text-default-disabled]',
          'before:absolute before:left-0 before:h-6 before:border-0 before:border-l before:border-solid before:border-separator before:content-[""] first:before:border-none',
        ],
      },
      secondary: {
        base: [
          'transition-border rounded-md border text-[color:--lsds-c-tab-color-text-secondary-default] duration-100 hover:border-[color:--lsds-c-tab-border-secondary-default]',
          'aria-selected:border-[color:--lsds-c-tab-border-secondary-default] aria-selected:bg-[--lsds-c-tab-color-bg-secondary-default] aria-selected:font-semibold',
          'aria-disabled:text-[color:--lsds-c-tab-color-text-secondary-disabled]',
        ],
      },
    },
  },
});

const classes = computed(() => lscTabVariantStyleConfig({ variant: variant.value }));

const badgeVariant = computed(() => {
  if (variant.value === 'secondary' && !props.item.disabled) {
    return 'surface-emphasis';
  }
  return 'neutral';
});
</script>
<template>
  <Component
    :is="componentToRender"
    role="tab"
    :class="classes.base()"
    :type="componentToRender === 'button' ? 'button' : undefined"
    :aria-selected="active"
    :aria-disabled="Boolean(item.disabled)"
    :disabled="Boolean(item.disabled)"
    :to="item.href"
    :href="item.href"
    target="_blank"
    :data-identifier="item.dataIdentifier"
  >
    <slot name="prepend">
      <LscIcon v-if="item.prependIcon" :icon="item.prependIcon" :class="classes.icon()" />
    </slot>
    <LscOverflowEllipsis :class="classes.label()">
      {{ item.label }}
    </LscOverflowEllipsis>
    <slot name="append">
      <LscBadge v-if="item.count !== undefined" :variant="badgeVariant" :count="item.count" :class="classes.badge()" />
    </slot>
  </Component>
</template>
