<script setup>
const props = defineProps({
  /**
   * The percentage to show.
   */
  percentage: {
    type: Number,
    default: 0,
  },
  /**
   * The minimum value.
   */
  min: {
    type: Number,
    default: 0,
  },
  /**
   * The maximum value.
   */
  max: {
    type: Number,
    default: 100,
  },
  /**
   * The size of the progress circle.
   * @type {import('vue').PropType<'sm' | 'md' | 'lg'>}
   */
  size: {
    type: String,
    default: 'lg',
    validator: (v) => ['sm', 'md', 'lg'].includes(v),
  },
  /**
   * The background color of the progress circle.
   */
  backgroundColor: {
    type: String,
    default: 'var(--lsds-c-progress-color-background-default)',
  },
  /**
   * The color of the progress segment.
   */
  valueColor: {
    type: String,
    default: 'var(--lsds-c-progress-color-default)',
  },
});

const sizeDefaults = {
  sm: {
    progressCircle: 'h-[--lsds-c-progress-circular-size-sm] w-[--lsds-c-progress-circular-size-sm]',
    text: 'text-[length:--lsds-c-tinytext-font-size]',
  },
  md: {
    progressCircle: 'h-[--lsds-c-progress-circular-size-md] w-[--lsds-c-progress-circular-size-md]',
    text: 'text-label',
  },
  lg: {
    progressCircle: 'h-[--lsds-c-progress-circular-size-lg] w-[--lsds-c-progress-circular-size-lg]',
    text: 'text-body-2',
  },
};

const classes = computed(() => sizeDefaults[props.size]);

// Clamp percentage value between min and max
const boundedValue = computed(() => Math.min(Math.max(props.percentage, props.min), props.max));

// Calculations are relative to the viewBox size
const circumference = 2 * Math.PI * 40;
const dashArray = `${circumference} ${circumference}`;
const dashOffset = computed(() => circumference * (1 - boundedValue.value / props.max));
</script>

<template>
  <div class="relative flex items-center justify-center" :class="classes.progressCircle">
    <svg viewBox="0 0 100 100">
      <circle class="z-0" cx="50" cy="50" r="40" :stroke="backgroundColor" stroke-width="10" fill="none" />
      <circle
        class="z-1 origin-center -rotate-90 transition-[stroke-dashOffset]"
        cx="50"
        cy="50"
        r="40"
        fill="none"
        :stroke="valueColor"
        stroke-width="10"
        stroke-linecap="round"
        :stroke-dasharray="dashArray"
        :stroke-dashoffset="dashOffset"
      />
    </svg>
    <span class="absolute flex items-center justify-center font-semibold" :class="classes.text">
      <!-- @slot Use the default slot to show content within the circle area -->
      <slot />
    </span>
  </div>
</template>
