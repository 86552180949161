import { getLastChildPath } from '../lastChildPath';

export const clientsRoutes = [
  {
    path: '/clients/:clientId',
    component: () => import('./RouteClient.vue'),
    props: (route) => ({
      clientId: Number(route.params.clientId),
    }),
    children: [
      {
        path: '',
        redirect(to) {
          return `/clients/${to.params.clientId}/${getLastChildPath('client', 'activity')}`;
        },
      },
      {
        path: 'overview',
        component: () => import('./RouteClientsOverview.vue'),
      },
      {
        path: 'activity',
        component: () => import('./RouteClientsActivity.vue'),
      },
      {
        path: 'projects',
        component: () => import('./RouteClientsProjects.vue'),
      },
      {
        path: 'people',
        component: () => import('./RouteClientsPeople.vue'),
      },
      {
        path: 'teams',
        component: () => import('./RouteClientsTeams.vue'),
      },
      {
        path: 'loggedtime',
        component: () => import('./RouteClientsTime.vue'),
      },
      {
        path: 'tickets',
        component: () => import('./RouteClientsTickets.vue'),
      },
      {
        path: 'tickets',
        component: () => import('./RouteClientsTickets.vue'),
      },
      {
        path: 'emails',
        component: () => import('./RouteClientsEmailList.vue'),
      },
      {
        path: 'emails/:threadId',
        component: () => import('./RouteClientsEmail.vue'),
      },
    ],
  },
  {
    path: '/clients',
    component: () => import('./RouteClients.vue'),
    children: [
      {
        path: '',
        component: () => import('./RouteClientsHome.vue'),
      },
      {
        path: ':pathMatch(.*)*',
        redirect: '/clients',
      },
    ],
  },
];
