import { useLegacyBridge } from './useLegacyBridge';

/**
 * Adds the height of the target element to the top offset value.
 * This is used to offset the top of the legacy iframe so that it doesn't overlap with the new UI.
 *
 * @param {import('vue').ShallowRef<HTMLElement|null>} target - The element to offset the legacy iframe by.
 */
export function useLegacyFrameOffset(target) {
  const { incrementTopOffset, decrementTopOffset } = useLegacyBridge();

  const height = shallowRef(0);

  function increment() {
    // If the target is not set or the height is already set, don't increment
    if (!target.value || height.value) {
      return;
    }

    const el = target.value.$el ? target.value.$el : target.value;
    height.value = el ? el.offsetHeight : 0;

    // No need to increment the top offset when element height is 0
    if (!height.value) {
      return;
    }

    incrementTopOffset(height.value);
  }

  function decrement() {
    if (!height.value) {
      return;
    }

    decrementTopOffset(height.value);
    height.value = 0;
  }

  onMounted(increment);
  onUnmounted(decrement);
  watch(target, () => {
    if (target.value) {
      increment();
    } else {
      decrement();
    }
  });
}
