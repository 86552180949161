<script setup>
import { useProjectsV3Loader } from '@/api';
import ProjectJumpToDrawerProjectsList from './ProjectJumpToDrawerProjectsList.vue';
import ProjectJumpToDrawerStarredBlankSlate from './ProjectJumpToDrawerStarredBlankSlate.vue';

const numOfItems = 50;
const count = shallowRef(numOfItems);
const state = useProjectsV3Loader({
  count,
  pageSize: numOfItems,
  params: {
    searchTerm: null,
    include: 'companies',
    includeProjectUserInfo: true,
    'fields[projects]': 'id,name,isStarred,companyId,startPage',
    orderBy: 'name',
    orderMode: 'asc',
    onlyStarredProjects: true,
    onlyProjectsWithExplicitMembership: 1,
  },
});
</script>

<template>
  <ProjectJumpToDrawerProjectsList v-model:count="count" :step="numOfItems" :state="state">
    <template #blank>
      <ProjectJumpToDrawerStarredBlankSlate />
    </template>
  </ProjectJumpToDrawerProjectsList>
</template>
