<script setup>
import { providePendo } from '../features/usePendo';
import { provideTWIMSocket } from '../base/useTWIMSocket';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { provideFeatures } from '../features/useFeatures';

provideFeatures(true);
providePendo(true);
useRealTimeUpdates().emitFromSocket(provideTWIMSocket());
</script>
<template>
  <slot />
</template>
