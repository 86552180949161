<script setup>
const props = defineProps({
  project: {
    type: Object,
    default: null,
  },
  addExistingUsersToProjects: {
    type: Boolean,
    default: false,
  },
  panelSource: {
    // value used for pendo tracking of where modal was opened from
    type: String,
    default: '',
  },
});

const emit = defineEmits(['invited']);

const UserInviteDialogPanel = defineAsyncComponent(() => import('./UserInviteDialogPanel.vue'));
</script>

<template>
  <WidgetDialog width="690px">
    <template #default="{ close }">
      <UserInviteDialogPanel v-bind="props" @invited="emit('invited', $event)" @close="close" />
    </template>
  </WidgetDialog>
</template>
