import { useRouter } from 'vue-router';
import { useRoute } from './useRoute';
import { useProjectV2Loader } from '@/api';

/**
 * Loads a project for the current route.
 * The loader's state is guaranteed to be consistent with the Vue router.
 * In most cases the `item` will be available immediately on route changes,
 * however, it is still necessary to handle all the usual loader states.
 */
export function useCurrentProjectLoader() {
  const scope = getCurrentScope();
  const route = useRoute();
  const router = useRouter();

  const params = {
    getPermissions: true,
    getNotificationSettings: true,
    getActivePages: true,
    getDateInfo: true,
    getEmailAddress: true,
    formatMarkdown: false,
    includeProjectOwner: true,
    includeCustomFields: true,
  };

  function createState({ type, id } = {}) {
    const itemType = shallowRef(type);
    const itemId = shallowRef(id);
    return {
      ...useProjectV2Loader({ itemType, itemId, params }),
      itemType,
      itemId,
    };
  }

  function fixType(type) {
    if (type === 'projects') {
      return 'project';
    }
    if (type === 'tasks') {
      return 'task';
    }
    if (type === 'tasklists') {
      return 'tasklist';
    }
    if (type === 'notebooks') {
      return 'notebook';
    }
    if (type === 'files') {
      return 'file';
    }
    if (type === 'links') {
      return 'link';
    }
    if (type === 'messages') {
      return 'message';
    }
    if (type === 'milestones') {
      return 'milestone';
    }
    return undefined;
  }

  function getTypeAndId(_route) {
    const typeAndId = /^\/(projects|tasks|tasklists|notebooks|files|links|messages|milestones)\/(\d+)/.exec(
      _route.path.toLowerCase(),
    );
    return {
      type: typeAndId ? fixType(typeAndId[1]) : undefined,
      id: typeAndId ? Number(typeAndId[2]) : undefined,
    };
  }

  const currentState = shallowRef(createState(getTypeAndId(route)));
  const nextState = shallowRef(createState());

  // Just before navigation is confirmed,
  // prepare `nextState` and wait until it's ready.
  onScopeDispose(
    router.beforeResolve((to, from, next) => {
      const { type, id } = getTypeAndId(to);

      // Do nothing to the current project when opening a quick view.
      if (to.meta.isQuickViewRoute) {
        // If param is set force refresh project
        if (to.query?.refreshProject && type === 'task') {
          currentState.value.itemId.value = 0;
          currentState.value.itemType.value = '';

          setTimeout(() => {
            currentState.value.itemId.value = id;
            currentState.value.itemType.value = type;
            // Remove temp query param and redirect
            const queryParams = to.query;
            delete queryParams.refreshProject;
            next({ ...to, query: queryParams });
          });
        } else {
          next();
        }
        return;
      }

      // `currentState` or `nextState` is the expected state, so proceed with the route change.
      if (
        (currentState.value.itemType.value === type && currentState.value.itemId.value === id) ||
        (nextState.value.itemType.value === type && nextState.value.itemId.value === id)
      ) {
        next();
        return;
      }

      const { inSync, item, error, itemType, itemId } = nextState.value;
      let unwatch;

      // Wait until `nextState` is initialized.
      scope.run(() => {
        unwatch = watch([inSync, item, error, itemType, itemId], () => {
          if (unwatch && (inSync.value || item.value !== undefined || error.value)) {
            unwatch();
            unwatch = null;
            next();
          }
        });
      });

      // Update `nextState`.
      itemType.value = type;
      itemId.value = id;
    }),
  );

  // Just after navigation has been confirmed,
  // make sure that `currentState` is correct.
  onScopeDispose(
    router.afterEach(() => {
      const { type, id } = getTypeAndId(route);

      // `currentState` is the expected state.
      if (currentState.value.itemType.value === type && currentState.value.itemId.value === id) {
        return;
      }

      // `nextState` is the expected state, so swap it with `currentState`.
      if (nextState.value.itemType.value === type && nextState.value.itemId.value === id) {
        const previousCurrentState = currentState.value;
        currentState.value = nextState.value;
        nextState.value = previousCurrentState;
        return;
      }

      // No state ready to use, so update `currentState`.
      currentState.value.itemType.value = type;
      currentState.value.itemId.value = id;
    }),
  );

  return {
    item: computed(() => currentState.value.item.value),
    inSync: computed(() => currentState.value.inSync.value),
    loaded: computed(() => currentState.value.loaded.value),
    meta: computed(() => currentState.value.meta.value),
    error: computed(() => currentState.value.error.value),
  };
}
