<script setup>
import { tv } from '../../../util/tailwind/tv';

/**
 * @typedef {import('../../../composables/breadcrumbs/useLsBreadcrumbs').LsBreadcrumb} LsBreadcrumb
 */

const props = defineProps({
  /**
   * The size of the breadcrumbs.
   * @type {import('vue').PropType<'sm' | 'md'>}
   */
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md'].includes(value),
  },
  /**
   * @type {import('vue').PropType<LsBreadcrumb>}
   */
  item: {
    type: Object,
    required: true,
  },
});

const breadcrumbVariantStyleConfig = tv({
  base: ['flex min-w-0 max-w-full items-center gap-1'],
  slots: {
    icon: ['shrink-0'],
    text: ['truncate no-underline hover:underline'],
  },
  variants: {
    size: {
      sm: {
        base: ['text-body-2'],
      },
      md: {
        base: ['text-body-1'],
      },
    },
    disabled: {
      true: {
        icon: ['text-icon-disabled'],
        text: ['text-disabled !no-underline'],
      },
      false: {
        icon: ['text-icon-subtle'],
        text: ['text-default'],
      },
    },
    hasTruncation: {
      true: {
        text: ['max-w-[--truncation-width]'],
      },
    },
    hasIconColor: {
      true: {
        icon: ['text-[color:--icon-color]'],
      },
    },
    isEllipses: {
      true: {
        base: 'shrink-0',
      },
      false: {
        base: 'shrink',
      },
    },
  },
});
const classes = computed(() =>
  breadcrumbVariantStyleConfig({
    size: props.size,
    disabled: Boolean(props.item.disabled),
    hasTruncation: Boolean(props.item.truncationWidth),
    hasIconColor: Boolean(props.item.iconColor),
    isEllipses: Boolean(props.item.isEllipses),
  }),
);

const style = computed(() => ({
  '--truncation-width': props.item.truncationWidth ? `${props.item.truncationWidth}px` : undefined,
}));

const iconSizeMap = {
  sm: 'xs',
  md: 'sm',
};
const iconSize = computed(() => iconSizeMap[props.size]);

function click() {
  if (props.item.disabled) {
    return;
  }

  props.item.onClick?.();
}
</script>

<template>
  <div :class="classes.base()" :style="style">
    <LscIcon
      v-if="item.icon"
      :size="iconSize"
      :class="classes.icon()"
      :style="{
        '--icon-color': item.iconColor,
      }"
      :icon="item.icon"
    />
    <RouterLink
      v-if="item.path"
      v-LsdTooltip="item.tooltip || item.label"
      :disabled="item.disabled ? 'disabled' : undefined"
      :data-identifier="item.dataIdentifier"
      :class="classes.text()"
      :to="item.path"
      @click.stop="click"
    >
      {{ item.label }}
    </RouterLink>
    <button
      v-else
      v-LsdTooltip="item.tooltip || item.label"
      type="button"
      :disabled="item.disabled"
      :data-identifier="item.dataIdentifier"
      :class="classes.text()"
      @click.stop="click"
    >
      {{ item.label }}
    </button>
  </div>
</template>
