/**
 * Do not edit directly
 * Generated on Sat, 30 Mar 2024 00:02:51 GMT
 */

export const LsdsCSidebarDrawerColorSurface = "#cd9100";
export const LsdsCSidebarDrawerIconColorOnSurface = "#fff0ca";
export const LsdsCSidebarDrawerIconColorOnHover = "#fff9ec";
export const LsdsCSidebarDrawerIconColorOnSelected = "#ffffff";
export const LsdsCSidebarDrawerListItemBackgroundColorOnHover = "#bf8700";
export const LsdsCSidebarDrawerListItemBackgroundColorOnFocus = "#94c7fb";
export const LsdsCSidebarDrawerListItemBackgroundColorOnSelected = "#b27e00";
export const LsdsCSidebarDrawerListItemColorOnSurface = "#ffffff";
export const LsdsCSidebarDrawerListItemColorOnHover = "#ffffff";
export const LsdsCSidebarDrawerListItemColorOnSelected = "#ffffff";
export const LsdsCSidebarDrawerQuickAddColorSurface = "#ffffff";
export const LsdsCSidebarDrawerQuickAddColorSurfaceHover = "#edeff5";
export const LsdsCSidebarDrawerQuickAddColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerAddYourTeamColorSurface = "#e2b13a";
export const LsdsCSidebarDrawerUpgradeNowColorSurface = "#bf8700";
export const LsdsCSidebarDrawerNotificationsBadgeColorSurface = "#ffffff";
export const LsdsCSidebarDrawerNotificationsBadgeColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerChatBadgeColorSurface = "#ffffff";
export const LsdsCSidebarDrawerChatBadgeColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerClosePanelIconColorSurface = "#0c0e1f";
export const LsdsCSidebarDrawerClosePanelIconColorOnSurface = "#eeeeee";
export const LsdsCSidebarDrawerClosePanelIconColorOnHover = "#0d0f2f";
export const LsdsCSidebarDrawerProfileColorOnSurface = "#ffffff";
export const LsdsCSidebarDrawerProfileColorOnSurfaceSubtle = "#ffffff";
