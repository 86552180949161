import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

export function useClientActions() {
  const api = useAxios();
  const { socketId, emit: emitRealTimeUpdate } = useRealTimeUpdates();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();

  function deleteClient(id) {
    const promise = api.delete(`/companies/${id}.json`).then(() =>
      emitRealTimeUpdate({
        type: 'company',
        action: 'delete',
        companyId: id,
      }),
    );

    emitOptimisticUpdate({
      type: 'company',
      action: 'delete',
      promise,
    });

    return promise;
  }

  function restoreClient(companyId) {
    const promise = api.put(`/companies/${companyId}/undelete.json`).then((response) => {
      emitRealTimeUpdate({
        type: 'company',
        action: 'restored',
        companyId,
      });
      return response;
    });

    emitOptimisticUpdate({
      promise,
      type: 'company',
      action: 'update',
      companyId,
    });

    return promise;
  }

  function moveClientUser(targetClientId, currentClientId, users) {
    const promise = api
      .put(
        `/companies/${targetClientId}/movepeople.json`,
        { peopleToMove: users },
        { headers: { 'Socket-ID': socketId } },
      )
      .then(() => {
        emitRealTimeUpdate({
          type: 'company',
          action: 'update',
          companyId: currentClientId,
        });
      });

    emitOptimisticUpdate({
      type: 'company',
      action: 'update',
      promise,
      client: { id: currentClientId },
    });

    return promise;
  }

  function updateClient(client) {
    const { id, ...clientData } = client;

    const promise = api
      .patch(`/projects/api/v3/companies/${id}.json`, { company: clientData }, { headers: { 'Socket-ID': socketId } })
      .then(() =>
        emitRealTimeUpdate({
          ...clientData,
          type: 'company',
          action: 'update',
          companyId: id,
        }),
      );

    emitOptimisticUpdate({
      type: 'company',
      action: 'update',
      promise,
      client,
    });

    return promise;
  }

  function updateClientHealth(client, healthUpdate) {
    const { id } = client;
    const promise = api
      .post(`/projects/api/v3/companies/${id}/update.json`, { ...healthUpdate }, { headers: { 'Socket-ID': socketId } })
      .then(() =>
        emitRealTimeUpdate({
          ...client,
          type: 'company',
          action: 'update',
        }),
      );

    emitOptimisticUpdate({
      type: 'company',
      action: 'update',
      promise,
      client: { id, health: healthUpdate },
    });

    return promise;
  }

  return {
    deleteClient,
    restoreClient,
    moveClientUser,
    updateClient,
    updateClientHealth,
  };
}
