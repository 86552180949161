<script setup>
import { tv } from '../../../util/tailwind/tv';

const props = defineProps({
  /**
   * The variant of the label.
   * @type {import('vue').PropType<'emphasis' | 'highlight'>}
   */
  variant: {
    type: String,
    default: 'emphasis',
    validator: (value) => ['emphasis', 'highlight'].includes(value),
  },
  /**
   * The size of the label.
   * @type {import('vue').PropType<'sm' | 'lg'>}
   */
  size: {
    type: String,
    default: 'sm',
    validator: (value) => ['sm', 'lg'].includes(value),
  },
});

const LscLabelVariantStyleConfig = tv({
  base: 'inline-flex items-center rounded-md p-1 text-overline',
  variants: {
    size: {
      sm: 'p-1',
      lg: 'p-2',
    },
    variant: {
      emphasis: 'bg-[--lsds-c-label-color-background-emphasis-default] text-on-emphasis',
      highlight:
        'bg-[--lsds-c-label-color-background-highlight-default] text-[color:--lsds-c-label-color-text-highlight-default]',
    },
  },
});

const classes = computed(() => LscLabelVariantStyleConfig(props));
</script>

<template>
  <span :class="classes">
    <slot />
  </span>
</template>
