/**
 * Maps icon codes used by Spaces API to Lightspeed icon set, as close as possible
 * @param {String} icon
 */
function getSpaceIcon(icon) {
  switch (icon) {
    case 'address-book':
      return 'lsi-spaces-avatar-address-book';
    case 'address-card':
      return 'lsi-spaces-avatar-address-card';
    case 'alarm-clock':
      return 'lsi-spaces-avatar-alarm';
    case 'archive':
      return 'lsi-archived';
    case 'box-full':
      return 'lsi-spaces-avatar-gift';
    case 'calendar-alt':
      return 'lsi-calendar';
    case 'camera-retro':
      return 'lsi-photo';
    case 'bell':
      return 'lsi-notifications';
    case 'book':
      return 'lsi-spaces-avatar-book';
    case 'clock':
      return 'lsi-time';
    case 'cut':
      return 'lsi-spaces-avatar-scissors';
    case 'copy':
      return 'lsi-copy';
    case 'paste':
      return 'lsi-spaces-avatar-paste';
    case 'bullhorn':
      return 'lsi-announcement';
    case 'calculator':
      return 'lsi-spaces-avatar-calculator';
    case 'columns':
      return 'lsi-board-column';
    case 'bookmark':
      return 'lsi-bookmark';
    case 'comment-alt-dots':
      return 'lsi-spaces-avatar-comment-bubble';
    case 'envelope-open':
      return 'spaces-avatar-envelope-open';
    case 'desktop':
      return 'lsi-spaces-avatar-monitor';
    case 'browser':
      return 'lsi-spaces-avatar-application';
    case 'print':
      return 'lsi-spaces-avatar-printer';
    case 'bug':
      return 'lsi-spaces-avatar-bug';
    case 'lock-alt':
      return 'lsi-privacy';
    case 'globe':
      return 'lsi-website';
    case 'handshake':
      return 'lsi-spaces-avatar-handshake';
    case 'home':
      return 'lsi-spaces-avatar-home';
    case 'building':
      return 'lsi-company';
    case 'map-marker-alt':
      return 'lsi-page';
    case 'coffee':
      return 'lsi-spaces-avatar-coffee-cup';
    case 'phone':
      return 'lsi-spaces-avatar-phone';
    case 'clipboard-check':
      return 'lsi-spaces-avatar-clipboard-check';
    case 'clipboard-list':
      return 'lsi-tasklist';
    case 'file-alt':
      return 'lsi-guide';
    case 'file-check':
      return 'lsi-spaces-avatar-file-check';
    case 'file-edit':
      return 'lsi-edit';
    case 'file-exclamation"':
      return 'lsi-spaces-avatar-file-exclamation';
    case 'file-plus':
      return 'lsi-add-file-task';
    case 'list-alt':
      return 'lsi-tasklist';
    case 'folder-open':
      return 'lsi-spaces-avatar-folder-open';
    case 'images':
      return 'lsi-spaces-avatar-image-multiple';
    case 'Pencil-alt':
      return 'lsi-edit';
    case 'info-square':
      return 'lsi-tooltip';
    case 'chart-pie':
      return 'lsi-chart-pie';
    case 'chart-line':
      return 'lsi-chart-line';
    case 'chart-bar':
      return 'lsi-chart-bar';
    case 'cloud':
      return 'lsi-cloud';
    case 'cloud-upload':
      return 'lsi-upload-new-file';
    case 'cloud-download':
      return 'lsi-spaces-avatar-cloud-arrow-down';
    case 'clone':
      return 'lsi-copy';
    case 'code':
      return 'lsi-text-code';
    case 'code-branch':
      return 'lsi-spaces-avatar-code-branch';
    case 'code-commit':
      return 'lsi-spaces-avatar-code-commit';
    case 'cog':
      return 'lsi-settings';
    case 'credit-card':
      return 'lsi-subscription';
    case 'smile':
      return 'lsi-add-reaction';
    case 'frown':
      return 'lsi-spaces-avatar-emoticon-frown';
    case 'meh':
      return 'lsi-spaces-avatar-emoticon-neutral';
    default:
      return 'lsi-incomplete-task';
  }
}

/**
 * Get 2 letter version of a spaces title
 *
 * @param {string} title Title of the space
 */
function getSpaceInitials(title) {
  return title.substring(0, 2);
}

export function useSpacesIcons() {
  return {
    getSpaceIcon,
    getSpaceInitials,
  };
}
