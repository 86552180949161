import { useLocalStorage, useIntervalFn, createSharedComposable } from '@vueuse/core';
import semverMajor from 'semver/functions/major';
import semverValid from 'semver/functions/valid';

// The version number from `./lightspeed/package.json` is inserted into
// the `data-teamwork-version` attribute on the `<html>` element at build time.
// This line must be the only place where we touch this attribute.
const version = document.documentElement.getAttribute('data-teamwork-version') || '';

export const appVersion = version;
export const useVersion = createSharedComposable(() => {
  // We store `latestVersion` using `useLocalStorage` to keep it in sync in all browser tabs.
  const latestVersion = useLocalStorage('teamwork/useVersion/latestVersion', version);

  // `useVersion` runs right after the app has started, so we know for sure that the current version
  // is the latest version right now. We assign it here to keep other browser tabs in sync and to avoid
  // any risk of infinite redirects in case the latest version in `localStorage` is higher than the
  // version available on the server for any reason.
  latestVersion.value = version;

  // Check for the new version every 30 minutes.
  useIntervalFn(
    async () => {
      const response = await fetch('/app');
      if (response.ok) {
        const html = await response.text();
        const versionMatch = html.match(/data-teamwork-version="(\d+\.\d+\.\d+)"/);
        if (versionMatch) {
          const loadedVersion = versionMatch[1];
          latestVersion.value = loadedVersion;
        }
      }
    },
    1000 * 60 * 30,
  );

  return {
    // The current version number.
    version,
    // The latest available version number.
    latestVersion: readonly(latestVersion),
    // Whether a new major version is available.
    hasNewMajorVersion: computed(() =>
      Boolean(
        semverValid(latestVersion.value) &&
          semverValid(version) &&
          semverMajor(latestVersion.value) > semverMajor(version),
      ),
    ),
  };
});
