import { useFeatures } from '@/api';
import { useCurrentProjectBudgetState } from '@/route';

/**
 * @returns {import('./useFeaturesLimit').FeatureLimitObject}
 */
export function useBudgetsLimit({ type, isNewBudget }) {
  if (type !== 'financial' && type !== 'time') {
    return null;
  }

  const { item: projectBudgets, inSync, loaded } = useCurrentProjectBudgetState();

  const { financialBudgetLimit, timeBudgetLimit, projectTimeBudgetsEnabled, projectFinancialBudgetsEnabled } =
    useFeatures();

  const loading = computed(() => !loaded.value || !inSync.value);

  const activeAndUpcomingBudgetsCount = computed(() => {
    const upcoming = projectBudgets.value[type].upcoming ?? 0;
    const active = projectBudgets.value[type].active ?? 0;
    return active + upcoming;
  });

  const newActiveBudgetCount = computed(() => {
    if (!projectBudgets.value) {
      return 0;
    }
    // if the user is editing an existing budget do not count it
    return isNewBudget ? activeAndUpcomingBudgetsCount.value : activeAndUpcomingBudgetsCount.value - 1;
  });

  const isFeatureEnabled = computed(() =>
    type === 'financial' ? projectFinancialBudgetsEnabled.value : projectTimeBudgetsEnabled.value,
  );

  const featureLimit = computed(() => (type === 'financial' ? financialBudgetLimit.value : timeBudgetLimit.value));

  const isOverFeatureLimit = computed(() => {
    if (!loading.value) {
      return undefined;
    }

    return featureLimit.value !== -1 && newActiveBudgetCount.value >= featureLimit.value;
  });

  return {
    isOverFeatureLimit,
    isFeatureEnabled,
    featureLimit,
    loading,
  };
}
