<script setup>
import { provideUserInvite } from './useUserInvite';

const props = defineProps({
  project: {
    type: Object,
    default: null,
  },
});

const UserInviteDialogPanel = defineAsyncComponent(() => import('./UserInviteDialogPanel.vue'));

provideUserInvite();
</script>

<template>
  <WidgetDialog width="580px">
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <UserInviteDialogPanel v-bind="props" @close="close" />
    </template>
  </WidgetDialog>
</template>
