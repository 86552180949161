import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

let nextCustomfieldId = -1;

function toId(value) {
  return Number(value) || null;
}

export function useCustomfieldActions() {
  const api = useAxios();
  const { socketId, emit: _emitRealTimeUpdate } = useRealTimeUpdates();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();

  function config() {
    return {
      headers: {
        'Socket-ID': socketId,
      },
    };
  }

  /**
   *
   * @param {Promise} promise
   * @param {'create'|'update'|'delete'} action
   * @param {object} customfield
   */
  function emitOptimisticUpdate(promise, action, customfield) {
    _emitOptimisticUpdate({
      promise,
      type: 'customfield',
      action,
      customfield,
    });
  }

  /**
   *
   * @param {string} action
   * @param {object} customfield
   */
  function emitRealTimeUpdate(action, customfield) {
    _emitRealTimeUpdate({
      type: 'customfield',
      action,
      customfieldId: toId(customfield.id),
      projectId: toId(customfield.projectId),
    });
  }

  return {
    createCustomfield(customfield) {
      const newCustomfieldId = nextCustomfieldId--;
      const promise = api.post('/projects/api/v3/customfields.json', { customfield }, config()).then(({ data }) => {
        emitOptimisticUpdate(promise, 'update', { ...data.customfield, tempId: newCustomfieldId });
        emitRealTimeUpdate('new', data.customfield);
        return data.customfield;
      });
      emitOptimisticUpdate(promise, 'create', { ...customfield, id: newCustomfieldId });
      return promise;
    },

    updateCustomfield(customfield) {
      const promise = api
        .put(`/projects/api/v3/customfields/${customfield.id}.json`, { customfield }, config())
        .then(({ data }) => {
          emitRealTimeUpdate('edited', data.customfield);
          return data.customfield;
        });
      emitOptimisticUpdate(promise, 'update', customfield);
      return promise;
    },

    deleteCustomfield(customfield) {
      const promise = api.delete(`/projects/api/v3/customfields/${customfield.id}.json`, config()).then(() => {
        emitRealTimeUpdate('deleted', customfield);
      });
      emitOptimisticUpdate(promise, 'delete', customfield);
      return promise;
    },
  };
}
