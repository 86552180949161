<script setup>
import { ENTITY_COLOR_MAP } from './entity';
import EntityIcon from './EntityIcon.vue';

const props = defineProps({
  group: {
    type: String,
    default: '',
  },

  action: {
    type: String,
    default: '',
  },

  size: {
    type: [Number, String],
    default: 13,
  },
  offsetX: {
    type: Number,
    default: 0,
  },
});

const color = computed(() => ENTITY_COLOR_MAP[props.group] || ENTITY_COLOR_MAP.default);
</script>

<template>
  <VBadge :color="color" v-bind="VBadgeEntity" :offsetX="offsetX">
    <slot />
    <template #badge>
      <EntityIcon v-bind="props" />
    </template>
  </VBadge>
</template>
