const symbol = Symbol('LscAlert');

export function provideLscAlert({ variant: _variant } = {}) {
  const variant = shallowRef(_variant);
  provide(symbol, { variant });
}

export function useLscAlert() {
  const context = inject(symbol);
  if (!context) {
    throw new Error('useLscAlert must be used within a LscAlertProvider');
  }
  return context;
}
