<script setup>
import { useRouter } from 'vue-router';
import { useLegacyBridge } from '@/module/legacy';
import { useI18n } from '@/util';
import { useCurrentUser, useCurrentAccountActions, useCurrentAccount } from '@/api';
import { useAppShellSidebar } from '../useAppShellSidebar';

const { t } = useI18n();
const { showLegacyModal } = useLegacyBridge();
const { accessLinkedAccount } = useCurrentAccountActions();
const { isUserMenuSwitchAccountsOpen } = useAppShellSidebar();

const router = useRouter();
const user = useCurrentUser();
const account = useCurrentAccount();

const defaultAccountFavicon = `${import.meta.env.BASE_URL}favicon.png`;

function showLinkedAccounts() {
  showLegacyModal({
    modalName: 'linkedAccounts',
    args: { userId: user.id },
  });
}

async function onLinkedAccountClick({ metaKey, ctrlKey }, { id, userId }) {
  const url = await accessLinkedAccount({
    id,
    userId,
  });
  if (!url) {
    return;
  }
  const openNewTab = metaKey || ctrlKey;

  // Intentionally not using Vue's router here
  // External URLs otherwise wouldn't work with it
  if (openNewTab) {
    router.newTab(url);
  } else {
    window.location.href = url;
  }
}
</script>
<template>
  <WidgetMenu v-model="isUserMenuSwitchAccountsOpen" location="end" openOnHover openOnClick>
    <template #activator="activator">
      <VListItem class="focus-visible:bg-selected" v-bind="activator.props">
        <template #prepend><VIcon size="20">lsi-website</VIcon></template>
        <VListItemTitle>{{ t('Switch accounts') }}</VListItemTitle>
        <template #append>
          <VIcon size="x-small" class="text-icon-subtle">lsi-open-panel</VIcon>
        </template>
      </VListItem>
    </template>
    <VList v-bind="VListRounded">
      <VListItem
        v-for="account in user.accounts"
        :key="account.name"
        tabindex="0"
        link
        class="focus-visible:bg-selected"
        @click="onLinkedAccountClick($event, account)"
      >
        <template #prepend>
          <VImg
            class="mx-2"
            :src="`${account.favIcon == 'favicon.ico' ? defaultAccountFavicon : account.favIcon}`"
            width="20px"
            height="20px"
          />
        </template>
        <div>
          <VListItemTitle :title="`${account.name} (${account.email})`">
            {{ account.name }} ({{ account.email }})
          </VListItemTitle>
          <VListItemSubtitle :title="account.link">{{ account.link }}</VListItemSubtitle>
        </div>
      </VListItem>
      <VListItem class="focus-visible:bg-selected" @click="showLinkedAccounts">
        <template #prepend>
          <VIcon class="mx-2" size="20">lsi-group</VIcon>
        </template>
        <VListItemTitle>
          {{ t('Link {teamwork} accounts', { teamwork: 'Teamwork.com' }) }}
        </VListItemTitle>
      </VListItem>
      <VListItem
        v-if="account.isPartner"
        link
        target="_blank"
        rel="noreferrer noopener"
        class="focus-visible:bg-selected"
        href="/?action=demoSite&demoType=partner"
      >
        <template #prepend>
          <VIcon class="mx-2" size="20">lsi-group</VIcon>
        </template>
        <VListItemTitle>
          {{ t('Create Partner Demo') }}
        </VListItemTitle>
      </VListItem>
    </VList>
  </WidgetMenu>
</template>
