<script setup>
import { useCurrentUser, useExperimentA46 } from '@/api';
import { BadgeLevelProgressBar, BadgeLevelUser } from '@/module/badge';
import { useAppShellSidebar } from './useAppShellSidebar';

const user = useCurrentUser();
const { isSidebarExpanded } = useAppShellSidebar();
const { isExp46Variation } = useExperimentA46();
</script>

<template>
  <button
    type="button"
    :class="[
      'flex min-w-12 items-center overflow-hidden rounded-full p-2 text-left outline-none',
      'ring-0 ring-[color:--lsds-c-sidebar-drawer-list-item-background-color-on-focus] transition-colors',
      'bg-transparent',
      'text-[--lsds-c-sidebar-drawer-profile-color-on-surface]',
      'hover:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
      'hover:text-[--lsds-c-sidebar-drawer-list-item-color-on-hover]',
      'focus-visible:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
      'focus-visible:text-[--lsds-c-sidebar-drawer-list-item-color-on-hover]',
      'focus-visible:ring-2',
      'aria-pressed:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-selected]',
      'aria-pressed:text-[--lsds-c-sidebar-drawer-list-item-color-on-selected]',
    ]"
  >
    <div
      v-LsdTooltip:right="isSidebarExpanded ? undefined : `${user.firstName} ${user.lastName}`"
      class="relative flex size-8 items-center"
    >
      <LscAvatar v-bind="getLsAvatarProps({ user })" size="md" :showTooltip="false" />
      <div v-if="isExp46Variation && currentLevel" class="absolute -inset-x-3 -inset-y-2 z-10">
        <BadgeLevelUser :code="currentLevel.code" />
      </div>
      <div
        v-if="user.isClockedIn"
        class="absolute -bottom-1 -right-1 flex size-5 items-center justify-center rounded-full bg-default text-icon-default"
      >
        <LscIcon size="sm" icon="lsi-time" />
      </div>
    </div>
    <div class="ml-3 flex min-w-0 flex-col items-stretch gap-1 overflow-hidden">
      <LscOverflowEllipsis class="block whitespace-nowrap text-button-1 font-semibold">
        {{ user.firstName }} {{ user.lastName }}
      </LscOverflowEllipsis>
      <LscOverflowEllipsis
        class="block whitespace-nowrap text-button-2 text-[color:--lsds-c-sidebar-drawer-profile-color-on-surface-subtle]"
      >
        {{ user.title }}
      </LscOverflowEllipsis>
      <BadgeLevelProgressBar v-if="isExp46Variation && nextLevel !== null" :progress="nextLevelProgress" />
    </div>
  </button>
</template>
