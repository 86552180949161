<script setup>
import { computedEager } from '@vueuse/core';
import { useExperimentA15, useExperimentE17, useExperimentE23b, usePendo, usePermissions } from '@/api';
import { ClientAddDialog } from '@/module/client';
import { useLegacyBridge } from '@/module/legacy';
import { ProjectAddPeopleDialog } from '@/module/project';
import { TaskAddDialog } from '@/module/task';
import { TimeTimelogAddOrEditDialog, TimeTimerAddOrEditDialog, useTimeTimerTracking } from '@/module/time';
import { UserInviteDialog, UserInviteV2Dialog } from '@/module/user';
import { useCurrentProject } from '@/route';
import { UseKeyboardShortcut, useI18n, formatKeyboardShortcut } from '@/util';
import AppShellSidebarQuickAddMenuSplitButton from './AppShellSidebarQuickAddMenuSplitButton.vue';
import { useAppShellSidebar } from './useAppShellSidebar';
import { useQuickAddPermissions } from './useQuickAddPermissions';

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits(['buttonClicked', 'experimentButtonClicked']);

const { t } = useI18n();
const project = useCurrentProject();
const {
  canInviteUser,
  addMessage,
  addOrEditTimer,
  addOrEditEvent,
  addOrEditProjectUpdate,
  quickAddTask,
  addOrEditProjectWizard,
  addOrEditMilestone,
} = useQuickAddPermissions();
const { showLegacyModal } = useLegacyBridge();
const { isSidebarExpanded, clearActiveDrawerPanelIfNotPinned, isQuickAddOpen } = useAppShellSidebar();
const { trackPendoEvent } = usePendo();
const { canAccessPeopleCompaniesTeams, canAddCustomReports, isOwnerAdmin } = usePermissions();
const { EXP_A15_COMMON_METRICS, EXP_A15_FLAG_KEY, isExpA15Variation } = useExperimentA15();
const { isExpE17Variation } = useExperimentE17();
const { isExp23bVariation, trackExperimentE23b, trackExperimentE23bEvent } = useExperimentE23b();
const { trackTimerStartClicked } = useTimeTimerTracking();

const buttonActivatorRef = shallowRef();
const dialogName = shallowRef('');
const isDialogOpen = shallowRef(false);

const quickAddItems = [
  {
    text: t('Task'),
    icon: 'lsi-task',
    shortcutKey: 'T',
    dialogName: 'TaskAddDialog',
    permission: computed(() => quickAddTask.value && !isExpE17Variation.value),
    pendoLabel: 'task',
    dataIdentifier: 'side-nav-quick-add-task',
  },
  {
    text: t('Start timer'),
    icon: 'lsi-start-timer',
    shortcutKey: 'S',
    dialogName: 'TimeTimerAddOrEditDialog',
    permission: addOrEditTimer,
    pendoLabel: 'start_timer',
    dataIdentifier: 'side-nav-quick-add-timer',
  },
  {
    text: t('Log time'),
    icon: 'lsi-time',
    shortcutKey: 'L',
    dialogName: 'TimeTimelogAddOrEditDialog',
    permission: computed(() => addOrEditTimer.value && !isExpA15Variation.value),
    pendoLabel: 'log_time',
    dataIdentifier: 'side-nav-quick-add-log-time',
  },
  {
    text: t('Project'),
    icon: 'lsi-project',
    shortcutKey: 'P',
    legacyModal: 'addOrEditProjectWizard',
    permission: addOrEditProjectWizard,
    pendoLabel: 'project',
    dataIdentifier: 'side-nav-quick-add-project',
  },
  {
    text: t('Budget'),
    icon: 'lsi-budget',
    shortcutKey: 'B',
    legacyModal: 'ProjectBudgetModal',
    permission: isOwnerAdmin,
    pendoLabel: 'budget',
    dataIdentifier: 'side-nav-quick-add-budgets',
    modalProps: shallowRef({ openSource: 'quick_add_menu' }),
  },
  {
    text: t('Invite'),
    icon: 'lsi-assignee',
    dialogName: computed(() => (project.value ? 'ProjectAddPeopleDialog' : 'UserInviteDialog')),
    permission: canInviteUser,
    pendoLabel: 'people',
    dataIdentifier: 'side-nav-quick-add-people',
  },
  {
    text: t('Message'),
    icon: 'lsi-message',
    shortcutKey: 'N',
    legacyModal: 'addMessage',
    permission: addMessage,
    pendoLabel: 'message',
    dataIdentifier: 'side-nav-quick-add-message',
  },
  {
    text: t('Event'),
    icon: 'lsi-event',
    shortcutKey: 'E',
    legacyModal: 'addOrEditEvent',
    permission: addOrEditEvent,
    pendoLabel: 'event',
    dataIdentifier: 'side-nav-quick-add-event',
  },
  {
    text: t('Milestone'),
    icon: 'lsi-milestone',
    shortcutKey: 'M',
    legacyModal: 'addOrEditMilestone',
    permission: addOrEditMilestone,
    pendoLabel: 'milestone',
    dataIdentifier: 'side-nav-quick-add-milestone',
  },
  {
    text: t('Update'),
    icon: 'lsi-announcement',
    legacyModal: 'addOrEditProjectUpdate',
    permission: addOrEditProjectUpdate,
    pendoLabel: 'update',
    dataIdentifier: 'side-nav-quick-add-project-update',
    modalProps: computed(() => {
      return { projectId: project.value?.id };
    }),
  },
  {
    text: t('Company'),
    icon: 'lsi-company',
    dialogName: 'ClientAddEditDialog',
    permission: computed(() => isExpE17Variation.value && canAccessPeopleCompaniesTeams.value),
    pendoLabel: 'company',
    dataIdentifier: 'side-nav-quick-add-company',
  },
  {
    text: t('User rate'),
    icon: 'lsi-billable',
    legacyModal: 'BudgetUserRatesModal',
    permission: computed(() => isExpE17Variation.value && isOwnerAdmin.value),
    pendoLabel: 'user_rate',
    dataIdentifier: 'side-nav-quick-add-user-rate',
  },
  {
    text: t('Report'),
    icon: 'lsi-reports',
    legacyModal: 'ReportBuilder',
    permission: computed(() => isExpE17Variation.value && canAddCustomReports.value),
    pendoLabel: 'report',
    dataIdentifier: 'side-nav-quick-add-report',
  },
];
const shortcuts = quickAddItems.map(({ shortcutKey }) => shortcutKey).filter(Boolean);
const allowedQuickAddItems = computedEager(() => quickAddItems.filter((item) => item.permission.value));

const menuItems = computedEager(() => {
  return allowedQuickAddItems.value;
});
function trackQuickAddClick() {
  trackPendoEvent({
    eventName: 'NAVIGATION_EVENT',
    commonMetrics: EXP_A15_COMMON_METRICS,
    launchDarklyFlagKey: EXP_A15_FLAG_KEY,
    metadata: isExpA15Variation.value
      ? {
          event_action: 'quick_add_opened',
        }
      : {
          event_action: 'left_navigation_clicked',
          navigation_item: 'quick_add',
        },
  });
}

function trackQuickAddMenuItemClick(pendoLabel) {
  if (pendoLabel === 'start_timer') {
    trackTimerStartClicked('quick_add_menu');
  }
  trackPendoEvent({
    eventName: 'NAVIGATION_EVENT',
    commonMetrics: EXP_A15_COMMON_METRICS,
    launchDarklyFlagKey: EXP_A15_FLAG_KEY,
    metadata: {
      event_action: 'quick_add_item_clicked',
      quick_add_item: pendoLabel,
    },
  });
}

function trackQuickAddLogTimeClick() {
  trackPendoEvent({
    eventName: 'NAVIGATION_EVENT',
    commonMetrics: EXP_A15_COMMON_METRICS,
    launchDarklyFlagKey: EXP_A15_FLAG_KEY,
    metadata: {
      event_action: 'left_navigation_clicked',
      navigation_item: 'log_time',
    },
  });
}

function onClickQuickAddMenuBtn(experiment) {
  clearActiveDrawerPanelIfNotPinned();

  // TODO: this is a bit messy, and in this case, we have no baseline metric if not in these experiments
  // but currently this is duplicating events across all of them
  if (experiment === 'A15') {
    trackQuickAddClick();
  } else if (experiment === 'E17') {
    emit('experimentButtonClicked', 'quick_add');
  }
}

function onClickQuickAdd(item, fromQuickAddExperimentBtn) {
  document.activeElement?.blur();
  isQuickAddOpen.value = false;
  clearActiveDrawerPanelIfNotPinned();

  if (fromQuickAddExperimentBtn) {
    emit('experimentButtonClicked', item.dataIdentifier === 'side-nav-quick-add-log-time' ? 'log_time' : 'add_task');
  } else {
    emit('buttonClicked', item.pendoLabel);
  }

  if (item.dataIdentifier === 'side-nav-quick-add-log-time' && fromQuickAddExperimentBtn) {
    trackQuickAddLogTimeClick();
  } else {
    trackQuickAddMenuItemClick(item.pendoLabel);
  }

  if (item.dataIdentifier === 'side-nav-quick-add-people') {
    trackExperimentE23b();
    trackExperimentE23bEvent('QUICK_ADD_EVENT', {
      event_action: 'people_clicked',
    });
  }

  if (item.dialogName) {
    dialogName.value = unref(item.dialogName);
    isDialogOpen.value = true;
  } else if (item.legacyModal) {
    if (item.legacyModal === 'addOrEditProjectWizard') {
      const source = 'quick_add_sidebar_menu';
      trackPendoEvent({
        eventName: 'PROJECTS_EVENT',
        commonMetrics: ['plan_name', 'user_role', 'page', 'on_trial', 'is_icp', 'is_paid'],
        metadata: {
          page_type: 'projects',
          event_action: 'add_project_clicked',
          event_category: 'advanced_discovery_event',
          source,
        },
      });

      showLegacyModal({ modalName: item.legacyModal, params: { ...item.modalProps, source } });
    } else {
      showLegacyModal({ modalName: item.legacyModal, params: item.modalProps });
    }
  }
}

function onClickQuickAddShortcut(key) {
  /**
   * Do not execute shortcuts with closed menu.
   */
  if (!isQuickAddOpen.value) {
    return false;
  }

  const item = allowedQuickAddItems.value.find(({ shortcutKey, permission }) => permission && shortcutKey === key);

  if (item) {
    onClickQuickAdd(item);
    // stop propagation and prevent default to prevent other shortcuts from executing
    return true;
  }

  return false;
}

function handleTimelogSaved() {
  trackPendoEvent({
    eventName: 'TIME_LOG_EVENT',
    commonMetrics: EXP_A15_COMMON_METRICS,
    launchDarklyFlagKey: EXP_A15_FLAG_KEY,
    metadata: {
      event_action: 'time_logged',
      event_source: 'quick_add_menu',
    },
  });
}

function handleQShortcutClicked() {
  if (isExpA15Variation.value || isExpE17Variation.value) {
    buttonActivatorRef.value.performRightButtonClick?.();
    return;
  }

  buttonActivatorRef.value.click?.();
  buttonActivatorRef.value.focus?.();
}
</script>

<template>
  <WidgetMenu v-model="isQuickAddOpen" location="end" aria-labelledby="side-nav-quick-add-button">
    <template #activator="activator">
      <AppShellSidebarQuickAddMenuSplitButton
        v-if="isExpA15Variation"
        v-bind="activator.props"
        id="side-nav-quick-add-button"
        ref="buttonActivatorRef"
        icon="lsi-time"
        :text="t('Log time')"
        @leftButtonClick="onClickQuickAdd(quickAddItems[2], true)"
        @rightButtonClick="onClickQuickAddMenuBtn('A15')"
      />
      <AppShellSidebarQuickAddMenuSplitButton
        v-else-if="isExpE17Variation"
        v-bind="activator.props"
        id="side-nav-quick-add-button"
        ref="buttonActivatorRef"
        icon="lsi-task"
        :text="t('Add task')"
        @leftButtonClick="onClickQuickAdd(quickAddItems[0], true)"
        @rightButtonClick="onClickQuickAddMenuBtn('E17')"
      />
      <button
        v-else
        v-bind="activator.props"
        id="side-nav-quick-add-button"
        ref="buttonActivatorRef"
        v-LsdTooltip:right="
          isSidebarExpanded || isQuickAddOpen
            ? undefined
            : {
                text: t('Quick add'),
                subtext: formatKeyboardShortcut('Q'),
              }
        "
        type="button"
        data-identifier="side-nav-quick-add-menu"
        :class="[
          'flex h-9 w-full min-w-10 items-center rounded-full',
          'outline-none ring-0 ring-[color:--lsds-c-sidebar-drawer-list-item-background-color-on-focus]',
          'text-body-1 font-medium text-[--lsds-c-sidebar-drawer-quick-add-color-on-surface]',
          'bg-[--lsds-c-sidebar-drawer-quick-add-color-surface] transition-colors',
          'hover:bg-[--lsds-c-sidebar-drawer-quick-add-color-surface-hover]',
          'aria-expanded:ring-0',
          'focus-visible:z-10 focus-visible:bg-[--lsds-c-sidebar-drawer-quick-add-color-surface-hover] focus-visible:ring-2',
        ]"
        @click="onClickQuickAddMenuBtn"
      >
        <div class="flex size-10 shrink-0 items-center justify-center">
          <LscIcon icon="lsi-sidenav-quick-add" />
        </div>
        <LscOverflowEllipsis class="flex-1 text-left">{{ t('Quick add') }}</LscOverflowEllipsis>
        <div class="flex size-10 min-w-0 items-center justify-center">
          <LscIcon icon="lsi-open-panel" size="sm" />
        </div>
      </button>
    </template>
    <VList v-bind="VListRounded" class="group ml-3">
      <VListSubheader>{{ t('Quick add') }}</VListSubheader>
      <VListItem
        v-for="item in menuItems"
        :key="item.code"
        :data-identifier="item.dataIdentifier"
        tabindex="0"
        link
        density="compact"
        class="focus-visible:group-focus-within:bg-selected"
        @click="onClickQuickAdd(item)"
      >
        <template #prepend>
          <VIcon size="22">{{ item.icon }}</VIcon>
        </template>
        <VListItemTitle>{{ item.text }}</VListItemTitle>

        <template #append>
          <VListItemAction v-if="item.shortcutKey">
            <span
              class="ml-auto flex size-5 content-center items-center justify-center rounded-sm border-2 border-solid border-subtle bg-default text-center text-body-3 uppercase text-subtle"
            >
              {{ item.shortcutKey }}
            </span>
          </VListItemAction>
        </template>
      </VListItem>
    </VList>
    <UseKeyboardShortcut
      v-for="shortcut in shortcuts"
      :key="shortcut"
      :shortcut="shortcut"
      @activate="onClickQuickAddShortcut(shortcut)"
    />
  </WidgetMenu>
  <UseKeyboardShortcut shortcut="Q" @activate="handleQShortcutClicked" />
  <LscSlotSwitch :name="dialogName">
    <template #TaskAddDialog>
      <TaskAddDialog v-model="isDialogOpen" pendoSource="app_sidebar_quick_add" />
    </template>
    <template #TimeTimelogAddOrEditDialog>
      <TimeTimelogAddOrEditDialog v-model="isDialogOpen" :openFromQuickAdd="true" @save="handleTimelogSaved" />
    </template>
    <template #UserInviteDialog>
      <UserInviteV2Dialog v-if="isExp23bVariation" v-model="isDialogOpen" panelSource="quick_add_people" />
      <UserInviteDialog v-else v-model="isDialogOpen" panelSource="quick_add_people" />
    </template>
    <template #ProjectAddPeopleDialog>
      <ProjectAddPeopleDialog v-model="isDialogOpen" :project="project" />
    </template>
    <template #TimeTimerAddOrEditDialog>
      <TimeTimerAddOrEditDialog v-model="isDialogOpen" />
    </template>
    <template #ClientAddEditDialog>
      <ClientAddDialog v-model="isDialogOpen" />
    </template>
  </LscSlotSwitch>
</template>
