<script setup>
import { useExperimentE23b, useFeatures } from '@/api';
import { useAppShellSidebar } from '@/appShell';
import { useWindowSize } from '@/util';
import { useLegacyBridge } from './useLegacyBridge';
import { AccountManageSubscriptionDialog } from '@/module/account';
import { SubscriptionAddSeatsDialog } from '@/module/subscription';
import { FeatureLimitUpgradeDialog } from '@/module/featureLimit';
import { ProofAddOrEditDialog } from '@/module/proof';
import { ReactivationDialog } from '@/module/reactivation';
import { TimeTimelogAddOrEditDialog, TimeTimerAddOrEditDialog } from '@/module/time';
import { DeskPromoDialog } from '@/module/desk';
import { UserInviteDialog, UserInviteV2Dialog, UserAddYourTeamDialog } from '@/module/user';
import { AIEditorOptionsDialog } from '@/module/ai';
import { FeatureTrialUpgradeDialog } from '@/module/featureTrial';
import OnboardingWistiaVideoDialog from '@/module/onboarding/OnboardingWistiaVideoDialog.vue';
import { TemplateGalleryDialog } from '@/module/template';
import { ProjectPortfolioManageBoardsDialog } from '@/module/project';
import { CustomfieldAddOrEditDialogPanel } from '@/module/customfield';
import { JobRolesAddDialog } from '@/module/jobRoles';

const { activeDrawerPanel, isDrawerPanelPinned } = useAppShellSidebar();

const { width } = useWindowSize();
const { isExp23bVariation, trackExperimentE23b, trackExperimentE23bEvent } = useExperimentE23b();
const { projectsDeskExpansionExperimentEnabled, peopleJobRolesPageEnabled } = useFeatures();

const {
  legacyBaseUrl,
  legacyIframeEl,
  iframeVisibilityMode,
  isLegacyModalOpen,
  legacyPageToShow,
  isLegacyInteractionDisabled,
  shouldUseLegacyView,
  isLegacyConnected,
  isLegacyIframeLoaded,
  isLegacyFullscreenEnabled,
  dialogName,
  dialogProps,
  isDialogOpen,
  updateLegacyEditorContent,
} = useLegacyBridge();

const contentPadding = computed(() => {
  if (width.value < 1200 || !activeDrawerPanel.value || !isDrawerPanelPinned.value) {
    return 0;
  }
  return 'var(--active-drawer-panel-width)';
});

const overlaySize = computed(() => {
  if (width.value < 1200 || !activeDrawerPanel.value) {
    return 'var(--sidebar-width)';
  }
  return 'calc(var(--active-drawer-panel-width) + var(--sidebar-width))';
});

function legacyIframeLoaded() {
  isLegacyIframeLoaded.value = true;
}

const canShowPlaceholder = import.meta.env.DEV;
const initialSearchQuery = window.location.search;

watch([dialogName, isDialogOpen], ([dialog, isOpen]) => {
  if (dialog === 'UserInviteDialog' && isOpen) {
    trackExperimentE23b();
    trackExperimentE23bEvent('PEOPLE_EVENT', {
      event_action: 'invite_users_clicked',
    });
  }
});
</script>

<template>
  <div>
    <!-- The legacy iframe -->
    <iframe
      ref="legacyIframeEl"
      class="tw-legacyViewWrapper"
      :allowfullscreen="true"
      :src="`${legacyBaseUrl}/#/idle${initialSearchQuery}`"
      :class="[
        iframeVisibilityMode,
        {
          'pointer-events-none': isLegacyInteractionDisabled,
          'fullscreen-mode': isLegacyFullscreenEnabled,
        },
      ]"
      :style="{
        top: `var(--legacy-top-offset)`,
        height: `calc(100% - var(--legacy-top-offset))`,
        paddingLeft: contentPadding,
      }"
      data-test-id="legacy-app-iframe"
      @load="legacyIframeLoaded"
    />

    <LscSlotSwitch :name="dialogName">
      <template #OnboardingWistiaVideoDialog>
        <OnboardingWistiaVideoDialog v-model="isDialogOpen" v-bind="dialogProps" />
      </template>
      <template #AccountManageSubscriptionDialog>
        <AccountManageSubscriptionDialog v-model="isDialogOpen" v-bind="dialogProps" />
      </template>
      <template #SubscriptionAddSeatsDialog>
        <SubscriptionAddSeatsDialog v-model="isDialogOpen" v-bind="dialogProps" />
      </template>
      <template #FeatureLimitUpgradeDialog>
        <FeatureLimitUpgradeDialog v-model="isDialogOpen" v-bind="dialogProps" />
      </template>
      <template #ProofAddOrEditDialog>
        <ProofAddOrEditDialog v-model="isDialogOpen" v-bind="dialogProps" />
      </template>
      <template #TimeTimelogAddOrEditDialog>
        <TimeTimelogAddOrEditDialog v-model="isDialogOpen" v-bind="dialogProps" />
      </template>
      <template #TimeTimerAddOrEditDialog>
        <TimeTimerAddOrEditDialog v-model="isDialogOpen" v-bind="dialogProps" />
      </template>
      <template #UserInviteDialog>
        <UserInviteV2Dialog
          v-if="isExp23bVariation"
          v-model="isDialogOpen"
          v-bind="dialogProps"
          panelSource="people_invite_user"
        />
        <UserInviteDialog v-else v-model="isDialogOpen" v-bind="dialogProps" panelSource="people_invite_user" />
      </template>
      <template #UserAddYourTeamDialog>
        <UserAddYourTeamDialog v-model="isDialogOpen" />
      </template>
      <template #DeskExpansionModal>
        <DeskPromoDialog v-if="projectsDeskExpansionExperimentEnabled" v-model="isDialogOpen" v-bind="dialogProps" />
      </template>
      <template #AIEditorOptionsDialog>
        <AIEditorOptionsDialog v-model="isDialogOpen" v-bind="dialogProps" @apply="updateLegacyEditorContent" />
      </template>
      <template #ReactivateSubscriptionModal>
        <ReactivationDialog v-model="isDialogOpen" v-bind="dialogProps" />
      </template>
      <template #FeatureTrialUpgradeDialog>
        <FeatureTrialUpgradeDialog v-model="isDialogOpen" v-bind="dialogProps" />
      </template>
      <template #TemplateGalleryDialog>
        <TemplateGalleryDialog v-model="isDialogOpen" v-bind="dialogProps" />
      </template>
      <template #ProjectPortfolioManageBoardsDialog>
        <ProjectPortfolioManageBoardsDialog v-model="isDialogOpen" v-bind="dialogProps" />
      </template>
      <template v-if="peopleJobRolesPageEnabled" #JobRolesAddDialog>
        <JobRolesAddDialog v-model="isDialogOpen" v-bind="dialogProps" />
      </template>
      <template #CustomfieldAddOrEditDialog>
        <WidgetDialog v-model="isDialogOpen" width="var(--customfield-dialog-width)">
          <CustomfieldAddOrEditDialogPanel v-bind="dialogProps" :canAddFormula="true" @close="isDialogOpen = false" />
        </WidgetDialog>
      </template>
    </LscSlotSwitch>

    <!-- Placeholder when developing in localhost -->
    <div
      v-if="canShowPlaceholder && !isLegacyConnected && shouldUseLegacyView"
      class="legacyAppPlaceholder"
      :style="{
        top: 'var(--legacy-top-offset)',
        height: `calc(100% - var(--legacy-top-offset))`,
        paddingLeft: contentPadding,
      }"
    >
      <p>Attempting to connect to local Teamwork Web App.</p>
      <p>To enable this feature, ensure you have it running locally.</p>
      <small class="mt-2">{{ legacyPageToShow }}</small>
    </div>

    <!-- Overlay matcher -->
    <template v-if="isLegacyModalOpen">
      <div
        class="topCover"
        :style="{
          height: 'var(--legacy-top-offset)',
          left: overlaySize,
        }"
      />
      <div
        class="sidebarCover"
        :style="{
          width: overlaySize,
        }"
      />
    </template>
  </div>
</template>

<style scoped>
/* content width = width - drawer flex-basis width (when closed) */
.tw-legacyViewWrapper {
  flex-grow: 1;
  overflow: hidden;
  height: 100vh;
  border: none;
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: none;
  z-index: 11;

  &.normal,
  &.modal {
    display: block;
  }

  &.fullscreen-mode {
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    top: 0 !important;
    z-index: 1010;
  }
}

.legacyAppPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 12;
}

.topCover,
.sidebarCover {
  background: rgba(11, 14, 31, 0.2) !important;
  position: fixed;
  top: 0;
  z-index: 1210;
}

.topCover {
  left: var(--v-layout-left);
  width: 100%;
}

.sidebarCover {
  left: 0;
  width: var(--v-layout-left);
  height: 100%;
}
</style>
