<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          "Tasks are a powerhouse. They're how you track everything that needs to get done. They tell you who is doing what and when it's being done.",
        )
      }}
    </p>
    <p class="pt-6">
      {{ t("Essentially, if there's anything that needs to get done or be tracked then it should be a task.") }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Great task names are short and descriptive. Starting them with a verb gives focus on the action that needs to get done. ',
        )
      }}
    </p>
  </div>
</template>
