<script setup>
import { useResizeObserver } from '@vueuse/core';
import { getVNodeTextContent } from '@/util';

const textRef = ref(null);
const isEllipsized = shallowRef(false);
const slots = useSlots();
const tooltip = computed(() => {
  if (!isEllipsized.value) {
    return '';
  }
  return getVNodeTextContent(slots.default?.({}));
});

useResizeObserver(textRef, (entries) => {
  const entry = entries.at(-1);
  if (entry.target) {
    isEllipsized.value = entry.target.offsetWidth < entry.target.scrollWidth;
  }
});
</script>

<template>
  <span ref="textRef" v-LsdTooltip="tooltip" class="truncate">
    <slot />
  </span>
</template>
