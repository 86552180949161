import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useI18n } from '@/util';

export function usePersonActions() {
  const api = useAxios();
  const { socketId, emit: emitRealTimeUpdate } = useRealTimeUpdates();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();
  const { t } = useI18n();
  const toast = useLsToast();

  /**
   * Emit an optimistic update
   * @param {Promise<any>} promise
   * @param {"create"|"update"|"delete"} action
   * @param {Number} personId
   */
  function emitOptimisticUpdate(promise, action, person) {
    _emitOptimisticUpdate({
      promise,
      type: 'person',
      action,
      person,
    });
  }

  function config() {
    return {
      headers: {
        'Socket-ID': socketId,
      },
    };
  }

  /**
   * Reassign person items
   * @param {Object} payload
   */
  function reassignPersonItems(payload) {
    return api({
      method: 'post',
      url: '/?action=people2.ajaxLBActionReassignUserItems()',
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  /**
   * Send invite
   * @param {Object} user
   */
  function sendInvite(user) {
    return api
      .post('/people.json', user, {
        headers: {
          'Socket-ID': socketId,
        },
        errorMessage(error) {
          if (
            error.response?.status === 422 &&
            (error?.response?.data?.MESSAGE.includes('in use') ||
              error?.response?.data?.MESSAGE.includes('Email address already used by another user'))
          ) {
            return t('Email {address} is already used by another user', { address: user.person['email-address'] });
          }
          if (
            error.response?.status === 422 &&
            error?.response?.data?.MESSAGE.includes('Client users can not have the same email domain as the site owner')
          ) {
            return t('Invite not sent to {address}. Client users cannot have the same email domain as the site owner', {
              address: user.person['email-address'],
            });
          }
          return t('Failed to send invite');
        },
      })
      .then((response) => {
        emitRealTimeUpdate({
          type: 'invite',
          action: 'new',
          userId: response.data.id,
        });

        return response;
      });
  }

  /**
   * Resend invite
   * @param {Object} user
   */
  function resendInvite(user) {
    return api
      .put(`/users/${user.id}/sendinvite.json`, user, {
        headers: {
          'Socket-ID': socketId,
        },
        errorMessage: t('Failed to resend invite'),
      })
      .then((response) => {
        emitRealTimeUpdate({
          type: 'invite',
          action: 'new',
          userId: response.data.id,
        });

        return response;
      });
  }

  /**
   * Get the invite link for a user
   * @param {Object} user
   * @returns {Promise<string>} the invite link
   */
  function getInviteLink(user) {
    const url = `/users/${user.id}/getinvitelink.json`;
    return api.get(url).then((response) => response.data.userInviteUrl);
  }

  /**
   * Add users to a project
   * @param  {number} projectId
   * @param {number[]} userIds
   */
  function addUserListToProject(projectId, userIds) {
    return api.post(`/projects/${projectId}/people.json`, { add: { userIdList: userIds } }, config());
  }

  /**
   *  Bulk update permissions for users
   * @param {number[]} userIds
   * @param  {object } permissions
   */
  function applyPermissionsToPeople(userIds, permissions) {
    return api
      .put(`/people/permissions.json`, { userIds: userIds.toString(), permissions }, config())
      .then((response) => {
        emitRealTimeUpdate({
          type: 'person',
          action: 'edited',
          userId: userIds[0].id,
        });
        return response;
      });
  }

  /**
   * Bulk update permissions for users on a project
   * @param {number} projectId
   * @param {number[]} userIds
   * @param  {object } permissions
   */
  function applyPermissionsToPeopleOnProject(projectId, userIds, permissions) {
    const url = `/projects/${projectId}/people/permissions.json`;
    return api.put(url, { userIds: userIds.toString(), permissions }, config()).then((response) => {
      emitRealTimeUpdate({
        type: 'person',
        action: 'edited',
        userId: userIds[0].id,
      });
      return response;
    });
  }

  /**
   * Delete users
   * @param {number[]} userIds
   * @param {boolean} unassignFromAll
   * @returns {Promise}
   */
  function deletePeople(userIds, unassignFromAll = true) {
    // We need to send a data payload with the delete request, axios does
    // not support data on delete so we must do it manually with config
    const c = config();
    c.data = {
      userIds: userIds.toString(),
      unassignFromAll,
    };
    const promise = api.delete(`/people.json`, c).then(() => {
      // No realtime updates on server side so need to emit this event
      emitRealTimeUpdate({
        type: 'person',
        action: 'deleted',
        userId: userIds[0].id,
      });
    });

    for (const userId of userIds) {
      emitOptimisticUpdate(promise, 'delete', { id: userId });
    }

    return promise;
  }

  /**
   * Restore user from trash
   * @param {number} userId
   */
  function restorePerson(person) {
    const promise = api.put(`/trashcan/people/${person.id}/restore.json`, {}, config()).then((response) => {
      emitRealTimeUpdate({
        type: 'person',
        action: 'edited',
        userId: person.id,
      });
      return response;
    });

    emitOptimisticUpdate(promise, 'update', person);

    return promise;
  }

  /**
   * Update person
   * @param {object} person
   */
  function updatePerson(person) {
    const promise = api.put(`/people/${person.id}.json`, { person }, config()).then((response) => {
      emitRealTimeUpdate({
        type: 'person',
        action: 'edited',
        userId: person.id,
      });
      return response;
    });

    emitOptimisticUpdate(promise, 'update', person);

    return promise;
  }

  /**
   * Add and/or remove users from project
   * @param {Object} project
   * @param {Array} add
   * @param {Array} remove
   */
  function addRemoveFromProject(projectId, { add = [], remove = [] }) {
    const userIdsToAdd = add.map((u) => {
      return typeof u === 'number' ? u : u.id;
    });
    const userIdsToRemove = remove.map((u) => {
      return typeof u === 'number' ? u : u.id;
    });

    return api
      .put(
        `/projects/${projectId}/people.json`,
        { add: { userIdList: userIdsToAdd.toString() }, remove: { userIdList: userIdsToRemove.toString() } },
        {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('Failed to update people on project'),
        },
      )
      .then((response) => {
        const anyUserId = userIdsToAdd.length ? userIdsToAdd[0] : userIdsToRemove[0];
        emitRealTimeUpdate({
          type: 'project',
          action: 'permissions',
          projectId,
          userId: anyUserId,
        });
        return response;
      });
  }

  /**
   * Change permissions of user on project
   * @param {Object} user
   * @param {Object} project
   * @param {Object} permissions
   */
  function setUserProjectPermissions(user, project, permissions) {
    return api
      .put(`/projects/${project.id}/people/${user.id}.json`, permissions, {
        headers: {
          'Socket-ID': socketId,
        },
        errorMessage: t('Failed to set permissions'),
      })
      .then((response) => {
        emitRealTimeUpdate({
          type: 'project',
          action: 'permissions',
          projectId: project.id,
          userId: user.id,
        });
        return response;
      });
  }

  /**
   * Update person cell
   * @param {number} personId
   * @param {string} phoneNumberMobile
   */
  function updatePersonCell(personId, phoneNumberMobile) {
    const promise = api
      .put(`/people/${personId}.json`, { person: { 'phone-number-mobile': phoneNumberMobile } }, config())
      .then((response) => {
        emitRealTimeUpdate({
          type: 'person',
          action: 'edited',
          userId: personId,
        });
        return response;
      });

    emitOptimisticUpdate(promise, 'update', {
      id: personId,
      phoneNumberMobile,
    });

    return promise;
  }

  /**
   * enable or disable email notifications for a person on a project
   * @param {number} projectId
   * @param {number} personId
   * @param {Boolean} preference
   */
  function updatePersonEmailPreference(projectId, personId, emailPreference) {
    return api
      .put(
        `/projects/${projectId}/people/${personId}.json`,
        {
          permissions: {
            receiveEmailNotifications: emailPreference,
          },
        },
        config(),
      )
      .then((response) => {
        emitRealTimeUpdate({
          type: 'project',
          action: 'edited',
          userId: personId,
          projectId,
        });
        toast.success(t('Project email setting updated'));
        return response;
      });
  }

  return {
    updatePerson,
    updatePersonCell,
    updatePersonEmailPreference,
    reassignPersonItems,
    sendInvite,
    resendInvite,
    getInviteLink,
    addUserListToProject,
    applyPermissionsToPeople,
    applyPermissionsToPeopleOnProject,
    deletePeople,
    restorePerson,
    addRemoveFromProject,
    setUserProjectPermissions,
  };
}
