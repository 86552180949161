import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

/**
 * Constants, computed properties and functions related to Experiment E-23-25
 * https://app.launchdarkly.com/default/production/features/projects-exp-23-25-deliver-desk-expansion/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/45282-e-23-25-expansion-offer-to-deliver-customers
 */
export function useExperimentE2325() {
  const { projectsDeskExpansionExperimentEnabled } = useFeatures();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();

  const EXP_E23_25_FLAG_KEY = 'projects-exp-23-25-deliver-desk-expansion';
  const EXP_23_25_COMMON_METRICS = ['plan_name', 'user_role', 'page'];
  const defaultFlagValue = false;
  const ignoreInExperimentOnRuleMatch = true;

  function trackExperimentE2325() {
    trackExperimentInPendo({
      launchDarklyFlagKey: EXP_E23_25_FLAG_KEY,
      defaultValue: defaultFlagValue,
      ignoreInExperimentOnRuleMatch,
    });
  }

  /** @param {string} eventAction  */
  function trackDeskOfferEvent(eventAction) {
    trackPendoEvent({
      eventName: 'DESK_OFFER_EVENT',
      commonMetrics: EXP_23_25_COMMON_METRICS,
      metadata: {
        page_type: 'whats_desk_modal',
        event_action: eventAction,
      },
    });
  }

  return {
    trackExperimentE2325,
    projectsDeskExpansionExperimentEnabled,
    trackDeskOfferEvent,
  };
}
