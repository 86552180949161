<script setup>
import { DateTime } from 'luxon';
import { usePendo } from '@/api';
import { useHelpCenter } from '../useHelpCenter';
import HelpCenterDrawerWebinarTag from './HelpCenterDrawerWebinarTag.vue';
import { useI18n } from '@/util';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  date: {
    validator(value) {
      return value == null || (DateTime.isDateTime(value) && value.isValid);
    },
    default: undefined,
  },
  tags: {
    type: Array,
    default: () => [],
  },
  description: {
    type: String,
    required: true,
  },
  actionText: {
    type: String,
    required: true,
  },
  actionPendoLabel: {
    type: String,
    required: true,
  },
  webinarDuration: {
    type: Number,
    default: 0,
  },
  isUpcoming: {
    type: Boolean,
    default: false,
  },
  webinarLink: {
    type: String,
    default: '',
  },
  webinarKey: {
    type: String,
    default: '',
  },
});

const { formatMinutes, t } = useI18n();
const { trackPendoEvent } = usePendo();
const { HELP_CENTER_COMMON_METRICS } = useHelpCenter();

const isTruncated = shallowRef(true);
const isWebinarButtonClicked = shallowRef(false);

function truncateDescription(description) {
  return description.length > 239
    ? `${description
        .slice(0, 239)
        .replace(/\s\S*$/, '')
        .replace(/\.\.\.$/, '')} &hellip;`
    : description;
}

function parseParagraphs(description) {
  return description
    .split('\n\n')
    .map((paragraph) => `<p>${paragraph}</p>`)
    .join('');
}

const processedDescription = computed(() =>
  isTruncated.value ? parseParagraphs(truncateDescription(props.description)) : parseParagraphs(props.description),
);

function trackWebinarClick() {
  trackPendoEvent({
    eventName: 'HELP_EVENT',
    metadata: {
      event_action: 'webinars_item_clicked',
      event_label: props.title.toLowerCase(),
      action_type: props.actionPendoLabel,
    },
    commonMetrics: HELP_CENTER_COMMON_METRICS,
  });
}

function register() {
  trackWebinarClick();
  window.open(props.webinarLink, '_blank');
}
</script>

<template>
  <LscSheet class="!p-6">
    <h2 class="mb-2 text-subtitle-2">{{ title }}</h2>
    <div class="mb-4 flex items-center justify-start">
      <HelpCenterDrawerWebinarTag v-for="tag in tags" :key="tag.label" :color="tag.type" :label="tag.label" />
    </div>
    <div class="text-text text-sm mb-4 leading-5">
      <div v-if="date" class="mb-4 text-body-1"><LscDateTime :dateTime="date" format="medium" /></div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="text-body-1" v-html="processedDescription" />
      <LscButton v-if="description.length > 239" variant="plain-secondary" @click.prevent="isTruncated = !isTruncated">
        {{ isTruncated ? t('Show more') : t('Show less') }}
      </LscButton>
    </div>
    <div class="flex items-center justify-between">
      <div class="flex h-10 w-full items-center justify-between">
        <LscButton :variant="isUpcoming ? 'primary' : 'plain-secondary'" size="md" @click="register">
          {{ actionText }}
        </LscButton>
        <span v-if="webinarDuration && !isWebinarButtonClicked" class="text-right text-body-2 leading-6">
          {{ formatMinutes(webinarDuration, { unitDisplay: 'long' }) }}
        </span>
      </div>
    </div>
  </LscSheet>
</template>
