<script setup>
const props = defineProps({
  maxHeight: {
    type: Number,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['scroll', 'scrollRefMounted']);
const scrollContainerRef = shallowRef(null);

const style = computed(() => {
  return props.maxHeight ? { maxHeight: `${props.maxHeight}px` } : {};
});

onMounted(() => {
  emit('scrollRefMounted', scrollContainerRef.value);
});
</script>
<template>
  <div class="m-0 flex h-full max-h-[inherit] flex-col overflow-hidden p-0" :style="style">
    <div
      ref="scrollContainerRef"
      v-scroll.self="(event) => emit('scroll', event)"
      class="h-full"
      :class="{ 'overflow-y-auto': !disabled, 'overflow-y-hidden': disabled }"
    >
      <slot />
    </div>
  </div>
</template>
