<script setup>
import { tv } from '../../../util/tailwind/tv';

const props = defineProps({
  /**
   * Whether the sheet has border & padding.
   */
  inset: {
    type: Boolean,
    default: true,
  },
  /**
   * Whether the sheet is elevated.
   */
  elevated: {
    type: Boolean,
    default: false,
  },
});

const cardVariantConfig = tv({
  base: 'border-[--lsds-a-color-border-default]',
  variants: {
    inset: {
      true: ['rounded-md border border-solid border-default bg-default p-4 shadow-1'],
      false: ['p-0'],
    },
    elevated: {
      true: ['shadow-4'],
    },
  },
  compoundVariants: [
    {
      elevated: true,
      inset: true,
      class: ['shadow-4'],
    },
  ],
});

const classes = computed(() => cardVariantConfig(props));
</script>
<template>
  <div :class="classes">
    <slot />
  </div>
</template>
