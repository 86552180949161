import { watchOnce } from '@vueuse/core';
import { useCurrentUser, useCurrentAccount, useFeatures, useWelcomeEmailTemplatesV1Loader } from '@/api';
import { useI18n } from '@/util';
import { UserType, UserPermissionGroup } from './userInvite';
import { useCheckoutData } from '@/module/checkout';

const symbol = Symbol('useUserInvite');

export function provideUserInvite() {
  const { t } = useI18n();
  const user = useCurrentUser();
  const account = useCurrentAccount();
  const { checkoutData } = useCheckoutData();
  const { clientUsersEnabled } = useFeatures();
  const { item: savedMessage, loaded: loadedSavedMessage } = useWelcomeEmailTemplatesV1Loader();

  const defaultMessage = shallowRef('');
  const allProjects = shallowRef(false);

  /**
   * State variables
   */
  const company = shallowRef();
  const message = shallowRef();
  const projects = shallowRef();
  const userType = shallowRef();
  const emailAddresses = shallowRef();
  const permissionGroup = shallowRef();
  const canAddUsers = shallowRef();
  const canCreateProjects = shallowRef();

  /**
   * Computed properties.
   */
  const maxProjects = computed(() => {
    return user.value.isClientUser ? 5 : Infinity;
  });

  const isInvitingClientUser = computed(() => userType.value === UserType.client);

  const isInvitingClientUserOrCollaborator = computed(() => {
    return userType.value === UserType.client || userType.value === UserType.collaborator;
  });

  const seatsLimit = computed(() => {
    return account.value.paidForUsers;
  });

  const activeUsers = computed(() => {
    return checkoutData.value?.usage.numUsers || 0;
  });

  const hasExceededSeatsLimit = computed(() => {
    return activeUsers.value + emailAddresses.value.length > seatsLimit.value;
  });

  const canAddClientUsers = computed(() => {
    const isOwnCompany = company.value?.id === user.value.companyId;
    return clientUsersEnabled.value && !isOwnCompany;
  });

  const isSaveButtonDisabled = computed(() => {
    return !(emailAddresses.value.length && company.value && message.value.length <= 5000);
  });

  const showPermissionsToggle = computed(() => {
    return userType.value === UserType.standard && permissionGroup.value !== UserPermissionGroup.administrator;
  });

  const isCustomMessage = computed(() => {
    return message.value !== defaultMessage.value && message.value !== savedMessage.value;
  });

  const seatsToAdd = computed(() => {
    const amountRequired = activeUsers.value + emailAddresses.value.length;
    if (amountRequired <= seatsLimit.value) {
      return 0;
    }
    return amountRequired - seatsLimit.value;
  });

  /**
   * Methods.
   */
  function $resetUserType() {
    userType.value = UserType.standard;
  }

  function $resetPermissions() {
    permissionGroup.value = UserPermissionGroup.default;

    if (userType.value === UserType.standard) {
      canAddUsers.value = true;
      canCreateProjects.value = true;

      return;
    }

    canAddUsers.value = false;
    canCreateProjects.value = false;
  }

  function $resetMessage() {
    message.value = savedMessage.value || defaultMessage.value;
  }

  function $reset() {
    company.value = {
      id: user.value.companyId,
      name: user.value.companyName,
    };
    projects.value = [];
    emailAddresses.value = [];

    $resetMessage();
    $resetUserType();
    $resetPermissions();
  }

  function $handleUserTypeChange(currentUserType) {
    $resetPermissions();

    if (currentUserType === UserType.client) {
      projects.value = [];
    }
  }

  // set default values
  $reset();

  watchOnce(
    () => loadedSavedMessage.value,
    (loaded) => {
      if (loaded) {
        defaultMessage.value = t('Welcome onboard 👋');
        $resetMessage();
      }
    },
  );

  /**
   * Watchers.
   */
  watch(canAddClientUsers, $resetUserType);

  watch(userType, $handleUserTypeChange);

  provide(symbol, {
    emailAddresses,
    company,
    userType,
    permissionGroup,
    projects,
    canAddUsers,
    canCreateProjects,
    message,
    allProjects,

    isInvitingClientUser,
    isInvitingClientUserOrCollaborator,
    seatsToAdd,
    seatsLimit,
    maxProjects,
    canAddClientUsers,
    hasExceededSeatsLimit,
    isSaveButtonDisabled,
    showPermissionsToggle,
    isCustomMessage,
    activeUsers,

    $reset,
  });
}

export function useUserInvite() {
  return inject(symbol);
}
