<script setup>
import { useBadge } from './useBadge';

const { dialogShown, lastBadgeCompleted } = useBadge();

const BadgeAchievedDialogPanel = defineAsyncComponent(() => import('./BadgeAchievedDialogPanel.vue'));
</script>

<template>
  <WidgetDialog v-model="dialogShown" width="448px">
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <BadgeAchievedDialogPanel
        v-if="lastBadgeCompleted"
        :code="lastBadgeCompleted.code"
        :level="lastBadgeCompleted.level.code"
        :title="lastBadgeCompleted.displayName"
        @close="close"
      />
    </template>
  </WidgetDialog>
</template>
