import { useI18n } from '../../util/i18n/useI18n';
import { useLaunchDarkly } from '../features/useLaunchDarkly';
import { usePendo } from '../features/usePendo';
import { useFeatures } from '../features/useFeatures';

/**
 * Constants, computed properties and functions related to experiment A54
 * https://digitalcrew.teamwork.com/spaces/growth/page/49716-a-23-54-optimise-upgrade-now-cta-for-trials
 * https://app.launchdarkly.com/default/production/features/projects-exp-a-23-54-optimise-upgrade-now-cta-for-trials/targeting
 */
export function useExperimentA54() {
  const { trackExperimentInPendo } = usePendo();
  const { optimiseUpgradeNowCTAForTialsEnabled } = useFeatures();
  const { client } = useLaunchDarkly();
  const { t } = useI18n();

  const { launchDarklyFlagKey, defaultValue } = optimiseUpgradeNowCTAForTialsEnabled;
  const ignoreInExperimentOnRuleMatch = true;

  const variation1 = 'add-billing-details';
  const variation2 = 'compare-plans';

  const expA54Variation = computed(() => {
    if (client.value.variation(launchDarklyFlagKey) === variation1) {
      return {
        icon: 'lsi-billable',
        label: t('Add billing details'),
        meta: { cta_variant: 'add_billing_details' },
      };
    }
    if (client.value.variation(launchDarklyFlagKey) === variation2) {
      return {
        icon: 'lsi-multiple-templates',
        label: t('Compare plans'),
        meta: { cta_variant: 'compare_plans' },
      };
    }
    return {
      icon: 'lsi-sidenav-upgrade',
      label: t('Upgrade now'),
      meta: { cta_variant: 'upgrade_now' },
    };
  });

  function trackExperimentA54() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
    });
  }

  return {
    trackExperimentA54,
    expA54Variation,
  };
}
