import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useCurrentUser } from './useCurrentUser';

export function useCurrentUserActions() {
  const api = useAxios();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate } = useRealTimeUpdates();
  const user = useCurrentUser();

  function emitOptimisticUpdate(promise, action, person) {
    _emitOptimisticUpdate({
      promise,
      type: 'person',
      action,
      person,
    });
  }

  async function logout() {
    if (import.meta.env.DEV && import.meta.env.TW_APP_PROXY_TARGET) {
      // eslint-disable-next-line no-console
      console.warn('Cannot log out in dev proxy mode');
    } else {
      await api.put('/v/1/logout.json');
      window.location = '/launchpad/logout';
    }
  }

  function toggleClockIn() {
    if (user.value.isClockedIn) {
      const promise = api.post('/me/clockout.json', { info: '' }).then(() => {
        emitRealTimeUpdate({
          type: 'person',
          action: 'update',
          person: { id: user.value.id, isClockedIn: false },
          userId: user.value.id,
        });
      });

      emitOptimisticUpdate(promise, 'update', {
        id: user.value.id,
        isClockedIn: false,
      });

      return promise;
    }

    const promise = api.post('/me/clockin.json', { info: '' }).then(() => {
      emitRealTimeUpdate({
        type: 'person',
        action: 'update',
        person: { id: user.value.id, isClockedIn: true },
        userId: user.value.id,
      });
    });

    emitOptimisticUpdate(promise, 'update', {
      id: user.value.id,
      isClockedIn: true,
    });
    return promise;
  }

  function reloadApp() {
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }

  async function impersonate(userId) {
    if (!userId) {
      return;
    }
    await api.put(`/people/${userId}/impersonate.json`);
    reloadApp();
  }

  async function revertImpersonate() {
    if (!user.value.impersonating) {
      window.location.reload();
      return;
    }
    const { data } = await api.put('/people/impersonate/revert.json');
    const { previousProjectId, impersonatedUserId } = data;

    if (Number(previousProjectId)) {
      window.location.href = `/app/projects/${previousProjectId}/people`;
      reloadApp();
      return;
    }
    if (Number(impersonatedUserId)) {
      window.location.href = `/app/people/${impersonatedUserId}`;
      reloadApp();
      return;
    }

    window.location.href = '/app/people';
    reloadApp();
  }

  return { logout, toggleClockIn, impersonate, revertImpersonate };
}
