import { useItemLoader } from '../base/useItemLoader';

function responseToItem({ data }) {
  return data?.integrations;
}

export function useUserIntegrationsV2Loader() {
  const { state } = useItemLoader({
    url: '/synthesis/api/v2/integrations/user.json',
    responseToItem,
  });

  return state;
}
