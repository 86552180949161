<script setup>
import { provideRoute } from './useRoute';
import RouteSetupCurrentProject from './RouteSetupCurrentProject.vue';
import RouteSetupCurrentProjectPermissions from './RouteSetupCurrentProjectPermissions.vue';
import RouteSetupCurrentProjectBudget from './RouteSetupCurrentProjectBudget.vue';

provideRoute();
</script>
<template>
  <RouteSetupCurrentProject>
    <RouteSetupCurrentProjectBudget>
      <RouteSetupCurrentProjectPermissions>
        <slot />
      </RouteSetupCurrentProjectPermissions>
    </RouteSetupCurrentProjectBudget>
  </RouteSetupCurrentProject>
</template>
