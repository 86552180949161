<script setup>
import { useCustomfieldsV3Loader } from '@/api';
import { useI18n } from '@/util';
import { customfieldTypes } from '../constants';

const props = defineProps({
  editing: {
    type: Boolean,
    required: false,
    default: false,
  },
  entity: {
    type: String,
    required: true,
  },
});

const modelValue = defineModel({
  type: String,
  default: null,
});

const { t } = useI18n();

const searchTermLeft = shallowRef('');
const searchTermRight = shallowRef('');

const leftRef = shallowRef();
const rightRef = shallowRef();
const operatorRef = shallowRef();

const leftFocused = shallowRef(false);
const rightFocused = shallowRef(false);

const title = computed(() => (props.editing ? t('Edit formula') : t('Create formula')));

const selectedCustomfieldIds = computed(() => [leftRef.value, rightRef.value].filter(Boolean));
const selectedCustomfieldsIdsCount = computed(() => selectedCustomfieldIds.value.length || -1);

const { items: selectedCustomfieldsItems, inSync: selectedCustomfieldsInSync } = useCustomfieldsV3Loader({
  count: selectedCustomfieldsIdsCount,
  pageSize: selectedCustomfieldsIdsCount,
  params: computed(() => ({
    ids: selectedCustomfieldIds.value.join(','),
  })),
});

const { items: customfields, inSync: customfieldsInSync } = useCustomfieldsV3Loader({
  count: 500,
  pageSize: 500,
  params: computed(() => ({
    // eslint-disable-next-line no-nested-ternary
    searchTerm: leftFocused.value ? searchTermLeft.value : rightFocused.value ? searchTermRight.value : undefined,
    entities: props.entity,
    onlySiteLevel: true,
  })),
});

const selectedCustomfieldsMap = computed((prevState) => {
  const selected = selectedCustomfieldsItems.value;

  if (!selected.length && prevState && !selectedCustomfieldsInSync.value) {
    return prevState;
  }

  return selected.reduce((acc, customfield) => {
    acc[customfield.id] = {
      title: customfield.name,
      value: customfield.id,
    };
    return acc;
  }, {});
});

const operators = computed(() => [
  {
    value: '+',
    title: t('Add'),
    icon: 'lsi-add',
  },
  {
    value: '-',
    title: t('Subtract'),
    icon: 'lsi-remove',
  },
  {
    value: '*',
    title: t('Multiply'),
    icon: 'lsi-close',
  },
  {
    value: '/',
    title: t('Divide'),
    icon: 'lsi-forward-slash',
  },
]);

const filtered = computed((prevState) => {
  const selectedItemsMap = selectedCustomfieldsMap.value;
  const current = customfields.value.filter(
    (customfield) => customfield.type === customfieldTypes.NUMBER && !selectedItemsMap[customfield.id],
  );

  if (!current.length && prevState?.length && !customfieldsInSync.value) {
    return prevState;
  }

  return current.map((customfield) => ({
    title: customfield.name,
    value: customfield.id,
  }));
});

const leftItems = computed(() => {
  const selectedItemsMap = selectedCustomfieldsMap.value;
  const selectedItem = selectedItemsMap[leftRef.value];

  const items = filtered.value;

  if (selectedItem) {
    return [selectedItem, ...items];
  }

  return items;
});

const rightItems = computed(() => {
  const selectedItemsMap = selectedCustomfieldsMap.value;
  const selectedItem = selectedItemsMap[rightRef.value];

  const items = filtered.value;

  if (selectedItem) {
    return [selectedItem, ...items];
  }

  return items;
});

const fieldByIdRegex = /FIELDBYID\(([0-9]+)\)/g;

function matchFieldById(target) {
  try {
    const value = Number([...target.matchAll(fieldByIdRegex)][0][1]);

    if (Number.isNaN(value)) {
      return null;
    }

    return value;
  } catch (error) {
    return null;
  }
}

watch(
  modelValue,
  (model) => {
    if (!model) {
      leftRef.value = null;
      rightRef.value = null;
      operatorRef.value = operators.value[0].value;
      return;
    }

    const [left, operator, right] = model.split(' ');

    const leftValue = matchFieldById(left);
    const rightValue = matchFieldById(right);

    if (!(leftValue && rightValue && operators.value.find(({ value }) => value === operator))) {
      return;
    }

    leftRef.value = leftValue;
    rightRef.value = rightValue;
    operatorRef.value = operator;
  },
  { immediate: true },
);

function updateModel() {
  const [left, operator, right] = [leftRef.value, operatorRef.value, rightRef.value];

  if (!(left && operator && right)) {
    return;
  }

  modelValue.value = `FIELDBYID(${left}) ${operator} FIELDBYID(${right})`;
}

function handleUpdateLeft(value) {
  leftRef.value = value;
  searchTermLeft.value = '';
  updateModel();
}

function handleUpdateOperator(value) {
  operatorRef.value = value;
  updateModel();
}

function handleUpdateRight(value) {
  rightRef.value = value;
  searchTermRight.value = '';
  updateModel();
}
</script>

<template>
  <h5 class="flex items-center text-subtitle-2 font-semibold">
    {{ title }}
  </h5>
  <div class="flex items-center gap-3">
    <VAutocomplete
      v-model:focused="leftFocused"
      v-model:search="searchTermLeft"
      class="flex-1"
      required
      hideSelected
      :modelValue="leftRef"
      :items="leftItems"
      :loading="!customfieldsInSync && leftFocused"
      :label="t('Select a field')"
      :menuProps="{ width: 'auto' }"
      @update:modelValue="handleUpdateLeft"
    />
    <VSelect
      class="max-w-20"
      :modelValue="operatorRef"
      :menuProps="{ width: 'auto' }"
      :items="operators"
      @update:modelValue="handleUpdateOperator"
    >
      <template #item="{ item, props: itemProps }">
        <VListItem
          density="compact"
          class="w-auto min-w-40 focus-visible:group-focus-within:bg-selected"
          :active="operatorRef === item.value"
          v-bind="itemProps"
        >
          <template #prepend>
            <LscIcon :icon="item.raw.icon" size="md" class="mr-2" />
          </template>
        </VListItem>
      </template>
      <template #selection="{ item }">
        <LscIcon :icon="item.raw.icon" size="md" class="shrink-0 text-icon-subtle" />
      </template>
    </VSelect>
    <VAutocomplete
      v-model:focused="rightFocused"
      v-model:search="searchTermRight"
      class="flex-1"
      required
      hideSelected
      :modelValue="rightRef"
      :items="rightItems"
      :loading="!customfieldsInSync && rightFocused"
      :label="t('Select a field')"
      :menuProps="{ width: 'auto' }"
      @update:modelValue="handleUpdateRight"
    />
  </div>
</template>
