export const defaultCurrency = Object.freeze({
  id: 1,
  name: 'US dollar',
  code: 'USD',
  symbol: '$',
  decimalPoints: 2,
});

export const createFormatCurrency = ({ currency, languageCode }) =>
  /**
   * Returns a formatted string for currency values in the form of ${n}.
   * @param {Number} amount
   * @param {"standard"|"compact"} notation
   */
  function formatCurrency(amount, notation = 'standard') {
    return new Intl.NumberFormat(languageCode.value, {
      compactDisplay: 'short',
      currency: currency.value.code,
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: Number.isInteger(amount) ? 0 : currency.value.decimalPoints,
      notation,
      style: 'currency',
    }).format(amount);
  };

export const createConvertToCentValue = ({ currency }) =>
  /**
   * Converts a standard currency value to a cent value.
   */
  function convertToCentValue(value) {
    if (!value && value !== 0) {
      return undefined;
    }
    const { decimalPoints } = currency.value;
    const centValue = value * 10 ** decimalPoints;
    return Number(`${Math.round(`${centValue}e${decimalPoints}`)}e-${decimalPoints}`);
  };

export const createConvertFromCentValue = ({ currency }) =>
  /**
   * Converts a cent value to a standard currency value.
   */
  function convertFromCentValue(value) {
    if (!value && value !== 0) {
      return undefined;
    }
    const { decimalPoints } = currency.value;
    const singleCentValue = 10 ** (decimalPoints * -1);
    const zeroDecimalString = `.${'0'.repeat(decimalPoints)}`;
    return (value * singleCentValue).toFixed(decimalPoints).replace(zeroDecimalString, '');
  };
