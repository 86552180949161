import './defaults.css';
import { LsdsAColorBorderSeparator } from '@teamwork/lightspeed-designsystem-tokens/default';

const global = {
  ripple: false,
};

const VAutocomplete = {
  clearIcon: 'lsi-clear',
  menuIcon: 'lsi-dropdown',
  color: 'primary',
  density: 'compact',
  hideDetails: 'auto',
  variant: 'outlined',
  menuProps: {
    width: 320,
  },
};

const VBadge = {
  color: 'accent',
  max: 99,
};

const VBtn = {
  color: 'primary',
  density: 'compact', // Not working
  rounded: true,
  flat: true,
  VIcon: {
    size: 20,
  },
  height: 40, // Height was used as size prop appears to be broken
  variant: 'outlined',
};

const VCheckbox = {
  color: 'primary',
  hideDetails: true,
  density: 'compact',
  VIcon: {
    size: 20,
  },
};

const VChip = {
  closeIcon: 'lsi-close',
  color: 'secondary',
  height: 32,
  variant: 'flat',
};

const VCombobox = {
  clearIcon: 'lsi-clear',
  menuIcon: 'lsi-dropdown',
  closableChips: true,
  chips: true,
  color: 'primary',
  density: 'compact',
  hideDetails: 'auto',
  multiple: true,
  variant: 'outlined',
  menuProps: {
    maxHeight: 320,
    width: 320,
  },
};

const VDivider = {
  color: LsdsAColorBorderSeparator,
};

const VRadio = {
  color: 'primary', // Not working
};

const VRadioGroup = {
  color: 'primary', // Not working
  hideDetails: 'auto',
};

const VSelect = {
  clearIcon: 'lsi-clear',
  menuIcon: 'lsi-dropdown',
  color: 'primary',
  density: 'compact',
  hideDetails: 'auto',
  transition: false, // Probably just a bug - shouldn't be required
  variant: 'outlined',
  menuProps: {
    width: 320,
    maxHeight: 320,
  },
};

const VSlider = {
  step: 10,
  color: 'primary',
  hideDetails: 'auto',
  ticks: true,
  thumbLabel: false,
  trackColor: 'secondary',
};

const VSheet = {
  rounded: true,
};

const VSwitch = {
  // Fix thumb color when active
  color: 'primary',
  density: 'compact', // Not working
  hideDetails: 'auto',
  inset: true,
};

const VTextarea = {
  color: 'primary',
  density: 'compact',
  hideDetails: 'auto',
  noResize: true,
  rows: 4,
  variant: 'outlined',
};

const VTextField = {
  color: 'primary',
  density: 'compact',
  hideDetails: 'auto',
  variant: 'outlined',
};

const VTabs = {
  color: 'primary',
  density: 'default',
  height: 48,
  sliderColor: 'primary',
  VBtn: {
    rounded: false,
  },
};

const VTab = {
  minWidth: 'auto',
  color: 'secondary', // Not working (we hope this would set the inactive color for that tab?)
};

// Needs revisited
const VProgressLinear = {
  color: '#d8e0ff',
  bgColor: '#f2f5fe',
  bgOpacity: 100,
};

export const defaults = {
  global,
  VAutocomplete,
  VBadge,
  VBtn,
  VCheckbox,
  VChip,
  VCombobox,
  VDivider,
  VRadio,
  VRadioGroup,
  VSelect,
  VSheet,
  VTabs,
  VTab,
  VTextarea,
  VTextField,
  VSlider,
  VSwitch,
  VProgressLinear,
};
