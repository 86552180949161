<script setup>
import { useI18n } from '@/util';
import { useLegacyBridge } from '@/module/legacy';
import { useEnabledApps, usePermissions } from '@/api';
import { useAppShellSidebar } from './useAppShellSidebar';

const { t } = useI18n();
const { isSidebarExpanded } = useAppShellSidebar();
const { isOwnerAdmin } = usePermissions();
const { showLegacyModal } = useLegacyBridge();
const { isChatAvailable, isCrmAvailable, isDeskAvailable, isSpacesAvailable } = useEnabledApps();

const otherAppsItems = computed(() =>
  [
    {
      appCode: 'chat',
      icon: 'lsi-app-chat',
      exact: true,
      text: 'Teamwork Chat',
      permission: isChatAvailable,
    },
    {
      appCode: 'spaces',
      icon: 'lsi-app-spaces',
      exact: false,
      text: 'Teamwork Spaces',
      permission: isSpacesAvailable,
    },
    {
      appCode: 'desk',
      icon: 'lsi-app-desk',
      exact: false,
      text: 'Teamwork Desk',
      permission: isDeskAvailable,
    },
    {
      appCode: 'crm',
      icon: 'lsi-app-crm',
      exact: false,
      text: 'Teamwork CRM',
      permission: isCrmAvailable,
    },
  ].filter((app) => app.permission.value),
);
const canManageOtherApps = computed(() => isOwnerAdmin.value);
const otherAppsMenuListStyle = computed(() => {
  /**
   * If user has permission to manage apps then number of items is increased by one
   */
  const numberOfItems = canManageOtherApps.value ? otherAppsItems.value.length + 1 : otherAppsItems.value.length;

  /**
   * Calculate list min height using it's size, with a 15px margin.
   */
  const minHeight = `${16 + numberOfItems * 36}`;

  return {
    minHeight: `${minHeight}px`,
    marginLeft: '10px',
    marginTop: `${minHeight * -1 + 32}px`,
  };
});

function showManageAppsModal() {
  showLegacyModal({
    modalName: 'manageApps',
  });
}
</script>

<template>
  <WidgetMenu location="top">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <VList v-bind="VListRounded" :style="otherAppsMenuListStyle">
      <VListItem
        v-for="item in otherAppsItems"
        :key="item.name"
        v-LsdTooltip="isSidebarExpanded ? undefined : item.text"
        :href="`/${item.appCode}`"
        tabindex="0"
        target="_blank"
        link
        class="focus-visible:bg-selected"
        density="compact"
      >
        <template #prepend>
          <VIcon size="20" class="block">{{ item.icon }}</VIcon>
        </template>
        <VListItemTitle>{{ item.text }}</VListItemTitle>
      </VListItem>
      <template v-if="canManageOtherApps">
        <VDivider v-if="otherAppsItems.length" class="my-2" />
        <VListItem link density="compact" tabindex="0" class="focus-visible:bg-selected" @click="showManageAppsModal">
          <template #prepend>
            <VIcon size="20" :title="t('Manage apps')">lsi-settings</VIcon>
          </template>
          <VListItemTitle>{{ t('Manage apps') }}</VListItemTitle>
        </VListItem>
      </template>
    </VList>
  </WidgetMenu>
</template>
