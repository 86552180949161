import { useAxios } from '../base/useAxios';

export function useFeatureLimitActions() {
  const api = useAxios();

  /**
   * @param {number} planId
   * @param {string} feature
   * @param {undefined | string} message
   */
  async function sendPlanTrialRequest(planId, feature, message, featureLimitReached = true) {
    await api.post('/account/plantrial/request.json', {
      trial: {
        planId,
        feature,
        message,
        featureLimitReached,
      },
    });
  }

  /**
   * Post start a plan trial
   * @param {number} planId
   */
  function startPlanTrial({ planId }) {
    const promise = api.post('/account/plantrial.json', {
      trial: { planId },
    });

    return promise;
  }

  return { sendPlanTrialRequest, startPlanTrial };
}
