<script setup>
import { tv } from '../../../util/tailwind/tv';
import { useI18n } from '@/util';
import LscAvatar from '../../media/avatar/LscAvatar.vue';

/**
 * @typedef {import('./constants').LscChipSizes} LscChipSizes
 * @typedef {import('../../media/avatar/constants').LscAvatar} LscAvatar
 */

const props = defineProps({
  /**
   * The HTML tag to use for the chip.
   * @type {import('vue').PropType<'div'|'button'>}
   */
  is: {
    type: String,
    default: 'div',
    validator: (value) => ['div', 'button'].includes(value),
  },
  /**
   * The variant of the chip.
   * @type {import('vue').PropType<'default'|'on-surface'>}
   */
  variant: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'on-surface'].includes(value),
  },
  /**
   * The size of the chip.
   * @type {import('vue').PropType<LscChipSizes>}
   */
  size: {
    type: String,
    default: 'lg',
    validator: (value) => ['xs', 'sm', 'lg'].includes(value),
  },
  /**
   * Whether the chip is disabled.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the chip is clearable.
   */
  clearable: {
    type: Boolean,
    default: false,
  },
  /**
   * The tooltip to show when hovering over the clear button.
   */
  clearableTooltip: {
    type: String,
    default: '',
  },
  /**
   * The avatar to show in the chip.
   * @type {import('vue').PropType<LscAvatar>}
   */
  avatar: {
    type: Object,
    default: undefined,
  },
  /**
   * The icon to show in the chip.
   * @type {import('vue').PropType<import('@teamwork/lightspeed-icons/dist/icon-list').all|undefined>}
   */
  icon: {
    type: String,
    default: undefined,
  },
  /**
   * The color of the icon.
   */
  iconColor: {
    type: String,
    default: 'var(--lsds-a-color-icon-subtle)',
  },
  /**
   * The label to show in the chip.
   */
  label: {
    type: String,
    default: '',
  },
});

const emit = defineEmits('clear');
const { t } = useI18n();

const type = computed(() => (props.is === 'button' ? 'button' : undefined));

const avatarSizeMap = {
  xs: 'xxs',
  sm: 'xxs',
  lg: 'sm',
};
const avatarSize = computed(() => avatarSizeMap[props.size]);

const iconSizeMap = {
  xs: 'xs',
  sm: 'sm',
  lg: 'md',
};

const iconSize = computed(() => iconSizeMap[props.size]);

const clearableTooltip = computed(() => props.clearableTooltip || t('Clear'));

const chipVariantStyleConfig = tv({
  base: 'content-border inline-flex cursor-default items-center rounded-full px-1',
  slots: {
    prependSlot: 'flex items-center',
    icon: 'text-[color:--icon-color]',
    avatar: '',
    label: '',
    clearButton: 'mr-1 flex items-center justify-center',
  },
  variants: {
    size: {
      xs: {
        base: 'h-[--lsds-c-chip-height-xs]',
        label: 'mx-1 max-w-32 text-body-2',
      },
      sm: {
        base: 'h-[--lsds-c-chip-height-sm]',
        label: 'mx-1 max-w-32 text-body-2',
      },
      lg: {
        base: 'h-[--lsds-c-chip-height-lg]',
        label: 'mx-2 max-w-40 text-body-1',
      },
    },
    variant: {
      default: {
        base: ['text-default', 'bg-[--lsds-c-chip-color-bg-default-default]'],
      },
      'on-surface': {
        base: [
          'bg-[--lsds-c-chip-color-bg-on-surface-default]',
          'border border-solid text-default',
          'border-[--lsds-c-chip-color-border-on-surface-default]',
        ],
      },
    },
  },
  compoundVariants: [
    {
      variant: 'default',
      is: 'button',
      class: {
        base: 'hover:bg-[--lsds-c-chip-color-bg-default-hover]',
      },
    },
    {
      variant: 'on-surface',
      is: 'button',
      class: {
        base: 'hover:border-[--lsds-c-chip-color-border-on-surface-hover]',
      },
    },
    {
      is: 'button',
      disabled: false,
      class: {
        base: '!cursor-pointer',
      },
    },
  ],
});

const classes = computed(() => chipVariantStyleConfig(props));
</script>

<template>
  <Component :is="is" :type="type" :class="classes.base()" :aria-disabled="disabled" :disabled="disabled">
    <span v-if="avatar || avatar === null || icon" :class="classes.prependSlot()">
      <LscAvatar
        v-if="avatar || avatar === null"
        v-bind="avatar"
        :showTooltip="false"
        :size="avatarSize"
        :class="classes.avatar()"
      />
      <LscIcon
        v-else-if="icon"
        :icon="icon"
        :size="iconSize"
        :class="classes.icon()"
        :style="{ '--icon-color': iconColor }"
      />
    </span>

    <LscOverflowEllipsis :class="classes.label()">
      <slot>{{ label }}</slot>
    </LscOverflowEllipsis>

    <button
      v-if="clearable && !disabled"
      v-LsdTooltip="clearableTooltip"
      :aria-label="clearableTooltip"
      :class="classes.clearButton()"
      type="button"
      @click="emit('clear')"
    >
      <LscIcon icon="lsi-close" :size="iconSize" />
    </button>
  </Component>
</template>
