<script setup>
import { useBranding, useExperimentA15, provideExperimentE23b, usePendo, useExperiment46 } from '@/api';
import { useThemeColor } from '@/module/theme';
import { useBadge } from '@/module/badge';

import { useAppShellSidebar } from './useAppShellSidebar';
import AppShellSidebarLogo from './AppShellSidebarLogo.vue';
import AppShellSidebarNavigation from './AppShellSidebarNavigation.vue';
import AppShellSidebarActionsGrowth from './AppShellSidebarActionsGrowth.vue';
import AppShellSidebarActionsBottom from './AppShellSidebarActionsBottom.vue';
import AppShellSidebarUserMenu from './userMenu/AppShellSidebarUserMenu.vue';
import AppShellSidebarUserButton from './AppShellSidebarUserButton.vue';
import AppShellSidebarExpandButton from './AppShellSidebarExpandButton.vue';

defineOptions({
  inheritAttrs: false,
});

const branding = useBranding();
const { previewTheme } = useThemeColor();
const { trackPendoEvent } = usePendo();
const { trackExperimentA15 } = useExperimentA15();

const { EXP_46_COMMON_METRICS } = useExperiment46();
const { getOtherExp46CommonMetrics } = useBadge();
const { isSidebarExpanded, isSidebarOpenOnMobile, trackSideNavItemClicked } = useAppShellSidebar();

provideExperimentE23b('quick_add');

const currentlyActiveThemeColor = computed(() => {
  if (previewTheme.value) {
    return `is-${previewTheme.value}`;
  }
  return '';
});

function trackProfileMenuOpened() {
  trackPendoEvent({
    eventName: 'NAVIGATION_EVENT',
    commonMetrics: EXP_46_COMMON_METRICS,
    metadata: {
      event_action: 'left_navigation_clicked_opened',
      navigation_item: 'profile_menu',
      ...getOtherExp46CommonMetrics(),
    },
  });
}

function userMenuClicked() {
  trackSideNavItemClicked('user_profile');
  trackProfileMenuOpened();
}

onMounted(trackExperimentA15);
</script>

<template>
  <div
    id="mainNav"
    v-bind="$attrs"
    :class="[
      currentlyActiveThemeColor,
      'fixed left-0 top-0 z-50 flex h-screen flex-col justify-between',
      'bg-[--lsds-c-sidebar-drawer-color-surface]',
      '-translate-x-full sm:translate-x-0',
      'transition-[width,transform] duration-300 will-change-[width,transform]',
      'w-[--sidebar-width]',
      {
        'translate-x-0': isSidebarOpenOnMobile,
      },
    ]"
  >
    <AppShellSidebarLogo
      v-if="branding.hasTeamworkBranding && !isSidebarOpenOnMobile"
      class="mx-3 mt-5 block"
      :collapsed="isSidebarExpanded"
    />

    <AppShellSidebarNavigation class="mb-2 flex-1" />
    <AppShellSidebarActionsGrowth :class="isSidebarExpanded ? 'mb-8' : 'mb-4'" />
    <AppShellSidebarActionsBottom class="mx-6 shrink-0 pb-3" />
    <AppShellSidebarUserMenu location="top end" origin="bottom start">
      <template #activator="activator">
        <AppShellSidebarUserButton v-bind="activator.props" class="mx-5 mb-4 shrink-0" @click="userMenuClicked" />
      </template>
    </AppShellSidebarUserMenu>

    <AppShellSidebarExpandButton
      :class="{
        visible: isSidebarOpenOnMobile,
        'invisible sm:visible': !isSidebarOpenOnMobile,
      }"
    />
  </div>
  <Transition
    appearFromClass="opacity-0"
    appearToClass="opacity-20"
    appearActiveClass="transition-opacity duration-300"
    leaveFromClass="opacity-20"
    leaveToClass="opacity-0"
    leaveActiveClass="transition-opacity duration-300"
  >
    <div
      v-if="isSidebarOpenOnMobile"
      class="fixed inset-0 z-40 bg-surface-dark opacity-20"
      @click="isSidebarOpenOnMobile = !isSidebarOpenOnMobile"
    />
  </Transition>
</template>
