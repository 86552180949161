const symbol = Symbol('LscTabs');

/**
 *
 * @typedef {object} LsTab
 * @property {string|number} id - unique id for the tab
 * @property {string} label - the text to display
 * @property {string=} prependIcon - the icon to display (optional)
 * @property {number=} count - the count to display (optional)
 * @property {boolean=} disabled - whether the tab is disabled (optional)
 * @property {string=} href - the path to navigate to when clicked (optional)
 * @property {string=} dataIdentifier - the data identifier to use for the tab (optional)
 */

export function provideLscTabs({ variant: _variant } = {}) {
  const variant = shallowRef(_variant);
  provide(symbol, { variant });
}

export function useLscTabs() {
  const context = inject(symbol);

  if (!context) {
    throw new Error('<LscTab> must be used within <LscTabs>');
  }

  return context;
}
