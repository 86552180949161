const symbol = Symbol('feedbackDataIdentifier');

export function feedbackDataIdentifierPlugin(app) {
  const feedbackDataIdentifier = shallowRef('');

  app.provide(symbol, feedbackDataIdentifier);
}

/**
 * Controls the visibility of the feedback button in the app shell.
 * This data identifier can be used to trigger a Pendo guide.
 * @param {import('@vueuse/core').MaybeRef<String>} dataIdentifier
 */
export function useFeedbackDataIdentifier(_dataIdentifier) {
  const feedbackDataIdentifier = inject(symbol);
  const dataIdentifier = toRef(_dataIdentifier);

  onMounted(() => {
    if (dataIdentifier.value) {
      feedbackDataIdentifier.value = dataIdentifier.value ?? '';
    }
  });

  watch(dataIdentifier, (value) => {
    feedbackDataIdentifier.value = value ?? '';
  });

  onUnmounted(() => {
    if (dataIdentifier.value) {
      feedbackDataIdentifier.value = '';
    }
  });

  return feedbackDataIdentifier;
}
