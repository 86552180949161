<script setup>
import { useI18n } from '@/util';
import { useCurrentAccount, useFeatures } from '@/api';
import { useCurrentProjectPermissions } from '@/route';

const props = defineProps({
  styleOverwrite: {
    type: String,
    default: '',
  },
  canAddFiles: {
    type: Boolean,
    default: true,
  },
  canUploadFiles: {
    type: Boolean,
    default: true,
  },
  multiple: {
    type: Boolean,
    default: true,
  },
  accept: {
    type: String,
    default: '*',
  },
  dataIdentifier: {
    type: String,
    default: '',
  },
  showGoogleDriveOption: {
    type: Boolean,
    default: false,
  },
  focusFirstItem: {
    type: Boolean,
    default: false,
  },
  tooltipText: {
    type: [String, Object],
    default: undefined,
  },
});

const emit = defineEmits(['filesToUpload', 'selectExistingFiles', 'selectProvider']);
const fileInput = ref(null);

const { t } = useI18n();

const account = useCurrentAccount();
const isGoogledriveEnabled = computed(() => account.value?.integrations?.googledrive?.enabled);
const { projectsGoogledriveEnabled } = useFeatures();
const { canAccessGoogleDrive } = useCurrentProjectPermissions();

const firstOption = shallowRef();
const secondOption = shallowRef();

const shouldShowGoogleDriveOption = computed(() => {
  return (
    canAccessGoogleDrive.value &&
    isGoogledriveEnabled.value &&
    projectsGoogledriveEnabled.value &&
    props.showGoogleDriveOption
  );
});

function onClickUploadANewFile() {
  fileInput.value.value = null;
  fileInput.value.click();
}

function onSelectFilesToUpload(event) {
  if (!event.target.files) {
    return;
  }
  emit('filesToUpload', event.target.files);
}

function onSelectExistingFiles() {
  emit('selectExistingFiles');
}

function onSelectProvider(provider) {
  emit('selectProvider', provider);
}

watch([firstOption], () => {
  /* Focus first rendered option if requested by keyboard shortcut */
  if (props.focusFirstItem) {
    setTimeout(() => {
      if (!firstOption.value) {
        secondOption.value?.focus();
      } else {
        firstOption.value?.focus();
      }
    }, 100);
  }
});
</script>
<template>
  <div>
    <WidgetOptionsMenu v-if="canAddFiles" v-bind="$attrs">
      <template #activator="activator">
        <slot name="activator" v-bind="{ activator, onClickUploadANewFile }">
          <VBtn
            v-LsdTooltip="tooltipText ? tooltipText : t('Upload')"
            v-bind="{ ...VBtnFileUpload, ...activator.props }"
            :class="styleOverwrite"
            :data-identifier="dataIdentifier ? dataIdentifier : undefined"
          >
            <LscIcon icon="lsi-add" />
          </VBtn>
        </slot>
      </template>
      <WidgetOptionsMenuItem
        v-if="canUploadFiles"
        ref="firstOption"
        :text="t('Upload new files')"
        :prepend="{ icon: 'lsi-upload-new-file' }"
        @click="onClickUploadANewFile"
      />
      <WidgetOptionsMenuItem
        ref="secondOption"
        :text="t('Choose from existing files')"
        :prepend="{ icon: 'lsi-choose-existing-file' }"
        @click="onSelectExistingFiles"
      />
      <WidgetOptionsMenuItem
        v-if="shouldShowGoogleDriveOption"
        text="Google Drive"
        :prepend="{ icon: 'lsi-integration-google-drive' }"
        @click="onSelectProvider('googledrive')"
      />
    </WidgetOptionsMenu>

    <input
      ref="fileInput"
      :multiple="multiple"
      :accept="accept"
      type="file"
      class="hidden"
      @change="onSelectFilesToUpload"
    />
  </div>
</template>
