import { computed } from 'vue';
import { useCurrentAccount } from '../account/useCurrentAccount';

const subscriptionUrl = '/launchpad/v1/checkoutref/projects.json';

export function useCheckoutUtils() {
  const account = useCurrentAccount();

  /** @type {import('vue').Ref<string>} */
  const checkoutBaseUrl = computed(() => account.value?.environment.serviceUrls.checkout);

  return {
    checkoutBaseUrl,
    subscriptionUrl,
  };
}
