<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{ t("Board's power comes from being customizable, you can (and should) make them match your team's workflow.") }}
    </p>
    <p class="pt-6">
      {{
        t(
          "Columns automatically appear on the board's right side. You'll be dragging and dropping tasks between columns, so we should arrange them in a way that mimics a tasks normal workflow. ",
        )
      }}
    </p>
    <p class="pt-6">
      {{ t('For example, "Completed" should come after (not before) "In Progress".') }}
    </p>
  </div>
</template>
