import { useFeatures } from '../features/useFeatures';
import { useLaunchDarkly } from '../features/useLaunchDarkly';
import { usePendo } from '../features/usePendo';

/**
 * Constants, computed properties and functions related to Experiment A-23-41
 * https://app.launchdarkly.com/default/production/features/projects-exp-a-23-41-slack-integration-during-onboarding/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/47834-a-23-41-setup-slack-integration-during-onboarding
 */

export function useExperimentA41() {
  const { slackIntegrationDuringOnboarding } = useFeatures();
  const { trackExperimentInPendo } = usePendo();
  const { client } = useLaunchDarkly();

  const { launchDarklyFlagKey, defaultValue } = slackIntegrationDuringOnboarding;
  const ignoreInExperimentOnRuleMatch = true;
  const EXP_A41_COMMON_METRICS = ['plan_name', 'user_role', 'page', 'page_tab'];

  const variation1 = 'variation-1';
  const variation2 = 'variation-2';
  const control = 'control';

  const isExpA41Variation1 = computed(
    () => slackIntegrationDuringOnboarding.value && client.value.variation(launchDarklyFlagKey) === variation1,
  );

  const isExpA41Variation2 = computed(
    () => slackIntegrationDuringOnboarding.value && client.value.variation(launchDarklyFlagKey) === variation2,
  );

  const isExpA41Control = computed(
    () => !slackIntegrationDuringOnboarding.value || client.value.variation(launchDarklyFlagKey) === control,
  );

  function trackExperimentA41() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
    });
  }

  return {
    trackExperimentA41,
    isExpA41Variation1,
    isExpA41Variation2,
    isExpA41Control,
    EXP_A41_COMMON_METRICS,
  };
}
