<script setup>
import { useI18n } from '@/util';
import { ENTITY_ICON_MAP } from './entity';
import like from '@/designsystem/assets/emoji/EmojiLike.png?url';
import dislike from '@/designsystem/assets/emoji/EmojiDislike.png?url';
import heart from '@/designsystem/assets/emoji/EmojiHeart.png?url';
import joy from '@/designsystem/assets/emoji/EmojiJoy.png?url';
import frown from '@/designsystem/assets/emoji/EmojiFrown.png?url';

const props = defineProps({
  group: {
    type: String,
    default: '',
  },

  action: {
    type: String,
    default: '',
  },

  size: {
    type: [String, Number],
    default: 32,
  },
});

const { t } = useI18n();

const REACTIONS = {
  liked: {
    alt: t('Like'),
    src: like,
  },
  disliked: {
    alt: t('Dislike'),
    src: dislike,
  },
  loved: {
    alt: t('Love'),
    src: heart,
  },
  laughed: {
    alt: t('Joy'),
    src: joy,
  },
  frowned: {
    alt: t('Frown'),
    src: frown,
  },
};

const reaction = computed(() => REACTIONS[props.action]);
const icon = computed(() => ENTITY_ICON_MAP[props.group]);
</script>

<template>
  <VImg v-if="reaction" v-bind="reaction" :width="size" :height="size" />
  <VIcon v-else-if="icon" color="#0b0e1f" :icon="icon" :size="size" />
</template>
