import { usePendo } from '../features/usePendo';

/**
 * Constants and functions related to experiment A27
 * https://digitalcrew.teamwork.com/spaces/growth/page/46171-a-23-27-measurement-doc
 * https://app.launchdarkly.com/default/staging/features/projects-exp-a-23-27-dummy-data/targeting
 */
export function useExperimentA27() {
  const EXP_A27_COMMON_METRICS = ['plan_name', 'user_role', 'page'];

  const { trackPendoEvent } = usePendo();

  function trackDummyDataEvent(eventAction) {
    trackPendoEvent({
      eventName: 'DUMMY_DATA_EVENT',
      metadata: { event_action: eventAction },
      commonMetrics: EXP_A27_COMMON_METRICS,
    });
  }

  return {
    EXP_A27_COMMON_METRICS,
    trackDummyDataEvent,
  };
}
