<script setup>
import { provideDocumentTitle, useI18n } from '@/util';
import AppShellError from './AppShellError.vue';
import AppShellLoading from './AppShellLoading.vue';
import AppShellNotFound from './AppShellNotFound.vue';
import AppShellToastContainer from './toast/AppShellToastContainer.vue';

const { pathname } = window.location;
const { ready, error } = useI18n();

const FormPublic = defineAsyncComponent(() => import('@/module/form').then((m) => m.FormPublic));

provideDocumentTitle();
</script>

<template>
  <AppShellError v-if="error" />
  <AppShellLoading v-else-if="!ready" />
  <template v-else-if="pathname.startsWith('/app/public/forms')">
    <FormPublic />
  </template>
  <AppShellNotFound v-else />
  <AppShellToastContainer />
</template>
