<script setup>
import { useRoute, useQuickViewRoute } from './useRoute';

const route = useRoute();
const quickViewRoute = useQuickViewRoute();

// Allows delaying of the rendering of the main view to prioritize rendering of the quick view.
const isLoadingQuickViewOnStartup = shallowRef(false);

function enableLoadingQuickViewOnStartup() {
  isLoadingQuickViewOnStartup.value = true;
  // Delays rendering of the main view to prioritize rendering of the quick view.
  setTimeout(() => {
    isLoadingQuickViewOnStartup.value = false;
  }, 3000);
  const unwatch = watch(
    () => quickViewRoute.fullPath !== undefined,
    (hasQuickView) => {
      if (!hasQuickView) {
        unwatch();
        isLoadingQuickViewOnStartup.value = false;
      }
    },
  );
}

// If `quickViewRoute` is defined,
// we delay rendering of the main view to prioritize rendering of the quick view.
if (quickViewRoute.fullPath !== undefined) {
  enableLoadingQuickViewOnStartup();
}

// If `quickViewRoute` is not defined and the initial main `route` is `isQuickViewRoute`,
// then the appropriate route component will be rendered by `MainRouterView` instead of `QuickViewRouterView`.
// The route component will then typically set `quickViewBackgroundPath` and trigger the router to refresh,
// so that a quick view would be rendered with an appropriate background route. If that happens,
// we delay rendering of the main view to prioritize rendering of the quick view.
if (quickViewRoute.fullPath === undefined && route.meta.isQuickViewRoute) {
  const initialFullPath = route.fullPath;
  const unwatch = watch(
    () => route.fullPath,
    () => {
      unwatch();
      if (route.fullPath !== initialFullPath && quickViewRoute.fullPath === initialFullPath) {
        enableLoadingQuickViewOnStartup();
      }
    },
  );
}
</script>

<template>
  <WidgetLoadingProgress v-if="isLoadingQuickViewOnStartup" />
  <RouterView v-else :route="route" />
</template>
