<script setup>
import useVuelidate from '@vuelidate/core';
import { useI18n, useValidators } from '@/util';
import { usePendo, useFeedbackActions, useCurrentAccount, useCurrentUser, useVersion } from '@/api';
import { useFileUploads, FileUploadMenu, FileList } from '@/module/file';
import { useHelpCenter } from '../../useHelpCenter';
import { MAX_UPLOAD_SIZE } from '../../constants';
import { useCurrentProjectPermissions } from '@/route';

const props = defineProps({
  subject: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: undefined,
  },
});

const { trackPendoEvent } = usePendo();
const { t } = useI18n();
const { required, helpers } = useValidators();
const toast = useLsToast();
const { isHelpCenterContactFilledOut, HELP_CENTER_COMMON_METRICS, resetState } = useHelpCenter();
const { postFeedback } = useFeedbackActions();
const account = useCurrentAccount();
const user = useCurrentUser();
const { version } = useVersion();
const fileUploads = useFileUploads({ maxUploadSize: MAX_UPLOAD_SIZE });
const { canDownloadFiles, canPreviewFiles } = useCurrentProjectPermissions();

const { fileMetaList, removeFile, uploadFiles } = fileUploads;

const contactSubject = shallowRef(props.subject ? `${props.subject} ` : '');
const contactDescription = shallowRef('');
const mailMeACopy = shallowRef(false);

const formSubmitted = shallowRef(false);
const submitting = shallowRef(false);

const container = ref(undefined);

const files = computed(() => {
  return Array.isArray(fileMetaList.value) ? fileMetaList.value : [];
});

const uploadedFilesValidAndComplete = computed(() => {
  if (files.value.length > 0) {
    return files.value.every((file) => file.uploadProgress === 100 && !file.isOverMaxSize);
  }

  return true;
});

// Desk ticketTypes:
// Unspecified, Question, Problem, Request, Sales Enquiry, Marketing, Careers, Feedback, Renewals
const contactTypes = [
  {
    value: 'Request',
    title: t('I want to request a feature'),
  },
  {
    value: 'Problem',
    title: t('Bug report'),
  },
  {
    value: 'Question',
    title: t('I have a question'),
  },
];

const selectedType = shallowRef(props.type);

const validationRules = {
  selectedType: {
    required: helpers.withMessage(t('Please select an option.'), required),
  },
  contactSubject: {
    required: helpers.withMessage(t('Please enter your subject.'), required),
  },
  contactDescription: {
    required: helpers.withMessage(t('Please enter your message.'), required),
  },
};

const v$ = useVuelidate(validationRules, { selectedType, contactSubject, contactDescription }, { $autoDirty: true });

function getSubjectWithDetails(summary) {
  let trialText = '';
  const hasActivePlanTrial = account.value?.pricePlanTrialData?.remainingDays > 0;
  if (hasActivePlanTrial) {
    trialText = ` [Current trial: ${account.value.pricePlanTrialData.name} (${account.value.pricePlanTrialData.remainingDays} days left)]`;
  }
  return `${account.value.awsRegion}: ${trialText ? `${trialText} - ` : ''}${summary}`;
}

function getUserType() {
  if (user.value.siteOwner) {
    return 'Site Owner';
  }
  if (user.value.isClientUser) {
    return 'Client User';
  }

  return ` ${user.value.userType}${user.value.administrator ? '(Administrator)' : ''}`;
}

function getDescriptionWithDetails(message) {
  return `${message}

---

User Type: ${getUserType()}
URL: ${window.location.href}
User-Agent: ${navigator.userAgent}

`;
}

async function submit() {
  v$.value.$touch();
  if (v$.value.$error || uploadedFilesValidAndComplete.value === false) {
    return;
  }

  const formData = {
    feedback: {
      source: 'Teamwork Projects',
      type: selectedType.value,
      summary: getSubjectWithDetails(contactSubject.value.trim()),
      message: getDescriptionWithDetails(contactDescription.value.trim()),
      sendACopy: mailMeACopy.value,
      fileurl: '',
      buildInfo: `App/LS ${version ?? '0.0.1'}; API 0.0.1`, // TODO: can't get these values in LS
      pendingFileAttachments: files.value.map((file) => file.fileRef),
      updateFiles: true,
      attachments: '',
      removeOtherFiles: true,
    },
  };

  try {
    submitting.value = true;
    const response = await postFeedback(formData);
    if (response.status === 200) {
      isHelpCenterContactFilledOut.value = false;
      formSubmitted.value = true;

      trackPendoEvent({
        eventName: 'HELP_EVENT',
        metadata: {
          event_action: 'contact_support_submitted',
        },
        commonMetrics: HELP_CENTER_COMMON_METRICS,
      });
    }
  } catch (error) {
    toast.critical(t('Failed to submit feedback, try again later'));
  } finally {
    submitting.value = false;
  }
}

async function onSelectFilesToUpload(_files) {
  await uploadFiles(_files, true);
}

watch([selectedType, contactSubject, contactDescription, mailMeACopy], () => {
  isHelpCenterContactFilledOut.value = true;
});
</script>

<template>
  <div ref="container" class="flex min-h-0 shrink grow basis-0 flex-col overflow-x-hidden pb-6">
    <h3 v-if="!formSubmitted" class="mb-6 px-6 text-subtitle-1 font-semibold">{{ t('Contact support') }}</h3>
    <div class="m-0 h-full max-h-[inherit] overflow-y-auto p-0">
      <Transition
        enterFromClass="opacity-0"
        enterToClass="opacity-100"
        enterActiveClass="transition-opacity duration-200 ease-in-out"
        leaveFromClass="opacity-100"
        leaveToClass="opacity-0"
        leaveActiveClass="transition-opacity duration-200 ease-in-out"
        mode="out-in"
      >
        <VForm v-if="!formSubmitted">
          <div class="flex flex-col gap-6 px-6 py-2">
            <VSelect
              v-model="selectedType"
              :items="contactTypes"
              variant="outlined"
              :label="t('What do you need support with?')"
              density="compact"
              :menuProps="{ attach: container }"
              :errorMessages="v$.selectedType.$errors.map((error) => error.$message)"
              :disabled="submitting"
              required
              @blur="v$.selectedType.$touch()"
            />

            <VTextField
              v-model="contactSubject"
              :label="t('Subject')"
              required
              :errorMessages="v$.contactSubject.$errors.map((error) => error.$message)"
              :disabled="submitting"
              @blur="v$.contactSubject.$touch()"
            />

            <VTextarea
              v-model="contactDescription"
              :label="t('Your message *')"
              :placeholder="t('Provide us with a more detailed description')"
              :errorMessages="v$.contactDescription.$errors.map((error) => error.$message)"
              :disabled="submitting"
              required
              @blur="v$.contactDescription.$touch()"
            />

            <FileUploadMenu :multiple="false" @filesToUpload="onSelectFilesToUpload">
              <template #activator="{ onClickUploadANewFile }">
                <LscDropzone :multiple="false" @change="onSelectFilesToUpload">
                  <template v-if="files.length <= 0">
                    <div
                      class="flex h-full w-full cursor-pointer select-none flex-col items-center justify-center rounded-sm border-2 border-dashed border-default bg-default px-4 py-8 text-center text-default"
                      :class="submitting ? 'pointer-events-none opacity-60' : 'opacity-100'"
                      @click="onClickUploadANewFile"
                    >
                      <p class="mb-1 text-body-1 font-semibold text-default">
                        <LscReplace :text="t('Drag and drop your file here or click to {browse}')">
                          <template #browse>
                            <span class="text-primary">{{ t('browse') }}</span>
                          </template>
                        </LscReplace>
                      </p>
                      <p class="text-gr mb-1 text-body-2 text-subtle">
                        {{
                          t(
                            'A screenshot would be helpful for us to figure out the problem. File size can be up to 2MB.',
                          )
                        }}
                      </p>
                    </div>
                  </template>
                  <template v-else>
                    <div :class="submitting ? 'pointer-events-none opacity-60' : 'opacity-100'">
                      <p class="mb-2 text-body-1 font-semibold">{{ t('Attachments') }}</p>
                      <FileList
                        grid="1"
                        class="mb-2 w-full"
                        :files="files"
                        :canPreviewFiles="canPreviewFiles"
                        :canDownloadFiles="canDownloadFiles"
                      >
                        <template #actions="{ file: { fileRef } }">
                          <LscIconButton
                            v-LsdTooltip="t('Delete')"
                            :ariaLabel="t('Delete')"
                            size="sm"
                            icon="lsi-delete"
                            @click="removeFile(fileRef)"
                          />
                        </template>
                      </FileList>
                      <VMessages
                        :messages="
                          t('Please attach an image smaller than {limit}', {
                            limit: '2MB',
                          })
                        "
                        :active="files?.[0].isOverMaxSize"
                        color="error"
                        class="opacity-100"
                      />
                    </div>
                  </template>
                </LscDropzone>
              </template>
            </FileUploadMenu>

            <VCheckbox
              v-model="mailMeACopy"
              :label="t('Send a copy of this message to my email')"
              :disabled="submitting"
            />

            <LscButton
              variant="primary"
              class="self-start"
              :loading="submitting"
              :disabled="submitting"
              @click="submit"
            >
              {{ t('Send feedback') }}
            </LscButton>
          </div>
        </VForm>

        <div v-else class="content-center px-6 py-20 text-center">
          <div
            class="mx-auto mb-6 flex size-10 flex-none items-center justify-center rounded-full bg-surface-success-default"
          >
            <LscIcon icon="lsi-selected" />
          </div>
          <h3 class="mb-2 text-h4 font-semibold">{{ t('Feedback sent') }}</h3>
          <p class="text-body-1">
            {{
              t(
                'Thank you {firstName}, your feedback will help us make {teamwork} a better product. If you have asked a question, our Customer Success team will be in touch with you shortly.',
                { firstName: user.firstName, teamwork: 'Teamwork.com' },
              )
            }}
          </p>
          <LscButton variant="primary" class="mt-6 self-start" @click="resetState">
            {{ t('Back to help center') }}
          </LscButton>
        </div>
      </Transition>
    </div>
  </div>
</template>
