import { useItemLoader } from '../base/useItemLoader';

function responseToItem({ data: { welcomeemail } }) {
  return welcomeemail;
}

/**
 * Loads the branding information.
 */
export function useWelcomeEmailTemplatesV1Loader(params) {
  const { state } = useItemLoader({
    url: '/templates/welcomeemail.json',
    params,
    responseToItem,
  });
  return state;
}
