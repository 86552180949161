<script setup>
import { useCurrentUser } from '@/api';
import { useCurrentProject } from '@/route';
import { useI18n } from '@/util';
import SampleTemplatePreview from '../SampleTemplatePreview.vue';

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
  step: {
    type: Number,
    default: 10,
  },
  shouldLoadOnScroll: {
    type: Boolean,
    default: true,
  },
  searchTerm: {
    type: String,
    default: '',
  },
});

const searchTerm = toRef(props, 'searchTerm');

const count = defineModel('count', { type: Number, required: true });

const { t } = useI18n();

const user = useCurrentUser();
const currentProject = useCurrentProject();

const projects = computed(() => unref(props.state.items));

const uniqueCategories = computed(() => {
  const result = [];
  const map = new Map();
  for (const project of projects.value) {
    if (!map.has(project.category.id)) {
      map.set(project.category.id, true);
      result.push(project.category);
    }
  }
  return result;
});

const projectsInCategory = (catId) => projects.value.filter((p) => p.category.id === catId);
const isOpen = shallowRef([]);
const selectedTemplate = shallowRef();
const isTemplateDialogOpen = shallowRef(false);

function previewTemplate(template) {
  selectedTemplate.value = template;
  isTemplateDialogOpen.value = true;
}

// When there is a search, open all categories, otherwise close all
watch(props.state.items, () => {
  if (!props.searchTerm) {
    isOpen.value = [];
    return;
  }
  isOpen.value = uniqueCategories.value.map((cat) => cat.id);
});
</script>

<template>
  <WidgetLoadingState :state="state" :loadingMessage="t('Loading sample projects')">
    <template #default>
      <h4 class="mb-2 pl-6 text-body-1 font-semibold">
        {{ t('Sample project templates') }}
        <template v-if="searchTerm">&mdash; {{ t('results') }}</template>
      </h4>
      <VList
        v-for="cat in uniqueCategories"
        :key="cat.id"
        v-model:opened="isOpen"
        density="compact"
        class="!px-4 !py-0"
      >
        <VListGroup :value="cat.id">
          <template #activator="activator">
            <VListItem
              v-bind="{ ...activator.props, ...VListItemSidebarProjectsDrawer }"
              class="!m-0 text-body-1"
              data-identifier="side-nav-templates-panel-project-teamwork-template-item"
            >
              {{ cat.name }}
            </VListItem>
          </template>
          <VList density="compact" class="m-0 !pt-0">
            <VListItem
              v-for="project in projectsInCategory(cat.id)"
              :key="project.id"
              :active="currentProject?.id === project.id"
              v-bind="VListItemSidebarProjectsDrawer"
              @click="previewTemplate(project)"
            >
              <template #prepend>
                <div class="mr-2">
                  <VIcon color="#8F9BB3" :size="20">lsi-single-template</VIcon>
                </div>
              </template>
              <VListItemTitle :title="project.name" class="text-body-1">{{ project.name }}</VListItemTitle>
              <VListItemSubtitle
                v-if="project.company?.name && user.companyId !== project.companyId"
                :title="project.company?.name"
                class="text-body-2"
              >
                {{ project.company.name }}
              </VListItemSubtitle>
            </VListItem>
          </VList>
        </VListGroup>
      </VList>
      <WidgetLoadingLazy v-model:count="count" :step="step" :state="state" />
    </template>
    <template #blank>
      <slot name="blank" />
    </template>
  </WidgetLoadingState>
  <SampleTemplatePreview v-if="selectedTemplate" v-model="isTemplateDialogOpen" :template="selectedTemplate" />
</template>
