<script setup>
import { tv } from '../../../util/tailwind/tv';
import { useI18n } from '@/util';
import { provideLscAlert } from './useLscAlert';

const props = defineProps({
  /**
   * Whether the alert is closable.
   */
  closable: {
    type: Boolean,
    default: false,
  },
  /**
   * The text for the close button.
   */
  closeButtonText: {
    type: String,
    default: undefined,
  },
  /**
   * The message of the alert.
   */
  message: {
    type: String,
    default: undefined,
  },
  /**
   * The icon of the alert.
   * Note: This is overridden by the media slot.
   */
  icon: {
    type: String,
    default: undefined,
  },
  /**
   * The title of the alert.
   */
  title: {
    type: String,
    default: undefined,
  },
  /**
   * The data identifier prefix of the alert.
   */
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
  /**
   * The layout of the alert.
   * - Alert: The alert is displayed inline with the content.
   * - Banner: The alert is displayed full-width as a banner above the content with no rounded corners.
   * @type {import('vue').PropType<'banner' | 'alert'>}
   */
  layout: {
    type: String,
    default: 'alert',
    validator: (value) => ['banner', 'alert'].includes(value),
  },
  /**
   * The variant of the alert.
   * @type {import('vue').PropType<'info' | 'info-subdued' | 'success' | 'success-subdued' | 'warning' | 'warning-subdued' | 'critical' | 'critical-subdued'>}
   */
  variant: {
    type: String,
    default: 'info',
    validator: (value) =>
      [
        'info',
        'info-subdued',
        'success',
        'success-subdued',
        'warning',
        'warning-subdued',
        'critical',
        'critical-subdued',
      ].includes(value),
  },
});
const emit = defineEmits(['close']);

const { t } = useI18n();
const closeButtonText = computed(() => props.closeButtonText ?? t('Close'));

const alertVariantStyleConfig = tv({
  base: ['flex min-w-0 items-center justify-between gap-3 border p-3'],
  slots: {
    left: 'flex min-w-0 flex-auto items-start gap-2',
    leftIcon: ['leading-[--lsds-a-typography-body-1-regular-line-height]', 'shrink-0', 'mt-0.5'],
    leftContent: 'min-w-0 flex-auto',
    titleWrapper: 'flex items-center gap-1',
    title: 'text-balance text-body-1 font-semibold',
    message: [
      'text-pretty',
      'text-[length:--lsds-a-typography-body-1-regular-font-size]',
      'leading-[--lsds-a-typography-body-1-regular-line-height]',
    ],
    right: 'flex shrink-0 items-center gap-2 self-center',
    closeButton: ['size-[--lsds-c-button-size-xs]', 'mt-0.5'],
  },
  variants: {
    layout: {
      banner: {
        base: ['rounded-none'],
      },
      alert: {
        base: ['rounded-md'],
      },
    },
    variant: {
      info: {
        base: ['bg-[--lsds-c-alert-color-info-default]'],
        leftIcon: ['text-[color:--lsds-a-color-icon-default]'],
        title: ['text-[color:--lsds-a-color-text-default]'],
        message: ['text-[color:--lsds-a-color-text-default]'],
        closeButton: ['text-[color:--lsds-a-color-icon-default]'],
      },
      'info-subdued': {
        base: ['bg-[--lsds-c-alert-color-info-subdued]'],
        leftIcon: ['text-[color:--lsds-a-color-icon-default]'],
        title: ['text-[color:--lsds-a-color-text-default]'],
        message: ['text-[color:--lsds-a-color-text-default]'],
        closeButton: ['text-[color:--lsds-a-color-icon-default]'],
      },
      success: {
        base: ['bg-[--lsds-c-alert-color-success-default]'],
        leftIcon: ['text-[color:--lsds-a-color-icon-default]'],
        title: ['text-[color:--lsds-a-color-text-default]'],
        message: ['text-[color:--lsds-a-color-text-default]'],
        closeButton: ['text-[color:--lsds-a-color-icon-default]'],
      },
      'success-subdued': {
        base: ['bg-[--lsds-c-alert-color-success-subdued]'],
        leftIcon: ['text-[color:--lsds-a-color-icon-default]'],
        title: ['text-[color:--lsds-a-color-text-default]'],
        message: ['text-[color:--lsds-a-color-text-default]'],
        closeButton: ['text-[color:--lsds-a-color-icon-default]'],
      },
      warning: {
        base: ['bg-[--lsds-c-alert-color-warning-default]'],
        leftIcon: ['text-[color:--lsds-a-color-icon-default]'],
        title: ['text-[color:--lsds-a-color-text-default]'],
        message: ['text-[color:--lsds-a-color-text-default]'],
        closeButton: ['text-[color:--lsds-a-color-icon-default]'],
      },
      'warning-subdued': {
        base: ['bg-[--lsds-c-alert-color-warning-subdued]'],
        leftIcon: ['text-[color:--lsds-a-color-icon-default]'],
        title: ['text-[color:--lsds-a-color-text-default]'],
        message: ['text-[color:--lsds-a-color-text-default]'],
        closeButton: ['text-[color:--lsds-a-color-icon-default]'],
      },
      critical: {
        base: ['bg-[--lsds-c-alert-color-critical-default]'],
        leftIcon: ['text-[color:--lsds-a-color-icon-on-primary]'],
        title: ['text-[color:--lsds-a-color-text-on-primary]'],
        message: ['text-[color:--lsds-a-color-text-on-primary]'],
        closeButton: ['text-[color:--lsds-a-color-icon-on-primary]'],
      },
      'critical-subdued': {
        base: ['bg-[--lsds-c-alert-color-critical-subdued]'],
        leftIcon: ['text-[color:--lsds-a-color-icon-default]'],
        title: ['text-[color:--lsds-a-color-text-default]'],
        message: ['text-[color:--lsds-a-color-text-default]'],
        closeButton: ['text-[color:--lsds-a-color-icon-default]'],
      },
    },
  },
});
const classes = computed(() =>
  alertVariantStyleConfig({
    variant: props.variant,
    layout: props.layout,
  }),
);

const icon = computed(() => {
  if (props.icon) {
    return props.icon;
  }
  switch (props.variant) {
    case 'info':
    case 'info-subdued':
      return 'lsi-tooltip';
    case 'success':
    case 'success-subdued':
      return 'lsi-success';
    case 'warning':
    case 'warning-subdued':
      return 'lsi-comment-alert';
    case 'critical':
    case 'critical-subdued':
      return 'lsi-alert';
    default:
      return 'lsi-tooltip';
  }
});

provideLscAlert({
  variant: computed(() => props.variant),
});

function close() {
  emit('close');
}
</script>

<template>
  <div :class="classes.base()">
    <div :class="classes.left()">
      <!-- @slot media - The media of the alert. If not provided, the icon prop is used otherwise the default icon is used. -->
      <slot name="media">
        <LscIcon :class="classes.leftIcon()" :icon="icon" size="md" />
      </slot>
      <div :class="classes.leftContent()">
        <div v-if="title" :class="classes.titleWrapper()">
          <h4 :class="classes.title()">{{ title }}</h4>
          <!-- @slot infoTooltip - The tooltip to display when hovering over the title. -->
          <slot name="infoTooltip" />
        </div>

        <div v-if="message || $slots.default" :class="classes.message()">
          <!-- @slot default - The message of the alert. -->
          <slot>{{ message }}</slot>
        </div>
      </div>
    </div>
    <div :class="classes.right()">
      <!-- @slot action - The action of the alert. Prefer to LscAlertButton here -->

      <slot name="action" />
    </div>
    <button
      v-if="closable"
      type="button"
      :aria-label="closeButtonText"
      :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-close` : undefined"
      @click="close"
    >
      <LscIcon size="sm" :class="classes.closeButton()" icon="lsi-close" />
    </button>
  </div>
</template>
