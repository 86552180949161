import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useAxios } from '../base/useAxios';

export function useBrandingActions() {
  const api = useAxios();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  function config() {
    return {
      headers: {
        'Socket-ID': socketId,
      },
    };
  }

  return {
    updateBrandingColor(theme, { useBackgroundColorOnLogin = false } = {}) {
      const promise = api
        .put('/colourthemes/current.json', { color: theme.color, useBackgroundColorOnLogin }, config())
        .then(() => {
          emitRealTimeUpdate({ type: 'branding', action: 'edited' });
        });
      emitOptimisticUpdate({
        promise,
        type: 'branding',
        action: 'update',
        branding: { theme },
      });
      return promise;
    },
  };
}
