import { useAxios } from '../base/useAxios';

export function useFeatureTrialActions() {
  const api = useAxios();

  /**
   * Post start a feature trial
   * @param {object} payload
   * @param {string} payload.name
   * @param {number} payload.numDays
   */
  function startFeatureTrial(payload) {
    const startFeatureTrialUrl = '/account/featuretrial.json';

    const promise = api.post(startFeatureTrialUrl, {
      trial: payload,
    });

    return promise;
  }

  return {
    startFeatureTrial,
  };
}
