<script setup>
import { useI18n } from '@/util';

defineProps({
  /**
   * The data identifier of the button.
   */
  dataIdentifier: {
    type: String,
    required: true,
  },
  /**
   * The size of the button.
   * @type {import('vue').PropType<'sm' | 'lg'>}
   */
  size: {
    type: String,
    default: 'lg',
    validator: (value) => ['sm', 'lg'].includes(value),
  },
});

const { t } = useI18n();
</script>
<template>
  <LscButton variant="plain-primary" :size="size" :data-identifier="dataIdentifier" prependIcon="lsi-feedback">
    {{ t('Send feedback') }}
  </LscButton>
</template>
