import { useFeatures, useCurrentUser, useCurrentAccount, useDeskCurrentUser } from '@/api';

export function useTicketPermissions(client_ = {}) {
  const client = computed(() => unref(client_));

  const { projectsClientTicketsEnabled } = useFeatures();
  const user = useCurrentUser();
  const account = useCurrentAccount();
  const deskUser = useDeskCurrentUser();

  const isDeskEnabled = computed(() =>
    Boolean(account.value?.deskEnabled && (user.value?.inOwnerCompany || !account.value?.strictBranding)),
  );

  const canAccessClientTickets = computed(() =>
    Boolean(projectsClientTicketsEnabled.value && !client.value?.isOwner && isDeskEnabled.value),
  );

  const hasDeskAccount = computed(() => Boolean(deskUser.value));

  return {
    canAccessClientTickets,
    isDeskEnabled,
    hasDeskAccount,
  };
}
