<script setup>
import { useI18n } from '@/util';
import LscDropzoneEmptyState from './assets/LscDropzoneEmptyState.svg';

defineProps({
  /**
   * Whether the overlay should be shown.
   */
  isOverDropZone: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the overlay should stick to the top of the dropzone.
   */
  shouldStickToTop: {
    type: Boolean,
    default: false,
  },
  /**
   * The size of the dropzone.
   * @type {import('vue').PropType<'sm' | 'md' | 'full'>}
   */
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md', 'full'].includes(value),
  },
});

const { t } = useI18n();
</script>

<template>
  <VFadeTransition>
    <div
      v-if="isOverDropZone"
      class="left-0 top-0 z-10 h-full w-full"
      :class="shouldStickToTop ? 'sticky' : 'absolute'"
    >
      <div
        class="absolute inset-0 bg-default"
        :class="{
          'p-4': size === 'full',
        }"
      >
        <div
          class="flex h-full w-full items-center justify-center gap-1 rounded-sm border border-dashed border-form-active"
          :class="{
            'flex-col': size === 'md' || size === 'full',
          }"
        >
          <LscDropzoneEmptyState
            :class="{
              'h-10': size === 'sm',
              'h-20': size === 'md' || size === 'full',
            }"
          />
          <h4 class="mx-2 truncate text-center text-default">
            {{ t('Drag and drop files here') }}
          </h4>
        </div>
      </div>
    </div>
  </VFadeTransition>
</template>
