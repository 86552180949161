import { useLocalStorage } from '@vueuse/core';
import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

const symbol = Symbol('useExperimentE23b');
const launchDarklyFlagKey = 'projects-e-23-23b-invite-user-flow';
const commonMetrics = ['plan_name', 'user_role', 'page', 'timestamp'];

/**
 * https://digitalcrew.teamwork.com/spaces/growth/page/45277-e-23-23b-measurement-plan
 */
function ExperimentE23b(source) {
  const { userInviteV2Enabled } = useFeatures();
  const { trackPendoEvent, trackExperimentInPendo } = usePendo();

  const newSeatsAdded = useLocalStorage('teamwork/useExperimentE23b/newSeatsAdded', 0);

  const isExp23bVariation = computed(() => userInviteV2Enabled.value);
  const isExp23bControl = computed(() => !userInviteV2Enabled.value);

  function trackExperimentE23bEvent(eventName, metadata) {
    trackPendoEvent({
      eventName,
      commonMetrics,
      launchDarklyFlagKey,
      metadata,
    });
  }

  function trackExperimentE23bInviteUsersEvent(action, data) {
    trackExperimentE23bEvent('INVITE_USERS_EVENT', {
      event_action: action,
      invite_source: source,
      ...data,
    });
  }

  function trackExperimentE23b() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue: false,
      ignoreInExperimentOnRuleMatch: true,
    });
  }

  return {
    newSeatsAdded,
    isExp23bControl,
    isExp23bVariation,
    trackExperimentE23b,
    trackExperimentE23bEvent,
    trackExperimentE23bInviteUsersEvent,
  };
}

export function provideExperimentE23b(source) {
  provide(symbol, ExperimentE23b(source));
}

export function useExperimentE23b() {
  return inject(symbol);
}
