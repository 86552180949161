<script setup>
import { useDebounce } from '@vueuse/core';
import { useI18n } from '@/util';
import { useTemplateProjectsV3Loader, useFeatures, useCheckoutActions } from '@/api';
import saveProjectAsTemplateImage from './assets/SaveAsTemplate@2x.png';

const { t, languageCode } = useI18n();
const { projectTemplatesLimit } = useFeatures();
const { goToUpgrade } = useCheckoutActions();

const searchTerm = shallowRef();
const uniqueCategories = shallowRef([]);
const debouncedSearchTerm = useDebounce(searchTerm, 400);

const selectedCategoryId = shallowRef();
const categoryIcon = {
  2: 'lsi-multiple-templates',
  3: 'lsi-multiple-templates',
  4: 'lsi-multiple-templates',
  5: 'lsi-multiple-templates',
  6: 'lsi-multiple-templates',
};

const { items: sampleTemplateProjects } = useTemplateProjectsV3Loader({
  count: shallowRef(100),
  pageSize: 100,
  params: computed(() => ({
    searchTerm: debouncedSearchTerm.value,
    include: 'companies',
    includeProjectUserInfo: true,
    'fields[projects]': 'id,name,isStarred,companyId,startPage',
    orderBy: 'lastWorkedOn',
    orderMode: 'desc',
    onlyStarredProjects: false,
  })),
  getSamples: true,
});

const { items: customTemplateProjects } = useTemplateProjectsV3Loader({
  count: Infinity,
  pageSize: 500,
  params: computed(() => ({
    searchTerm: debouncedSearchTerm.value,
    include: 'companies,users',
    includeProjectUserInfo: true,
    'fields[projects]': 'id,name,isStarred,companyId,startPage,createdBy',
    orderBy: 'lastWorkedOn',
    orderMode: 'desc',
    onlyStarredProjects: false,
  })),
});

const usedCustomTemplatePercentage = computed(() =>
  projectTemplatesLimit.value > 0 ? (customTemplateProjects.value.length / projectTemplatesLimit.value) * 100 : 0,
);
const customTemplatesRemaining = computed(() =>
  projectTemplatesLimit.value > 0 ? projectTemplatesLimit.value - customTemplateProjects.value.length : 0,
);

const infoCalloutTitle = computed(() =>
  projectTemplatesLimit.value === customTemplateProjects.value.length
    ? t("You've reached the {n} template limit", { n: projectTemplatesLimit.value })
    : t("You've used {usage} of {limit} templates", {
        usage: customTemplateProjects.value.length,
        limit: projectTemplatesLimit.value,
      }),
);

function formatUsedByCount(count) {
  const formatter = new Intl.NumberFormat(languageCode.value, {
    notation: 'compact',
    compactDisplay: 'short',
  });
  return formatter.format(count);
}

function openLearnMoreLink() {
  window.open('https://support.teamwork.com/projects/project-templates/creating-project-templates', '_blank');
}

const unwatch = watch(sampleTemplateProjects, () => {
  const result = [];
  const map = new Map();
  for (const project of sampleTemplateProjects.value) {
    if (!map.has(project.category.id)) {
      map.set(project.category.id, true);
      result.push({ ...project.category, prependIcon: categoryIcon[project.category.id] });
    }
  }
  uniqueCategories.value = result;
  unwatch();
});
</script>
<template>
  <WidgetDialogPanel :title="t('Choose template')">
    <template #before-header-close-btn>
      <LscButton
        variant="plain-primary"
        prependIcon="lsi-feedback"
        data-identifier="templates-gallery-feedback-button"
        class="mr-6"
      >
        {{ t('Leave feedback') }}
      </LscButton>
      <LscSearchBar v-model="searchTerm" class="mr-12 max-w-80" :placeholder="t('Template name or creator')" />
    </template>
    <div class="-mx-6 flex h-full overflow-hidden border-0 border-t border-solid border-t-separator">
      <div class="flex shrink-0 basis-96 flex-col border-0 border-r border-solid border-r-separator">
        <div class="px-8 py-6">
          <LscButton prependIcon="lsi-add" variant="primary">{{ t('Create a template') }}</LscButton>
        </div>
        <div class="grow overflow-y-auto border-0 border-b border-solid border-b-separator">
          <div class="mb-8">
            <div class="ml-8 uppercase text-subtle">{{ t('Custom Templates') }}</div>
            <LscSidebar>
              <LscSidebarItem :label="t('All templates')" :active="true" prependIcon="lsi-multiple-templates">
                {{ t('All templates') }}
                <template v-if="customTemplateProjects?.length" #append>
                  <LscBadge :count="customTemplateProjects.length" variant="contrast" />
                </template>
              </LscSidebarItem>
              <LscSidebarItem :label="t('Design Team')" :active="false" prependIcon="lsi-single-template">
                Design Team
                <template #append>
                  <WidgetOptionsMenu class="!ml-2">
                    <WidgetOptionsMenuItem :text="t('Rename')" :prepend="{ icon: 'lsi-edit' }" />
                    <WidgetOptionsMenuItem :text="t('Delete')" :prepend="{ icon: 'lsi-delete' }" critical />
                  </WidgetOptionsMenu>
                  <LscBadge :count="0" />
                </template>
              </LscSidebarItem>
            </LscSidebar>

            <div class="ml-8">
              <LscButton prependIcon="lsi-add" variant="plain-secondary">{{ t('Add folder') }}</LscButton>
            </div>
          </div>

          <div class="mb-6">
            <div class="ml-8 uppercase text-subtle">Teamwork templates</div>
            <LscSidebar>
              <LscSidebarItem
                v-for="category in uniqueCategories"
                :key="category.id"
                :label="category.name"
                :prependIcon="category.prependIcon"
                :active="category.id === selectedCategoryId"
                @click="selectedCategoryId = category.id"
              >
                {{ category.name }}
                <template v-if="category.count" #append>
                  <LscBadge :count="category.count" />
                </template>
              </LscSidebarItem>
            </LscSidebar>
          </div>
        </div>
        <div v-if="projectTemplatesLimit > 0" class="mx-6 my-4 flex items-center">
          <LscProgressCircular :percentage="usedCustomTemplatePercentage" size="md" />
          <div class="ml-2">
            <div class="text-body-2 font-semibold leading-1">
              {{ t('{count} templates created', { count: customTemplateProjects.length }) }}
            </div>
            <div class="flex items-center">
              <span class="text-body-2 leading-1">
                {{ t('{count} templates remaining', { count: customTemplatesRemaining }) }}
              </span>
              <LscInfoCallout
                icon="lsi-multiple-templates"
                :title="infoCalloutTitle"
                size="md"
                :primaryButtonText="t('Upgrade')"
                :secondaryButtonText="t('Learn more')"
                openOnHover
                @primaryAction="goToUpgrade('TemplateLimit')"
                @secondaryAction="openLearnMoreLink"
              >
                <template #activator="activator">
                  <LscIcon v-bind="activator.props" icon="lsi-tooltip" class="ml-2 text-icon-subtle" />
                </template>
                <template #message>
                  {{
                    t('You can create up to {n} templates on your current plan. Upgrade to create more templates', {
                      n: projectTemplatesLimit,
                    })
                  }}
                </template>
              </LscInfoCallout>
            </div>
          </div>
        </div>
      </div>
      <div class="grow overflow-y-scroll pb-32 pl-8 pr-5">
        <h3 class="py-6 text-h3">{{ t('Custom templates') }}</h3>
        <div class="mb-4 flex items-center">
          <LscIcon icon="lsi-assignee" />
          <span class="ml-2 text-subtitle-2 font-semibold text-default">{{ t('All templates') }}</span>
        </div>

        <div class="mb-8 flex flex-wrap gap-6">
          <template v-for="template in customTemplateProjects" :key="template.id">
            <VLazy>
              <LscCard class="w-[380px]">
                <template #header>
                  <LscCardMediaHeader>
                    <template #media>
                      <LscCardMediaImage src="https://placehold.co/380x380.png" title="some title" alt="alt title" />
                    </template>
                  </LscCardMediaHeader>
                </template>
                <template #body>
                  <div class="flex flex-col p-4">
                    <div class="mb-1 text-subtitle-2 font-bold text-default">{{ template.name }}</div>
                    <div class="flex items-center">
                      <LscIcon icon="lsi-assignee" />
                      <span class="ml-2 text-body-1 text-default">{{ t('Created by') }} John Doe</span>
                    </div>
                  </div>
                </template>
              </LscCard>
            </VLazy>
          </template>
        </div>

        <LscAlert
          variant="info-subdued"
          :title="t('No custom templates')"
          :message="t('Custom templates help you and your teams create new projects in seconds.')"
          class="mb-8"
        >
          <template #media>
            <img src="https://placehold.co/120x120" class="h-[60px]" />
          </template>
          <template #infoTooltip>
            <LscInfoCallout
              :title="t('Save a project as a template')"
              :message="t('You can save any project as a template from the menu beside the project name')"
              location="bottom center"
              offset="4"
              size="lg"
              openOnHover
            >
              <template #activator="activator">
                <LscIcon
                  v-bind="activator.props"
                  class="pointer-events-auto h-full cursor-pointer text-icon-subtle"
                  size="sm"
                  icon="lsi-tooltip"
                />
              </template>
              <template #image>
                <img :src="saveProjectAsTemplateImage" class="h-auto w-full" />
              </template>
            </LscInfoCallout>
          </template>
          <template #action>
            <LscButton variant="primary" prependIcon="lsi-add">
              {{ t('Create a template') }}
            </LscButton>
          </template>
        </LscAlert>

        <div class="mb-8 border-0 border-t border-solid border-t-separator" />

        <h3 class="mb-6 text-h3">{{ t('{teamwork} templates', { teamwork: 'Teamwork.com' }) }}</h3>
        <template v-for="category in uniqueCategories" :key="category.id">
          <div class="mb-4 flex items-center">
            <LscIcon icon="lsi-assignee" />
            <span class="ml-2 text-subtitle-2 font-semibold text-default">{{ category.name }}</span>
          </div>

          <div class="mb-8 flex flex-wrap gap-6">
            <template v-for="template in sampleTemplateProjects" :key="template.id">
              <template v-if="category.id === template.categoryId">
                <VLazy>
                  <LscCard class="w-[380px]">
                    <template #header>
                      <LscCardMediaHeader>
                        <template #media>
                          <LscCardMediaImage
                            src="https://placehold.co/380x380.png"
                            title="some title"
                            alt="alt title"
                          />
                        </template>
                        <template #overlay>
                          <div class="h-full bg-default">
                            <LscIcon icon="lsi-app-teamwork" class="h-auto w-full" />
                          </div>
                        </template>
                      </LscCardMediaHeader>
                    </template>
                    <template #body>
                      <div class="flex flex-col p-4">
                        <div class="mb-1 text-subtitle-2 font-bold text-default">{{ template.name }}</div>
                        <div class="flex items-center">
                          <LscIcon icon="lsi-assignee" />
                          <span class="ml-2 text-body-1 text-default">
                            {{ t('Used by {count} users', { count: formatUsedByCount(2000) }) }}
                          </span>
                        </div>
                      </div>
                    </template>
                  </LscCard>
                </VLazy>
              </template>
            </template>
          </div>
        </template>
      </div>
    </div>
  </WidgetDialogPanel>
</template>
