<script setup>
import { debouncedRef } from '@vueuse/core';
import { useProjectsV3Loader } from '@/api';
import { useI18n } from '@/util';
import PickersNoStarredProjectsFound from '../../../assets/PickersNoStarredProjectsFound.svg';
import PickersNoProjectsFound from '../../../assets/PickersNoProjectsFound.svg';

const props = defineProps({
  modelValue: {
    type: Object,
    default: null,
  },
  autofocus: {
    type: Boolean,
    deafult: false,
  },
  disabled: {
    type: Boolean,
    deafult: false,
  },
  label: {
    type: String,
    default: '',
  },
  /**
   * Extra params to pass to the useProjectsV3Loader
   */
  loaderParams: {
    type: Object,
    default: () => ({}),
  },
  hideDetails: {
    type: Boolean,
    default: true,
  },
  labelTooltip: {
    type: String,
    default: '',
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessages: {
    type: Array,
    default: () => [],
  },
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
  includeRecentTab: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const { t } = useI18n();

const searchTerm = shallowRef('');
const debouncedSearchTerm = debouncedRef(searchTerm, 300);
const count = shallowRef(10);
const pageSize = shallowRef(10);
const selectedTabId = shallowRef(props.includeRecentTab ? 'recent' : 'all');

const tabs = computed(() => [
  ...(props.includeRecentTab
    ? [
        {
          id: 'recent',
          label: t('Recent'),
          dataIdentifier: props.dataIdentifierPrefix
            ? `${props.dataIdentifierPrefix}-project-picker-recent-tab`
            : undefined,
        },
      ]
    : []),
  {
    id: 'starred',
    label: t('Starred'),
    dataIdentifier: props.dataIdentifierPrefix ? `${props.dataIdentifierPrefix}-project-picker-starred-tab` : undefined,
  },
  {
    id: 'all',
    label: t('All'),
    dataIdentifier: props.dataIdentifierPrefix ? `${props.dataIdentifierPrefix}-project-picker-all-tab` : undefined,
  },
]);

const state = useProjectsV3Loader({
  count,
  pageSize,
  params: computed(() => ({
    searchTerm: debouncedSearchTerm.value,
    includeProjectUserInfo: true, // needed for isStarred
    'fields[projects]': 'id,name,isStarred,companyId,allowNotifyAnyone,notifyTaskAssignee,isBillable',
    orderBy: selectedTabId.value === 'recent' ? 'lastWorkedOn' : 'companyname',
    orderMode: selectedTabId.value === 'recent' ? 'desc' : 'asc',
    onlyProjectsWithExplicitMembership: 1,
    searchCompanies: true,
    onlyStarredProjects: Boolean(!debouncedSearchTerm.value && selectedTabId.value === 'starred'),
    ...props.loaderParams,
    include: (props.loaderParams.include?.split(',') ?? []).concat('companies').join(','),
  })),
});

const shouldShowCompanyHeaders = computed(() => Boolean(searchTerm.value || selectedTabId.value === 'all'));

const { items: projects, loaded, inSync } = state;

const emptyStateTitle = computed(() => {
  if (searchTerm.value) {
    return t('There are no projects that match your search');
  }
  if (selectedTabId.value === 'recent') {
    return t('No recent projects');
  }
  if (selectedTabId.value === 'starred') {
    return t('No starred projects');
  }
  return t('No projects');
});

const emptyStateDescription = computed(() => {
  if (searchTerm.value) {
    return t('Please try again with a different term');
  }
  if (selectedTabId.value === 'recent') {
    return t('Once you start interacting with projects, they will be shown here');
  }
  if (selectedTabId.value === 'starred') {
    return t('Star any project and you can easily access it from this tab');
  }
  return t('Add some projects and they’ll be displayed here');
});

const items = computed(() => {
  const results = [];
  // Creates required structure for vautocomplete with company header separating projects
  for (let i = 0; i < projects.value.length; i++) {
    if (
      shouldShowCompanyHeaders.value &&
      (i === 0 || projects.value[i - 1].companyId !== projects.value[i].companyId)
    ) {
      results.push({ header: projects.value[i].company.name });
    }
    results.push(projects.value[i]);
  }
  return results;
});

/**
 * Prevent Vuetify attempting to search for a nullish name and handle null modelValue / modelValue.id
 */
const modelValue = computed({
  get() {
    if (props.modelValue?.id) {
      return {
        id: props.modelValue.id,
        name: props.modelValue.name || '',
      };
    }
    return null;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const clearable = computed(() => Boolean(props.modelValue || searchTerm.value));

const shouldShowEmptyState = computed(() => loaded.value && projects.value.length === 0 && inSync.value);

function onSearch(val) {
  if (val.trim() === props.modelValue?.name) {
    return;
  }
  searchTerm.value = val.trim();
}
</script>

<template>
  <VAutocomplete
    v-bind="VAutocompleteLswPickers"
    v-model="modelValue"
    :autofocus="autofocus"
    :items="items"
    :disabled="disabled"
    :error="error"
    :errorMessages="errorMessages"
    noFilter
    returnObject
    itemValue="id"
    itemTitle="name"
    :clearable="clearable"
    clearIcon="lsi-clear"
    :menuProps="{
      ...VAutocompleteLswPickers.menuProps,
      class: {
        [VAutocompleteLswPickers.menuProps.class]: true,
        'VAutocompleteLswPickersMenu--has-tabs': !searchTerm,
      },
    }"
    openOnClear
    :loading="!inSync"
    :label="label || t('Select a project')"
    @update:search="onSearch"
    @update:menu="onSearch('')"
  >
    <template #prepend-item>
      <div class="advanced-options">
        <LscTabs v-if="!searchTerm" variant="secondary">
          <template #items>
            <LscTab
              v-for="tab in tabs"
              :key="tab.id"
              :item="tab"
              :active="tab.id === selectedTabId"
              @click="selectedTabId = tab.id"
            />
          </template>
        </LscTabs>
      </div>
    </template>
    <template #item="{ item, props: { onClick } }">
      <VListItem v-if="item.value.header" class="v-list-item--header">
        <VListItemTitle class="mb-0">
          <LscOverflowEllipsis class="w-full font-semibold">
            {{ item.value.header }}
          </LscOverflowEllipsis>
        </VListItemTitle>
      </VListItem>
      <VListItem v-else v-bind="{ onClick }" :active="modelValue?.id === item.value">
        <VListItemTitle class="flex items-center gap-2">
          <LscIcon class="shrink-0" icon="lsi-project" />
          <p class="flex w-full items-center gap-1 overflow-hidden" :class="{ 'pr-7': !item.raw.isStarred }">
            <LscOverflowEllipsis class="text-body-1">
              {{ item.title }}
            </LscOverflowEllipsis>
            <LscOverflowEllipsis
              v-if="!shouldShowCompanyHeaders"
              class="mt-0.5 shrink-0 grow basis-1/4 text-body-2 text-subtle"
            >
              {{ item.raw.company.name }}
            </LscOverflowEllipsis>
          </p>
          <LscIcon
            v-if="item.raw.isStarred"
            class="shrink-0 !text-[color:--project-starred-color]"
            icon="lsi-favorite"
          />
        </VListItemTitle>
      </VListItem>
    </template>
    <template #append-item>
      <WidgetLoadingLazy v-model:count="count" :state="state" :step="pageSize" />
    </template>
    <template #append-inner>
      <div v-if="labelTooltip && !disabled" v-LsdTooltip="labelTooltip" class="flex items-center">
        <LscIcon class="pointer-events-auto cursor-pointer text-icon-subtle" size="sm" icon="lsi-tooltip" />
      </div>
    </template>
    <template #no-data>
      <LscEmptyState
        v-if="shouldShowEmptyState"
        size="md"
        class="mt-5"
        :title="emptyStateTitle"
        :message="emptyStateDescription"
      >
        <template v-if="!searchTerm" #image>
          <LscSlotSwitch :name="selectedTabId">
            <template #recent>
              <PickersNoProjectsFound />
            </template>
            <template #starred>
              <PickersNoStarredProjectsFound />
            </template>
            <template #all>
              <PickersNoProjectsFound />
            </template>
          </LscSlotSwitch>
        </template>
      </LscEmptyState>
    </template>
  </VAutocomplete>
</template>
