<script setup>
import { provideKeyboardShortcut } from '@/util';

defineOptions({
  inheritAttrs: false,
});

defineProps({
  /**
   * Controls focus retention on dialog's first child.
   * Set to false for compatibility with external tools like Pendo.
   * @see https://vuetifyjs.com/en/api/v-dialog/#props-retain-focus
   */
  retainFocus: {
    type: Boolean,
    default: true,
  },
});

const modelValue = defineModel({
  type: Boolean,
  default: false,
});

const { shouldCloseOnClickOutside } = provideLsDialog();
provideKeyboardShortcut(modelValue);

function close() {
  modelValue.value = false;
}

let clickOutsideEvent = null;

function handleClickOutside(event) {
  clickOutsideEvent = event;
  nextTick(() => {
    clickOutsideEvent = null;
  });
}

const modelValueFiltered = computed({
  get() {
    return modelValue.value;
  },
  set(value) {
    /**
     * Do not close the dialog:
     * - when a toast was clicked.
     * - when pendo guide is clicked.
     * - when shouldCloseOnClickOutside is false.
     */
    if (clickOutsideEvent?.target.closest('.LscToast, #pendo-guide-container') || !shouldCloseOnClickOutside.value) {
      return;
    }
    modelValue.value = value;
  },
});
</script>

<template>
  <VThemeProvider theme="main">
    <VDialog
      v-model="modelValueFiltered"
      class="WidgetDialog"
      :scrollable="true"
      :retainFocus="retainFocus"
      v-bind="$attrs"
      @click:outside="handleClickOutside"
    >
      <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
      <slot name="default" :close="close" />
    </VDialog>
  </VThemeProvider>
</template>

<style>
.WidgetDialog.v-overlay > .v-overlay__content {
  width: auto;
}
.WidgetDialog.v-overlay.alignLeft > .v-overlay__content {
  left: 0;
}
</style>
