import { useFeatures } from '../features/useFeatures';
import { useCurrentUser } from '../user/useCurrentUser';
import { usePendo } from '../features/usePendo';

/**
 * Constants, computed properties and functions related to experiment A15
 * https://digitalcrew.teamwork.com/spaces/growth/page/42757-a15-create-a-worker-bee-ui-focused-on-logging-time
 * https://app.launchdarkly.com/default/production/features/projects-exp-a-23-15-worker-bee-log-time-ctas/targeting
 */
export function useExperimentA15() {
  const user = useCurrentUser();
  const { workerBeeLogTimeCtaEnabled } = useFeatures();
  const { trackExperimentInPendo } = usePendo();
  const { launchDarklyFlagKey: EXP_A15_FLAG_KEY, defaultValue } = workerBeeLogTimeCtaEnabled;
  const WORKER_BEE_ROLES = [5, 6];
  const EXP_A15_COMMON_METRICS = ['account_id', 'on_trial', 'plan_name', 'user_role', 'page'];
  const ignoreInExperimentOnRuleMatch = true;

  const expA15AppLevelTargeting = computed(() => WORKER_BEE_ROLES.includes(user.value?.companyRoleId));
  const isExpA15Variation = computed(() => workerBeeLogTimeCtaEnabled.value && expA15AppLevelTargeting.value);
  const isExpA15Control = computed(() => !isExpA15Variation.value);

  function trackExperimentA15() {
    trackExperimentInPendo({
      launchDarklyFlagKey: EXP_A15_FLAG_KEY,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: expA15AppLevelTargeting,
    });
  }

  return {
    EXP_A15_COMMON_METRICS,
    EXP_A15_FLAG_KEY,
    expA15AppLevelTargeting,
    isExpA15Variation,
    isExpA15Control,
    trackExperimentA15,
  };
}
