<script setup>
// This component makes lazy loading trivial.
//
// It renders a single DOM node and requests more items when it becomes visible.
// It only ever sets `count` to positive integers.
// It prevents excessive loading after the loader state is reset.
//
// Usage: <WidgetLoadingLazy v-model:count="loaderCount" :state="loaderState" />

import { useIntersectionObserver } from '@vueuse/core';

const props = defineProps({
  // The `state` object from `useListLoader`.
  state: {
    type: Object,
    required: true,
  },
  // The increment value for `count`.
  step: {
    type: Number,
    default: 5,
  },
  // The min value for `count`.
  minCount: {
    type: Number,
    default: 20,
  },
});

// The `count` param for `useListLoader`.
const count = defineModel('count', {
  type: Number,
  required: true,
});

const step = computed(() => Math.max(props.step, 1));
const minCount = computed(() => Math.max(props.minCount, 1));
const items = computed(() => props.state.items.value);

const visible = shallowRef(false);
const rootElement = shallowRef();
let handle = null;

useIntersectionObserver(
  rootElement,
  (entries) => {
    const isVisible = entries[entries.length - 1].isIntersecting;
    if (visible.value !== isVisible) {
      visible.value = isVisible;
    }
  },
  { threshold: 0 },
);

watch(
  [count, step, items, visible],
  () => {
    cancelAnimationFrame(handle);

    // Ensure we never request too many items.
    const maxCount = Math.max(items.value.length + step.value, minCount.value);
    if (count.value > maxCount) {
      count.value = maxCount;
      return;
    }

    // Wait a bit to ensure that new items are rendered before requesting more.
    handle = requestAnimationFrame(() => {
      handle = requestAnimationFrame(() => {
        handle = requestAnimationFrame(() => {
          if (visible.value) {
            count.value = Math.max(items.value.length + step.value, minCount.value);
          }
        });
      });
    });
  },
  { immediate: true },
);
</script>

<template>
  <div ref="rootElement" class="min-h-px min-w-px" />
</template>
