<script setup>
import { useExperimentA27 } from '@/api';
import { useI18n } from '@/util';
import { useLegacyFrameOffset } from '@/module/legacy';
import { useSampleProjects } from './useSampleProjects';

const { trackDummyDataEvent } = useExperimentA27();
const { t } = useI18n();
const {
  isSampleProjectsBannerVisible,
  areSampleProjectsVisible,
  dismissSampleProjectsBanner,
  deleteSampleData,
  restoreSampleProjects,
} = useSampleProjects();

const bannerEl = shallowRef(null);

useLegacyFrameOffset(bannerEl);

async function handleCTAButtonClicked() {
  if (areSampleProjectsVisible.value) {
    await deleteSampleData();

    trackDummyDataEvent('hide_sample_projects_clicked');
  } else {
    await restoreSampleProjects();

    trackDummyDataEvent('show_sample_projects_clicked');
  }
}

function handleCloseButtonClicked() {
  dismissSampleProjectsBanner();

  trackDummyDataEvent('sample_projects_banner_dismissed');
}
</script>

<template>
  <LscAlert
    v-if="isSampleProjectsBannerVisible"
    ref="bannerEl"
    :closable="!areSampleProjectsVisible"
    layout="banner"
    :message="
      areSampleProjectsVisible
        ? t('You are currently using sample data to explore {teamwork}.', { teamwork: 'Teamwork.com' })
        : t('Sample data has been hidden. Unhide with a single click.')
    "
    @close="handleCloseButtonClicked"
  >
    <template #action>
      <LscAlertButton @click="handleCTAButtonClicked">
        {{ areSampleProjectsVisible ? t('Hide sample data') : t('Show sample data') }}
      </LscAlertButton>
    </template>
  </LscAlert>
</template>
