import { useItemLoader } from '../base/useItemLoader';

function responseToItem({ data: { account } }) {
  return account;
}

/**
 * Loads the current account.
 *
 * Please note that this endpoint is not fully ported yet and should not be widely used.
 * The reason for its current inclusion and usage is to support the `/shared` routes
 * within the app
 */
export function useCurrentAccountV3Loader({ params } = {}) {
  const { state } = useItemLoader({
    url: '/projects/api/v3/account.json',
    params,
    responseToItem,
  });

  return state;
}
