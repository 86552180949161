<script setup>
import { tv } from '../../../util/tailwind/tv';
import { useLscAlert } from './useLscAlert';

defineProps({
  prependIcon: {
    type: String,
    default: undefined,
  },
  appendIcon: {
    type: String,
    default: undefined,
  },
});
const { variant } = useLscAlert();

const alertButtonVariantStyleConfig = tv({
  base: [
    'flex items-center gap-1 whitespace-nowrap rounded-full font-semibold',
    'text-button-1',
    'px-[--lsds-c-button-spacing-outer-md]',
    'h-[--lsds-c-button-size-md]',
    'gap-[--lsds-c-button-spacing-gap-md]',
    'text-[color:--lsds-a-color-text-default]',
    'bg-[color:--lsds-c-alert-button-color-bg-default] hover:bg-[color:--lsds-c-alert-button-color-bg-hover]',
  ],
  variants: {
    variant: {
      critical: 'text-[color:--lsds-a-color-text-on-primary]',
    },
  },
});

const classes = computed(() => alertButtonVariantStyleConfig({ variant: variant.value }));
</script>

<template>
  <button type="button" :class="classes">
    <slot name="prepend">
      <LscIcon v-if="prependIcon" :icon="prependIcon" size="sm" />
    </slot>
    <slot />
    <slot name="append">
      <LscIcon v-if="appendIcon" :icon="appendIcon" size="sm" />
    </slot>
  </button>
</template>
