<script setup>
import { providePermissions } from '../permissions/usePermissions';
import { providePreferences } from '../preferences/usePreferences';

providePermissions();
providePreferences();
</script>

<template>
  <slot />
</template>
