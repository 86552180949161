<script setup>
const props = defineProps({
  client: {
    type: Object,
    default: () => ({}),
  },
});

const ClientAddDialogPanel = defineAsyncComponent(() => import('./ClientAddEditDialogPanel.vue'));
</script>

<template>
  <WidgetDialog width="600px">
    <template #default="{ close }">
      <ClientAddDialogPanel v-bind="props" @close="close" />
    </template>
  </WidgetDialog>
</template>
