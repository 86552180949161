<script setup>
import { provideKeyboardShortcut } from '@/util';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  preventUserSelect: {
    type: Boolean,
    default: true,
  },
  preventParentMenuClose: {
    type: Boolean,
    default: false,
  },
});

const attrs = useAttrs();
const modelValue = defineModel({ type: Boolean, default: false });
provideKeyboardShortcut(modelValue);

// Handle click outside.
let clickOutsideEvent = null;
const modelValueFiltered = computed({
  get() {
    return modelValue.value;
  },
  set(value) {
    if (clickOutsideEvent?.target.closest('.LscToast')) {
      // Do not close the dialog when a toast was clicked.
      return;
    }
    if (props.preventParentMenuClose && !clickOutsideEvent && value === false) {
      return;
    }
    modelValue.value = value;
  },
});

function handleClickOutside(event) {
  clickOutsideEvent = event;
  nextTick(() => {
    clickOutsideEvent = null;
  });
}

const menu = ref();

function updateLocation() {
  if (!menu.value) {
    return;
  }

  menu.value.updateLocation();
}

defineExpose({
  updateLocation,
  contentEl: computed(() => menu.value?.contentEl),
});
</script>

<template>
  <VMenu
    ref="menu"
    v-model="modelValueFiltered"
    v-bind="attrs"
    :retainFocus="false"
    :openOnFocus="false"
    :class="preventUserSelect ? 'select-none' : 'select-auto'"
    @click:outside="handleClickOutside"
  >
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <slot name="default" />
  </VMenu>
</template>
