export const SCHEDULED_REPORTS_FEATURE_KEY = 'schedulereporting';
export const RETAINER_BUDGETS_FEATURE_KEY = 'retainerbudgets';
export const TASKLIST_BUDGETS_FEATURE_KEY = 'tasklistbudgets';
export const PROFITABILITY_FEATURE_KEY = 'profitability';
export const FINANCIAL_BUDGETS_FEATURE_KEY = 'projectfinancialbudgets';
export const TIME_BUDGETS_FEATURE_KEY = 'projecttimebudgets';
export const BUDGETS_FEATURE_KEY = 'budgets';
export const CUSTOM_REPORTS_FEATURE_KEY = 'customreporting';
export const COMPANY_TIMESHEETS_FEATURE_KEY = 'companytimesheets';
export const BUDGET_EXPENSES_FEATURE_KEY = 'projectbudgetexpenses';
export const CUSTOM_FIELDS_FEATURE_KEY = 'customfields';
export const PROOFS_FEATURE_KEY = 'proofs';
export const INTAKE_FORMS_FEATURE_KEY = 'intakeforms';
export const INTAKE_FORMS_LOGIC_FEATURE_KEY = 'intakeformsconditionallogic';
export const INTAKE_FORMS_ADVANCED_LOGIC_FEATURE_KEY = 'intakeformsadvancedconditionallogic';
export const PROJECT_TEMPLATES_FEATURE_KEY = 'projecttemplates';
export const ACTIVE_PROJECT_LIMIT_FEATURE_KEY = 'activeprojectlimit';
export const TIME_APPROVALS_FEATURE_KEY = 'timeapprovals';
export const WORKLOAD_PLANNER_FEATURE_KEY = 'workloadplanner';
export const CALENDAR_LOG_ON_GOOGLE_EVENT_FEATURE_KEY = 'googlecalendarlogtimeevents';
export const CALENDAR_READ_FEATURE_KEY = 'googlecalendarreadevents';
export const CALENDAR_EDIT_FEATURE_KEY = 'googlecalendareditevents';
export const CALENDAR_CREATE_TIME_BLOCK_FEATURE_KEY = 'timeblocks';
export const CALENDAR_LOG_ON_TIME_BLOCK_FEATURE_KEY = 'logtimeblocks';

export const SCHEDULED_REPORTS_FEATURE_TRIAL_LENGTH = 7;
export const RETAINER_BUDGETS_FEATURE_TRIAL_LENGTH = 7;
export const TASKLIST_BUDGETS_FEATURE_TRIAL_LENGTH = 7;
export const PROFITABILITY_FEATURE_TRIAL_LENGTH = 7;
export const CUSTOM_REPORTS_FEATURE_TRIAL_LENGTH = 7;
export const COMPANY_TIMESHEETS_FEATURE_TRIAL_LENGTH = 7;

export const CUSTOM_REPORTS_GROW_FEATURE_LIMIT_VALUE = 2;

export const GROW_PLAN_ID = 69;
