<script setup>
import { tv } from '../../../util/tailwind/tv';

const props = defineProps({
  /**
   * The variant of the badge.
   * @type {import('vue').PropType<'highlight' | 'neutral' | 'surface-emphasis' | 'contrast'>}
   */
  variant: {
    type: String,
    default: 'neutral',
    validator: (text) => ['highlight', 'neutral', 'surface-emphasis', 'contrast'].includes(text),
  },
  /**
   * The count of the badge.
   */
  count: {
    type: Number,
    default: 0,
  },
});

const badgeVariantStyleConfig = tv({
  base: ['flex h-5 w-fit min-w-5 items-center justify-center px-1', 'rounded-full', 'text-button-2'],
  variants: {
    variant: {
      highlight: ['bg-[--lsds-a-color-surface-accent]', 'text-[color:--lsds-a-color-text-default]'],
      neutral: ['bg-[--lsds-a-color-surface-selected]', 'text-[color:--lsds-a-color-text-subtle]'],
      'surface-emphasis': ['bg-[--lsds-a-color-surface-emphasis-selected]', 'text-[color:--lsds-a-color-text-primary]'],
      contrast: ['bg-default', 'text-subtle'],
    },
  },
});

const classes = computed(() => badgeVariantStyleConfig(props));
const truncatedCount = computed(() => (props.count > 99 ? '99+' : props.count));
</script>

<template>
  <div :class="classes">
    <slot>{{ truncatedCount }}</slot>
  </div>
</template>
