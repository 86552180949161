<script setup>
import { useI18n, createFileInput } from '@/util';
import LscDropzoneEmptyState from './assets/LscDropzoneEmptyState.svg';

const props = defineProps({
  error: {
    type: Boolean,
    default: false,
  },
  isOverDropZone: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  message: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md', 'full'].includes(value),
  },
});

const emit = defineEmits(['change']);
const { t } = useI18n();

let destroyFileInputFn = null;

function onFileChange(event) {
  emit('change', event.target.files);
}

function selectFiles() {
  const { input, destroy } = createFileInput({ multiple: props.multiple });
  input.onchange = onFileChange;
  input.click(); // open file dialog

  destroyFileInputFn = destroy;
}

onBeforeUnmount(() => {
  if (destroyFileInputFn) {
    destroyFileInputFn();
  }
});
</script>
<template>
  <div
    class="border-d relative flex cursor-pointer flex-col items-center justify-center rounded-sm border border-dashed border-form-default bg-default transition hover:border-form-hover hover:bg-hover active:border-form-active active:bg-selected"
    :class="{
      '!border-critical-default !bg-default': error,
      'min-h-20': size === 'sm',
      'min-h-36': size === 'md',
      'h-full': size === 'full',
      '!border-form-active !bg-selected': isOverDropZone,
    }"
    role="button"
    @click="selectFiles"
  >
    <template v-if="!isOverDropZone">
      <h4 class="mb-0 text-body-1 font-semibold" :class="error ? 'text-critical' : 'text-default'">
        {{ title || t('Drag and drop your file here or click to browse') }}
      </h4>
      <p
        class="text-center text-body-2"
        :class="[
          error ? 'text-critical' : 'text-subtle',
          {
            'max-w-96': size === 'md' || size === 'full',
          },
        ]"
      >
        {{ message || t('Supported formats include .pdf, .doc, .docx, .png, .jpg') }}
      </p>
    </template>
    <template v-else>
      <div
        class="flex items-center justify-center gap-2"
        :class="{
          'flex-col': size === 'md' || size === 'full',
        }"
      >
        <LscDropzoneEmptyState
          :class="{
            'h-10': size === 'sm',
            'h-20': size === 'md' || size === 'full',
          }"
        />
        <h4 class="text-center text-body-1 text-default">
          {{ title || t('Drop your files here') }}
        </h4>
      </div>
    </template>
  </div>
</template>
