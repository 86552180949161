<script setup>
import { useCurrentUser, useProjectActions } from '@/api';
import { useAppShellSidebar } from '@/appShell';
import { useCurrentProject } from '@/route';
import { useI18n } from '@/util';

defineProps({
  projects: {
    type: Array,
    required: true,
  },
});

const { t } = useI18n();
const { clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();
const { starProject, unstarProject } = useProjectActions();
const currentProject = useCurrentProject();

const user = useCurrentUser();

function toggleProjectStarred(project) {
  if (project.isStarred) {
    unstarProject(project);
    return;
  }
  starProject(project);
}
</script>

<template>
  <VListItem
    v-for="project in projects"
    :key="project.id"
    :to="`/projects/${project.id}`"
    :active="currentProject?.id === project.id"
    v-bind="VListItemSidebarProjectsDrawer"
    tabindex="0"
    @click="clearActiveDrawerPanelIfNotPinned"
  >
    <template #prepend>
      <button
        v-LsdTooltip="project.isStarred ? t('Remove from Starred') : t('Add to Starred')"
        class="mr-2 flex items-center justify-center"
        type="button"
        :aria-label="project.isStarred ? t('Remove from Starred') : t('Add to Starred')"
        @click.stop.prevent="() => toggleProjectStarred(project)"
      >
        <LscIcon
          size="md"
          :class="project.isStarred ? 'text-[color:--project-starred-color]' : 'text-icon-subtle'"
          :icon="project.isStarred ? 'lsi-favorite' : 'lsi-favorite-unselected'"
        />
      </button>
    </template>
    <VListItemTitle :title="project.name" class="text-body-1">{{ project.name }}</VListItemTitle>
    <VListItemSubtitle
      v-if="project.company?.name && user.companyId !== project.companyId"
      :title="project.company?.name"
      class="text-body-2"
    >
      {{ project.company.name }}
    </VListItemSubtitle>
  </VListItem>
</template>
