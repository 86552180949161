<script setup>
import { useFeatures } from '@/api';
import { useI18n } from '@/util';
import { useAppShellSidebar } from './useAppShellSidebar';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['click']);

const { t } = useI18n();
const { isItemActive, isSidebarExpanded, setItemPinned } = useAppShellSidebar();
const { commandCenterEnabled } = useFeatures();

const navItemRef = shallowRef(null);

const tooltip = computed(() =>
  isSidebarExpanded.value || props.isActive ? undefined : props.item.tooltip ?? props.item.text,
);

const ariaAttributeKey = computed(() => (props.item.path ? 'aria-current' : 'aria-pressed'));

const isActive = computed(() => props.isActive || isItemActive(props.item));

const ariaAttributeValue = computed(() => {
  if (props.item.path) {
    return isActive.value ? 'page' : undefined;
  }
  return isActive.value ? 'true' : 'false';
});

const canUnpinItem = computed(() => Boolean(!props.item.fixed && isSidebarExpanded.value));
</script>

<template>
  <div class="relative">
    <Component
      :is="item.path ? 'RouterLink' : 'button'"
      ref="navItemRef"
      v-LsdTooltip:right="tooltip"
      v-bind="$attrs"
      :class="[
        'group/AppShellSidebarNavigationItem peer/AppShellSidebarNavigationItem',
        'relative flex h-9 w-full min-w-10 appearance-none items-center overflow-hidden rounded-full',
        'ring-0 ring-[color:--lsds-c-sidebar-drawer-list-item-background-color-on-focus]',
        'text-left text-body-1 font-medium no-underline outline-none transition-colors',
        'bg-transparent',
        'text-[--lsds-c-sidebar-drawer-list-item-color-on-surface]',
        'hover:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
        'hover:text-[color:--lsds-c-sidebar-drawer-list-item-color-on-hover]',
        'focus-visible:ring-2',
        'focus-visible:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
        'focus-visible:text-[color:--lsds-c-sidebar-drawer-list-item-color-on-hover]',
        'data-[active-item]:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-selected]',
        'data-[active-item]:text-[color:--lsds-c-sidebar-drawer-list-item-color-on-selected]',
      ]"
      :data-active-item="isActive ? '' : undefined"
      :[ariaAttributeKey]="ariaAttributeValue"
      :aria-label="item.text"
      :data-identifier="item.dataIdentifier"
      :to="item.path"
      :type="item.path ? undefined : 'button'"
      @click="emit('click', $event)"
    >
      <div class="flex size-10 shrink-0 items-center justify-center">
        <LscIcon
          :icon="item.icon"
          size="md"
          :class="[
            'transition-colors',
            'text-[color:--lsds-c-sidebar-drawer-icon-color-on-surface]',
            'group-focus-visible/AppShellSidebarNavigationItem:text-[color:--lsds-c-sidebar-drawer-icon-color-on-hover]',
            'group-hover/AppShellSidebarNavigationItem:text-[color:--lsds-c-sidebar-drawer-icon-color-on-hover]',
            'group-data-[active-item]/AppShellSidebarNavigationItem:text-[color:--lsds-c-sidebar-drawer-icon-color-on-selected]',
          ]"
        />
      </div>

      <LscOverflowEllipsis class="flex-auto">{{ item.text }}</LscOverflowEllipsis>

      <div
        v-if="item.itemKey === 'search' && commandCenterEnabled"
        :class="[
          { 'opacity-0': !isSidebarExpanded, 'opacity-100': isSidebarExpanded },
          'flex h-10 items-center text-nowrap pr-4 text-right text-body-2 text-[var(--lsds-a-color-text-subtle)] transition-opacity',
        ]"
      >
        {{ item.tooltip.subtext }}
      </div>
      <div
        v-else
        class="flex size-10 shrink-0 items-center justify-center"
        :class="{
          'group-hover/AppShellSidebarNavigationItem:opacity-0': canUnpinItem,
        }"
      >
        <LscIcon
          v-if="item.drawerPanel"
          icon="lsi-open-panel"
          size="xs"
          class="text-[color:--lsds-c-sidebar-drawer-icon-color-on-surface]"
          :class="{ '-right-3': !isSidebarExpanded }"
        />
      </div>
    </Component>
    <button
      v-if="canUnpinItem"
      v-LsdTooltip="t('Unpin')"
      type="button"
      :class="[
        'peer/AppShellSidebarNavigationPinButton',
        'absolute right-0 top-0 outline-none',
        'ring-0 ring-[color:--lsds-c-sidebar-drawer-list-item-background-color-on-focus]',
        'bg-transparent',
        'text-[--lsds-c-sidebar-drawer-list-item-color-on-surface]',
        'hover:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
        'hover:text-[color:--lsds-c-sidebar-drawer-list-item-color-on-hover]',
        'focus-visible:ring-2',
        'focus-visible:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
        'focus-visible:text-[color:--lsds-c-sidebar-drawer-list-item-color-on-hover]',
        'flex size-10 items-center justify-center',
        'rounded-full opacity-0 outline-none transition-opacity',
        'peer-hover/AppShellSidebarNavigationItem:opacity-100',
        'peer-hover/AppShellSidebarNavigationItem:ring-offset-0',
        'peer-hover/AppShellSidebarNavigationItem:ring-0',
        'peer-focus-visible/AppShellSidebarNavigationItem:opacity-100',
        'peer-focus-visible/AppShellSidebarNavigationItem:ring-offset-0',
        'peer-focus-visible/AppShellSidebarNavigationItem:ring-0',
        'hover:opacity-100',
        'focus-visible:opacity-100',
      ]"
      @click.stop.prevent="setItemPinned(item.itemKey, false)"
    >
      <LscIcon icon="lsi-pin" class="text-[color:--lsds-c-sidebar-drawer-icon-color-on-surface]" size="sm" />
    </button>
  </div>
</template>
