/* eslint-disable no-underscore-dangle, no-param-reassign */
import { isValidId } from '@/util';
import { useAxios } from '../base/useAxios';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { inboxFiltersMatchItem } from './inboxFiltersMatchItem';

function isNil(value) {
  return value === null || value === undefined;
}

export function useInboxActions({ preview, filter, hasPendingBulkAction }) {
  const api = useAxios();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { socketId } = useRealTimeUpdates();

  function config() {
    return {
      headers: {
        'Socket-ID': socketId,
      },
    };
  }

  function bulkUpdateItemState(ids, isRead, isArchived) {
    // Note: we'll set this flag to true here to ensure there's no delay between the
    // bulk action being initiated and the first socket event relating to it being
    // received. We rely on the `completed` socket event to set this flag to false
    // (as it indicated the server is now in-sync) however if this initial request
    // fails we have to ensure we set this flag to false again
    hasPendingBulkAction.value = true;

    const promise = api
      .post(
        '/projects/api/v3/inbox/items/bulk/update.json',
        {
          all: !ids.length,
          ids,
          excludeIds: [],
          meta: {
            changes: {
              ...(!isNil(isRead) && { isRead }),
              ...(!isNil(isArchived) && { isArchived }),
            },
          },
        },
        config(),
      )
      .catch((error) => {
        hasPendingBulkAction.value = false;

        throw error;
      });

    return promise;
  }

  /**
   * Determines whether or not the preview pane needs updated i.e. whether or not the active item
   * shown in the preview pane will still be in the items list after it is updated
   *
   * @param {Object} target - The target object that the `source` properties will be applied to
   * @param {Object} source - The source object which contains the properties that will be applied
   * @returns {boolean} - A flag indicating whether or not the preview pane needs updated
   */
  function _doesPreviewPaneNeedUpdated(target, source) {
    const itemPreviewIsActive = !!(preview.activeItem.value?.id && target.id === preview.activeItem.value?.id);

    if (!itemPreviewIsActive) {
      return false;
    }

    const itemExistsInList = inboxFiltersMatchItem(filter.queryParams, { ...target, ...source });

    if (itemExistsInList) {
      return false;
    }

    return true;
  }

  function _updateItem(item, source) {
    if (!isValidId(item.id)) {
      return Promise.reject(Error('`item.id` argument is missing or invalid'));
    }

    if (_doesPreviewPaneNeedUpdated(item, source)) {
      preview.setActiveItem(undefined);
    }

    const promise = api
      .patch(
        `/projects/api/v3/inbox/items/${item.id}.json`,
        {
          item: {
            ...source,
          },
        },
        config(),
      )
      .catch((error) => {
        throw error;
      });

    const oldInboxItem = { ...item };
    const newInboxItem = { ...oldInboxItem, ...source };

    emitOptimisticUpdate({
      promise,
      type: 'inboxItem',
      action: 'update',
      inboxItem: newInboxItem,
      oldInboxItem,
    });

    return promise;
  }

  function _updateItemReadState(item, isRead) {
    // Nothing to do
    if (isRead === item.isRead) {
      return Promise.resolve();
    }

    return _updateItem(item, {
      isRead,
    });
  }

  function setItemRead(item) {
    _updateItemReadState(item, true);
  }

  function setItemUnread(item) {
    _updateItemReadState(item, false);
  }

  function toggleItemReadState(item) {
    if (item.isRead) {
      return setItemUnread(item);
    }

    return setItemRead(item);
  }

  function _setAdjacentItemActive() {
    return preview.next() || preview.prev() || preview.setActiveItem(undefined);
  }

  function _updateItemArchivedState(item, isArchived) {
    // Nothing to do
    if (isArchived === item.isArchived) {
      return Promise.resolve();
    }

    const source = {
      isArchived,
    };

    // Special case for archiving items - set adjacent item active when the item being updated
    // no longer exists in the list
    if (_doesPreviewPaneNeedUpdated(item, source)) {
      _setAdjacentItemActive();
    }

    return _updateItem(item, source);
  }

  function setItemArchived(item) {
    _updateItemArchivedState(item, true);
  }

  function setItemUnarchived(item) {
    _updateItemArchivedState(item, false);
  }

  function toggleItemArchivedState(item) {
    if (item.isArchived) {
      return setItemUnarchived(item);
    }

    return setItemArchived(item);
  }

  return {
    setItemRead,
    setItemUnread,
    toggleItemReadState,
    toggleItemArchivedState,
    bulkUpdateItemState,
  };
}
