import {
  LsdsCColorPicker1,
  LsdsCColorPicker2,
  LsdsCColorPicker3,
  LsdsCColorPicker4,
  LsdsCColorPicker5,
  LsdsCColorPicker6,
  LsdsCColorPicker7,
  LsdsCColorPicker8,
  LsdsCColorPicker9,
  LsdsCColorPicker10,
  LsdsCColorPicker11,
  LsdsCColorPicker12,
} from '@teamwork/lightspeed-designsystem-tokens/default';
import { useI18n } from '@/util';

/**
 * @typedef {Object} ColorObject
 * @property {string=} color
 * @property {string=} label
 */

export function useDefaultColorPickerColors() {
  const { t } = useI18n();

  /** @type {ColorObject} */
  const emptyColor = { color: null, label: t('Clear') };

  /** @type {ColorObject[]} */
  const defaultColors = [
    {
      color: LsdsCColorPicker1,
      label: t('Red'),
    },
    {
      color: LsdsCColorPicker2,
      label: t('Orange'),
    },
    {
      color: LsdsCColorPicker3,
      label: t('Yellow'),
    },
    {
      color: LsdsCColorPicker4,
      label: t('Green'),
    },
    {
      color: LsdsCColorPicker5,
      label: t('Deep Blue'),
    },
    {
      color: LsdsCColorPicker6,
      label: t('Blue'),
    },
    {
      color: LsdsCColorPicker7,
      label: t('Teal'),
    },
    {
      color: LsdsCColorPicker8,
      label: t('Purple'),
    },
    {
      color: LsdsCColorPicker9,
      label: t('Pink'),
    },
    {
      color: LsdsCColorPicker10,
      label: t('Light Purple'),
    },
    {
      color: LsdsCColorPicker11,
      label: t('Black'),
    },
    {
      color: LsdsCColorPicker12,
      label: t('Grey'),
    },
  ];

  return {
    defaultColors,
    emptyColor,
  };
}
