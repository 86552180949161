import { useAxios } from '../base/useAxios';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';

export function useTrashcanActions() {
  const api = useAxios();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();
  const { socketId, emit: emitRealTimeUpdate } = useRealTimeUpdates();

  function emitOptimisticUpdate(promise, action, trashCanItem) {
    _emitOptimisticUpdate({
      promise,
      type: 'trashCanItem',
      action,
      trashCanItem,
    });
  }

  function config() {
    return {
      headers: {
        'Socket-ID': socketId,
      },
    };
  }

  /**
   * @param {objec} item
   * @param {string} item.type
   * @param {number} item.id
   */
  function restoreItem(item) {
    const newItem = { ...item, wasRestored: true };

    let normalizedType = item.type;
    if (item.type === 'files') {
      normalizedType = 'fileversions';
    }

    const promise = api
      .put(`/trashcan/${normalizedType}/${item.id}/restore.json`, { includeComments: true }, config())
      .then(() => {
        emitRealTimeUpdate({
          type: item.type,
          id: item.id,
          action: 'added',
        });
      });

    emitOptimisticUpdate(promise, 'update', newItem);

    return promise;
  }

  return {
    restoreItem,
  };
}
