<script setup>
import { useLegacyBridge } from '@/module/legacy';
import { useCurrentUser, usePermissions, useFeatures } from '@/api';
import { useI18n } from '@/util';
import { useAppShellSidebar } from '../useAppShellSidebar';

const { t } = useI18n();
const { showLegacyModal } = useLegacyBridge();
const { isSiteAdmin, isOwnerAccount } = usePermissions();
const { isMyShortcutsOpen, isProductUpdatesOpen, isSuccessPlannerOpen, isUserMenuMoreItemsOpen } = useAppShellSidebar();
const { myBoardsEnabled, projectsWorkflowsEnabled } = useFeatures();

const user = useCurrentUser();

function closeUserMoreMenu() {
  isUserMenuMoreItemsOpen.value = false;
}

function updateMyStatus() {
  closeUserMoreMenu();
  showLegacyModal({
    modalName: 'addOrEditStatus',
  });
}

function openSuccessReport() {
  closeUserMoreMenu();
  isSuccessPlannerOpen.value = true;
}

function openWhatsNew() {
  closeUserMoreMenu();
  isProductUpdatesOpen.value = true;
}

function openMyShortcuts() {
  closeUserMoreMenu();
  isMyShortcutsOpen.value = !isMyShortcutsOpen.value;
}
</script>

<template>
  <WidgetMenu v-model="isUserMenuMoreItemsOpen" location="end" openOnHover openOnClick>
    <template #activator="activator">
      <VListItem
        class="focus-visible:bg-selected"
        v-bind="activator.props"
        tabindex="0"
        :active="activator.isActive"
        link
      >
        <template #prepend><VIcon size="22">lsi-options</VIcon></template>
        <VListItemTitle>{{ t('More') }}</VListItemTitle>
        <template #append>
          <VListItemAction v-if="user.hasFeatureUpdates">
            <VChip color="#FF61C7" size="x-small" class="notificationsCount">
              {{ user.featureUpdatesCount }}
            </VChip>
            <VIcon size="x-small" class="text-icon-subtle">lsi-open-panel</VIcon>
          </VListItemAction>
        </template>
      </VListItem>
    </template>
    <VList v-bind="VListRounded">
      <VListItem v-if="isOwnerAccount" tabindex="0" class="focus-visible:bg-selected" link @click="updateMyStatus">
        <template #prepend>
          <VIcon size="22">lsi-announcement</VIcon>
        </template>
        <VListItemTitle>{{ t('Update my status') }}</VListItemTitle>
      </VListItem>
      <VListItem class="focus-visible:bg-selected" tabindex="0" link @click="openMyShortcuts">
        <template #prepend>
          <VIcon size="20">lsi-bookmarks</VIcon>
        </template>
        <VListItemTitle>{{ t('My shortcuts') }}</VListItemTitle>
      </VListItem>
      <VListItem
        v-if="!projectsWorkflowsEnabled && myBoardsEnabled"
        class="focus-visible:bg-selected"
        v-bind="VListItemSidebarMenuLink"
        tabindex="0"
        :to="`/people/${user.id}/boards`"
        link
        @click="closeUserMoreMenu"
      >
        <template #prepend>
          <VIcon size="20">lsi-dashboard</VIcon>
        </template>
        <VListItemTitle>{{ t('My boards') }}</VListItemTitle>
      </VListItem>
      <VDivider />
      <VListItem v-if="isSiteAdmin" class="focus-visible:bg-selected" tabindex="0" link @click="openSuccessReport">
        <template #prepend>
          <VIcon size="20">lsi-health</VIcon>
        </template>
        <VListItemTitle>{{ t('Success planner') }}</VListItemTitle>
      </VListItem>
      <VListItem class="focus-visible:bg-selected" link tabindex="0" @click="openWhatsNew">
        <template #prepend><VIcon size="20">lsi-whats-new</VIcon></template>
        <VListItemTitle>{{ t("What's new?") }}</VListItemTitle>

        <template #append>
          <VListItemAction v-if="user.hasFeatureUpdates">
            <VChip color="#FF61C7" size="x-small" class="notificationsCount">
              {{ user.featureUpdatesCount }}
            </VChip>
          </VListItemAction>
        </template>
      </VListItem>
    </VList>
  </WidgetMenu>
</template>
