import { DateTime } from 'luxon';
import { useCurrentAccount, usePricePlan } from '@/api';

export function usePlanTrial() {
  const account = useCurrentAccount();
  const { canMaxpUpgradeAccount } = usePricePlan();
  const isCtaDisabled = ref(false);

  const planTrial = computed(() => account.value?.pricePlanTrialData || undefined);

  const trialDaysRemaining = computed(() =>
    Math.floor(DateTime.fromISO(planTrial.value?.endDate).diff(DateTime.now(), 'days').toObject().days),
  );

  const hasTrialExpiryLapsed = computed(() => Math.abs(trialDaysRemaining.value) >= 30);

  const exactTrialTimeRemaining = computed(() =>
    DateTime.fromISO(planTrial.value?.endDate).diff(DateTime.now(), 'millisecond').toMillis(),
  );

  const isTrialActive = computed(() => Boolean(planTrial.value && exactTrialTimeRemaining.value >= 0));
  const isTrialExpired = computed(() => Boolean(planTrial.value && !isTrialActive.value));

  const hasTrialEverStarted = computed(() => Boolean(planTrial.value));

  const maxpGrowTrialAvailable = computed(
    () =>
      account.value.pricePlanType.toLowerCase() === 'maxprojects' &&
      canMaxpUpgradeAccount.value &&
      !hasTrialEverStarted.value,
  );

  return {
    isCtaDisabled,
    hasTrialExpiryLapsed,
    trialDaysRemaining,
    isTrialExpired,
    isTrialActive,
    hasTrialEverStarted,
    maxpGrowTrialAvailable,
  };
}
