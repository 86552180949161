import { DateTime } from 'luxon';
import { useCurrentAccount, useCurrentAccountState } from '../account/useCurrentAccount';
import { useCurrentUser, useCurrentUserState } from '../user/useCurrentUser';
import { usePreferenceActions } from './usePreferenceActions';

export function useBooleanType(defaultValue = false) {
  return {
    parse(value) {
      switch (typeof value) {
        case 'boolean': {
          return value;
        }
        case 'string': {
          switch (value.toLowerCase()) {
            case 'true':
            case 'yes': {
              return true;
            }
            case 'false':
            case 'no': {
              return false;
            }
            default: {
              return unref(defaultValue);
            }
          }
        }
        default: {
          return unref(defaultValue);
        }
      }
    },
    serialize(value) {
      return JSON.stringify(value);
    },
  };
}

export function useStringType(defaultValue = '') {
  return {
    parse(value) {
      switch (typeof value) {
        case 'string': {
          return value;
        }
        default: {
          return unref(defaultValue);
        }
      }
    },
    serialize(value) {
      return value.toString();
    },
  };
}

export function useObjectType(defaultValue = {}) {
  return {
    parse(value) {
      switch (typeof value) {
        case 'object': {
          if (value !== null && !Array.isArray(value)) {
            return value;
          }
          return unref(defaultValue);
        }
        case 'string': {
          try {
            const parsedValue = JSON.parse(value);
            if (typeof parsedValue === 'object' && parsedValue !== null && !Array.isArray(parsedValue)) {
              return { ...unref(defaultValue), ...parsedValue };
            }
            return unref(defaultValue);
          } catch (error) {
            return unref(defaultValue);
          }
        }
        default: {
          return unref(defaultValue);
        }
      }
    },
    serialize(value) {
      return JSON.stringify(value);
    },
  };
}

export function useArrayType(defaultValue = []) {
  return {
    parse(value) {
      if (Array.isArray(value)) {
        return value;
      }

      if (typeof value !== 'string' || !value) {
        return unref(defaultValue);
      }
      try {
        const parsedValue = JSON.parse(value);

        if (Array.isArray(parsedValue)) {
          return parsedValue;
        }
      } catch (error) {
        //
      }

      return unref(defaultValue);
    },
    serialize(value) {
      return JSON.stringify(value);
    },
  };
}

export function useDateTimeType(defaultValue = null) {
  return {
    parse(value) {
      if (!(typeof value === 'string' && value)) {
        return unref(defaultValue);
      }

      try {
        const date = DateTime.fromISO(JSON.parse(value));

        if (date.isValid) {
          return date;
        }
      } catch (error) {
        //
      }

      return unref(defaultValue);
    },
    serialize(value) {
      return JSON.stringify(value.toISO());
    },
  };
}

function useScopeObject(scope) {
  switch (scope) {
    case 'account': {
      return useCurrentAccount();
    }
    case 'me': {
      return useCurrentUser();
    }
    default: {
      // eslint-disable-next-line no-console
      console.warn(`usePreferences: Unsupported scope: "${scope}"`);
      return undefined;
    }
  }
}

let lastPromise = Promise.resolve();

export function useServerStore({ scope, name }) {
  const scopeObject = useScopeObject(scope);
  const { savePreferences } = usePreferenceActions();

  return {
    value: computed(() => scopeObject.value?.preferences?.[name]),
    save(value) {
      // Parallel API calls to update pref is causing an issue and overwritting prefs on server sometimes
      // To avoid this, we are queuing the calls as of now. Ideally, there should be a single debounced call
      // @TODO: Refactor logic to update prefs on server with min API calls
      lastPromise = lastPromise.catch(() => undefined).then(() => savePreferences(scope, { [name]: value }));
      return lastPromise;
    },
  };
}

export function usePreference({ type, store }) {
  const loadedValue = store.value;
  const savedValue = shallowRef();
  const pendingValue = shallowRef();
  let promise;

  return computed({
    get() {
      if (pendingValue.value !== undefined) {
        return pendingValue.value;
      }
      if (savedValue.value !== undefined) {
        return savedValue.value;
      }
      return type.parse(loadedValue.value);
    },
    set(value) {
      const localPromise = store.save(type.serialize(value)).then(
        () => {
          if (promise === localPromise) {
            promise = undefined;
            pendingValue.value = undefined;
            savedValue.value = value;
          }
        },
        () => {
          if (promise === localPromise) {
            promise = undefined;
            pendingValue.value = undefined;
          }
        },
      );
      promise = localPromise;
      pendingValue.value = value;
    },
  });
}

export function useInitialized() {
  const initialized = shallowRef(false);
  const { inSync: accountInSync } = useCurrentAccountState();
  const { inSync: userInSync } = useCurrentUserState();

  const unwatch = watch(
    [accountInSync, userInSync],
    () => {
      if (accountInSync.value && userInSync.value) {
        initialized.value = true;
        // prevent potential undefined value on first call
        queueMicrotask(() => {
          unwatch();
        });
      }
    },
    { immediate: true },
  );

  return initialized;
}
