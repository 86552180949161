import { useLocalStorage } from '@/util';

export function useLegacySwitches() {
  return {
    // We keep `useLightspeedView` in the key name for now for backward compatibility.
    listView: useLocalStorage('teamwork/useLightspeedView/listView', true),
    tableView: useLocalStorage('teamwork/useLightspeedView/tableView', true),
    boardView: useLocalStorage('teamwork/useLightspeedView/boardView', true),
    people: useLocalStorage('teamwork/useLightspeedView/people', true),
    customReports: useLocalStorage('teamwork/useLightspeedView/customReports', true),
    timeAll: useLocalStorage('teamwork/useLightspeedView/timeAll', true),
    timeProject: useLocalStorage('teamwork/useLightspeedView/timeProject', true),
    profitabilityProjectsReportPage: useLocalStorage(
      'teamwork/useLightspeedView/profitabilityProjectsReportPage',
      true,
    ),
    projectTimeReportPage: useLocalStorage('teamwork/useLightspeedView/projectTimeReportPage', true),
    projectHealthReportPage: useLocalStorage('teamwork/useLightspeedView/projectHealthReportPage', true),
    plannedVsActualTasksReportPage: useLocalStorage('teamwork/useLightspeedView/plannedVsActualTasksReportPage', true),
    plannedVsActualMilestonesReportPage: useLocalStorage(
      'teamwork/useLightspeedView/plannedVsActualMilestonesReportPage',
      true,
    ),
    taskTimeReportPage: useLocalStorage('teamwork/useLightspeedView/taskTimeReportPage', true),
    teams: useLocalStorage('teamwork/useLightspeedView/teams', true),
    userTimeReportPage: useLocalStorage('teamwork/useLightspeedView/userTimeReportPage', true),
    userTaskCompletionReportPage: useLocalStorage('teamwork/useLightspeedView/userTaskCompletionReportPage', true),
    reportGallery: useLocalStorage('teamwork/useLightspeedView/reportGallery', true),
    statuses: useLocalStorage('teamwork/useLightspeedView/statuses', true),
    scheduler: useLocalStorage('teamwork/useLightspeedView/scheduler', true),
    reportsUtilization: useLocalStorage('teamwork/useLightspeedView/reportsUtilization', true),
    planningOverview: useLocalStorage('teamwork/useLightspeedView/planningOverview', true),
  };
}
