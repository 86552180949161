/**
 * Do not edit directly
 * Generated on Sat, 30 Mar 2024 00:02:49 GMT
 */

export const LsdsCSidebarDrawerColorSurface = "#4461d7";
export const LsdsCSidebarDrawerIconColorOnSurface = "#d8e0ff";
export const LsdsCSidebarDrawerIconColorOnHover = "#f2f4fc";
export const LsdsCSidebarDrawerIconColorOnSelected = "#ffffff";
export const LsdsCSidebarDrawerListItemBackgroundColorOnHover = "#405ac9";
export const LsdsCSidebarDrawerListItemBackgroundColorOnFocus = "#8f9fe7";
export const LsdsCSidebarDrawerListItemBackgroundColorOnSelected = "#3c55bd";
export const LsdsCSidebarDrawerListItemColorOnSurface = "#ffffff";
export const LsdsCSidebarDrawerListItemColorOnHover = "#ffffff";
export const LsdsCSidebarDrawerListItemColorOnSelected = "#ffffff";
export const LsdsCSidebarDrawerQuickAddColorSurface = "#ffffff";
export const LsdsCSidebarDrawerQuickAddColorSurfaceHover = "#edeff5";
export const LsdsCSidebarDrawerQuickAddColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerAddYourTeamColorSurface = "#364daa";
export const LsdsCSidebarDrawerUpgradeNowColorSurface = "#3c55bd";
export const LsdsCSidebarDrawerNotificationsBadgeColorSurface = "#ffffff";
export const LsdsCSidebarDrawerNotificationsBadgeColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerChatBadgeColorSurface = "#ffffff";
export const LsdsCSidebarDrawerChatBadgeColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerClosePanelIconColorSurface = "#0c0e1f";
export const LsdsCSidebarDrawerClosePanelIconColorOnSurface = "#eeeeee";
export const LsdsCSidebarDrawerClosePanelIconColorOnHover = "#0d0f2f";
export const LsdsCSidebarDrawerProfileColorOnSurface = "#ffffff";
export const LsdsCSidebarDrawerProfileColorOnSurfaceSubtle = "#ffffff";
