import { useFeatures } from '../features/useFeatures';
import { useCurrentUser } from '../user/useCurrentUser';

/**
 * Constants, computed properties and functions related to Experiment E17
 * https://app.launchdarkly.com/default/production/features/projects-exp-e-23-17-leaner-ui-for-icp-project-managers/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/42280-e-23-17-leaner-ui-for-project-managers
 */
export function useExperimentE17() {
  const user = useCurrentUser();
  const { leanerUIForICPProjectManagersEnabled, leanerUIForICPProjectManagersBudgetViewEnabled } = useFeatures();

  const PM_ROLES = [1, 2, 3, 4];
  const { launchDarklyFlagKey, defaultValue } = leanerUIForICPProjectManagersEnabled;
  const ignoreInExperimentOnRuleMatch = true;

  const expE17AppLevelTargeting = computed(() => PM_ROLES.includes(user.value?.companyRoleId));
  const isExpE17Variation = computed(() => leanerUIForICPProjectManagersEnabled.value && expE17AppLevelTargeting.value);
  const isExpE17Control = computed(() => !isExpE17Variation.value);
  const isExpE17BudgetViewVariation = computed(() => {
    return leanerUIForICPProjectManagersBudgetViewEnabled.value && expE17AppLevelTargeting.value;
  });

  const EXP_E17_FEATURE_ORDER = {
    list: 0,
    board: 1,
    table: 2,
    dashboard: 3,
    files: 4,
    time: 5,
    forms: 6,
    finance: 7,
    milestones: 8,
    messages: 9,
    gantt: 10,
    comments: 11,
    notebooks: 12,
    links: 13,
    people: 14,
    risks: 15,
    billing: 16,
    settings: 17,
  };

  // providePendo has not been invoked when this composable is loaded
  // so we need to pass the trackExperimentInPendo as an argument
  function trackExperimentE17(trackExperimentInPendo) {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: expE17AppLevelTargeting,
    });
  }

  return {
    expE17AppLevelTargeting,
    isExpE17Variation,
    isExpE17BudgetViewVariation,
    isExpE17Control,
    trackExperimentE17,
    EXP_E17_FEATURE_ORDER,
  };
}
