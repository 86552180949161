/**
 * Constants, computed properties and functions related to Experiment A-23-49
 * https://app.launchdarkly.com/default/staging/features/projects-exp-a-23-49-context-aware-help-menu/targeting
 *  - re-using old flag for this that's already in place
 * https://digitalcrew.teamwork.com/spaces/growth/page/49711-a-23-49-context-aware-help-menu
 */

import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

export function useExperimentA49() {
  const { contextAwareHelpMenuEnabled } = useFeatures();
  const { trackExperimentInPendo } = usePendo();
  const ignoreInExperimentOnRuleMatch = true;
  const { launchDarklyFlagKey, defaultValue } = contextAwareHelpMenuEnabled;

  const EXP_A49_COMMON_METRICS = ['plan_name', 'user_role', 'page'];

  const isExpA49Variation = computed(() => contextAwareHelpMenuEnabled.value);

  function trackExperimentA49() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
    });
  }

  return {
    isExpA49Variation,
    EXP_A49_COMMON_METRICS,
    trackExperimentA49,
  };
}
