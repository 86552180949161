<script setup>
import { tv } from '../../../util/tailwind/tv';
import { provideLscTabs } from './useLscTabs';

/**
 * @typedef {import('./useLscTabs').LsTab} LsTab
 */

const props = defineProps({
  /**
   * The items to display.
   * @type {import('vue').PropType<LsTab[]>}
   */
  items: {
    type: Array,
    default: () => [],
  },
  /**
   * The variant of the tabs.
   * @type {import('vue').PropType<'secondary'>}
   */
  variant: {
    type: String,
    default: 'secondary',
    validator: (value) => ['secondary'].includes(value),
  },
});

provideLscTabs({
  variant: computed(() => props.variant),
});

const lscTabsVariantStyleConfig = tv({
  base: 'flex items-center justify-center',
  variants: {
    variant: {
      secondary: 'gap-1 rounded-md bg-surface-emphasis-default p-1',
    },
  },
});

const classes = computed(() => lscTabsVariantStyleConfig(props));
</script>
<template>
  <nav class="LscTabs" role="tablist" :class="classes">
    <slot name="items" />
  </nav>
</template>
