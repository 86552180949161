<script setup>
import { providePermissions } from '../permissions/usePermissions';
import { providePreferences } from '../preferences/usePreferences';
import { provideInboxFilters } from '../inbox/useInboxFilters';
import ApiSetup5 from './ApiSetup5.vue';

providePermissions();
providePreferences();
provideInboxFilters();
</script>

<template>
  <ApiSetup5>
    <slot />
  </ApiSetup5>
</template>
