/**
 * Creates a dynamic file input.
 * - Compatible with desktop and mobile.
 * - Detects file upload dialog "cancel" operation.
 * @param {Object} options

 * @returns {Object}
 */
export function createFileInput({ multiple = false } = {}) {
  const fileInputId = `utils_forms_fileInput_${Date.now()}`;

  function destroy() {
    const existingFileInput = document.querySelector(`body > #${fileInputId}`);
    if (existingFileInput) {
      existingFileInput.remove();
    }

    // eslint-disable-next-line no-use-before-define
    window.removeEventListener('focus', onDocumentFocus);
    // eslint-disable-next-line no-use-before-define
    document.body.removeEventListener('touchstart', onBodyTouchStart);
  }

  // Fires on desktop browser when file dialog is dismissed
  // Fires on mobile browser when taking a picture from camera
  function onDocumentFocus() {
    destroy();
  }

  // Fires on mobile browser when selecting an existing file
  function onBodyTouchStart() {
    destroy();
  }

  const input = document.createElement('input');
  input.type = 'file';
  input.classList.add('hidden');
  input.id = fileInputId;
  input.multiple = multiple;

  // iOS Safari "Take picture" only works when element input is added to DOM.
  document.body.appendChild(input);

  input.addEventListener('click', () => {
    window.addEventListener('focus', onDocumentFocus, true);
    document.body.addEventListener('touchstart', onBodyTouchStart, true);
  });

  return { input, destroy };
}
