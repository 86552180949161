/**
 * Constants, computed properties and functions related to Experiment A-23-45
 * https://app.launchdarkly.com/default/production/features/projects-exp-a-23-45-onboarding-for-paid-invited-users/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/48379-a-23-45-onboarding-for-paid-invited-users
 */

import { useFeatures } from '../features/useFeatures';
import { usePermissions } from '../permissions/usePermissions';
import { useCurrentUser } from '../user/useCurrentUser';
import { usePendo } from '../features/usePendo';

export function useExperimentA45() {
  const { onboardingForPaidInvitedUsersEnabled } = useFeatures();
  const { isClientUser, isCollaborator } = usePermissions();
  const user = useCurrentUser();
  const { trackExperimentInPendo } = usePendo();

  const { launchDarklyFlagKey, defaultValue } = onboardingForPaidInvitedUsersEnabled;
  const ignoreInExperimentOnRuleMatch = true;

  const invitedUserFirst30Days = computed(() => {
    return !user.value?.siteOwner && Math.abs(user.value?.userInviteAcceptedDate?.diffNow('days').days) <= 30;
  });

  const expA45AppLevelTargeting = computed(() => !isClientUser.value && !isCollaborator.value);

  const isExpA45Variation = computed(() => onboardingForPaidInvitedUsersEnabled.value && expA45AppLevelTargeting.value);

  const isExpA45SiteAdministratorVariation = computed(() => isExpA45Variation.value && user.value?.administrator);

  const isExpA45NotASiteAdministratorVariation = computed(() => isExpA45Variation.value && !user.value?.administrator);

  function trackExperimentA45() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: expA45AppLevelTargeting,
    });
  }

  return {
    trackExperimentA45,
    isExpA45Variation,
    invitedUserFirst30Days,
    isExpA45SiteAdministratorVariation,
    isExpA45NotASiteAdministratorVariation,
  };
}
