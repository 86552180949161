import {
  LsdsCColorPicker1,
  LsdsCColorPicker2,
  LsdsCColorPicker3,
  LsdsCColorPicker4,
  LsdsCColorPicker5,
  LsdsCColorPicker6,
  LsdsCColorPicker7,
  LsdsCColorPicker8,
  LsdsCColorPicker9,
  LsdsCColorPicker10,
  LsdsCColorPicker11,
  LsdsCColorPicker12,
} from '@teamwork/lightspeed-designsystem-tokens/default';

const defaultColors = [
  LsdsCColorPicker1,
  LsdsCColorPicker2,
  LsdsCColorPicker3,
  LsdsCColorPicker4,
  LsdsCColorPicker5,
  LsdsCColorPicker6,
  LsdsCColorPicker7,
  LsdsCColorPicker8,
  LsdsCColorPicker9,
  LsdsCColorPicker10,
  LsdsCColorPicker11,
  LsdsCColorPicker12,
];

let colorsToPick = [];

export function getRandomPickerColor() {
  if (!colorsToPick.length) {
    colorsToPick = [...defaultColors];
  }

  const pickedColor = colorsToPick[Math.floor(Math.random() * colorsToPick.length)];
  colorsToPick = colorsToPick.filter((color) => color !== pickedColor);

  return pickedColor;
}
