import { useRouter } from 'vue-router';
import { useI18n } from '@/util';
import {
  useFeatures,
  usePreferences,
  useProjectActions,
  usePersonActions,
  useTrashcanActions,
  usePricePlan,
  useCohort,
  useClientActions,
} from '@/api';
import { useCurrentProject, useRoute } from '@/route';

export function useSampleProjects() {
  const currentProject = useCurrentProject();
  const toast = useLsToast();
  const { projectsDummyDataEnabled, projectsDummyDataV3Enabled } = useFeatures();
  const {
    sampleProjectsBannerDismissed,
    sampleProjectsVisible,
    sampleProjectIds,
    sampleUserIds,
    sampleClientIds,
    sampleProjectsRemovedDuringCheckout,
  } = usePreferences();
  const { deleteProject } = useProjectActions();
  const { deletePeople } = usePersonActions();
  const { restoreItem } = useTrashcanActions();
  const { deleteClient, restoreClient } = useClientActions();
  const router = useRouter();
  const route = useRoute();
  const { t } = useI18n();
  const { isPaid } = usePricePlan();
  const { isICP, isCompanySizeAboveTen } = useCohort();

  const expA27AppLevelTargeting = computed(() => isICP.value && isCompanySizeAboveTen.value);

  const isSampleProjectRoute = computed(() => {
    return (
      route.matched.some(({ meta }) => meta.sampleProjectsBannerVisible) &&
      currentProject.value != null &&
      sampleProjectIds.value.includes(currentProject.value.id)
    );
  });

  const isExpA27Variation = computed(() => projectsDummyDataEnabled.value && expA27AppLevelTargeting.value);
  const isExpA27V3Variation = computed(() => projectsDummyDataV3Enabled.value && isExpA27Variation.value);

  const isSampleProjectsBannerRoute = computed(
    () =>
      (route.matched.some(({ meta }) => meta.sampleProjectsBannerVisible) && currentProject.value == null) ||
      isSampleProjectRoute.value,
  );

  const isSampleProjectsBannerVisible = computed(
    () => isExpA27Variation.value && isSampleProjectsBannerRoute.value && sampleProjectIds.value.length > 0,
  );

  const areSampleProjectsVisible = computed(() => sampleProjectsVisible.value.length > 0);

  function dismissSampleProjectsBanner() {
    sampleProjectsBannerDismissed.value = true;
  }

  async function deleteSampleData({ onCheckout = false } = {}) {
    // Ordering matters – Delete users first
    if (areSampleProjectsVisible.value) {
      if (sampleUserIds.value.length > 0) {
        const unassignFromAll = false;
        await deletePeople(sampleUserIds.value, unassignFromAll).catch(() => {
          if (!onCheckout) {
            toast.critical(t('There was a problem hiding sample users'));
          }
        });
      }

      // Delete projects next
      const deleteProjects = [];
      for (const sampleProjectId of sampleProjectIds.value) {
        deleteProjects.push(deleteProject({ id: sampleProjectId }));
      }
      sampleProjectsVisible.value = [];
      await Promise.all(deleteProjects)
        .then(() => {
          if (isSampleProjectRoute.value) {
            router.push('/projects/list');
          }
          if (onCheckout) {
            sampleProjectsRemovedDuringCheckout.value = true;
          } else {
            toast.success(t('All sample projects were hidden'));
          }
        })
        .catch(() => {
          sampleProjectsVisible.value = sampleProjectIds.value;
          if (!onCheckout) {
            toast.critical(t('There was a problem hiding sample projects'));
          }
        });

      // Delete clients after projects
      const deleteClients = [];
      for (const sampleClientId of sampleClientIds.value) {
        deleteClients.push(deleteClient(sampleClientId));
      }
      await Promise.allSettled(deleteClients);
    }
  }

  async function restoreSampleProjects({ onLoad } = {}) {
    // Order matters – restore clients first
    const restoreClients = [];
    for (const sampleClientId of sampleClientIds.value) {
      restoreClients.push(restoreClient(sampleClientId));
    }
    await Promise.allSettled(restoreClients);

    // Restore users before projects
    const restoreUsers = [];
    for (const sampleUserId of sampleUserIds.value) {
      restoreUsers.push(restoreItem({ type: 'people', id: sampleUserId }));
    }
    await Promise.allSettled(restoreUsers);

    // Restore projects last
    const restoreProjects = [];
    for (const sampleProjectId of sampleProjectIds.value) {
      restoreProjects.push(restoreItem({ type: 'projects', id: sampleProjectId }));
    }
    await Promise.allSettled(restoreProjects)
      .then(() => {
        sampleProjectsVisible.value = sampleProjectIds.value;
        if (!onLoad) {
          toast.success(t('All sample projects are visible'));
        }
      })
      .catch(() => toast.critical(t('There was a problem showing sample projects')));
  }

  async function checkIsPaidAfterCheckout() {
    if (isPaid.value) {
      await deleteSampleData({ onCheckout: true });
    } else if (sampleProjectsRemovedDuringCheckout.value) {
      await restoreSampleProjects({ onLoad: true }).then(() => {
        sampleProjectsRemovedDuringCheckout.value = false;
      });
    }
  }

  return {
    isExpA27Variation,
    isExpA27V3Variation,
    isSampleProjectsBannerVisible,
    areSampleProjectsVisible,
    dismissSampleProjectsBanner,
    deleteSampleData,
    restoreSampleProjects,
    checkIsPaidAfterCheckout,
  };
}
