<script setup>
import { usePendo } from '@/api';
import { CommandCenterDialog } from '@/module/commandCenter';
import { useI18n, useKeyboardShortcut } from '@/util';
import AppShellSidebarNavigationItem from './AppShellSidebarNavigationItem.vue';
import AppShellSidebarNavigationMoreMenu from './AppShellSidebarNavigationMoreMenu.vue';
import AppShellSidebarQuickAddMenu from './AppShellSidebarQuickAddMenu.vue';
import { useAppShellSidebar } from './useAppShellSidebar';

const { t } = useI18n();
const { trackPendoEvent } = usePendo();
const {
  dockedNavItems,
  moreNavItems,
  isSidebarOpenOnMobile,
  closeSidebarOnMobile,
  trackSideNavItemClicked,
  toggleActiveDrawerPanel,
  clearActiveDrawerPanelIfNotPinned,
} = useAppShellSidebar();
const dialogName = shallowRef('');
const isDialogOpen = shallowRef(false);

function trackQuickAddMenuItemClick(menuText) {
  trackPendoEvent({
    eventName: 'NAVIGATION_EVENT',
    commonMetrics: ['plan_name', 'user_role', 'page'],
    metadata: {
      event_action: 'quick_add_item_clicked',
      quick_add_item: menuText.replace(/\s+/g, '_').toLowerCase(),
    },
  });

  isSidebarOpenOnMobile.value = false;
}

function onClickNavItem(navItem) {
  if (navItem.drawerPanel) {
    toggleActiveDrawerPanel(navItem.drawerPanel);
  } else {
    clearActiveDrawerPanelIfNotPinned();
  }

  trackSideNavItemClicked(navItem.pendoLabel);

  if (!navItem.keepOpenOnMobile) {
    closeSidebarOnMobile();
  }
  navItem.onClick?.();

  if (navItem.dialogName) {
    dialogName.value = navItem.dialogName;
    isDialogOpen.value = true;
  }
}

[...dockedNavItems.value, ...moreNavItems.value].forEach((navItem) => {
  if (navItem.keyboardShortcut) {
    useKeyboardShortcut(
      navItem.keyboardShortcut,
      () => {
        onClickNavItem(navItem);
      },
      { activeOnInput: navItem.keyboardShortcutActiveOnInput ?? false },
    );
  }
});
</script>

<template>
  <nav
    class="flex h-full flex-col justify-between gap-2 overflow-y-auto overflow-x-hidden px-6 pt-5 scrollbar-on-dark"
    v-bind="$attrs"
  >
    <ul class="list-none space-y-2">
      <li>
        <AppShellSidebarQuickAddMenu
          @experimentButtonClicked="trackSideNavItemClicked"
          @buttonClicked="trackQuickAddMenuItemClick"
        />
      </li>
      <li v-for="item in dockedNavItems" :key="item.itemKey">
        <AppShellSidebarNavigationItem :item="item" @click="onClickNavItem(item)" />
      </li>

      <li v-if="moreNavItems.length">
        <AppShellSidebarNavigationMoreMenu role="menu" aria-labelledby="more-menu-button">
          <template #activator="activator">
            <AppShellSidebarNavigationItem
              v-bind="activator.props"
              id="more-menu-button"
              :item="{
                icon: 'lsi-sidenav-more',
                text: t('More'),
                fixed: true,
                keepOpenOnMobile: true,
                pendoLabel: 'more',
                dataIdentifier: 'side-nav-more-button',
              }"
              :isActive="activator.isActive"
            />
          </template>
        </AppShellSidebarNavigationMoreMenu>
      </li>
    </ul>
  </nav>
  <LscSlotSwitch :name="dialogName">
    <template #CommandCenterDialog>
      <CommandCenterDialog v-model="isDialogOpen" />
    </template>
  </LscSlotSwitch>
</template>
