<script setup>
import { tv } from '../../../util/tailwind/tv';
import { useLscCard } from './useLscCard';

const { layout } = useLscCard();

const cardMediaHeaderVariantStyleConfig = tv({
  base: ['relative cursor-pointer'],
  slots: {
    option: 'absolute right-2 top-2',
    overlay: 'absolute left-4 h-10 w-10 -translate-y-2/4 overflow-hidden rounded-full bg-selected ',
  },
  variants: {
    layout: {
      horizontal: {
        base: ['mb-0'],
      },
    },
  },
});

const classes = computed(() => cardMediaHeaderVariantStyleConfig({ layout: layout.value }));
</script>
<template>
  <div :class="classes.base()">
    <slot name="media" />
    <div v-if="$slots.overlay && layout !== 'horizontal'" :class="classes.overlay()">
      <slot name="overlay" />
    </div>
    <div :class="classes.option()">
      <slot name="option" />
    </div>
  </div>
</template>
