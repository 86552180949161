/**
 * Do not edit directly
 * Generated on Sat, 30 Mar 2024 00:02:50 GMT
 */

export const LsdsCSidebarDrawerColorSurface = "#e12d42";
export const LsdsCSidebarDrawerIconColorOnSurface = "#ffd0da";
export const LsdsCSidebarDrawerIconColorOnHover = "#fceaec";
export const LsdsCSidebarDrawerIconColorOnSelected = "#ffffff";
export const LsdsCSidebarDrawerListItemBackgroundColorOnHover = "#d52a3e";
export const LsdsCSidebarDrawerListItemBackgroundColorOnFocus = "#8f9fe7";
export const LsdsCSidebarDrawerListItemBackgroundColorOnSelected = "#cb283b";
export const LsdsCSidebarDrawerListItemColorOnSurface = "#ffffff";
export const LsdsCSidebarDrawerListItemColorOnHover = "#ffffff";
export const LsdsCSidebarDrawerListItemColorOnSelected = "#ffffff";
export const LsdsCSidebarDrawerQuickAddColorSurface = "#ffffff";
export const LsdsCSidebarDrawerQuickAddColorSurfaceHover = "#edeff5";
export const LsdsCSidebarDrawerQuickAddColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerAddYourTeamColorSurface = "#b42435";
export const LsdsCSidebarDrawerUpgradeNowColorSurface = "#cb283b";
export const LsdsCSidebarDrawerNotificationsBadgeColorSurface = "#ffffff";
export const LsdsCSidebarDrawerNotificationsBadgeColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerChatBadgeColorSurface = "#ffffff";
export const LsdsCSidebarDrawerChatBadgeColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerClosePanelIconColorSurface = "#0c0e1f";
export const LsdsCSidebarDrawerClosePanelIconColorOnSurface = "#eeeeee";
export const LsdsCSidebarDrawerClosePanelIconColorOnHover = "#0d0f2f";
export const LsdsCSidebarDrawerProfileColorOnSurface = "#ffffff";
export const LsdsCSidebarDrawerProfileColorOnSurfaceSubtle = "#ffffff";
