<script setup>
import TemplateGalleryDialogPanel from './TemplateGalleryDialogPanel.vue';
import { useBreakpoints } from '@/util';

const props = defineProps({});

const breakpoints = useBreakpoints();
const isSmallerThanXl = breakpoints.smallerOrEqual('xl');
</script>
<template>
  <WidgetDialog :width="isSmallerThanXl ? 1336 : 1826" :height="isSmallerThanXl ? 836 : 986">
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <TemplateGalleryDialogPanel v-bind="props" @close="close" />
    </template>
  </WidgetDialog>
</template>
