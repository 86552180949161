import { useCurrentAccount } from '../account/useCurrentAccount';
import { usePricePlan } from '../account/usePricePlan';
import { useCurrentUser } from '../user/useCurrentUser';
import { useFeatures } from '../features/useFeatures';

const symbol = Symbol('usePermissions');

function PermissionsService() {
  const account = useCurrentAccount();
  const user = useCurrentUser();
  const {
    clientUsersEnabled,
    customfieldsProjectsEnabled,
    customfieldsTasksEnabled,
    inboxEnabled,
    lightspeedCalendarEnabled,
    calendarEnabled,
    projectTemplatesEnabled,
    reportsEnabled,
    projectsPlantrialUpgradeSlatesEnabled,
    proofsEnabled,
    timeRemindersEnabled,
    timeApprovalsEnabled,
    timeApprovalsFlagEnabled,
    intakeFormsEnabled,
    resourceSchedulingEnabled,
  } = useFeatures();
  const { isInUpgradeSegment } = usePricePlan();

  const isAccount = computed(() => Boolean(user.value?.userType === 'account'));
  const isSiteAdmin = computed(() => Boolean(user.value?.administrator));
  const isSiteOwner = computed(() => Boolean(user.value?.siteOwner));
  const isOwnerAdmin = computed(() => Boolean(isSiteAdmin.value && user.value?.inOwnerCompany));
  const isOwnerAccount = computed(() => Boolean(user.value?.inOwnerCompany && isAccount.value));
  const isCollaborator = computed(() => Boolean(user.value?.userType === 'collaborator'));
  const isClientUser = computed(() => Boolean(clientUsersEnabled.value && user.value?.isClientUser));
  const isInOwnerCompany = computed(() => Boolean(user.value?.inOwnerCompany));
  const isAdminOnAProject = computed(() => Boolean(user.value?.permissions.isAdminOnAProject));

  const isHubspotActive = computed(() => account.value.integrations.hubSpot.enabled);
  const canOpenSupportCenter = computed(() => account.value?.strictBranding || user.value?.inOwnerCompany);
  const canAccessPeopleCompaniesTeams = computed(() =>
    Boolean(isOwnerAccount.value || (user.value?.permissions.canManagePeople && isAccount.value)),
  );
  const canAccessPortfolio = computed(() => Boolean(user.value?.permissions.canAccessPortfolio));

  const canAddProjects = computed(() => Boolean(isSiteAdmin.value || user.value?.permissions.canAddProjects));

  const canAddCustomReports = computed(() => Boolean(user.value?.permissions.canAddCustomReports));

  const canManageProjectTemplates = computed(() =>
    Boolean(isSiteAdmin.value || user.value?.permissions.canManageProjectTemplates),
  );
  const canManageTaskTemplates = computed(() => {
    return Boolean(isSiteAdmin.value || user.value?.permissions.canAccessTemplates);
  });
  const canManagePeople = computed(() => Boolean(user.value?.permissions.canManagePeople));

  const canManagePortfolio = computed(() => Boolean(isSiteAdmin.value || user.value?.permissions.canManagePortfolio));

  const canManageCustomfields = computed(() => Boolean(user.value?.permissions.canManageCustomFields));

  const canViewGlobalSettingsTags = computed(() => Boolean(isOwnerAdmin.value && account.value?.tagsEnabled));
  const canViewGlobalSettingsCustomfields = computed(() =>
    Boolean(
      (customfieldsProjectsEnabled.value || customfieldsTasksEnabled.value) &&
        (isOwnerAdmin.value || canManageCustomfields.value),
    ),
  );
  const canViewProjectTemplates = computed(() =>
    Boolean(projectTemplatesEnabled.value && (isSiteAdmin.value || user.value?.permissions.canViewProjectTemplates)),
  );
  const canViewProjectTemplatesUpgradeCta = computed(() =>
    Boolean(
      !projectTemplatesEnabled.value && canAddProjects.value && user.value?.inOwnerCompany && isInUpgradeSegment.value,
    ),
  );
  const canViewReports = computed(() =>
    Boolean((isSiteAdmin.value || isAdminOnAProject.value) && reportsEnabled.value),
  );
  const shouldShowNewUpgradePath = computed(() => !projectsPlantrialUpgradeSlatesEnabled.value);
  const canViewReportsUpgradeCta = computed(() =>
    Boolean(
      (isInUpgradeSegment.value || shouldShowNewUpgradePath.value) &&
        (isSiteAdmin.value || isAdminOnAProject.value) &&
        user.value?.inOwnerCompany,
    ),
  );

  const canViewFormsPanel = computed(() => Boolean(!isClientUser.value) && intakeFormsEnabled.value);
  const canViewInbox = inboxEnabled;
  const canViewCalendar = computed(() => Boolean(isSiteAdmin.value || user.value?.permissions.canAccessCalendar));
  const canViewMyCalendar = computed(() =>
    Boolean(
      lightspeedCalendarEnabled.value &&
        calendarEnabled.value &&
        !isClientUser.value &&
        !isCollaborator.value &&
        !user.value.impersonating,
    ),
  );
  const canViewProofs = computed(() =>
    Boolean(proofsEnabled.value && (isAccount.value || isCollaborator.value) && !isClientUser.value),
  );
  const canAddProofs = computed(() => {
    return isAccount.value && !isCollaborator.value && !isClientUser.value;
  });
  const canViewPeople = computed(() => Boolean(isAccount.value && canManagePeople.value));

  const canManageTimeReminders = computed(() => Boolean(user.value?.permissions.canManageTimeReminders));

  const canApproveTime = computed(() => Boolean(user.value?.permissions.canApproveTime));

  const canViewSettings = computed(() => {
    return (
      isOwnerAdmin.value ||
      canViewGlobalSettingsTags.value ||
      canViewGlobalSettingsCustomfields.value ||
      (canManageTimeReminders.value && timeRemindersEnabled.value) ||
      (timeApprovalsEnabled.value && timeApprovalsFlagEnabled.value && isSiteAdmin.value)
    );
  });

  const ssoEnabledAutoStart = computed(() => {
    const { SSO } = account.value;
    return SSO.enabled && SSO.loginStyle === 'SSO automatic-start';
  });

  const canViewAutomations = computed(() => isOwnerAccount.value);
  const canViewSchedule = computed(
    () => (isSiteAdmin.value || user.value.permissions.canViewSchedule) && resourceSchedulingEnabled.value,
  );

  const canManageSchedule = computed(() => canViewSchedule.value && user.value.permissions.canManageSchedule);

  const canAccessCompanyTimesheets = computed(() => Boolean(user.value?.companyTimesheetsPermissions?.canAccess));

  return {
    isAccount,
    isSiteAdmin,
    isSiteOwner,
    isCollaborator,
    isClientUser,
    isInOwnerCompany,
    isHubspotActive,
    isOwnerAccount,
    isOwnerAdmin,
    isAdminOnAProject,
    canAccessPeopleCompaniesTeams,
    canAccessPortfolio,
    canAddProjects,
    canAddCustomReports,
    canManagePeople,
    canManagePortfolio,
    canManageProjectTemplates,
    canManageSchedule,
    canManageTaskTemplates,

    canManageCustomfields,

    canViewFormsPanel,
    canViewGlobalSettingsCustomfields,
    canViewGlobalSettingsTags,
    canViewProjectTemplates,
    canViewProjectTemplatesUpgradeCta,
    canViewReportsUpgradeCta,
    canViewReports,
    canViewAutomations,

    canViewInbox,
    canViewCalendar,
    canViewMyCalendar,
    canViewProofs,
    canAddProofs,
    canViewPeople,
    canViewSchedule,

    canOpenSupportCenter,
    canViewSettings,

    ssoEnabledAutoStart,
    canAccessCompanyTimesheets,
    canManageTimeReminders,
    canApproveTime,
  };
}

export function providePermissions() {
  provide(symbol, PermissionsService());
}

/**
 * Provides global & project-level user permissions.
 * @type {PermissionsService}
 */
export function usePermissions() {
  return inject(symbol);
}
