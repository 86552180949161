<script setup>
const props = defineProps({
  role: {
    type: Object,
    default: () => ({}),
  },

  canAssignUsers: {
    type: Boolean,
    default: true,
  },
});

const JobRolesAddDialogPanel = defineAsyncComponent(() => import('./JobRolesAddDialogPanel.vue'));
</script>

<template>
  <WidgetDialog width="600px">
    <template #default="{ close }">
      <JobRolesAddDialogPanel v-bind="props" @close="close" />
    </template>
  </WidgetDialog>
</template>
