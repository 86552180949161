// eslint-disable-next-line no-restricted-imports
import createDOMPurify from 'dompurify';

const DOMPurify = createDOMPurify();

DOMPurify.addHook('uponSanitizeAttribute', (node, event) => {
  if (node.nodeName === 'IFRAME') {
    if (event.attrName === 'allowfullscreen' || event.attrName === 'frameborder') {
      // Allow `allowfullscreen` and `frameborder` on `<iframe>`.
      // eslint-disable-next-line no-param-reassign
      event.forceKeepAttr = true;
    } else if (event.attrName === 'src') {
      // Restrict the `src` on `<iframe>`.
      if (!event.attrValue.startsWith('https://www.youtube.com/embed')) {
        // eslint-disable-next-line no-param-reassign
        event.keepAttr = false;
      }
    }
  }
});

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  if (node.nodeName === 'A') {
    // Open all links in a new tab.
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer nofollow');
  }
});

/**
 * Returns a safe version of the provided `html`.
 * @param {string} html Potentially unsafe HTML.
 * @param {Object} options Options.
 * @param {boolean} options.allowIframe If `true`, `<iframe>` elements are allowed. Defaults to `false`.
 * @returns {string} Safe HTML.
 */
export function sanitize(html, options) {
  if (options == null) {
    return DOMPurify.sanitize(html);
  }

  const DOMPurifyOptions = { ADD_TAGS: [] };

  if (options.allowIframe) {
    DOMPurifyOptions.ADD_TAGS.push('iframe');
  }

  return DOMPurify.sanitize(html, DOMPurifyOptions);
}

/**
 * Returns a version of the provided `html` with all tags removed.
 * @param {string} html Potentially unsafe html.
 * @returns {string} Safe HTML.
 */
export function stripTags(html) {
  return DOMPurify.sanitize(html, { ALLOWED_TAGS: [] });
}

/**
 * A composable for sanitizing HTML.
 * @param {import('vue').Ref} html A read-write ref.
 * @param {Object} options Options.
 * @param {boolean} options.allowIframe If `true`, `<iframe>` elements are allowed. Defaults to `false`.
 * @return {import('vue').Ref} A read-write computed ref which:
 * - on `get()`, returns a sanitized version of `html.value`.
 * - on `set(value)`, sets `html.value` to the sanitized version of `value`.
 */
export function useSanitize(html, options) {
  return computed({
    get() {
      return sanitize(html.value, options);
    },
    set(value) {
      // eslint-disable-next-line no-param-reassign
      html.value = sanitize(value, options);
    },
  });
}
