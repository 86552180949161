export const UserType = Object.freeze({
  standard: 'standard',
  collaborator: 'collaborator',
  client: 'client',
});

export const UserPermissionGroup = Object.freeze({
  default: 'default',
  administrator: 'administrator',
  projectAdministrator: 'projectAdministrator',
});
