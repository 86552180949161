<script setup>
import { useI18n } from '@/util';

defineProps({
  title: {
    type: String,
    default: null,
  },
  infoTooltip: {
    type: String,
    default: null,
  },
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
});
const emit = defineEmits(['close']);

const { t } = useI18n();
</script>

<template>
  <div class="flex items-center justify-between gap-2">
    <div class="flex items-center gap-2">
      <slot name="title">
        <slot name="prependHeaderLeft" />

        <h4
          class="text-h4 font-semibold"
          :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-title` : undefined"
        >
          {{ title }}
        </h4>
        <slot name="appendHeaderLeft">
          <LscIcon
            v-if="infoTooltip"
            v-LsdTooltip="infoTooltip"
            class="text-icon-subtle"
            icon="lsi-tooltip"
            size="sm"
          />
        </slot>
      </slot>
    </div>

    <div class="flex items-center justify-end gap-2">
      <slot name="prependHeaderRight" />
      <LscIconButton
        v-LsdTooltip="t('Close')"
        class="shrink-0"
        size="md"
        variant="primary"
        icon="lsi-close"
        :ariaLabel="t('Close')"
        @click="emit('close')"
      />
      <slot name="appendHeaderRight" />
    </div>
  </div>
</template>
