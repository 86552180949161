import './VListItem.variants.css';
// #region techdebtFeatureSpecificPotentiallyUnnecessaryVariants

// Deprecated - Remove when Command Center is live - Peter C
export const VListItemSidebarSearchDrawer = {
  class: 'VListItemSidebarSearchDrawer',
  link: true,
};

export const VListItemCommandCenter = {
  class: 'VListItemCommandCenter',
  link: true,
};

export const VListItemSidebarProjectsDrawer = {
  class: 'VListItemSidebarProjectsDrawer',
  link: true,
};

export const VListItemSidebarHelpCenterDrawer = {
  class: 'VListItemSidebarHelpCenterDrawer',
  link: true,
};

export const VListItemSidebarNotificationsDrawer = {
  class: 'VListItemSidebarNotificationsDrawer',
};

export const VListItemIconTitle = {
  class: 'VListItemIconTitle',
};

export const VListItemSidebarMenuLink = {
  // class: 'VListItemSidebarMenuLink',
  // link: true,
  active: false,
  // rounded: true,
};

export const VListItemFilter = {
  class: 'VListItemFilter',
};

export const VListItemTaskBoardColumn = {
  class: 'VListItemTaskBoardColumn',
};
// #endregion techdebtFeatureSpecificPotentiallyUnnecessaryVariants
