import { useRouter } from 'vue-router';
import { useFeatures } from '@/api';

const useRouteSymbol = Symbol('useRoute');
const useQuickViewRouteSymbol = Symbol('useQuickViewRoute');

/**
 * Provides reactive route object.
 */
export function provideRoute() {
  const router = useRouter();
  const historyState = shallowRef(window.history.state || {});
  const { projectsLightspeedTaskDetails } = useFeatures();

  // update history state after each route change
  router.afterEach((to, from) => {
    if (
      projectsLightspeedTaskDetails.value &&
      to.meta.isQuickViewRoute &&
      window.history.state &&
      !window.history.state.quickViewBackgroundPath
    ) {
      // if we're coming from another quick view,
      // persist the background path, else use the last route path.
      if (from.meta.isQuickViewRoute) {
        window.history.state.quickViewBackgroundPath = historyState.value.quickViewBackgroundPath;
      } else {
        window.history.state.quickViewBackgroundPath = from.fullPath;
      }
    }

    historyState.value = window.history.state || {};
  });

  // Due to our quickview routes pattern, we can't always assume that
  // the active current route will be derived from `router.currentRoute`.
  // Sometimes it may be computed from `historyState.quickViewBackgroundPath`.

  // This means we cannot use `useRoute` directly from `vue-router`, but should instead
  // use `useRoute` from `./useRoute`.

  // Logic here is taken from the vue-router implementation of `useRoute`.

  // https://github.com/vuejs/router/blob/v4.1.6/packages/router/src/router.ts#L1231-L1239
  const reactiveRoute = {};
  const reactiveQuickViewRoute = {};

  // get the background path if there is one.
  const quickViewBackgroundRoute = computed(() =>
    historyState.value.quickViewBackgroundPath ? router.resolve(historyState.value.quickViewBackgroundPath) : null,
  );

  // quick view routes should have both a bakground path in history and be enabled through route meta.
  const isQuickViewRoute = computed(
    () => router.currentRoute.value.meta.isQuickViewRoute && quickViewBackgroundRoute.value,
  );

  for (const key of Object.keys(router.currentRoute.value)) {
    // Main route reactive object
    reactiveRoute[key] = computed(() => {
      if (isQuickViewRoute.value) {
        return quickViewBackgroundRoute.value[key];
      }
      return router.currentRoute.value[key];
    });

    // Drawer route reactive object
    reactiveQuickViewRoute[key] = computed(() => {
      if (isQuickViewRoute.value) {
        return router.currentRoute.value[key];
      }
      return undefined;
    });
  }
  const route = reactive(reactiveRoute);
  const quickViewRoute = reactive(reactiveQuickViewRoute);

  provide(useRouteSymbol, route);
  provide(useQuickViewRouteSymbol, quickViewRoute);
}

/**
 * Injects reactive route object.
 * @returns {import('vue-router').RouteLocationNormalizedLoaded}
 */
export function useRoute() {
  return inject(useRouteSymbol);
}

/**
 * Injects reactive quick view route object.
 * @returns {import('vue-router').RouteLocationNormalizedLoaded}
 */
export function useQuickViewRoute() {
  return inject(useQuickViewRouteSymbol);
}
