import { useFeatures } from '../features/useFeatures';
import { useCurrentUser } from '../user/useCurrentUser';

/**
 * Constants, computed properties and functions related to Experiment A-23-42
 * https://app.launchdarkly.com/default/production/features/projects-exp-a-23-42-pinning-time-to-side-nav-for-pms/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/48035-a-23-42-pinning-time-to-the-side-nav-for-pms
 */
export function useExperimentA42() {
  const user = useCurrentUser();
  const { pinningTimeToSideNavForPMsEnabled } = useFeatures();

  const PM_ROLES = [1, 2, 3, 4];
  const { launchDarklyFlagKey, defaultValue } = pinningTimeToSideNavForPMsEnabled;
  const ignoreInExperimentOnRuleMatch = true;

  const expA42AppLevelTargeting = computed(() => PM_ROLES.includes(user.value?.companyRoleId));
  const isExpA42Variation = computed(() => pinningTimeToSideNavForPMsEnabled.value && expA42AppLevelTargeting.value);
  const isExpA42Control = computed(() => !isExpA42Variation.value);

  // providePendo has not been invoked when this composable is loaded
  // so we need to pass the trackExperimentInPendo as an argument
  function trackExperimentA42(trackExperimentInPendo) {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: expA42AppLevelTargeting,
    });
  }

  return {
    isExpA42Variation,
    isExpA42Control,
    trackExperimentA42,
  };
}
