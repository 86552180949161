<script setup>
import { provideDocumentTitle, useI18n } from '@/util';
import { useHandle401 } from '@/api';
import AppShellError from './AppShellError.vue';
import AppShellLoading from './AppShellLoading.vue';
import AppShellNotFound from './AppShellNotFound.vue';
import AppShellUnauthenticated from './AppShellUnauthenticated.vue';
import AppShellToastContainer from './toast/AppShellToastContainer.vue';

const IntegrationHubSpotProjectCreate = defineAsyncComponent(() =>
  import('@/module/integration').then((m) => m.IntegrationHubSpotProjectCreate),
);

const { pathname } = window.location;
const { ready, error, t } = useI18n();
const { got401 } = useHandle401();

provideDocumentTitle();
</script>

<template>
  <AppShellError v-if="error" />
  <AppShellLoading v-else-if="!ready" />
  <AppShellUnauthenticated
    v-else-if="got401"
    :message="t('Please log in to {teamwork} to continue using this view', { teamwork: 'Teamwork.com' })"
  />
  <IntegrationHubSpotProjectCreate v-else-if="pathname === '/app/embed/hubspot/projects/new'" />
  <AppShellNotFound v-else />
  <AppShellToastContainer />
</template>
