/**
 * @tailwindcss/typography plugin configuration
 * See https://github.com/tailwindlabs/tailwindcss-typography/blob/master/src/styles.js for default styles
 */

function mapToMultipleProperties(selectors, properties) {
  const map = {};
  selectors.forEach((selector) => {
    map[selector] = properties;
  });
  return map;
}

export const tailwindTypography = {
  DEFAULT: {
    css: {
      '--tw-prose-body': 'var(--lsds-a-color-text-default)',
      '--tw-prose-headings': 'var(--lsds-a-color-text-default)',
      '--tw-prose-lead': 'var(--lsds-a-color-text-default)',
      '--tw-prose-links': 'var(--lsds-a-color-text-primary)',
      '--tw-prose-bold': 'var(--lsds-a-color-text-default)',
      '--tw-prose-counters': 'var(--lsds-a-color-text-default)',
      '--tw-prose-bullets': 'var(--lsds-a-color-text-default)',
      '--tw-prose-hr': 'var(--lsds-a-color-text-default)',
      '--tw-prose-quotes': 'var(--lsds-a-color-text-default)',
      '--tw-prose-quote-borders': 'var(--lsds-a-color-border-default)',
      '--tw-prose-captions': 'var(--lsds-a-color-text-default)',
      '--tw-prose-code': 'var(--lsds-a-color-text-default)',
      '--tw-prose-pre-code': 'var(--lsds-a-color-text-default)',
      '--tw-prose-pre-bg': 'var(--lsds-a-color-surface-emphasis-default)',
      '--tw-prose-th-borders': 'var(--lsds-a-color-border-default)',
      '--tw-prose-td-borders': 'var(--lsds-a-color-border-default)',
      color: 'var(--lsds-a-color-text-default)',
      maxWidth: 'unset',
      fontSize: 'var(--lsds-a-typography-body-1-regular-font-size)',
      lineHeight: 'var(--lsds-a-typography-body-1-regular-line-height)',
      p: {
        marginTop: 'var(--lsds-g-spacing-5)',
        marginBottom: 'var(--lsds-g-spacing-5)',
      },
      img: {
        maxWidth: '100%',
      },
      blockquote: {
        margin: 'var(--lsds-g-spacing-3) 0',
        paddingLeft: 'var(--lsds-g-spacing-5)',
        fontStyle: 'var(--lsds-g-font-style-italic)',
      },
      code: {
        backgroundColor: 'var(--lsds-a-color-surface-default)',
        padding: 'var(--lsds-g-spacing-1)',
        borderRadius: 'var(--lsds-g-border-rounded-sm)',
        fontWeight: 'var(--lsds-g-font-weight-regular)',
        '&::before': {
          content: '"" !important',
        },
        '&::after': {
          content: '"" !important',
        },
      },
      div: {
        minHeight: 'var(--lsds-a-typography-body-1-regular-line-height)',
      },
      ...mapToMultipleProperties(['a', '.cm-link'], {
        color: 'var(--lsds-a-color-icon-primary-default)',
        textDecoration: 'none',
        '&:hover': {
          color: 'var(--lsds-a-color-icon-primary-hover)',
          textDecoration: 'var(--lsds-g-font-text-decoration-underline)',
        },
      }),
      ...mapToMultipleProperties(['h1', '.cm-header-1'], {
        fontSize: 'var(--lsds-a-typography-heading-1-font-size)',
        fontWeight: 'var(--lsds-a-typography-heading-1-font-weight)',
        lineHeight: 'var(--lsds-a-typography-heading-1-line-height)',
        margin: '0 0 var(--lsds-g-spacing-4)',
      }),
      ...mapToMultipleProperties(['h2', '.cm-header-2'], {
        fontSize: 'var(--lsds-a-typography-heading-2-font-size)',
        fontWeight: 'var(--lsds-a-typography-heading-2-font-weight)',
        lineHeight: 'var(--lsds-a-typography-heading-2-line-height)',
        margin: 'var(--lsds-g-spacing-4) 0 var(--lsds-g-spacing-1)',
      }),
      ...mapToMultipleProperties(['h3', '.cm-header-3'], {
        fontSize: 'var(--lsds-a-typography-heading-3-font-size)',
        fontWeight: 'var(--lsds-a-typography-heading-3-font-weight)',
        lineHeight: 'var(--lsds-a-typography-heading-3-line-height)',
        margin: 'var(--lsds-g-spacing-2) 0 var(--lsds-g-spacing-1)',
      }),
      ...mapToMultipleProperties(['h4', '.cm-header-4'], {
        fontSize: 'var(--lsds-a-typography-heading-4-font-size)',
        fontWeight: 'var(--lsds-a-typography-heading-4-font-weight)',
        lineHeight: 'var(--lsds-a-typography-heading-4-line-height)',
        margin: 'var(--lsds-g-spacing-1) 0',
      }),
      ...mapToMultipleProperties(['h5', '.cm-header-5'], {
        fontSize: 'var(--lsds-a-typography-subtitle-1-font-size)',
        fontWeight: 'var(--lsds-a-typography-subtitle-1-font-weight)',
        lineHeight: 'var(--lsds-a-typography-subtitle-1-line-height)',
        margin: 'var(--lsds-g-spacing-1) 0 var(--lsds-g-spacing-0-5)',
      }),
      ...mapToMultipleProperties(['h6', '.cm-header-6'], {
        fontSize: 'var(--lsds-a-typography-subtitle-2-font-size)',
        fontWeight: 'var(--lsds-a-typography-subtitle-2-font-weight)',
        lineHeight: 'var(--lsds-a-typography-subtitle-2-line-height)',
        margin: 'var(--lsds-g-spacing-1) 0 var(--lsds-g-spacing-0-5)',
      }),
      '.atwho-inserted': {
        display: 'inline-block',
        height: 'var(--lsds-g-size-6)',
        borderRadius: 'var(--lsds-g-border-rounded-full)',
        padding: '0 var(--lsds-g-spacing-1)',
        border: 'none',
        backgroundColor: 'var(--lsds-a-color-surface-emphasis-default)',
        color: 'var(--lsds-a-color-text-default)',
        fontWeight: 'var(--lsds-g-font-weight-semibold)',
      },
    },
  },
};
