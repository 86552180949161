import { unrefElement } from '@vueuse/core';

export function useLsDraggable() {
  /**
   *
   * @param {import('@vueuse/core').MaybeComputedElementRef} _element
   * @param {object} options
   * @param {import('@vueuse/core').MaybeComputedElementRef=} options.dragHandle
   * @param {boolean} options.restrictToViewport
   */
  function draggable(_element, { dragHandle: _dragHandle, restrictToViewport = true } = {}) {
    const element = unrefElement(_element);
    const dragHandle = unrefElement(_dragHandle ?? _element);

    dragHandle.classList.add('cursor-move', 'select-none');

    let initialPointerX = 0;
    let initialPointerY = 0;
    let pointerX = 0;
    let pointerY = 0;
    let initialElementX = 0;
    let initialElementY = 0;
    let elementX = 0;
    let elementY = 0;

    function handlePointerMove(event) {
      if (!event.isPrimary) {
        return;
      }

      let minElementX = -Infinity;
      let maxElementX = Infinity;
      let minElementY = -Infinity;
      let maxElementY = Infinity;

      if (restrictToViewport) {
        const { left, right, top, bottom } = element.getBoundingClientRect();
        minElementX = Math.min(0, elementX - left);
        maxElementX = Math.max(0, elementX + window.innerWidth - right);
        minElementY = Math.min(0, elementY - top);
        maxElementY = Math.max(0, elementY + window.innerHeight - bottom);
      }

      pointerX = event.clientX;
      pointerY = event.clientY;
      elementX = Math.max(minElementX, Math.min(maxElementX, initialElementX + pointerX - initialPointerX));
      elementY = Math.max(minElementY, Math.min(maxElementY, initialElementY + pointerY - initialPointerY));

      element.style.transform = `translate3d(${elementX}px, ${elementY}px, 0px)`;
    }

    function handlePointerUp(event) {
      if (!event.isPrimary) {
        return;
      }

      event.target.releasePointerCapture(event.pointerId);
      event.target.removeEventListener('pointermove', handlePointerMove);
      event.target.removeEventListener('pointerup', handlePointerUp);
    }

    function handlePointerDown(event) {
      if (!event.isPrimary) {
        return;
      }

      event.target.setPointerCapture(event.pointerId);
      event.target.addEventListener('pointermove', handlePointerMove);
      event.target.addEventListener('pointerup', handlePointerUp);

      initialPointerX = event.clientX;
      initialPointerY = event.clientY;
      initialElementX = elementX;
      initialElementY = elementY;
    }

    dragHandle.addEventListener('pointerdown', handlePointerDown);
  }

  return {
    draggable,
  };
}
