<script setup>
import { useI18n } from '@/util';

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
    required: true,
  },
});

const modelValue = defineModel({ type: String, required: true });

const isMenuOpen = shallowRef(false);

const { t } = useI18n();

const selectedSearchEntityShortName = computed(() => {
  const $modelValue = modelValue.value;

  return props.options.find(({ entity }) => entity === $modelValue)?.shortName;
});

function onClickDrawerMenu(entity) {
  modelValue.value = entity;
  isMenuOpen.value = false;
}
</script>

<template>
  <WidgetMenu v-model="isMenuOpen" attach location="bottom end" :closeOnContentClick="true">
    <template #activator="activator">
      <VBtn
        v-LsdTooltip="t('Search type')"
        variant="plain"
        v-bind="activator.props"
        density="compact"
        class="focus-visible:border-px -mr-1 !h-8 grow-0 rounded-full border-0 border-solid border-separator py-0 pl-2 pr-1 !outline-0"
      >
        <span class="!text-body-2 !text-default">{{ selectedSearchEntityShortName }}</span>
        <VIcon>lsi-dropdown</VIcon>
      </VBtn>
    </template>
    <!-- Select search type drop menu -->
    <VList v-bind="VListRounded" class="!px-0 text-left">
      <VListItem
        v-for="option in options"
        :key="option.entity"
        link
        density="compact"
        :active="option.entity === modelValue"
        class="focus-visible:!bg-selected"
        @click="onClickDrawerMenu(option.entity)"
      >
        <template #prepend>
          <VIcon size="16">{{ option.icon }}</VIcon>
        </template>
        <div>
          <VListItemTitle>{{ option.name }}</VListItemTitle>
        </div>
      </VListItem>
    </VList>
  </WidgetMenu>
</template>
