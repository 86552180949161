import { useFeatures } from '../features/useFeatures';
import { useCurrentUser } from '../user/useCurrentUser';
import { usePendo } from '../features/usePendo';
/**
 * Constants, computed properties and functions related to experiment E16
 * https://app.launchdarkly.com/default/production/features/projects-exp-e-23-16-leaner-ui-for-worker-bees/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/42223-e-23-16-leaner-ui-for-worker-bees
 */
export function useExperimentE16() {
  const user = useCurrentUser();
  const { leanerUIForWorkerBeesEnabled } = useFeatures();
  const { trackExperimentInPendo } = usePendo();

  const { launchDarklyFlagKey, defaultValue } = leanerUIForWorkerBeesEnabled;
  const WORKER_BEE_ROLES = [5, 6];
  const EXP_E16_FEATURE_ORDER = {
    list: 0,
    board: 1,
    table: 2,
    time: 3,
    files: 4,
    comments: 5,
    notebooks: 6,
    messages: 7,
    people: 8,
    dashboard: 9,
    milestones: 10,
    forms: 11,
    links: 12,
    finance: 13,
    gantt: 14,
    settings: 15,
    risks: 16,
    billing: 17,
  };
  const ignoreInExperimentOnRuleMatch = true;

  const expE16AppLevelTargeting = computed(() => WORKER_BEE_ROLES.includes(user.value?.companyRoleId));
  const isExpE16Variation = computed(() => leanerUIForWorkerBeesEnabled.value && expE16AppLevelTargeting.value);

  function trackExperimentE16() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: expE16AppLevelTargeting,
    });
  }

  return {
    EXP_E16_FEATURE_ORDER,
    isExpE16Variation,
    trackExperimentE16,
  };
}
