<script setup>
import HelpCenterDrawerTitle from './HelpCenterDrawerTitle.vue';

defineProps({
  title: {
    type: String,
    default: undefined,
  },
  items: {
    type: Array,
    default: () => [],
    required: false,
  },
});

const emit = defineEmits(['itemClicked']);
</script>

<template>
  <div>
    <HelpCenterDrawerTitle v-if="title" :title="title" />
    <VList class="flex flex-col gap-2 py-0" density="compact">
      <VListItem
        v-for="(item, index) in items"
        :key="index"
        v-bind="VListItemSidebarHelpCenterDrawer"
        class="group"
        :href="item?.href"
        :target="item?.href && '_blank'"
        @click="emit('itemClicked', item)"
      >
        <template #prepend>
          <LscIcon
            :icon="item.icon ?? 'lsi-guide'"
            size="lg"
            class="text-icon-subtle group-hover:text-icon-primary-default"
          />
        </template>
        <template #append>
          <LscIcon
            :icon="item.hoverAppendIcon ?? 'lsi-open-panel'"
            size="lg"
            class="invisible text-icon-subtle group-hover:visible group-focus:visible"
          />
        </template>
        <VListItemTitle class="text-body-1 text-default" :title="item.title">
          {{ item.title }}
        </VListItemTitle>
      </VListItem>
    </VList>
  </div>
</template>
