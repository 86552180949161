<script setup>
import {
  useCurrentAccount,
  useCurrentUser,
  usePricePlan,
  useCheckoutActions,
  usePreferences,
  useExperimentA57,
  usePendo,
} from '@/api';
import { useLegacyFrameOffset } from '@/module/legacy';
import { useI18n } from '@/util';

defineOptions({
  inheritAttrs: false,
});

const { t } = useI18n();
const user = useCurrentUser();
const account = useCurrentAccount();
const { goToUpgrade } = useCheckoutActions();
const { shouldWarnTrialEndingSoon, shouldWarnTrialExpiry, paymentOverdue } = usePricePlan();
const { trialWarningBannerDismissed } = usePreferences();
const { isExpA57Variation, EXP_A57_COMMON_METRICS: commonMetrics } = useExperimentA57();
const { trackPendoEvent } = usePendo();

const bannerEl = shallowRef(null);

useLegacyFrameOffset(bannerEl);

const expiryMessage = computed(() => {
  let msg = '';
  if (paymentOverdue.value) {
    msg += t('Paid period has ended. A new subscription is required.');
  } else {
    msg += t("We hope you enjoyed the trial. It's time to choose a plan.");
  }
  if (!user.value.administrator) {
    msg += ' ';
    msg += t('Contact your site administrator to reactivate {twProjects}.', { twProjects: 'Teamwork Projects' });
  }
  return msg;
});

const trialEndingSoonMessage = computed(() => {
  if (isExpA57Variation.value) {
    return (
      t(
        'There are no remaining days on your {pricePlanName} plan trial. | There is 1 day remaining on your {pricePlanName} plan trial. | There are {n} days remaining on your {pricePlanName} plan trial.',
        { n: account.value.trialDaysRemaining, pricePlanName: 'Grow' },
      ) + t("Explore all the incredible features and benefits you'll unlock when you upgrade from your trial.")
    )
      .split('.')
      .join('. ');
  }
  return t(
    'Your free trial ends today. Upgrade your account. | Your free trial ends in {n} day. Upgrade your account today. | Your free trial ends in {n} days. Upgrade your account today.',
    { n: account.value.trialDaysRemaining },
  );
});

const isTrialEndingSoonBannerVisible = computed(
  () => shouldWarnTrialEndingSoon.value && !trialWarningBannerDismissed.value.trialEndingSoon,
);

const isTrialExpiryBannerVisible = computed(
  () => shouldWarnTrialExpiry.value && !trialWarningBannerDismissed.value.trialExpiry,
);

function trackEvent(label) {
  trackPendoEvent({
    eventName: 'GROW_PROMO_EVENT',
    commonMetrics,
    metadata: {
      event_action: 'expiry_banner_clicked',
      event_label: label,
    },
  });
}

function handleClose(banner) {
  trackEvent('dismiss');
  trialWarningBannerDismissed.value = {
    ...trialWarningBannerDismissed.value,
    [banner]: true,
  };
}

function upgradeClicked() {
  trackEvent('add_payment_details');
  goToUpgrade('HeaderBanner');
}
</script>

<template>
  <LscAlert
    v-if="isTrialEndingSoonBannerVisible"
    v-bind="$attrs"
    ref="bannerEl"
    layout="banner"
    closable
    variant="warning"
    :message="trialEndingSoonMessage"
    @close="handleClose('trialEndingSoon')"
  >
    <template #action>
      <LscAlertButton @click="upgradeClicked()">
        {{ isExpA57Variation ? t('Add payment details') : t('Upgrade') }}
      </LscAlertButton>
    </template>
  </LscAlert>

  <LscAlert
    v-if="isTrialExpiryBannerVisible"
    v-bind="$attrs"
    ref="bannerEl"
    layout="banner"
    closable
    type="warning"
    :message="expiryMessage"
    @close="handleClose('trialExpiry')"
  >
    <template #action>
      <LscAlertButton v-if="user.administrator" @click="upgradeClicked()">
        {{ paymentOverdue ? t('Update payment') : t('Choose a plan') }}
      </LscAlertButton>
    </template>
  </LscAlert>
</template>
