import './VBtn.variants.css';
import * as TOKENS from '@teamwork/lightspeed-designsystem-tokens/default';

export const FilterMenuButton = {
  variant: 'outlined',
  class: 'FilterMenuButton',
  height: 32,
};

export const VBtnIcon = {
  class: 'VBtnIcon',
  color: '#0b0e1f', // neutral-60
  icon: true,
  size: 24,
  variant: 'text',
};

export const VBtnListViewIcon = {
  class: 'VBtnListViewIcon',
  color: TOKENS.LsdsAColorTextDefault, // neutral-60
  variant: 'text',
  height: TOKENS.LsdsGSize4,
  minWidth: TOKENS.LsdsGSize4,
};

export const VBtnTaskDetailsToolbar = {
  class: 'VBtnTaskDetailsToolbar',
  color: '#0b0e1f', // neutral-60
  variant: 'text',
  height: 32,
  minWidth: 32,
};

// Variant introduced for experiment A-15
export const VBtnTaskDetailsToolbarPrimary = {
  class: 'VBtnTaskDetailsToolbarPrimary',
  color: '#0b0e1f', // neutral-60
  variant: 'text',
  height: 32,
  minWidth: 32,
};

export const VBtnTextEditorToolbar = {
  class: 'VBtnTextEditorToolbar',
  color: TOKENS.LsdsAColorTextDefault,
  flat: true,
  size: 32,
  variant: 'outlined',
};

export const VBtnTextEditorToolbarWithChevron = {
  class: 'VBtnTextEditorToolbar',
  color: TOKENS.LsdsAColorTextDefault,
  flat: true,
  variant: 'outlined',
  height: 32,
  width: 46,
  minWidth: 46,
};

export const VBtnFileUpload = {
  class: 'VBtnFileUpload',
  flat: true,
  variant: 'outlined',
  width: 82,
  height: 72,
};

// #region techdebtFeatureSpecificPotentiallyUnnecessaryVariants
// Examples Feature specific variants should removed

export const VBtnTaskStatus = {
  icon: true,
  class: 'VBtnTaskStatus',
};

// #endregion techdebtFeatureSpecificPotentiallyUnnecessaryVariants

export const VBtnDropdownOption = {
  variant: 'outlined',
  class: 'VBtnDropdownOption',
  height: 40,
  width: 208,
};
