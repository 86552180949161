<script setup>
import { useCurrentUser, useCurrentUserActions } from '@/api';
import { useLegacyFrameOffset } from '@/module/legacy';
import { useI18n } from '@/util';

const { t } = useI18n();
const user = useCurrentUser();
const { revertImpersonate } = useCurrentUserActions();
const bannerEl = shallowRef(null);

useLegacyFrameOffset(bannerEl);
</script>

<template>
  <LscAlert
    v-if="user.impersonating"
    ref="bannerEl"
    layout="banner"
    :message="
      t('{realName}, you are impersonating {impersonatingName}', {
        realName: user.impersonating?.fullName,
        impersonatingName: `${user.firstName} ${user.lastName}`,
      })
    "
    icon="lsi-assignee"
  >
    <template #action>
      <LscAlertButton @click="revertImpersonate">
        {{ t('Revert') }}
      </LscAlertButton>
    </template>
  </LscAlert>
</template>
