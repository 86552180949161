<script setup>
import { Icon } from '@iconify/vue/dist/offline';
import { tv } from '../../../util/tailwind/tv';

const props = defineProps({
  /**
   * The icon to display.
   * @type {import('vue').PropType<LsIcon>}
   */
  icon: {
    type: String,
    required: true,
  },
  /**
   * Can be a predefined size or a number in pixels.
   *  @type {import('vue').PropType<number|'xs'|'sm'|'md'|'lg'|'xl'>}
   */
  size: {
    type: [String, Number],
    default: 'md',
    validator: (v) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(v) || Number.isInteger(v),
  },
  /**
   * Sets the color of the icon.
   * @warning **Please prefer using the predefined tailwind classes**
   */
  color: {
    type: String,
    default: undefined,
  },
});

const iconVariantStyleConfig = tv({
  variants: {
    size: {
      xs: 'size-[--lsds-a-icon-size-xs]',
      sm: 'size-[--lsds-a-icon-size-sm]',
      md: 'size-[--lsds-a-icon-size-md]',
      lg: 'size-[--lsds-a-icon-size-lg]',
      xl: 'size-[--lsds-a-icon-size-xl]',
    },
    isCustom: {
      true: 'size-[--custom-icon-size]',
    },
    hasColor: {
      true: 'text-[--custom-icon-color]',
    },
  },
});

const isCustom = computed(() => Number.isInteger(props.size));

const styles = computed(() => ({
  '--custom-icon-size': isCustom.value ? `${props.size}px` : undefined,
  '--custom-icon-color': props.color,
}));

const classes = computed(() =>
  iconVariantStyleConfig({
    size: props.size,
    isCustom: isCustom.value,
    hasColor: Boolean(props.color),
  }),
);
</script>

<template>
  <Icon mode="svg" height="none" :style="styles" :class="classes" :icon="icon" />
</template>
