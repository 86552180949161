/**
 * Do not edit directly
 * Generated on Sat, 30 Mar 2024 00:02:48 GMT
 */

export const LsdsCSidebarDrawerColorSurface = "#9d6942";
export const LsdsCSidebarDrawerIconColorOnSurface = "#f4e0d1";
export const LsdsCSidebarDrawerIconColorOnHover = "#faf0e8";
export const LsdsCSidebarDrawerIconColorOnSelected = "#ffffff";
export const LsdsCSidebarDrawerListItemBackgroundColorOnHover = "#94613b";
export const LsdsCSidebarDrawerListItemBackgroundColorOnFocus = "#8f9fe7";
export const LsdsCSidebarDrawerListItemBackgroundColorOnSelected = "#8d5b35";
export const LsdsCSidebarDrawerListItemColorOnSurface = "#ffffff";
export const LsdsCSidebarDrawerListItemColorOnHover = "#ffffff";
export const LsdsCSidebarDrawerListItemColorOnSelected = "#ffffff";
export const LsdsCSidebarDrawerQuickAddColorSurface = "#ffffff";
export const LsdsCSidebarDrawerQuickAddColorSurfaceHover = "#edeff5";
export const LsdsCSidebarDrawerQuickAddColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerAddYourTeamColorSurface = "#9b4d13";
export const LsdsCSidebarDrawerUpgradeNowColorSurface = "#8d5b35";
export const LsdsCSidebarDrawerNotificationsBadgeColorSurface = "#ffffff";
export const LsdsCSidebarDrawerNotificationsBadgeColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerChatBadgeColorSurface = "#ffffff";
export const LsdsCSidebarDrawerChatBadgeColorOnSurface = "#0b0f1f";
export const LsdsCSidebarDrawerClosePanelIconColorSurface = "#0c0e1f";
export const LsdsCSidebarDrawerClosePanelIconColorOnSurface = "#eeeeee";
export const LsdsCSidebarDrawerClosePanelIconColorOnHover = "#0d0f2f";
export const LsdsCSidebarDrawerProfileColorOnSurface = "#ffffff";
export const LsdsCSidebarDrawerProfileColorOnSurfaceSubtle = "#ffffff";
