import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';
import { useCurrentUser } from '../user/useCurrentUser';

/**
 * Constants, computed properties and functions related to experiment 23-A29
 * https://digitalcrew.teamwork.com/spaces/growth/page/44778-a-23-29-personalised-worker-bee-onboarding
 * https://app.launchdarkly.com/default/staging/features/projects-exp-a-23-29-personalised-worker-bee-onboarding
 */
export function useExperimentA29() {
  const { personalizedWorkerBeeOnboardingEnabled } = useFeatures();
  const user = useCurrentUser();
  const { trackExperimentInPendo } = usePendo();
  const WORKER_BEE_ROLES = [5, 6];
  const EXP_A29_COMMON_METRICS = ['account_id', 'on_trial', 'plan_name', 'user_role', 'page'];
  const ignoreInExperimentOnRuleMatch = true;
  const { launchDarklyFlagKey, defaultValue } = personalizedWorkerBeeOnboardingEnabled;

  const expA29AppLevelTargeting = computed(
    () =>
      WORKER_BEE_ROLES.includes(user.value?.companyRoleId) &&
      !user.value?.siteOwner &&
      user.value?.inOwnerCompany &&
      !['contact', 'collaborator'].includes(user.value?.userType),
  );
  const isExpA29Variation = computed(
    () => personalizedWorkerBeeOnboardingEnabled.value && expA29AppLevelTargeting.value,
  );
  const isExpA29Control = computed(() => !isExpA29Variation.value);
  const firstLogin = computed(() => user.value?.loginCount <= 1);

  function trackExperimentA29() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: expA29AppLevelTargeting,
    });
  }

  return {
    EXP_A29_COMMON_METRICS,
    expA29AppLevelTargeting,
    isExpA29Variation,
    isExpA29Control,
    trackExperimentA29,
    firstLogin,
  };
}
