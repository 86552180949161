import { getLastChildPath } from '../lastChildPath';

export const everythingRoutes = [
  {
    path: '/everything',
    component: () => import('./RouteEverything.vue'),
    meta: {
      sampleProjectsBannerVisible: true,
    },
    children: [
      {
        path: '',
        redirect() {
          return `/everything/${getLastChildPath('everything', 'tasks')}`;
        },
      },
      {
        path: 'tasks',
        component: () => import('./RouteEverythingTasks.vue'),
      },
      {
        path: 'boards',
        component: () => import('./RouteEverythingBoards.vue'),
      },
      {
        path: 'completedTasks',
        component: () => import('./RouteEverythingCompletedTasks.vue'),
      },
      {
        path: 'milestones',
        component: () => import('./RouteEverythingMilestones.vue'),
        children: [
          {
            path: '',
            redirect() {
              return `/everything/milestones/${getLastChildPath('everything/milestones', 'all')}`;
            },
          },
          {
            path: ':milestoneTab(all|calendar|completed|late|upcoming)',
            component: () => import('./RouteEverythingMilestonesList.vue'),
            props: true,
          },
        ],
      },
      {
        path: 'messages',
        component: () => import('./RouteEverythingMessages.vue'),
      },
      {
        path: 'comments',
        component: () => import('./RouteEverythingComments.vue'),
      },
      {
        path: 'notebooks',
        component: () => import('./RouteEverythingNotebooks.vue'),
      },
      {
        path: 'files',
        component: () => import('./RouteEverythingFiles.vue'),
      },
      {
        path: 'links',
        component: () => import('./RouteEverythingLinks.vue'),
      },
      {
        path: 'time',
        redirect: '/time',
      },
      {
        path: 'risks',
        component: () => import('./RouteEverythingRisks.vue'),
      },
      {
        path: 'billing',
        component: () => import('./RouteEverythingBilling.vue'),
      },
      {
        path: ':pathMatch(.*)*',
        redirect() {
          return '/everything/tasks';
        },
      },
    ],
  },
];
