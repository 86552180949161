<script setup>
import { provideOnboardingTour } from '../tour/useOnboardingTour';
import { useOnboardingTourData } from '../tour/data/useOnboardingTourData';
import {
  useFeatures,
  useCurrentUser,
  useCurrentAccount,
  useExperimentA18,
  useExperimentA29,
  useExperimentA45,
  usePendo,
} from '@/api';
import { useLegacyBridge } from '@/module/legacy';

const OnboardingChecklistDrawer = defineAsyncComponent(() => import('./drawer/OnboardingChecklistDrawer.vue'));
const OnboardingChecklistDrawerActivator = defineAsyncComponent(
  () => import('./drawer/OnboardingChecklistDrawerActivator.vue'),
);
const OnboardingTourSheet = defineAsyncComponent(() => import('../tour/OnboardingTourSheet.vue'));

const { activeProductTourId, incompleteItems } = provideOnboardingTour();
const account = useCurrentAccount();
const user = useCurrentUser();
const {
  projectTimeBudgetsEnabled,
  projectFinancialBudgetsEnabled,
  utilizationReportEnabled,
  workloadPlannerEnabled,
  lightspeedOnboardingChecklistEnabled,
} = useFeatures();
const { onboardingTourData: tours } = useOnboardingTourData();
const { isExpA18Variation } = useExperimentA18();
const { isExpA29Variation, trackExperimentA29 } = useExperimentA29();
const { invitedUserFirst30Days, isExpA45Variation } = useExperimentA45();
const { trackPendoEvent } = usePendo();
const { toasts } = useLsToast();
const { isLegacyModalOpen } = useLegacyBridge();

const showCheckList = ref(false);

const isAdminTrialUser = computed(() => {
  return (
    user.value.administrator &&
    user.value.inOwnerCompany &&
    // account is in free trial state
    account.value.trialDaysRemaining > 0 &&
    account.value.paymentStatus !== 'paid'
  );
});

const productOutcomeFlowEnabled = computed(() => {
  return (
    projectFinancialBudgetsEnabled.value &&
    projectTimeBudgetsEnabled.value &&
    workloadPlannerEnabled.value &&
    utilizationReportEnabled.value &&
    lightspeedOnboardingChecklistEnabled.value
  );
});

const showChecklistFlow = computed(() => {
  if (isExpA18Variation.value || isExpA29Variation.value || (isExpA45Variation.value && invitedUserFirst30Days.value)) {
    return !activeProductTourId.value;
  }
  return !activeProductTourId.value && productOutcomeFlowEnabled.value && isAdminTrialUser.value;
});

const showOnboardingChecklistDrawerActivator = computed(() => {
  return !toasts.value.length && !isLegacyModalOpen.value;
});

const showChecklistButton = computed(() => {
  return showChecklistFlow.value && !showCheckList.value;
});

const showChecklistDrawer = computed(() => {
  return showChecklistFlow.value && showCheckList.value;
});

const currentTour = computed(() => Object.values(tours).find((tour) => tour.id === activeProductTourId.value));

function trackOnboardingChecklistOpened() {
  trackPendoEvent({
    eventName: 'ONBOARDING_CHECKLIST_EVENT',
    commonMetrics: ['plan_name', 'user_role', 'page'],
    metadata: {
      event_action: 'onboarding_checklist_opened',
    },
  });
}

function handleOnboardingChecklistOpened() {
  trackOnboardingChecklistOpened();
  showCheckList.value = true;
}

onMounted(() => {
  trackExperimentA29();
});
</script>
<template>
  <OnboardingTourSheet v-if="currentTour" :tour="currentTour" />
  <!-- match TWA Onboarding, the button-container class is used as the target element by pendo for guides currently -->
  <section class="button-container fixed bottom-8 right-6 z-20 p-1">
    <OnboardingChecklistDrawerActivator
      v-if="showChecklistButton"
      v-show="showOnboardingChecklistDrawerActivator"
      class="d-none d-mflex"
      :remainingTasks="incompleteItems.length"
      @click="handleOnboardingChecklistOpened"
    />
  </section>
  <OnboardingChecklistDrawer v-if="showChecklistDrawer" v-model="showCheckList" />
</template>
